import React, { useMemo } from 'react';
import { useStore } from 'effector-react';

import Button from '../../../Components/Button';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton';
import SwitcherQueque from '../../../Components/SwitcherQueque';
import { $userRoles } from '../../../Store/authStore';
import { RolesList } from '../../PageAdmin/PageAdmin.interface';
import { PanelTableHeaderProps } from '../PageLaboratoryTests.interface';

import styles from '../PageLaboratoryTests.module.scss';

function PanelTableHeader(props: PanelTableHeaderProps) {
  const { onExport, onTestCreate, activeQueue, onChangeQueue, queueCount } =
    props;

  const currentUserRoles: number[] = useStore($userRoles);

  const isButtonShown: boolean = useMemo(
    () => currentUserRoles.includes(RolesList.ASSISTANT),
    [currentUserRoles],
  );

  return (
    <div className={styles.panel_table_header}>
      <div className={styles.panel_table_header__item}>
        <SwitcherQueque
          queueCount={queueCount}
          activeQueue={activeQueue}
          onChangeQueue={onChangeQueue}
        />
      </div>
      <div className={styles.panel_table_header__item}>
        {isButtonShown && (
          <Button onClick={onTestCreate} className={styles.create_button}>
            Ввести результаты исследования
          </Button>
        )}
        <IconButton
          iconName={IconsNames.ExportArrow}
          onClick={onExport}
          className={styles.export_button}
        />
      </div>
    </div>
  );
}

export default PanelTableHeader;
