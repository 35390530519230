import { useMemo } from 'react';
import { useStore } from 'effector-react';

import PageTable from '../../Pages/PageLaboratoryTests/components/PageTable';
import { tableColumnsList } from '../../Pages/PageLaboratoryTests/constants';
import {
  $count,
  $limit,
  $offset,
  $researchReports,
  deleteResearchReportFx,
  getResearchClassifiersByIdFx,
  setModalIsInEditMode,
  setOffset,
  spreadDataToModalForm,
} from '../../Store/researchReportsPageStore';

import { PageResearchReportsContainerProps } from './ResearchReportsContainers.interface';

function ReportsTableContainer({
  onTestEdit,
  onTestCreate,
}: PageResearchReportsContainerProps) {
  const offset: number = useStore($offset);
  const count: number = useStore($count);
  const limit: number = useStore($limit);
  const reportsList = useStore($researchReports);

  const handleShowMoreButtonClick = () => {
    setOffset(offset + limit);
  };
  const handleResearchDelete = (id: number) => {
    deleteResearchReportFx(id);
  };

  const handleResearchEdit = (id: number) => {
    getResearchClassifiersByIdFx(id).then(() => {
      spreadDataToModalForm(id);
      setModalIsInEditMode(true);
      onTestEdit();
    });
  };

  const isShowMoreButtonVisible: boolean = useMemo(
    () => reportsList?.length < count,
    [reportsList, count],
  );

  return (
    <PageTable
      tableColumnsList={tableColumnsList}
      reportsList={reportsList}
      onResearchDelete={handleResearchDelete}
      onResearchEdit={handleResearchEdit}
      onShowMoreButtonClick={handleShowMoreButtonClick}
      isShowMoreButtonVisible={isShowMoreButtonVisible}
      onTestCreate={onTestCreate}
    />
  );
}

export default ReportsTableContainer;
