import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useStore } from 'effector-react';

import Button from '../../../Components/Button/Button';
import Icon from '../../../Components/Icon';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton/IconButton';
import IconWarningSection from '../../../Components/IconWarningSection';
import { InputTypes } from '../../../Components/Input/constants';
import Input from '../../../Components/Input/Input';
import Popover from '../../../Components/Popover';
import {
  PopoverPlacements,
  PopoverTriggerEvents,
} from '../../../Components/Popover/constants';
import { $userRoles } from '../../../Store/authStore';
import { RolesList } from '../../PageAdmin/PageAdmin.interface';
import { WaterTypes } from '../../PageLaboratoryTests/PageLaboratoryTests.interface';
import {
  standardTableColumns,
  standardTableColumnsShort,
  standardTableColumnsShortest,
} from '../constants';
import {
  StandardClassifierDataType,
  StandardTableSectionProps,
} from '../PageStandards.interface';

import styles from '../PageStandards.module.scss';

function StandardTableSection(props: StandardTableSectionProps) {
  const {
    standardData,
    isEditActive,
    onDeleteButtonClick,
    onEditButtonClick,
    onEditCancel,
    onEditSave,
    onStandardChange,
    onClassifierAdd,
  } = props;

  const {
    name,
    lastUpdate,
    lastUpdateAuthor,
    standardType,
    classifiers,
    isConst,
    isHistory,
  } = standardData;

  const currentUserRoles: number[] = useStore($userRoles);

  const [columnsList, setColumnsList] =
    useState<string[]>(standardTableColumns);

  const updateColumnsList = () => {
    if (window.innerWidth <= 1450) {
      setColumnsList(standardTableColumnsShortest);
    } else if (window.innerWidth <= 1700) {
      setColumnsList(standardTableColumnsShort);
    } else {
      setColumnsList(standardTableColumns);
    }
  };

  const handleClassifierValueChange =
    (classifierId: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value, name: cellName } = event.target;
      const classifierValue = value === '' ? null : value;
      const classifiersList: StandardClassifierDataType[] = classifiers.map(
        (classifier: StandardClassifierDataType) => {
          if (classifier.id === classifierId) {
            return {
              ...classifier,
              [cellName]: classifierValue,
            };
          }
          return { ...classifier };
        },
      );
      onStandardChange({
        ...standardData,
        classifiers: classifiersList,
      });
    };

  const handleStandardNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onStandardChange({ ...standardData, name: value });
  };

  const handleClassifierDelete = (classifierId: number) => () => {
    const classifiersList: StandardClassifierDataType[] = classifiers.filter(
      (classifier: StandardClassifierDataType) =>
        classifier.id !== classifierId,
    );
    onStandardChange({
      ...standardData,
      classifiers: classifiersList,
    });
  };

  const renderClassifierValue = (
    classifierId: number,
    value: number,
    valueName: string,
  ) => {
    return isEditActive ? (
      <Input
        value={value}
        type={InputTypes.Number}
        onChange={handleClassifierValueChange(classifierId)}
        className={styles.standard_input}
        name={valueName}
      />
    ) : (
      value?.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || '-'
    );
  };

  const standardTypeText: string = useMemo(() => {
    switch (standardType) {
      case WaterTypes.Potable:
        return 'ПИТЬЕВОЙ';
      case WaterTypes.Surface:
        return 'ПОВЕРХНОСТНЫЙ';
      case WaterTypes.Intermediate:
        return 'ПРОМЕЖУТОЧНЫЙ';
      default:
        return '';
    }
  }, [standardType]);

  const tableHeaderColumns: JSX.Element[] = useMemo(
    () =>
      columnsList.map((column: string) => (
        <div key={column} className={styles.table_header_column}>
          {column}
        </div>
      )),
    [columnsList],
  );

  const tableRows: JSX.Element[] = useMemo(
    () =>
      classifiers.map((classifier: StandardClassifierDataType) => {
        const {
          id: classifierId,
          name: classifierName,
          units,
          lsl,
          usl,
          lcl,
          ucl,
          description,
        } = classifier;

        return (
          <div className={styles.table_row} key={classifierId}>
            <div className={styles.table_cell}>
              <span
                className={styles.standard_name_cell}
              >{`${classifierName}, ${units}`}</span>
              {description && (
                <Popover
                  placement={PopoverPlacements.Right}
                  trigger={PopoverTriggerEvents.Hover}
                  content={<div>{description}</div>}
                >
                  <span className={styles.hint_icon}>
                    <Icon name={IconsNames.Help} />
                  </span>
                </Popover>
              )}
            </div>
            <div className={styles.table_cell}>
              {renderClassifierValue(classifierId, lsl, 'lsl')}
            </div>
            <div className={styles.table_cell}>
              {renderClassifierValue(classifierId, lcl, 'lcl')}
            </div>
            <div className={styles.table_cell}>
              {renderClassifierValue(classifierId, ucl, 'ucl')}
            </div>
            <div className={styles.table_cell}>
              {renderClassifierValue(classifierId, usl, 'usl')}
              {isEditActive && (
                <IconButton
                  iconName={IconsNames.Delete}
                  onClick={handleClassifierDelete(classifierId)}
                  className={styles.delete_classifier_button}
                  isClear
                />
              )}
            </div>
          </div>
        );
      }),
    [classifiers, renderClassifierValue, isEditActive],
  );

  const isEditButtonShown: boolean = useMemo(
    () =>
      !(isEditActive || isConst || isHistory) &&
      currentUserRoles.includes(RolesList.ADMIN),
    [isConst, isEditActive, isHistory, currentUserRoles],
  );
  const isDeleteButtonShown: boolean = useMemo(
    () => !(isConst || isHistory) && currentUserRoles.includes(RolesList.ADMIN),
    [isConst, isEditActive, isHistory, currentUserRoles],
  );
  const isEditDisabledHintShown: boolean = useMemo(() => isConst, [isConst]);
  const isWarningShown: boolean = useMemo(
    () => !isEditActive && classifiers.length === 0,
    [classifiers, isEditActive],
  );

  useEffect(() => {
    updateColumnsList();
    window.addEventListener('resize', updateColumnsList);

    return () => {
      window.removeEventListener('resize', updateColumnsList);
    };
  }, []);

  return (
    <div className={styles.standard_table_section}>
      <div className={styles.section_header}>
        <div className={styles.left_side_content}>
          <div className={clsx(styles.standard_type, styles[standardType])}>
            {standardTypeText}
          </div>
          <div className={styles.standard_name}>
            {isEditActive ? (
              <Input
                value={name}
                onChange={handleStandardNameChange}
                type={InputTypes.Text}
                className={styles.standard_name_input}
              />
            ) : (
              name
            )}
          </div>
          {isEditDisabledHintShown && (
            <Popover
              trigger={PopoverTriggerEvents.Hover}
              placement={PopoverPlacements.Top}
              content={
                <div className={styles.edit_disabled_hint_message}>
                  Редактирование базовых стандартов запрещено
                </div>
              }
            >
              <div className={styles.edit_disabled_hint}>
                <Icon
                  name={IconsNames.EditDisabled}
                  className={styles.edit_disabled_hint_icon}
                />
              </div>
            </Popover>
          )}
          {isEditButtonShown && (
            <button
              type="button"
              className={styles.edit_button}
              onClick={onEditButtonClick}
            >
              Редактировать
            </button>
          )}
          {isDeleteButtonShown && (
            <button
              type="button"
              className={styles.delete_button}
              onClick={onDeleteButtonClick}
            >
              Удалить
            </button>
          )}
        </div>
        <div className={styles.right_side_content}>
          <span>Последнее изменение:</span>
          <span>{lastUpdate}</span>
          <span>{lastUpdateAuthor}</span>
        </div>
      </div>
      {isWarningShown ? (
        <IconWarningSection
          iconName={IconsNames.WaterDrop}
          message="Показатели не добавлены"
        />
      ) : (
        <div className={styles.table_header}>{tableHeaderColumns}</div>
      )}

      <div
        className={clsx(styles.table_body, {
          [styles.edit_active]: isEditActive,
        })}
      >
        {tableRows}
      </div>
      {isEditActive && (
        <div
          className={clsx(styles.add_classifier_button_row, {
            [styles.edit_active]: isEditActive,
          })}
        >
          <button
            type="button"
            className={styles.add_classifier_button}
            onClick={onClassifierAdd}
          >
            Добавить новый показатель
          </button>
        </div>
      )}
      {isEditActive && (
        <div className={styles.save_standard_buttons_row}>
          <Button
            onClick={onEditCancel}
            isSecondary
            className={styles.save_standard_button}
          >
            Отменить редактирование
          </Button>
          <Button onClick={onEditSave} className={styles.save_standard_button}>
            Сохранить изменения
          </Button>
        </div>
      )}
    </div>
  );
}

export default StandardTableSection;
