import format from 'date-fns/format';
import getYear from 'date-fns/getYear';
import ru from 'date-fns/locale/ru';

import capitalizeFirstLetter from '../../../Utils/capitalizeFirstLetter';
import { DateFormats, DatePickerTypes } from '../DatePicker.interface';

export function getInputContent(
  type: DatePickerTypes,
  selectedDate: Date,
  startDate: Date,
  endDate: Date,
) {
  const DATE_FORMAT = DateFormats.ddMMyyyy;
  const DATE_TIME_FORMAT = DateFormats.ddMMyyyyHHmm;

  switch (type) {
    case DatePickerTypes.SingleDate:
      return format(selectedDate, DATE_FORMAT);
    case DatePickerTypes.SingleDateTime:
      return format(selectedDate, DATE_TIME_FORMAT);
    case DatePickerTypes.DateRange:
      if (endDate) {
        return `${format(startDate, DATE_FORMAT)} - ${format(
          endDate,
          DATE_FORMAT,
        )}`;
      }
      return `${format(startDate, DATE_FORMAT)} - ${format(
        startDate,
        DATE_FORMAT,
      )}`;
    case DatePickerTypes.MonthYear:
      return `${capitalizeFirstLetter(
        format(selectedDate, 'LLLL', { locale: ru }),
      )} ${getYear(selectedDate)}`;
    case DatePickerTypes.Year:
      return getYear(selectedDate);
    default:
      return '';
  }
}
