import React from 'react';
import cn from 'clsx';

import styles from './Skeleton.module.scss';

const TABLE_ROWS_COUNT = 12;
const TABLE_COLS_COUNT = 5;
const TABLE_ICONS_COUNT = 2;

function SkeletonPageTable() {
  const skeletonTableCells: JSX.Element[] = [];
  const skeletonTableRows: JSX.Element[] = [];
  const skeletonTableIcons: JSX.Element[] = [];

  for (let i = 0; i < TABLE_COLS_COUNT; i += 1) {
    skeletonTableCells.push(
      <div
        key={i}
        className={cn(styles.skeleton_table_cell, styles.skeleton_line)}
      />,
    );
  }
  for (let i = 0; i < TABLE_ICONS_COUNT; i += 1) {
    skeletonTableIcons.push(
      <div
        key={i}
        className={cn(styles.skeleton_table_icon, styles.skeleton_line)}
      />,
    );
  }
  for (let i = 0; i < TABLE_ROWS_COUNT; i += 1) {
    skeletonTableRows.push(
      <div key={i} className={styles.skeleton_table_row}>
        <div className={styles.cells_group}>{skeletonTableCells}</div>
        <div className={styles.icons_group}>{skeletonTableIcons}</div>
      </div>,
    );
  }

  return (
    <div className={styles.skeleton_table}>
      <div className={styles.skeleton_table_header}>
        <div className={styles.table_header_left}>
          <div className={styles.skeleton_line} />
          <div className={styles.skeleton_line} />
        </div>
        <div className={styles.table_header_right}>
          <div className={styles.skeleton_line} />
          <div className={styles.skeleton_line} />
        </div>
      </div>
      <div className={styles.skeleton_table_body}>{skeletonTableRows}</div>
    </div>
  );
}

export default SkeletonPageTable;
