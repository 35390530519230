import React, { useMemo } from 'react';

import Icon from '../../../Components/Icon';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton';
import Popover from '../../../Components/Popover';
import {
  PopoverPlacements,
  PopoverTriggerEvents,
} from '../../../Components/Popover/constants';
import {
  ClassifierDataType,
  StandardDefaultClassifiersListProps,
} from '../PageStandards.interface';

import styles from '../PageStandards.module.scss';

function StandardDefaultClassifiersList(
  props: StandardDefaultClassifiersListProps,
) {
  const { classifiersList, onClassifiersListChange } = props;

  const handleClassifierDelete = (classifierId: number) => () => {
    const classifiers: ClassifierDataType[] = classifiersList.filter(
      (classifier: ClassifierDataType) => classifier.id !== classifierId,
    );
    onClassifiersListChange(classifiers);
  };

  const classifiers: JSX.Element[] = useMemo(
    () =>
      classifiersList.map((classifierData: ClassifierDataType) => {
        const { id, description, units, name } = classifierData;

        return (
          <div className={styles.modal_classifiers_list_row} key={id}>
            {`${name}, ${units}`}
            {description && (
              <Popover
                placement={PopoverPlacements.Right}
                trigger={PopoverTriggerEvents.Hover}
                content={<div>{description}</div>}
              >
                <span className={styles.hint_icon}>
                  <Icon name={IconsNames.Help} />
                </span>
              </Popover>
            )}
            <IconButton
              iconName={IconsNames.Delete}
              onClick={handleClassifierDelete(id)}
              className={styles.delete_classifier_button}
              isClear
            />
          </div>
        );
      }),
    [classifiersList, onClassifiersListChange],
  );

  return (
    <div className={styles.modal_classifiers_list_wrapper}>{classifiers}</div>
  );
}

export default StandardDefaultClassifiersList;
