import { RegistrationRequestParamsType } from '../Store/types';

import { BackendRegistrationRequestParamsType } from './types';

export function revertSendRegistrationRequestAdapter(
  params: RegistrationRequestParamsType,
): BackendRegistrationRequestParamsType {
  const { waterCanalId, firstName, middleName, lastName, email, phone } =
    params;

  return {
    vodokanal_id: waterCanalId,
    first_name: firstName,
    last_name: lastName,
    middle_name: middleName,
    email,
    phone,
  };
}
