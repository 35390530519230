import React from 'react';
import ToggleComponent from 'rsuite/Toggle';

import { ToggleProps } from './Toggle.interface';

import styles from './Toggle.module.scss';

function Toggle(props: ToggleProps) {
  const { isChecked, isDisabled, onChange } = props;

  return (
    <ToggleComponent
      checked={isChecked}
      disabled={isDisabled}
      onChange={onChange}
      className={styles.toggle}
    />
  );
}

export default Toggle;
