export enum RolesList {
  ADMIN = 10,
  TECHNOLOGIST = 20,
  ASSISTANT = 30,
}

export type UserDataType = {
  id: number;
  initials: string;
  phone: string;
  email: string;
  hasLaboratoryAssistantRights: boolean;
  hasTechnologistRights: boolean;
  hasAdminRights: boolean;
};

export type RequestDataType = {
  id: number;
  fullName: string;
  phone: string;
  email: string;
};

export type PageAdminTableProps = {
  users: UserDataType[];
  onUserDelete: (user: UserToDelete) => void;
  onRoleChange: (id: number, roleId: number, isChecked: boolean) => void;
};
export type UserToDelete = {
  id: number;
  name: string;
};
export type PageAdminModalConfirmProps = {
  onConfirm: () => void;
  onClose: () => void;
  name: string;
};

export type PageAdminRequestsListProps = {
  requests: RequestDataType[];
  onAccept: (id: number) => void;
  onReject: (id: number) => void;
};
