export const reagentTableColumnsList: string[] = [
  'Реагент',
  'Расход в год, т',
  'Расход в месяц, т',
  'Расход в сутки, т',
  'Уд. стоимость, руб без НДС/т',
  'Опросный лист',
];

export const emptyValue: string = Number(0).toLocaleString('ru', {
  minimumFractionDigits: 2,
});
