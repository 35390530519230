export const phoneNumberRegExp =
  /^[(][0-9]{3}[)][ ][0-9]{3}[-][0-9]{2}[-][0-9]{2}$/;
export const emailRegExp = /^[\w-][\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const tinRegExp = /^(\d{10}|\d{12})$/;
export const passwordRegExp =
  /^(?=.*\d)(?=.*[a-zа-я])(?=.*[A-ZА-Я])(?=.*[!@#$%^&*])[0-9a-zA-Zа-яА-Я!@#$%^&*]{8,}$/;
export const nameRegExp = /^[a-zA-zА-Яа-я-]+$/;

// разрешенные символы для указанных полей
export const numberInputRegExp = /[^e.,\-+]+/;
export const phoneInputRegExp = /^[0-9+()_\-\s]+$/;
export const numericInputRegExp = /^[0-9]+$/;
export const initialsInputRegExp = /^[А-Яа-яA-Za-z\s]+$/;

export function checkIsPhoneNumberValid(value: string) {
  return phoneNumberRegExp.test(value);
}
export function checkIsTINValid(value: string) {
  return tinRegExp.test(value);
}
export function checkIsEmailValid(value: string) {
  return emailRegExp.test(value);
}
export function checkIsPasswordValid(value: string) {
  return passwordRegExp.test(value);
}
export function checkIsPasswordConfirmValid(
  password: string,
  passwordConfirm: string,
) {
  return password === passwordConfirm;
}
export function checkIsNameValid(value: string) {
  return nameRegExp.test(value);
}

export function checkIsPhoneSymbolValid(value: string) {
  return numericInputRegExp.test(value);
}
export function checkIsNumberSymbolValid(value: string) {
  return numberInputRegExp.test(value);
}
