import { forwardRef, Fragment, LegacyRef } from 'react';
import Xarrow, { Xwrapper } from 'react-xarrows';

import { ArrowPositions } from '../../Pages/PageMapping/PageMapping.interface';
import Checkbox from '../Checkbox/Checkbox';
import { IconsNames } from '../Icon/constants';
import Icon from '../Icon/Icon';

import ModalProps from './Modal.interface';

import styles from './MonitoringModalSettings.module.scss';

const MonitoringModalSettings = forwardRef(
  (
    { settings, onChangeSettings }: ModalProps,
    ref: LegacyRef<HTMLDivElement>,
  ) => {
    const { potable, surface } = settings;

    const handleCheckboxChange = (subType: string) => {
      onChangeSettings(subType);
    };

    const POTABLE_ID = 'settings-potable';
    const SURFACE_ID = 'settings-surface';

    return (
      <div className={styles.settings_wrapper} ref={ref}>
        <div className={styles.title}> Настройка отображения</div>
        <Xwrapper>
          <div className={styles.xwrapper}>
            <div className={styles.xwrapper__section}>
              <div className={styles.subitem_section}>
                {potable.map(({ title, isActive, subType }, index) => {
                  const itemId = POTABLE_ID + index;
                  const handleCheckboxChangePotable = () =>
                    handleCheckboxChange(subType);
                  return (
                    <Fragment key={itemId}>
                      <div className={styles.values_container} id={itemId}>
                        <Checkbox
                          checked={isActive}
                          className={styles.modal_warning_section_checkbox}
                          onChange={handleCheckboxChangePotable}
                          caption={title}
                        />
                      </div>
                      <Xarrow
                        start={POTABLE_ID}
                        end={itemId}
                        startAnchor={{
                          position: ArrowPositions.Left,
                          offset: { x: 7.5, y: -9 },
                        }}
                        endAnchor={ArrowPositions.Left}
                        color="#B3CDE0"
                        strokeWidth={1.4}
                        headSize={0}
                        tailSize={0}
                        path="grid"
                        headShape="circle"
                        tailShape="circle"
                        gridBreak="0"
                      />
                    </Fragment>
                  );
                })}
              </div>
              <div className={styles.values_container} id={POTABLE_ID}>
                <Checkbox
                  checked
                  disabled
                  className={styles.modal_warning_section_checkbox}
                  onChange={() => {}}
                  caption="Питьевой"
                />
              </div>
            </div>

            <div className={styles.xwrapper__section}>
              <div className={styles.subitem_section}>
                {surface.map(({ title, isActive, subType }, index) => {
                  const itemId = SURFACE_ID + index;
                  const handleCheckboxChangeSurface = () =>
                    handleCheckboxChange(subType);
                  return (
                    <Fragment key={itemId}>
                      <div className={styles.values_container} id={itemId}>
                        <Checkbox
                          checked={isActive}
                          className={styles.modal_warning_section_checkbox}
                          onChange={handleCheckboxChangeSurface}
                          caption={title}
                        />
                      </div>
                      <Xarrow
                        start={SURFACE_ID}
                        end={itemId}
                        startAnchor={{
                          position: ArrowPositions.Left,
                          offset: { x: 7.5, y: -9 },
                        }}
                        endAnchor={ArrowPositions.Left}
                        color="#B3CDE0"
                        strokeWidth={1.4}
                        headSize={0}
                        tailSize={0}
                        path="grid"
                        headShape="circle"
                        tailShape="circle"
                        gridBreak="0"
                      />
                    </Fragment>
                  );
                })}
              </div>
              <div className={styles.values_container} id={SURFACE_ID}>
                <Checkbox
                  checked
                  disabled
                  className={styles.modal_warning_section_checkbox}
                  onChange={() => {}}
                  caption="Поверхностный"
                />
              </div>
            </div>

            <Xarrow
              start={SURFACE_ID}
              end={POTABLE_ID}
              startAnchor={ArrowPositions.Left}
              endAnchor={ArrowPositions.Left}
              color="#B3CDE0"
              strokeWidth={1.4}
              headSize={0.01}
              tailSize={0}
              path="grid"
              headShape="circle"
              tailShape="circle"
              gridBreak="10"
            />
          </div>
        </Xwrapper>
        <div className={styles.info_container}>
          <Icon name={IconsNames.Info} />
          <div className={styles.info}>
            Для корректной работы приложения вы не можете изменить настройки
            отображения для питьевого и поверхностного ввода
          </div>
        </div>
      </div>
    );
  },
);

MonitoringModalSettings.displayName = 'MonitoringModalSettings';

export default MonitoringModalSettings;
