import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';

import AppRoutes from './Components/Routes/AppRoutes';
import { PathUrl } from './Components/Routes/AppRoutes.interface';
import TopBar from './Components/TopBar';
import ToastContainer from './Containers/ToastContainer';
import {
  $isAuthenticated,
  getCurrentUserFx,
  refreshTokenFx,
  setLocationPathname,
} from './Store/authStore';
import {
  getClassifiersListFx,
  getMeasuresListFx,
  getWaterCanalsListFx,
} from './Store/cataloguesStore';
import {
  checkForUpdates,
  getApiVersionInitialFx,
} from './Store/updatesCheckerStore';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useStore($isAuthenticated);
  const isTopBarHidden = useMemo(
    () =>
      [PathUrl.Login, PathUrl.Register, PathUrl.Loading].includes(
        location.pathname as PathUrl,
      ) || /\/change_password\/*/.test(location.pathname),
    [location],
  );
  const checkToken = () => {
    const refreshToken = localStorage.getItem('refresh');
    if (refreshToken) {
      setLocationPathname(location.pathname);
      navigate(PathUrl.Loading);
      refreshTokenFx({ refreshToken });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getClassifiersListFx();
      getMeasuresListFx();
      getCurrentUserFx().then(() => {
        getWaterCanalsListFx();
      });
    } else {
      checkToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    getApiVersionInitialFx();
    setInterval(() => {
      checkForUpdates();
    }, 600000);
  }, []);

  return (
    <div className="App">
      {!isTopBarHidden && <TopBar />}
      <AppRoutes />
      <ToastContainer />
    </div>
  );
}

export default App;
