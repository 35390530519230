import { useStore } from 'effector-react';

import { RangeType } from '../../Components/PeriodPickerSection/PeriodPickerSection.interface';
import { OptionType } from '../../Components/Select/Select.interface';
import PageMonitoringFilters from '../../Pages/PageMonitoring/components/PageMonitoringFilters';
import { $userConfig, updateSubTypesConfigFx } from '../../Store/authStore';
import {
  $dateRange,
  $periodType,
  $status,
  setDateRange,
  setPeriodType,
  setStatus,
} from '../../Store/monitoringPageStore';

function PageMonitoringFiltersContainer() {
  const periodType: OptionType = useStore($periodType);
  const status: OptionType = useStore($status);
  const dateRange: RangeType = useStore($dateRange);
  const userConfig = useStore($userConfig);

  const handleDateRangeChange = (range: RangeType) => {
    setDateRange(range);
  };

  const handleStatusChange = (option: OptionType) => {
    setStatus(option);
  };

  const handlePeriodTypeChange = (option: OptionType) => {
    setPeriodType(option);
  };

  const onChangeSettings = (subTypes: string[]) => {
    updateSubTypesConfigFx(subTypes);
  };

  return (
    <PageMonitoringFilters
      dateRange={dateRange}
      status={status}
      periodType={periodType}
      onPeriodTypeChange={handlePeriodTypeChange}
      onStatusChange={handleStatusChange}
      onDateRangeChange={handleDateRangeChange}
      subTypes={userConfig.subTypes ? (userConfig.subTypes as string[]) : []}
      onChangeSettings={onChangeSettings}
    />
  );
}

export default PageMonitoringFiltersContainer;
