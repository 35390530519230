import React, { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react';

import Button from '../../../Components/Button/Button';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton/IconButton';
import { InputTypes } from '../../../Components/Input/constants';
import Input from '../../../Components/Input/Input';
import Modal from '../../../Components/Modal/Modal';
import {
  defaultResetPasswordRequestFormValues,
  RegisterFieldsNames,
  RegisterFieldsNamesList,
} from '../constants';
import {
  ChangePasswordRequestModalProps,
  ValidatedFormValuesType,
} from '../PageAuth.interface';
import { checkIsValueValid } from '../utils/checkIsValueValid';

import styles from '../PageAuth.module.scss';

function ChangePasswordRequestModal(props: ChangePasswordRequestModalProps) {
  const { title, onSubmit, onClose } = props;

  const [isModalToBeClosed, setIsModalToBeClosed] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<ValidatedFormValuesType>(
    defaultResetPasswordRequestFormValues,
  );

  const handleCancelButtonClick = () => {
    setIsModalToBeClosed(true);
  };

  const handleConfirmButtonClick = () => {
    onSubmit(formValues.email.value, formValues.email.value);
  };

  const handleModalClose = () => {
    setIsModalToBeClosed(false);
    onClose();
  };

  const handleFormValuesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const isValueValid = checkIsValueValid(
      value,
      name as RegisterFieldsNamesList,
    );

    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value,
        isErrorShown: !isValueValid,
      },
    });
  };

  const isConfirmButtonDisabled: boolean = useMemo(
    () => !formValues.email.value || formValues.email.isErrorShown,
    [formValues],
  );

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isConfirmButtonDisabled) {
      handleConfirmButtonClick();
    }
  };

  return (
    <Modal
      isToBeClosed={isModalToBeClosed}
      onClose={handleModalClose}
      className={styles.password_change_request_modal}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <div className={styles.modal_title}>{title}</div>
          <IconButton
            iconName={IconsNames.Cross}
            isClear
            onClick={handleCancelButtonClick}
            className={styles.cross_button}
          />
        </div>
        <div className={styles.modal_form}>
          <div className={styles.modal_input_row}>
            <div className={styles.modal_input_label}>
              {RegisterFieldsNames.get(RegisterFieldsNamesList.Email)}
            </div>
            <Input
              type={InputTypes.Text}
              value={formValues.email.value}
              onChange={handleFormValuesChange}
              onKeyPress={handleKeyPress}
              name={RegisterFieldsNamesList.Email}
              className={styles.modal_input}
              isErrorShown={formValues.email.isErrorShown}
              errorMessage={formValues.email.errorMessage}
            />
          </div>
        </div>
        <div className={styles.buttons_row}>
          <Button
            className={styles.button}
            isSecondary
            onClick={handleCancelButtonClick}
          >
            Отменить
          </Button>
          <Button
            className={styles.button}
            onClick={handleConfirmButtonClick}
            isDisabled={isConfirmButtonDisabled}
          >
            Продолжить
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ChangePasswordRequestModal;
