import { AxiosResponse } from 'axios';
import { createEffect, createEvent, createStore } from 'effector';

import { fetchAdminDataAdapter } from '../Adapters/fetchAdminDataAdapter';
import { ToastTypes } from '../Components/Toast/Toast.interface';
import {
  acceptRegistrationRequest,
  changeUserRights,
  deleteUser,
  getUsersList,
  rejectRegistrationRequest,
} from '../DataAccessLayer/apiServices';
import {
  RequestDataType,
  UserDataType,
} from '../Pages/PageAdmin/PageAdmin.interface';

import { getCurrentUserFx } from './authStore';
import { setToastData } from './toastStore';
import { ChangeUserRightsParamsType } from './types';

const resetData = createEvent();

const setUsersList = createEvent<UserDataType[]>();
const $usersList = createStore<UserDataType[]>([])
  .on(setUsersList, (_, users: UserDataType[]) => users)
  .reset(resetData);

const setRequestsList = createEvent<RequestDataType[]>();
const $requestsList = createStore<RequestDataType[]>([])
  .on(setRequestsList, (_, requests: RequestDataType[]) => requests)
  .reset(resetData);

const getAdminDataFx = createEffect<void, void, Error>();
const getAdminDataAction = async () => {
  await getUsersList()
    .then((response: AxiosResponse) => {
      const { users, requests } = fetchAdminDataAdapter(response.data);
      setUsersList(users);
      setRequestsList(requests);
    })
    .catch(() => {});
};

getAdminDataFx.use(getAdminDataAction);

const changeUserRightsFx = createEffect<
  ChangeUserRightsParamsType,
  void,
  Error
>();
const changeUserRightsAction = async (params: ChangeUserRightsParamsType) => {
  const { roleId, userId, toggle } = params;
  await changeUserRights(userId, roleId, toggle)
    .then(() => {
      getAdminDataFx();
      getCurrentUserFx();
    })
    .catch(() => {});
};
changeUserRightsFx.use(changeUserRightsAction);

const acceptRegistrationRequestFx = createEffect<number, void, Error>();
const acceptRegistrationRequestAction = async (id: number) => {
  await acceptRegistrationRequest(id)
    .then(() => {
      getAdminDataFx();
    })
    .catch(() => {
      setToastData({
        toastType: ToastTypes.Critical,
        title: 'Что-то пошло не так',
        message:
          'Не удалось принять заявку на регистрацию на регистрацию. Попробуйте ещё раз',
        isShown: true,
        closeDelay: 5000,
      });
    });
};

acceptRegistrationRequestFx.use(acceptRegistrationRequestAction);

const rejectRegistrationRequestFx = createEffect<number, void, Error>();
const rejectRegistrationRequestAction = async (id: number) => {
  await rejectRegistrationRequest(id)
    .then(() => {
      getAdminDataFx();
    })
    .catch(() => {
      setToastData({
        toastType: ToastTypes.Critical,
        title: 'Что-то пошло не так',
        message:
          'Не удалось отклонить заявку на регистрацию на регистрацию. Попробуйте ещё раз',
        isShown: true,
        closeDelay: 5000,
      });
    });
};

rejectRegistrationRequestFx.use(rejectRegistrationRequestAction);

const deleteUserFx = createEffect<number, void, Error>();
const deleteUserAction = async (id: number) => {
  await deleteUser(id)
    .then(() => {
      getAdminDataFx();
    })
    .catch(() => {
      setToastData({
        toastType: ToastTypes.Critical,
        title: 'Что-то пошло не так',
        message: 'Не удалось удалить пользователя. Попробуйте ещё раз',
        isShown: true,
        closeDelay: 5000,
      });
    });
};

deleteUserFx.use(deleteUserAction);

export {
  $requestsList,
  $usersList,
  acceptRegistrationRequestFx,
  changeUserRightsFx,
  deleteUserFx,
  getAdminDataFx,
  rejectRegistrationRequestFx,
  resetData,
  setRequestsList,
  setUsersList,
};
