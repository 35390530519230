import { AxiosError, AxiosResponse } from 'axios';
import { formatISO, parse, subHours } from 'date-fns';
import { createEffect, createEvent, createStore, sample } from 'effector';

import {
  adaptMonitoringReportById,
  fetchMonitoringReportsAdapter,
  fetchRecommendedReagentsAdapter,
} from '../Adapters/monitoringPageAdapters';
import { DateFormats } from '../Components/DatePicker/DatePicker.interface';
import { defaultPeriodTypeOption } from '../Components/PeriodPickerSection/constants';
import { RangeType } from '../Components/PeriodPickerSection/PeriodPickerSection.interface';
import { OptionType } from '../Components/Select/Select.interface';
import { ToastTypes } from '../Components/Toast/Toast.interface';
import { MonitoringReportsListParamsType } from '../Containers/PageMonitoringContainers/PageMonitoringContainers.interface';
import {
  addDose,
  deleteDose,
  editDose,
  getMonitoringReportById,
  getMonitoringReportsDetailed,
  getMonitoringReportsList,
  getRecommendedReagentsList,
  skipDose,
} from '../DataAccessLayer/apiServices';
import { defaultDateRange } from '../Pages/PageLaboratoryTests/constants';
import { statusesOptionsList } from '../Pages/PageMonitoring/constants';
import {
  ModalReagentDataType,
  MonitoringCardStructureType,
} from '../Pages/PageMonitoring/PageMonitoring.interface';
import { StatusesList } from '../Static/statusesList';
import handleError from '../Utils/handleError';

import { $queueNumber } from './cataloguesStore';
import { setIsLoading } from './loadingStore';
import { setToastData } from './toastStore';
import {
  AddDoseParamsType,
  EditDoseParamsType,
  MonitoringReportsListFxParams,
} from './types';

const DEFAULT_LIMIT: number = 5;

// filters

const resetFiltersData = createEvent();

const setStatus = createEvent<OptionType>();
const $status = createStore<OptionType>(statusesOptionsList[0])
  .on(setStatus, (_, status: OptionType) => status)
  .reset(resetFiltersData);

const setPeriodType = createEvent<OptionType>();
const $periodType = createStore<OptionType>(defaultPeriodTypeOption)
  .on(setPeriodType, (_, periodType: OptionType) => periodType)
  .reset(resetFiltersData);

const setDateRange = createEvent<RangeType>();
const $dateRange = createStore<RangeType>(defaultDateRange)
  .on(setDateRange, (_, newDateRange: RangeType) => newDateRange)
  .reset(resetFiltersData);

const setOffset = createEvent<number>();
const $offset = createStore<number>(0)
  .on(setOffset, (_, newOffset: number) => newOffset)
  .reset(resetFiltersData);

const setCount = createEvent<number>();
const $count = createStore<number>(0)
  .on(setCount, (_, count: number) => count)
  .reset(resetFiltersData);

const $limit = createStore<number>(DEFAULT_LIMIT);

// table
const resetMonitoringCardsList = createEvent();
const setMonitoringCardsList = createEvent<MonitoringCardStructureType[]>();
const $monitoringCardsList = createStore<MonitoringCardStructureType[]>([])
  .on(setMonitoringCardsList, (_, monitoringCardsList) => monitoringCardsList)
  .reset(resetMonitoringCardsList);

const setMonitoringCardsIdsList = createEvent<number[]>();
const $monitoringCardsIdsList = createStore<number[]>([])
  .on(
    setMonitoringCardsIdsList,
    (_, monitoringCardsIdsList: number[]) => monitoringCardsIdsList,
  )
  .reset(resetMonitoringCardsList);

const setOpenPanelsIdsList = createEvent<number[]>();
const $openPanelsIdsList = createStore<number[]>([])
  .on(
    setOpenPanelsIdsList,
    (_, openPanelsIdsList: number[]) => openPanelsIdsList,
  )
  .reset(resetMonitoringCardsList);

// reagents modal

const resetReagentsModal = createEvent();

const setIsReagentsModalShown = createEvent<boolean>();
const $isReagentsModalShown = createStore<boolean>(false)
  .on(
    setIsReagentsModalShown,
    (_, isReagentsModalShown: boolean) => isReagentsModalShown,
  )
  .reset(resetReagentsModal);

const setActiveCardId = createEvent<number>();
const $activeCardId = createStore<number>(null).on(
  setActiveCardId,
  (_, activeCardId: number) => activeCardId,
);

const $activeCardMinDate = createStore<Date>(null);
const setActiveCardMinDate = createEvent<Date>();
$activeCardMinDate.on(setActiveCardMinDate, (_, payload) => payload);

const setReagentsModalDate = createEvent<Date>();
const $reagentsModalDate = createStore<Date>(new Date())
  .on(setReagentsModalDate, (_, reagentsModalDate: Date) => reagentsModalDate)
  .reset(resetReagentsModal);

const setReagentsModalPerformance = createEvent<number | string>();
const $reagentsModalPerformance = createStore<number | string>(null)
  .on(
    setReagentsModalPerformance,
    (_, reagentsModalPerformance: number | string) => reagentsModalPerformance,
  )
  .reset(resetReagentsModal);

const setReagentsModalComment = createEvent<string>();
const $reagentsModalComment = createStore<string>(null)
  .on(
    setReagentsModalComment,
    (_, reagentsModalComment: string) => reagentsModalComment,
  )
  .reset(resetReagentsModal);

const setReagentsModalReagentsData = createEvent<ModalReagentDataType[]>();
const $reagentsModalReagentsData = createStore<ModalReagentDataType[]>([])
  .on(
    setReagentsModalReagentsData,
    (_, reagentsModalReagentsData: ModalReagentDataType[]) =>
      reagentsModalReagentsData,
  )
  .reset(resetReagentsModal);
const setReagentsModalReagentsDefaultData =
  createEvent<ModalReagentDataType[]>();
const $reagentsModalReagentsDefaultData = createStore<ModalReagentDataType[]>(
  [],
).on(
  setReagentsModalReagentsDefaultData,
  (_, reagentsModalReagentsDefaultData: ModalReagentDataType[]) =>
    reagentsModalReagentsDefaultData,
);

const getMonitoringReportsListFx = createEffect<
  MonitoringReportsListFxParams,
  void,
  Error
>();

const getMonitoringReportsListAction = async (
  data: MonitoringReportsListFxParams,
) => {
  const { params, previousValue, shouldReset, isInitialRequest } = data;
  if (isInitialRequest) {
    setIsLoading(true);
  }

  await getMonitoringReportsList(params)
    .then((response: AxiosResponse) => {
      const { data: researchReportsList } = response;
      const { cards: adaptedResearchReportsList, ids } =
        fetchMonitoringReportsAdapter(researchReportsList.research);

      setCount(researchReportsList.count);
      setMonitoringCardsIdsList(ids);
      if (shouldReset) {
        setMonitoringCardsList(adaptedResearchReportsList);
      } else {
        setMonitoringCardsList(
          previousValue.concat(adaptedResearchReportsList),
        );
      }
    })
    .catch((error: AxiosError) => {
      handleError({ error, request: getMonitoringReportsListFx, params: data });

      setMonitoringCardsList([]);
    })
    .finally(() => {
      if (shouldReset) {
        setIsLoading(false);
      }
    });
};

getMonitoringReportsListFx.use(getMonitoringReportsListAction);

const getFilteredMonitoringReportsListFx = createEffect<
  MonitoringReportsListFxParams,
  void
>().use(getMonitoringReportsListAction);

type RequestReportByIdParams = {
  id: number;
  listData: MonitoringCardStructureType[];
};

interface RequestReportDataByIdParams extends RequestReportByIdParams {
  modalReagentsData: ModalReagentDataType[];
}

const getMonitoringReportByIdFx = createEffect<
  RequestReportByIdParams,
  void,
  Error
>();

const getMonitoringReportByIdAction = async (
  params: RequestReportByIdParams,
) => {
  const { id, listData } = params;

  const cardById = listData
    .map((item, blockIndex) => {
      const { potableResearch, surfaceResearch } = item;

      const typeKeys = {
        potable: 'potableResearch',
        surface: 'surfaceResearch',
      };

      const { id: potableId, intermediates: potableIntermediates } =
        potableResearch || { id: -1, intermediates: [] };
      const { id: surfaceId, intermediates: surfaceIntermediates } =
        surfaceResearch;

      if (potableId === id) {
        return {
          data: potableResearch,
          index: null,
          blockIndex,
          typeKey: typeKeys.potable,
        };
      }
      const intermediatePotableIndex = potableIntermediates.findIndex(
        (subItem) => subItem.id === id,
      );
      if (intermediatePotableIndex !== -1) {
        return {
          data: potableIntermediates[intermediatePotableIndex],
          index: intermediatePotableIndex,
          blockIndex,
          typeKey: typeKeys.potable,
        };
      }

      if (surfaceId === id) {
        return {
          data: surfaceResearch,
          index: null,
          blockIndex,
          typeKey: typeKeys.surface,
        };
      }

      const intermediateSurfaceIndex = surfaceIntermediates.findIndex(
        (subItem) => subItem.id === id,
      );
      if (intermediateSurfaceIndex !== -1) {
        return {
          data: surfaceIntermediates[intermediateSurfaceIndex],
          index: intermediateSurfaceIndex,
          blockIndex,
          typeKey: typeKeys.surface,
        };
      }
      return {
        data: undefined,
      };
    })
    .filter((el) => el.data !== undefined)[0];

  getMonitoringReportById(id).then((reportResponse) => {
    getRecommendedReagentsList(id).then((recommendedResponse) => {
      const reagentsData = recommendedResponse.data;
      const adaptedData = adaptMonitoringReportById(
        reportResponse.data,
        formatISO(
          parse(cardById.data.datetime, DateFormats.ddMMyyyyHHmm, new Date()),
        ),
        reagentsData,
      );

      const stateCopy = [...listData];

      if (cardById.index === null) {
        stateCopy[cardById.blockIndex][cardById.typeKey] = {
          ...stateCopy[cardById.blockIndex][cardById.typeKey],
          ...adaptedData,
        };
      } else {
        stateCopy[cardById.blockIndex][cardById.typeKey].intermediates[
          cardById.index
        ] = {
          ...stateCopy[cardById.blockIndex][cardById.typeKey].intermediates[
            cardById.index
          ],
          ...adaptedData,
        };
      }

      setMonitoringCardsList(stateCopy);
    });
  });
};

getMonitoringReportByIdFx.use(getMonitoringReportByIdAction);

const $activeDoseId = createStore<number>(-1);
const setActiveDoseId = createEvent<number>();
$activeDoseId.on(setActiveDoseId, (_, payload) => payload);

const getMonitoringReportDataByIdAction = async (
  params: RequestReportDataByIdParams,
) => {
  const { id, listData, modalReagentsData } = params;

  const cardById = listData
    .map((item, blockIndex) => {
      const { potableResearch, surfaceResearch } = item;

      const typeKeys = {
        potable: 'potableResearch',
        surface: 'surfaceResearch',
      };

      const { id: potableId, intermediates: potableIntermediates } =
        potableResearch || { id: -1, intermediates: [] };
      const { id: surfaceId, intermediates: surfaceIntermediates } =
        surfaceResearch;

      if (potableId === id) {
        return {
          data: potableResearch,
          index: null,
          blockIndex,
          typeKey: typeKeys.potable,
        };
      }
      const intermediatePotableIndex = potableIntermediates.findIndex(
        (subItem) => subItem.id === id,
      );
      if (intermediatePotableIndex !== -1) {
        return {
          data: potableIntermediates[intermediatePotableIndex],
          index: intermediatePotableIndex,
          blockIndex,
          typeKey: typeKeys.potable,
        };
      }

      if (surfaceId === id) {
        return {
          data: surfaceResearch,
          index: null,
          blockIndex,
          typeKey: typeKeys.surface,
        };
      }

      const intermediateSurfaceIndex = surfaceIntermediates.findIndex(
        (subItem) => subItem.id === id,
      );
      if (intermediateSurfaceIndex !== -1) {
        return {
          data: surfaceIntermediates[intermediateSurfaceIndex],
          index: intermediateSurfaceIndex,
          blockIndex,
          typeKey: typeKeys.surface,
        };
      }
      return {
        data: undefined,
      };
    })
    .filter((el) => el.data !== undefined)[0];

  return getMonitoringReportById(id).then((response) => {
    const modalDate = parse(
      cardById.data.datetime,
      DateFormats.ddMMyyyyHHmm,
      new Date(),
    );

    const adaptedData = adaptMonitoringReportById(
      response.data,
      formatISO(modalDate),
    );
    // $reagentsModalDate;
    setReagentsModalDate(modalDate);

    // $reagentsModalComment;
    setReagentsModalComment(adaptedData.comment);

    // $reagentsModalPerformance;
    setReagentsModalPerformance(adaptedData.performance || 0);

    // reagentsModalData
    const matchedRecommendedAndDetailsReagentsData =
      adaptedData.reagents.length !== 0
        ? modalReagentsData.map((defaultData) => {
            const actualValue = adaptedData.reagents.find(
              (item) => item.id === defaultData.id,
            ).queues['1'].value;
            const actualDefaultData = defaultData.queues['1'];
            return {
              ...defaultData,
              queues: {
                1: {
                  ...actualDefaultData,
                  value: actualValue,
                  isRecommended: actualValue === actualDefaultData.value,
                  isErrorShown:
                    actualValue > actualDefaultData.maxValue ||
                    actualValue < actualDefaultData.minValue,
                },
              },
            };
          })
        : modalReagentsData;

    setReagentsModalReagentsData(matchedRecommendedAndDetailsReagentsData);

    // $activeCardId;
    setActiveCardId(id);

    setActiveDoseId(adaptedData.doseId);

    // $activeCardMinDate;
    const MINUS_HOURS_DIAPOSON = 4;
    setActiveCardMinDate(subHours(modalDate, MINUS_HOURS_DIAPOSON));
  });
};

const getMonitoringReportDataByIdFx = createEffect<
  RequestReportDataByIdParams,
  void
>();
getMonitoringReportDataByIdFx.use(getMonitoringReportDataByIdAction);

const requestReportDetailsById = createEvent<number>();
sample({
  clock: requestReportDetailsById,
  source: $monitoringCardsList,
  target: getMonitoringReportByIdFx,
  fn: (sourceParam, clockParam) => ({ id: clockParam, listData: sourceParam }),
});

export const requestReportDetailsDataById = createEvent<number>();
sample({
  clock: requestReportDetailsDataById,
  source: {
    monitoringCardsList: $monitoringCardsList,
    modalReagentsData: $reagentsModalReagentsData,
  },
  target: getMonitoringReportDataByIdFx,
  fn: (sourceParam, clockParam) => ({
    id: clockParam,
    listData: sourceParam.monitoringCardsList,
    modalReagentsData: sourceParam.modalReagentsData,
  }),
});

// show all detailed reports

const getMonitoringReportsDetailedFx = createEffect<
  MonitoringReportsListParamsType,
  void,
  Error
>();

const getMonitoringReportsDetailedAction = async (
  params: MonitoringReportsListParamsType,
) => {
  return getMonitoringReportsDetailed(params).then((response) => {
    const data = fetchMonitoringReportsAdapter(response.data.research);
    setMonitoringCardsList(data.cards);
    setCount(response.data.count);
  });
};

getMonitoringReportsDetailedFx.use(getMonitoringReportsDetailedAction);

// recommended reagents

const setIsWarningShown = createEvent<boolean>();
const $isWarningShown = createStore<boolean>(false).on(
  setIsWarningShown,
  (_, isWarningShown: boolean) => isWarningShown,
);
const setIsWarningCheckboxChecked = createEvent<boolean>();
const $isWarningCheckboxChecked = createStore<boolean>(false).on(
  setIsWarningCheckboxChecked,
  (_, isWarningCheckboxChecked: boolean) => isWarningCheckboxChecked,
);

const getRecommendedReagentsFx = createEffect<number, void, Error>();

const getRecommendedReagentsAction = async (id: number) => {
  await getRecommendedReagentsList(id)
    .then((response: AxiosResponse) => {
      const { data: recommendedReagentsList } = response;
      const adaptedReagentsList: ModalReagentDataType[] =
        fetchRecommendedReagentsAdapter(recommendedReagentsList);

      setReagentsModalReagentsData(adaptedReagentsList);
    })
    .catch((error: AxiosError) => {
      handleError({ error, request: getRecommendedReagentsFx });
    });
};

getRecommendedReagentsFx.use(getRecommendedReagentsAction);

// recommended reagents
const addDoseFx = createEffect<AddDoseParamsType, void, Error>();

const addDoseAction = async (params: AddDoseParamsType) => {
  await addDose(params)
    .then(() => {
      const { startDate: dateStart, endDate: dateEnd } = $dateRange.getState();
      const status = $status.getState().value;
      const queueNumber = $queueNumber.getState();
      const monitoringActionParams: MonitoringReportsListParamsType = {
        dateStart,
        dateEnd,
        limit: DEFAULT_LIMIT,
        offset: 0,
        status: status as StatusesList,
        queueNumber,
      };
      const monitoringFxParams: MonitoringReportsListFxParams = {
        params: monitoringActionParams,
        previousValue: [],
        shouldReset: true,
      };
      getMonitoringReportsListFx(monitoringFxParams).then(() => {
        getMonitoringReportById(params.researchId);
      });
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: addDoseFx,
        shouldShowToast: true,
        toastMessage: 'Не удалось ввести данные',
      });
    })
    .finally(() => {});
};

addDoseFx.use(addDoseAction);

const editDoseFx = createEffect<EditDoseParamsType, void, Error>();

const editDoseAction = async (params: EditDoseParamsType) => {
  await editDose(params)
    .then(() => {
      const { startDate: dateStart, endDate: dateEnd } = $dateRange.getState();
      const status = $status.getState().value;
      const queueNumber = $queueNumber.getState();
      const monitoringActionParams: MonitoringReportsListParamsType = {
        dateStart,
        dateEnd,
        limit: DEFAULT_LIMIT,
        offset: 0,
        status: status as StatusesList,
        queueNumber,
      };
      const monitoringFxParams: MonitoringReportsListFxParams = {
        params: monitoringActionParams,
        previousValue: [],
        shouldReset: true,
      };
      getMonitoringReportsListFx(monitoringFxParams);
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: editDoseFx,
        shouldShowToast: true,
        toastMessage: 'Не удалось отредактировать данные',
      });
    })
    .finally(() => {});
};

editDoseFx.use(editDoseAction);

const deleteDoseFx = createEffect<number, void, Error>();
const deleteDoseAction = async (id: number) => {
  await deleteDose(id)
    .then(() => {
      const { startDate: dateStart, endDate: dateEnd } = $dateRange.getState();
      const status = $status.getState().value;
      const queueNumber = $queueNumber.getState();
      const monitoringActionParams: MonitoringReportsListParamsType = {
        dateStart,
        dateEnd,
        limit: DEFAULT_LIMIT,
        offset: 0,
        status: status as StatusesList,
        queueNumber,
      };
      const monitoringFxParams: MonitoringReportsListFxParams = {
        params: monitoringActionParams,
        previousValue: [],
        shouldReset: true,
      };
      setOffset(0);
      getMonitoringReportsListFx(monitoringFxParams);
      setToastData({
        isShown: true,
        toastType: ToastTypes.Success,
        title: 'Ввод активных доз реагентов успешно удален',
        message: '',
        closeDelay: 5000,
      });
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: deleteDoseFx,
        params: id,
        shouldShowToast: true,
        toastMessage: 'Не удалось удалить ввод активных доз реагентов',
      });
    });
};
deleteDoseFx.use(deleteDoseAction);

const skipDoseFx = createEffect<number, void, Error>();

const skipDoseAction = async (id: number) => {
  await skipDose(id)
    .then(() => {
      const { startDate: dateStart, endDate: dateEnd } = $dateRange.getState();
      const status = $status.getState().value;
      const queueNumber = $queueNumber.getState();
      const monitoringActionParams: MonitoringReportsListParamsType = {
        dateStart,
        dateEnd,
        limit: DEFAULT_LIMIT,
        offset: 0,
        status: status as StatusesList,
        queueNumber,
      };
      const monitoringFxParams: MonitoringReportsListFxParams = {
        params: monitoringActionParams,
        previousValue: [],
        shouldReset: true,
      };
      getMonitoringReportsListFx(monitoringFxParams).then(() => {
        getMonitoringReportById(id);
      });
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: skipDoseFx,
        shouldShowToast: true,
        toastMessage: 'Не удалось ввести данные',
      });
    })
    .finally(() => {});
};
skipDoseFx.use(skipDoseAction);

export {
  $activeCardId,
  $activeCardMinDate,
  $activeDoseId,
  $count,
  $dateRange,
  $isReagentsModalShown,
  $isWarningCheckboxChecked,
  $isWarningShown,
  $limit,
  $monitoringCardsIdsList,
  $monitoringCardsList,
  $offset,
  $openPanelsIdsList,
  $periodType,
  $reagentsModalComment,
  $reagentsModalDate,
  $reagentsModalPerformance,
  $reagentsModalReagentsData,
  $reagentsModalReagentsDefaultData,
  $status,
  addDoseFx,
  deleteDoseFx,
  editDoseFx,
  getFilteredMonitoringReportsListFx,
  getMonitoringReportsDetailedFx,
  getMonitoringReportsListFx,
  getRecommendedReagentsFx,
  requestReportDetailsById,
  resetFiltersData,
  resetMonitoringCardsList,
  resetReagentsModal,
  setActiveCardId,
  setActiveCardMinDate,
  setCount,
  setDateRange,
  setIsReagentsModalShown,
  setIsWarningCheckboxChecked,
  setIsWarningShown,
  setMonitoringCardsIdsList,
  setMonitoringCardsList,
  setOffset,
  setOpenPanelsIdsList,
  setPeriodType,
  setReagentsModalComment,
  setReagentsModalDate,
  setReagentsModalPerformance,
  setReagentsModalReagentsData,
  setReagentsModalReagentsDefaultData,
  setStatus,
  skipDoseFx,
};
