import React, { MutableRefObject, useEffect, useRef } from 'react';
import clsx from 'clsx';

import useEventListeners from './hooks/useEventListeners';
import { DragScrollableContainerProps } from './DragScrollableContainer.interface';

import styles from './DragScrollableContainer.module.scss';

function DragScrollableContainer(props: DragScrollableContainerProps) {
  const { children, className, isDisabled, id, currentValue } = props;

  const containerRef: MutableRefObject<HTMLDivElement> = useRef(null);

  const { addListeners, removeListeners, handleScrollToMiddle } =
    useEventListeners(containerRef);

  useEffect(() => {
    if (isDisabled) {
      removeListeners();
    } else {
      addListeners();
    }
    return () => {
      removeListeners();
    };
  }, [containerRef, isDisabled, addListeners, removeListeners]);

  useEffect(() => {
    if (!isDisabled && containerRef.current) {
      handleScrollToMiddle();
    }
  }, [currentValue]);

  return (
    <div
      className={clsx(styles.draggable_container, className)}
      ref={containerRef}
      id={id}
    >
      <div className={styles.draggable_content}>{children}</div>
    </div>
  );
}

export default DragScrollableContainer;
