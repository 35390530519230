import React from 'react';
import clsx from 'clsx';

import styles from './Skeleton.module.scss';

function SkeletonNavBar() {
  return (
    <div className={styles.skeleton_nav_bar}>
      <div className={clsx(styles.skeleton_line, styles.logo)} />
      <div className={styles.nav_links_group}>
        <div className={styles.skeleton_line} />
        <div className={styles.skeleton_line} />
        <div className={styles.skeleton_line} />
        <div className={styles.skeleton_line} />
        <div className={styles.skeleton_line} />
        <div className={styles.skeleton_line} />
      </div>
      <div className={clsx(styles.skeleton_line, styles.icon)} />
    </div>
  );
}

export default SkeletonNavBar;
