import {
  RequestDataType,
  UserDataType,
} from '../Pages/PageAdmin/PageAdmin.interface';
import { RolesList } from '../Static/rolesList';
import { getFullName, getShortFullName } from '../Utils/getFullName';

import {
  AdminDataType,
  BackendAdminDataType,
  BackendEmployeeDataType,
  BackendRequestDataType,
  BackendRoleDataType,
} from './types';

function getEmployees(employees: BackendEmployeeDataType[]): UserDataType[] {
  return employees.map((employee: BackendEmployeeDataType) => {
    const { user_id: userId, role, info } = employee;

    const {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      phone,
      email,
    } = info;

    const initials: string = getShortFullName(firstName, middleName, lastName);

    const includedRoles: number[] = role
      .filter((roleItem: BackendRoleDataType) => roleItem.rights)
      .map((roleItem: BackendRoleDataType) => roleItem.role_id);

    const hasLaboratoryAssistantRights: boolean = includedRoles.includes(
      RolesList.ASSISTANT,
    );
    const hasTechnologistRights: boolean = includedRoles.includes(
      RolesList.TECHNOLOGIST,
    );
    const hasAdminRights: boolean = includedRoles.includes(RolesList.ADMIN);

    return {
      id: userId,
      initials,
      phone,
      email,
      hasAdminRights,
      hasLaboratoryAssistantRights,
      hasTechnologistRights,
    };
  });
}

function getRequests(
  requestsList: BackendRequestDataType[],
): RequestDataType[] {
  return requestsList.map((request: BackendRequestDataType) => {
    const {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      phone,
      email,
      login,
      id,
    } = request;
    const fullName: string = getFullName(firstName, middleName, lastName);

    return { id, fullName, phone, email, login };
  });
}

export function fetchAdminDataAdapter(
  adminData: BackendAdminDataType,
): AdminDataType {
  const { employees, requests: requestsList } = adminData;

  const users: UserDataType[] = getEmployees(employees);

  const requests: RequestDataType[] = getRequests(requestsList);

  return {
    users,
    requests,
  };
}
