import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import { WaterTypes } from '../../../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';
import Icon from '../../Icon';
import { IconsNames } from '../../Icon/constants';
import IconButton from '../../IconButton';
import Popover from '../../Popover';
import {
  PopoverPlacements,
  PopoverTriggerEvents,
} from '../../Popover/constants';
import { StandardCardProps } from '../StandardsTree.interface';

import ChevronButton from './ChevronButton';

import styles from '../StandardsTree.module.scss';

function StandardCard(props: StandardCardProps) {
  const {
    id,
    name,
    datetime,
    isActive,
    isHistory,
    isChild,
    areChildrenOpen,
    isHistoryOpen,
    hasChildren,
    hasHistory,
    standardType,
    author,
    isCopyDisabled,
    onCardClick,
    onChevronButtonClick,
    onHistoryButtonClick,
    onCopyButtonClick,
  } = props;

  const nameRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState<boolean>(false);

  const isBaseCard: boolean = useMemo(
    () => !(isHistory || isChild),
    [isHistory, isChild],
  );

  const standardTypeText: string = useMemo(() => {
    switch (standardType) {
      case WaterTypes.Potable:
        return 'ПИТ';
      case WaterTypes.Surface:
        return 'ПОВ';
      case WaterTypes.Intermediate:
        return 'ПРОМ';
      default:
        return '';
    }
  }, [standardType]);

  const handleCardClick = () => {
    onCardClick(id);
  };
  const handleChevronButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onChevronButtonClick(id);
  };
  const handleHistoryButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onHistoryButtonClick(id);
  };
  const handleCopyButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onCopyButtonClick(id);
  };

  const checkIsEllipsisActive = () => {
    const element = nameRef?.current;
    const isEllipsisActiveValue = element
      ? element.offsetWidth < element.scrollWidth ||
        element.offsetHeight < element.scrollHeight
      : false;

    setIsEllipsisActive(isEllipsisActiveValue);
  };

  useEffect(() => {
    checkIsEllipsisActive();
    window.addEventListener('resize', checkIsEllipsisActive);

    return () => {
      window.removeEventListener('resize', checkIsEllipsisActive);
    };
  }, []);

  return (
    <button
      type="button"
      className={clsx(
        styles.standard_card,
        {
          [styles.standard_card_active]: isActive,
        },
        {
          [styles.standard_card_history]: isHistory,
        },
        {
          [styles.standard_card_child]: isChild,
        },
      )}
      onClick={handleCardClick}
    >
      <div className={styles.standard_card_buttons_row}>
        {isBaseCard && (
          <ChevronButton
            onClick={handleChevronButtonClick}
            isOpen={areChildrenOpen}
            isDisabled={!hasChildren}
          />
        )}
        <div className={clsx(styles.standard_type, styles[standardType])}>
          {standardTypeText}
        </div>

        {isEllipsisActive ? (
          <Popover
            trigger={PopoverTriggerEvents.Hover}
            placement={PopoverPlacements.Top}
            content={<div>{name}</div>}
          >
            <div className={styles.standard_name} ref={nameRef}>
              {name}
            </div>
          </Popover>
        ) : (
          <div className={styles.standard_name} ref={nameRef}>
            {name}
          </div>
        )}

        {isBaseCard && (
          <IconButton
            iconName={IconsNames.Plus}
            onClick={handleCopyButtonClick}
            className={styles.add_copy_button}
            isClear
            isDisabled={isCopyDisabled}
          />
        )}
      </div>
      <div className={styles.standard_card_history_row}>
        <div className={styles.standard_card_date_author}>
          <Icon name={IconsNames.Clock} className={styles.clock_icon} />
          {datetime}
          {author && `, ${author}`}
        </div>
        {hasHistory && (
          <button
            type="button"
            className={styles.standard_card_history_button}
            onClick={handleHistoryButtonClick}
          >
            История
            <Icon
              name={IconsNames.Chevron}
              className={clsx(styles.history_button_icon, {
                [styles.history_open]: isHistoryOpen,
              })}
            />
          </button>
        )}
      </div>
    </button>
  );
}

export default StandardCard;
