import { useMemo } from 'react';
import clsx from 'clsx';

import { StatusesLabelsList, StatusesList } from '../../Static/statusesList';
import { IconsNames } from '../Icon/constants';
import Icon from '../Icon/Icon';

import { StatusesOptionLabelsProps } from './OptionLabels.interface';

import styles from './OptionLabels.module.scss';

function StatusesOptionLabels(props: StatusesOptionLabelsProps) {
  const { status } = props;

  const iconName: IconsNames = useMemo(() => {
    switch (status) {
      case StatusesList.Critical:
        return IconsNames.CircleCross;
      case StatusesList.Warning:
        return IconsNames.CircleWarning;
      case StatusesList.Success:
        return IconsNames.CircleCheck;
      case StatusesList.Pending:
        return IconsNames.CircleClock;
      case StatusesList.ToBeFilled:
        return IconsNames.CirclePlus;
      default:
        return IconsNames.CircleAll;
    }
  }, [status]);
  const optionText: string = useMemo(
    () => StatusesLabelsList.get(status),
    [status],
  );

  return (
    <div className={styles.option} title={optionText}>
      {iconName && (
        <Icon
          name={iconName}
          className={clsx(
            styles.option_icon,
            styles.status_icon,
            styles[status],
          )}
        />
      )}
      <div className={styles.option_text}>{optionText}</div>
    </div>
  );
}

export default StatusesOptionLabels;
