import { useMemo } from 'react';
import clsx from 'clsx';

import Icon from '../../../Components/Icon';
import { IconsNames } from '../../../Components/Icon/constants';
import LoaderDrop from '../../../Components/LoaderDrop';
import ComplianceGraph from '../../../Components/СomplianceGraph/ComplianceGraph';
import { StatusesList } from '../../../Static/statusesList';
import { propertiesTableColumnsList } from '../constants';
import { PanelBodyProps, PropertyType } from '../PageLaboratoryTests.interface';

import styles from '../PageLaboratoryTests.module.scss';

function PanelBody(props: PanelBodyProps) {
  const { propertiesList } = props;

  const getGraphControlValues = (
    value: number,
    minStandardValue: number,
    minControlValue: number,
    maxControlValue: number,
    maxStandardValue: number,
  ): number[] => {
    const initialControlValues = [
      value,
      minStandardValue,
      minControlValue,
      maxControlValue,
      maxStandardValue,
    ];
    const maxFiniteNumber = Math.max(
      ...initialControlValues.filter((item) => Number.isFinite(item)),
    );
    const graphControlValues = initialControlValues.map((item) =>
      Number.isFinite(item) ? item : maxFiniteNumber,
    );

    return graphControlValues;
  };

  const propertiesTableRows = useMemo(
    () =>
      propertiesList.map((property: PropertyType) => {
        const {
          id,
          name,
          value,
          previousValue,
          valueDifference,
          minControlValue,
          maxControlValue,
          minStandardValue,
          maxStandardValue,
          isAdditionalProperty,
          status,
        } = property;

        const propertyStatus: StatusesList = isAdditionalProperty
          ? null
          : status;
        const graphControlValues: number[] = getGraphControlValues(
          value,
          minStandardValue,
          minControlValue,
          maxControlValue,
          maxStandardValue,
        );
        return (
          <div
            className={clsx(styles.property_table_row, styles[propertyStatus])}
            key={id}
          >
            <div className={styles.property_column}>
              {isAdditionalProperty && (
                <Icon name={IconsNames.Save} className={styles.property_icon} />
              )}
              {name}
            </div>
            <div className={styles.property_column}>
              {value.toLocaleString()}
            </div>
            <div className={styles.property_column}>
              {previousValue?.toLocaleString()}
            </div>
            <div className={styles.property_column}>
              {valueDifference?.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <div className={styles.property_column}>
              {!isAdditionalProperty && (
                <ComplianceGraph
                  value={graphControlValues[0]}
                  minStandardValue={graphControlValues[1]}
                  minControlValue={graphControlValues[2]}
                  maxControlValue={graphControlValues[3]}
                  maxStandardValue={graphControlValues[4]}
                  status={status}
                />
              )}
            </div>
          </div>
        );
      }),
    [propertiesList],
  );

  const propertiesTableColumns = propertiesTableColumnsList.map(
    (column: string) => (
      <div className={styles.property_column} key={column}>
        {column}
      </div>
    ),
  );

  return (
    <div className={styles.panel_body}>
      {propertiesList.length !== 0 ? (
        <>
          <div className={styles.panel_body_header}>
            {propertiesTableColumns}
          </div>
          <div className={styles.panel_body_content}>{propertiesTableRows}</div>
        </>
      ) : (
        <LoaderDrop />
      )}
    </div>
  );
}

export default PanelBody;
