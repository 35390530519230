import React from 'react';
import clsx from 'clsx';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';

import capitalizeFirstLetter from '../../../Utils/capitalizeFirstLetter';
import { IconsNames } from '../../Icon/constants';
import IconButton from '../../IconButton';
import {
  DatePickerCalendarView,
  HeaderMonthsProps,
} from '../DatePicker.interface';

import styles from '../DatePicker.module.scss';

function HeaderMonths(props: HeaderMonthsProps) {
  const {
    calendarView,
    date,
    handleMonthClick,
    handleYearClick,
    handleDecreaseClick,
    handleIncreaseClick,
  } = props;

  return (
    <>
      <button
        type="button"
        className={clsx(
          styles.change_year_button,
          calendarView === DatePickerCalendarView.Years && styles.active,
        )}
        onClick={handleYearClick}
      >
        {format(date, 'y')}
      </button>
      <IconButton
        iconName={IconsNames.Chevron}
        onClick={handleYearClick}
        className={clsx(styles.change_year_chevron, {
          [styles.active]: calendarView === DatePickerCalendarView.Years,
        })}
        isClear
      />
      <button
        type="button"
        className={clsx(
          styles.change_year_button,
          calendarView === DatePickerCalendarView.Months && styles.active,
        )}
        onClick={handleMonthClick}
      >
        {capitalizeFirstLetter(format(date, 'LLLL', { locale: ru }))}
      </button>
      <IconButton
        iconName={IconsNames.Chevron}
        onClick={handleMonthClick}
        className={clsx(styles.change_year_chevron, {
          [styles.active]: calendarView === DatePickerCalendarView.Months,
        })}
        isClear
      />

      {calendarView !== DatePickerCalendarView.Months && (
        <div className={styles.change_year_buttons}>
          <IconButton
            iconName={IconsNames.Chevron}
            onClick={handleDecreaseClick}
            className={styles.change_month_chevron_left}
            isClear
          />
          <IconButton
            iconName={IconsNames.Chevron}
            onClick={handleIncreaseClick}
            className={styles.change_month_chevron_right}
            isClear
          />
        </div>
      )}
    </>
  );
}

export default HeaderMonths;
