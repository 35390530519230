import React, { useState } from 'react';
import clsx from 'clsx';

import Button from '../../../Components/Button';
import Checkbox from '../../../Components/Checkbox';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import { DatePickerTypes } from '../../../Components/DatePicker/DatePicker.interface';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton';
import { InputTypes } from '../../../Components/Input/constants';
import Input from '../../../Components/Input/Input';
import Modal from '../../../Components/Modal';
import Textarea from '../../../Components/Textarea/Textarea';
import { strangeValueWarningMessage } from '../constants';
import { PageMonitoringReagentsModalProps } from '../PageMonitoring.interface';

import PageMonitoringModalReagentsForm from './PageMonitoringModalReagentsForm';

import styles from '../PageMonitoring.module.scss';

function PageMonitoringReagentsModal(props: PageMonitoringReagentsModalProps) {
  const {
    date,
    performance,
    reagents,
    comment,
    isRecommendedValueChanged,
    isConfirmButtonDisabled,
    isWarningShown,
    isWarningCheckboxChecked,
    onWarningCheckboxValueChange,
    onCommentValueChange,
    onReagentsDataChange,
    onPerformanceValueChange,
    onDateChange,
    onConfirm,
    onClose,
    queueNumber,
    minDate,
  } = props;

  const [isModalToBeClosed, setIsModalToBeClosed] = useState<boolean>(false);

  const handleCancelButtonClick = () => {
    setIsModalToBeClosed(true);
  };
  const handleConfirmButtonClick = () => {
    setIsModalToBeClosed(true);
    onConfirm();
  };

  const handleModalClose = () => {
    setIsModalToBeClosed(false);
    onClose();
  };

  return (
    <Modal
      isToBeClosed={isModalToBeClosed}
      onClose={handleModalClose}
      className={styles.reagents_modal}
    >
      <div className={styles.reagents_modal_content}>
        <div className={styles.modal_header}>
          <div className={styles.modal_title}>
            Расчетные дозы реагентов
            <br />
            для очереди {queueNumber}
          </div>
          <IconButton
            iconName={IconsNames.Cross}
            isClear
            onClick={handleCancelButtonClick}
            className={styles.modal_cross_icon}
          />
        </div>
        <div className={styles.modal_body}>
          <div className={styles.modal_form_row}>
            <div className={styles.modal_form_row_title}>
              Дата и время введения доз реагентов:
            </div>
            <div className={styles.modal_form_datepicker}>
              <DatePicker
                type={DatePickerTypes.SingleDateTime}
                selectedDate={date}
                onSingleDateChange={onDateChange}
                minDate={minDate}
              />
            </div>
          </div>
          <div className={styles.modal_form_row}>
            <div className={styles.modal_form_row_title}>
              Производительность очереди, подъем воды, тыс.куб.м/сут:
            </div>
            <Input
              type={InputTypes.Number}
              value={performance}
              onChange={onPerformanceValueChange}
              className={styles.modal_form_performance_input}
            />
          </div>

          <PageMonitoringModalReagentsForm
            isWarningShown={isWarningShown}
            reagents={reagents}
            onReagentsDataChange={onReagentsDataChange}
          />
          <div
            className={clsx(
              styles.modal_form_row,
              styles.modal_form_comment_row,
            )}
          >
            {isRecommendedValueChanged && (
              <>
                <div
                  className={clsx(
                    styles.modal_form_row_title,
                    styles.modal_form_row_comment_title,
                  )}
                >
                  Комментарий
                </div>
                <Textarea
                  value={comment}
                  onChange={onCommentValueChange}
                  className={styles.modal_from_comment_textarea}
                  placeholder="Введите комментарий"
                />
              </>
            )}
          </div>
        </div>
        <div className={styles.modal_footer}>
          <div
            className={clsx(styles.modal_warning_section, {
              [styles.modal_warning_section_hidden]: !isWarningShown,
            })}
          >
            <Checkbox
              checked={isWarningCheckboxChecked}
              className={styles.modal_warning_section_checkbox}
              onChange={onWarningCheckboxValueChange}
            />
            <div className={styles.modal_warning_section_message}>
              {strangeValueWarningMessage}
            </div>
          </div>
          <div className={styles.reagents_modal_buttons_row}>
            <Button
              onClick={handleConfirmButtonClick}
              className={styles.reagents_modal_button}
              isDisabled={isConfirmButtonDisabled}
            >
              Сохранить расчеты
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PageMonitoringReagentsModal;
