import {
  ReportPropertiesType,
  ReportPropertyType,
} from '../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';

import { BackendClassifierDataType } from './types';

export function fetchClassifiersListAdapter(
  classifiers: BackendClassifierDataType[],
): ReportPropertiesType {
  const properties: ReportPropertiesType = {};
  classifiers.forEach((classifier: BackendClassifierDataType) => {
    const {
      id,
      name,
      measure_name: measureName,
      min_value: minValue,
      max_value: maxValue,
    } = classifier;
    const property: ReportPropertyType = {
      id,
      name,
      units: measureName,
      value: null,
      minValue,
      maxValue,
    };
    Object.assign(properties, { [id]: property });
  });

  return properties;
}
