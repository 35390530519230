import { useCallback, useEffect, useMemo } from 'react';
import format from 'date-fns/format';
import { useStore } from 'effector-react';

import Card from '../../Components/Card';
import { DateFormats } from '../../Components/DatePicker/DatePicker.interface';
import { RangeType } from '../../Components/PeriodPickerSection/PeriodPickerSection.interface';
import { OptionType } from '../../Components/Select/Select.interface';
import { SkeletonPage } from '../../Components/Skeleton';
import { StatusesList } from '../../Static/statusesList';
import { $userConfig, getCurrentUserFx } from '../../Store/authStore';
import { $queueNumber } from '../../Store/cataloguesStore';
import {
  $dateRange,
  $isReagentsModalShown,
  $limit,
  $monitoringCardsList,
  $offset,
  $status,
  getFilteredMonitoringReportsListFx,
  getMonitoringReportsListFx,
  resetFiltersData,
  resetMonitoringCardsList,
  setIsReagentsModalShown,
  setOffset,
  setOpenPanelsIdsList,
} from '../../Store/monitoringPageStore';
import { MonitoringReportsListFxParams } from '../../Store/types';

import {
  MonitoringReportsListParamsType,
  PageMonitoringContainerProps,
} from './PageMonitoringContainers.interface';
import PageMonitoringFiltersContainer from './PageMonitoringFiltersContainer';
import PageMonitoringHeaderContainer from './PageMonitoringHeaderContainer';
import PageMonitoringReagentsModalContainer from './PageMonitoringReagentsModalContainer';
import PageMonitoringTableContainer from './PageMonitoringTableContainer';

function PageMonitoringContainer({
  cardClassName,
}: PageMonitoringContainerProps) {
  const isReagentsModalShown: boolean = useStore($isReagentsModalShown);
  const isPageLoading: boolean = useStore(getMonitoringReportsListFx.pending);
  const dateRange: RangeType = useStore($dateRange);
  const { startDate, endDate } = dateRange;
  const status: OptionType = useStore($status);
  const offset: number = useStore($offset);
  const limit: number = useStore($limit);
  const monitoringReportsList = useStore($monitoringCardsList);
  const queueNumber = useStore($queueNumber);
  const userConfig = useStore($userConfig);

  const handleReagentsModalClose = () => {
    setIsReagentsModalShown(false);
  };

  const getReportsList = useCallback(
    (shouldReset: boolean, isInitialRequest: boolean) => {
      const params: MonitoringReportsListParamsType = {
        dateStart: startDate,
        dateEnd: endDate,
        limit,
        offset: shouldReset ? 0 : offset,
        status: status.value as StatusesList,
        queueNumber,
      };
      const functionData: MonitoringReportsListFxParams = {
        params,
        shouldReset,
        previousValue: monitoringReportsList,
        isInitialRequest,
      };
      if (isInitialRequest) {
        getMonitoringReportsListFx(functionData);
      } else {
        getFilteredMonitoringReportsListFx(functionData);
      }
    },
    [
      startDate,
      offset,
      endDate,
      status,
      limit,
      monitoringReportsList,
      queueNumber,
      userConfig,
    ],
  );

  const formattedDateRange = useMemo(() => {
    return {
      dateStart: startDate ? format(startDate, DateFormats.ddMMyyyy) : '',
      dateEnd: endDate ? format(endDate, DateFormats.ddMMyyyy) : '',
    };
  }, [startDate, endDate]);

  useEffect(() => {
    if (!!formattedDateRange.dateEnd === !!formattedDateRange.dateStart) {
      getReportsList(true, false);
      setOffset(0);
    }
  }, [
    formattedDateRange.dateEnd,
    formattedDateRange.dateStart,
    limit,
    status,
    queueNumber,
    userConfig,
  ]);

  useEffect(() => {
    if (offset !== 0) {
      getReportsList(false, false);
    }
  }, [offset]);

  useEffect(() => {
    setOpenPanelsIdsList([]);
  }, [queueNumber]);

  useEffect(() => {
    getReportsList(true, true);
    getCurrentUserFx();

    return () => {
      resetFiltersData();
      resetMonitoringCardsList();
    };
  }, []);

  return (
    <>
      {isPageLoading ? (
        <SkeletonPage />
      ) : (
        <>
          <PageMonitoringFiltersContainer />
          <Card className={cardClassName}>
            <PageMonitoringHeaderContainer />
            <PageMonitoringTableContainer />
          </Card>
        </>
      )}
      {isReagentsModalShown && (
        <PageMonitoringReagentsModalContainer
          onClose={handleReagentsModalClose}
        />
      )}
    </>
  );
}

export default PageMonitoringContainer;
