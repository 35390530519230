import clsx from 'clsx';

import { valueViaSubType } from '../../PageLaboratoryTests/constants';
import { columnsList } from '../constants';
import {
  PageMonitoringPanelHeaderProps,
  ReagentsInputTypes,
} from '../PageMonitoring.interface';

import styles from '../PageMonitoring.module.scss';

function PageMonitoringPanelHeader(props: PageMonitoringPanelHeaderProps) {
  const { inputType, datetime, author, name, subType } = props;

  const columnsValues = [
    datetime,
    author,
    name,
    inputType,
    valueViaSubType[subType],
  ];

  const actualColumnsList =
    subType !== null
      ? columnsList
      : columnsList.slice(0, columnsList.length - 1);

  const columns = actualColumnsList.map((title, index) => (
    <div key={index} className={styles.table_panel_header_column}>
      <div className={styles.table_panel_header_column_title}>{title}</div>
      <div>{columnsValues[index]}</div>
    </div>
  ));

  return (
    <div
      className={clsx(styles.table_panel_header, {
        [styles.primary]:
          inputType === ReagentsInputTypes.Potable ||
          inputType === ReagentsInputTypes.Surface,
      })}
    >
      {columns}
    </div>
  );
}

export default PageMonitoringPanelHeader;
