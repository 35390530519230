import { Effect } from 'effector';

import { OptionType } from '../Components/Select/Select.interface';
import { MonitoringReportsListParamsType } from '../Containers/PageMonitoringContainers/PageMonitoringContainers.interface';
import { ResearchReportsListParamsType } from '../Containers/ResearchReportsContainers/ResearchReportsContainers.interface';
import {
  IntermediateWaterSubTypes,
  ReportsDataType,
  WaterTypes,
} from '../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';
import {
  CardsConnectionDataType,
  PageMappingCardProps,
} from '../Pages/PageMapping/PageMapping.interface';
import {
  ModalReagentDataType,
  MonitoringCardStructureType,
} from '../Pages/PageMonitoring/PageMonitoring.interface';
import { StandardTableDataType } from '../Pages/PageStandards/PageStandards.interface';

export type ResponseType<ResultT> = {
  result: void | ResultT;
};

export type StandardsListType = {
  surface: OptionType[];
  potable: OptionType[];
  intermediate: OptionType[];
  filters: OptionType[];
  sump: OptionType[];
  post_potable: OptionType[];
  all: OptionType[];
};

export type UserDataType = {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  waterCanalId: number;
  roles: number[];
  config: {
    [key: string]: boolean | string[];
  };
};

export type UserConfigType = { [key: string]: boolean | string[] };

export type ResearchReportsListFxParams = {
  params: ResearchReportsListParamsType;
  previousValue: ReportsDataType[];
  shouldReset: boolean;
};

export type MonitoringReportsListFxParams = {
  params: MonitoringReportsListParamsType;
  previousValue: MonitoringCardStructureType[];
  shouldReset: boolean;
  isInitialRequest?: boolean;
};

export enum StandardBufferActions {
  StandardCopy = 'standardCopy',
  StandardCreate = 'standardCreate',
  StandardClick = 'standardClick',
  LocationChange = 'locationChange',
}

export type StandardBufferActionType = {
  actionType: StandardBufferActions;
  standardId: number;
  path: string;
  isWarningModalShown: boolean;
};

export type CreateClassifierParamsType = {
  measureId: number;
  name: string;
  description: string;
  standardData: StandardTableDataType;
  units: string;
};

export type CreateStandardParamsType = {
  standardType: WaterTypes | IntermediateWaterSubTypes;
  standardName: string;
  classifiers: Array<{ id: number }>;
};

export type LoginParamsType = {
  login: string;
  password: string;
};

export type RefreshTokenParamsType = {
  refreshToken: string;
  request?: Effect<any, any, Error>;
  params?: any;
};

export type AddDoseParamsType = {
  researchId: number;
  comment: string;
  performance: number;
  datetime: Date;
  reagents: ModalReagentDataType[];
};

export type EditDoseParamsType = {
  doseId: number;
  comment: string;
  performance: number;
  datetime: Date;
  reagents: ModalReagentDataType[];
};

export type Bounds = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};

export type ChangeUserRightsParamsType = {
  roleId: number;
  userId: number;
  toggle: boolean;
};

export type RegistrationRequestParamsType = {
  waterCanalId: number;
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
};

export type ReportsSynchDataType = {
  waterTreatment: number;
  timeLag: number;
  connections: CardsConnectionDataType[];
  cards: PageMappingCardProps[];
};

export type CreatePasswordChangeRequestParamsType = {
  email: string;
  login: string;
};

export type ChangePasswordParamsType = {
  password: string;
  token: string;
};

export type ChangeLinkingIntervalParamsType = {
  waterTreatment: number;
  timeLag: number;
  date: Date;
};

export type LinkingDataType = {
  doseId: number;
  researchId: number;
};

export type ChangeLinkingParamsType = {
  add?: LinkingDataType;
  delete?: LinkingDataType;
  date: Date;
};

export type SetVodokanalPredictionType = {
  queueNumber: number;
  predictionType: string;
};
