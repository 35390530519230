import React from 'react';

import Card from '../../Components/Card';
import PageReagentTableContainer from '../../Containers/PageReagentContainers/PageReagentTableContainer';

import styles from './PageReagents.module.scss';

function PageReagents() {
  return (
    <div className={styles.page_wrapper}>
      <Card className={styles.card}>
        <PageReagentTableContainer />
      </Card>
    </div>
  );
}

export default PageReagents;
