import React from 'react';

import CalendarContainer from '../components/CalendarContainer';
import CustomHeader from '../components/CustomHeader';
import {
  CalendarContainerProps,
  CustomHeaderRenderProps,
  DatePickerCalendarView,
  DatePickerTypes,
} from '../DatePicker.interface';

export function getRenderFunctions(
  calendarView: DatePickerCalendarView,
  type: DatePickerTypes,
  handleHeaderMonthClick: () => void,
  handleHeaderYearClick: () => void,
) {
  const renderCalendarContainer: React.FC = (props: CalendarContainerProps) => {
    const { children, className } = props;

    return (
      <CalendarContainer className={className} calendarView={calendarView}>
        {children}
      </CalendarContainer>
    );
  };

  const renderCustomHeader = (data: CustomHeaderRenderProps) => {
    const { date, increaseMonth, decreaseMonth, increaseYear, decreaseYear } =
      data;

    return (
      <CustomHeader
        date={date}
        increaseMonth={increaseMonth}
        increaseYear={increaseYear}
        decreaseYear={decreaseYear}
        decreaseMonth={decreaseMonth}
        handleMonthClick={handleHeaderMonthClick}
        handleYearClick={handleHeaderYearClick}
        calendarView={calendarView}
        type={type}
      />
    );
  };

  return { renderCalendarContainer, renderCustomHeader };
}
