import React, { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useStore } from 'effector-react';

import PageAdmin from '../../Pages/PageAdmin';
import PageAuth from '../../Pages/PageAuth';
import { PageTypes } from '../../Pages/PageAuth/PageAuth.interface';
import PageLaboratoryTests from '../../Pages/PageLaboratoryTests';
import PageLoading from '../../Pages/PageLoading';
import PageMapping from '../../Pages/PageMapping';
import PageMonitoring from '../../Pages/PageMonitoring';
import PageReagents from '../../Pages/PageReagents';
import PageStandards from '../../Pages/PageStandards';
import { RolesList } from '../../Static/rolesList';
import { $isAuthenticated, $userRoles } from '../../Store/authStore';

import { PathUrl } from './AppRoutes.interface';

function AppRoutes() {
  const isAuthenticated = useStore($isAuthenticated);
  const currentUserRole = useStore($userRoles);

  const isAdmin: boolean = useMemo(
    () => currentUserRole.includes(RolesList.ADMIN),
    [currentUserRole],
  );
  const isTechnologist: boolean = useMemo(
    () => currentUserRole.includes(RolesList.TECHNOLOGIST),
    [currentUserRole],
  );

  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route
            path={PathUrl.LaboratoryTests}
            element={<PageLaboratoryTests />}
          />
          <Route path={PathUrl.Monitoring} element={<PageMonitoring />} />
          <Route path={PathUrl.Reagents} element={<PageReagents />} />
          <Route path={PathUrl.Standards} element={<PageStandards />} />
          <Route path={PathUrl.Loading} element={<PageLoading />} />
          {(isAdmin || isTechnologist) && (
            <Route path={PathUrl.ReportsSynch} element={<PageMapping />} />
          )}
          {isAdmin && <Route path={PathUrl.Admin} element={<PageAdmin />} />}
          <Route path="*" element={<Navigate to={PathUrl.LaboratoryTests} />} />
        </>
      ) : (
        <>
          <Route path={PathUrl.Loading} element={<PageLoading />} />
          <Route
            path={PathUrl.Login}
            element={<PageAuth pageType={PageTypes.Login} />}
          />
          <Route
            path={PathUrl.Register}
            element={<PageAuth pageType={PageTypes.Register} />}
          />
          <Route
            path={PathUrl.ChangePassword}
            element={<PageAuth pageType={PageTypes.ChangePassword} />}
          />
          <Route path="*" element={<Navigate to={PathUrl.Login} />} />
        </>
      )}
    </Routes>
  );
}

export default AppRoutes;
