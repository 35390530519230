import React from 'react';
import clsx from 'clsx';

import { IconsNames } from '../../Icon/constants';
import IconButton from '../../IconButton';
import { ChevronButtonProps } from '../StandardsTree.interface';

import styles from '../StandardsTree.module.scss';

function ChevronButton(props: ChevronButtonProps) {
  const { isDisabled, isOpen, onClick } = props;

  return (
    <IconButton
      iconName={IconsNames.Chevron}
      onClick={onClick}
      className={clsx(
        styles.chevron_button,
        { [styles.chevron_button_open]: isOpen },
        { [styles.chevron_button_disabled]: isDisabled },
      )}
      isDisabled={isDisabled}
      isClear
    />
  );
}

export default ChevronButton;
