import { useEffect, useMemo, useState } from 'react';
import Xarrow from 'react-xarrows';
import clsx from 'clsx';

import { ArrowPositions } from '../../Pages/PageMapping/PageMapping.interface';
import LoaderDrop from '../LoaderDrop';

import Panel from './components/Panel';
import { MonitoringPanel, MonitoringTableProps } from './Accordion.interface';

import styles from './Accordion.module.scss';

function MonitoringTable(props: MonitoringTableProps) {
  const {
    panels,
    isDeleteActive,
    onOpenPanelsIdsListChange,
    openPanelsIds,
    onDelete,
    className,
    onEdit,
    isLoading,
  } = props;

  const [isScreenSizeSmall, setIsScreenSizeSmall] = useState(false);

  const handlePanelClick = (isPanelOpen: boolean, id: number) => () => {
    let newOpenPanelsIdsList: number[] = openPanelsIds.slice();
    if (isPanelOpen) {
      newOpenPanelsIdsList = newOpenPanelsIdsList.filter(
        (panelId) => panelId !== id,
      );
    } else {
      newOpenPanelsIdsList.push(id);
    }

    onOpenPanelsIdsListChange(newOpenPanelsIdsList);
  };

  const handleResize = () => {
    if (window.innerWidth <= 1500) {
      setIsScreenSizeSmall(true);
    } else {
      setIsScreenSizeSmall(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getPanelsStructuralItem = (panel: MonitoringPanel | null) => {
    if (panel === null) {
      return null;
    }

    const { subPanels, mainPanel } = panel;

    const { headerContent, bodyContent, id, isDisabled, doseId, status } =
      mainPanel;

    const isPanelOpen = openPanelsIds.includes(id);
    const mainPanelArrowId: string = `main-${id}`;

    const subPanelsList: JSX.Element[] = subPanels.map((subPanel) => {
      const {
        headerContent: subPanelHeaderContent,
        bodyContent: subPanelBodyContent,
        id: subPanelId,
        doseId: subPanelDoseId,
        status: subPanelStatus,
        isDisabled: isSubPanelDisabled,
      } = subPanel;
      const subPanelArrowId: string = `sub-${subPanelId}`;
      const isSubPanelPanelOpen = openPanelsIds.includes(subPanelId);
      return (
        <div
          className={clsx(
            styles.monitoring_panel_wrapper,
            styles.monitoring_subpanel_wrapper,
            styles.panel_wrapper,
          )}
          key={subPanelId}
        >
          <Panel
            id={subPanelDoseId}
            panelId={subPanelArrowId}
            headerContent={subPanelHeaderContent}
            bodyContent={subPanelBodyContent}
            isOpen={isSubPanelPanelOpen}
            onClick={handlePanelClick(isSubPanelPanelOpen, subPanelId)}
            status={subPanelStatus}
            isDeleteActive={isDeleteActive && !isSubPanelDisabled}
            onDeleteButtonClick={onDelete}
            panelType="monitoring"
            onEditButtonClick={
              subPanelStatus === 'PENDING' ? onEdit : undefined
            }
          />
          <Xarrow
            start={mainPanelArrowId}
            end={subPanelArrowId}
            startAnchor={{
              position: ArrowPositions.Left,
              offset: { x: 5, y: isScreenSizeSmall ? -23 : -33 },
            }}
            endAnchor={ArrowPositions.Left}
            color="#B3CDE0"
            strokeWidth={1.4}
            headSize={4}
            tailSize={4}
            path="grid"
            headShape="circle"
            tailShape="circle"
            gridBreak="2"
            animateDrawing
          />
        </div>
      );
    });

    return {
      layout: (
        <div className={clsx(styles.monitoring_panel_wrapper)} key={id}>
          <div className={styles.monitoring_subpanels_list_wrapper}>
            {subPanelsList}
          </div>
          <div className={styles.panel_wrapper}>
            <Panel
              panelType="monitoring"
              id={doseId}
              panelId={mainPanelArrowId}
              headerContent={headerContent}
              bodyContent={bodyContent}
              isOpen={isPanelOpen}
              onClick={handlePanelClick(isPanelOpen, id)}
              status={status}
              isDeleteActive={isDeleteActive && !isDisabled}
              onDeleteButtonClick={onDelete}
              onEditButtonClick={status === 'PENDING' ? onEdit : undefined}
            />
          </div>
        </div>
      ),
      mainId: mainPanelArrowId,
    };
  };

  const panelsList = useMemo(
    () =>
      panels.map((structure, index) => {
        const { potable, surface } = structure;

        const { layout: PotableSection, mainId: potableMainId } =
          getPanelsStructuralItem(potable) || {
            layout: null,
            mainId: null,
          };
        const { layout: SurfaceSection, mainId: surfaceMainId } =
          getPanelsStructuralItem(surface);

        return (
          <div key={index} className={styles.panels_structure}>
            {PotableSection}
            {SurfaceSection}
            {potableMainId && (
              <Xarrow
                start={surfaceMainId}
                end={potableMainId}
                startAnchor={{
                  position: ArrowPositions.Left,
                  offset: { x: -6, y: 0 },
                }}
                endAnchor={ArrowPositions.Left}
                color="#B3CDE0"
                strokeWidth={1.4}
                headSize={4}
                showTail
                showHead
                tailSize={4}
                path="grid"
                headShape="circle"
                tailShape="circle"
                gridBreak="-15"
                animateDrawing
              />
            )}
          </div>
        );
      }),
    [panels, openPanelsIds, isDeleteActive, onOpenPanelsIdsListChange],
  );

  return isLoading ? (
    <div className={styles.loader}>
      <LoaderDrop backgroundColor="transparent" />
    </div>
  ) : (
    <div className={clsx(styles.monitoring_table_wrapper, className)}>
      {panelsList}
    </div>
  );
}

export default MonitoringTable;
