import React, { useEffect, useMemo, useState } from 'react';

import Icon from '../../../Components/Icon';
import { IconsNames } from '../../../Components/Icon/constants';
import IconWarningSection from '../../../Components/IconWarningSection/IconWarningSection';
import Textarea from '../../../Components/Textarea/Textarea';
import { reagentsTableColumnsList } from '../constants';
import {
  PageMonitoringPanelBodyReagentsProps,
  ReagentsInputTypes,
  ReagentValueDataType,
} from '../PageMonitoring.interface';

import PageMonitoringReagentsMessage from './PageMonitoringReagentsMessage';

import styles from '../PageMonitoring.module.scss';

function PageMonitoringPanelBodyReagents(
  props: PageMonitoringPanelBodyReagentsProps,
) {
  const {
    inputType,
    isToBeFilled,
    onReagentsAdd,
    onReagentsSkip,
    reagents,
    comment,
  } = props;
  const [currentQueueId, setCurrentQueueId] = useState<number>(null);

  const reagentsTableColumns: JSX.Element[] = reagentsTableColumnsList.map(
    (column: string, index) => (
      <div className={styles.reagents_table_column} key={index}>
        {column}
      </div>
    ),
  );

  const reagentsTableRows: JSX.Element[] = useMemo(
    () =>
      reagents.map((reagent: ReagentValueDataType) => {
        const { id, name, queues } = reagent;
        const queueData = queues[currentQueueId];

        let valueFormatted = '';
        let valueDifferenceFormatted = '';
        let recommendedValueDifferenceFormatted = '';
        let recommendedValueFormatted = '';

        if (queueData) {
          const {
            recommendedValue,
            recommendedValueDifference,
            value,
            valueDifference,
          } = queueData;

          valueFormatted =
            value !== null
              ? value.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : '';
          valueDifferenceFormatted =
            valueDifference !== null
              ? valueDifference.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : '';
          recommendedValueDifferenceFormatted =
            recommendedValueDifference !== null
              ? recommendedValueDifference.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : '';
          recommendedValueFormatted =
            recommendedValue !== null
              ? recommendedValue.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : '';
        }

        return (
          <div className={styles.reagents_table_row} key={id}>
            <div className={styles.reagents_table_cell}>{name}</div>
            <div className={styles.reagents_table_cell}>
              {recommendedValueFormatted}
            </div>
            <div className={styles.reagents_table_cell}>
              {recommendedValueDifferenceFormatted}
            </div>
            <div className={styles.reagents_table_cell}>{valueFormatted}</div>
            <div className={styles.reagents_table_cell}>
              {valueDifferenceFormatted}
            </div>
          </div>
        );
      }),
    [reagents, currentQueueId],
  );

  useEffect(() => {
    if (
      currentQueueId === null &&
      reagents?.[0]?.queues &&
      Object.keys(reagents?.[0]?.queues)[0]
    ) {
      const id = Number(Object.keys(reagents?.[0]?.queues)[0]);
      setCurrentQueueId(id);
    }
  }, [currentQueueId, reagents]);

  const isReagentsListEmpty = useMemo(
    () => !reagentsTableRows || reagentsTableRows.length === 0,
    [reagentsTableRows],
  );
  const iconWarningMessage = useMemo(
    () =>
      inputType === ReagentsInputTypes.Intermediate
        ? 'Корректировочный ввод пропущен'
        : 'Ввод пропущен',
    [inputType],
  );
  if (isToBeFilled) {
    return (
      <div className={styles.panel_reagents_wrapper}>
        <PageMonitoringReagentsMessage
          inputType={inputType}
          onReagentsAdd={onReagentsAdd}
          onReagentsSkip={onReagentsSkip}
        />
      </div>
    );
  }

  return (
    <div className={styles.panel_reagents_wrapper}>
      {!isReagentsListEmpty ? (
        <div className={styles.reagents_table_wrapper}>
          <div className={styles.reagents_header}>
            <Icon name={IconsNames.Flask} />
            <span className={styles.reagents_header_title}>
              Расчетные активные дозы реагентов
            </span>
          </div>
          <div className={styles.reagents_table}>
            <div className={styles.reagents_table_header}>
              {reagentsTableColumns}
            </div>
            <div className={styles.reagents_table_body}>
              {reagentsTableRows}
            </div>
          </div>
          {comment && (
            <Textarea
              value={comment}
              readOnly
              className={styles.reagent_table_comment}
            />
          )}
        </div>
      ) : (
        <div className={styles.icon_warning_section}>
          <IconWarningSection
            iconName={IconsNames.Dropper}
            message={iconWarningMessage}
          />
        </div>
      )}
    </div>
  );
}

export default PageMonitoringPanelBodyReagents;
