import React from 'react';
import { useStore } from 'effector-react';

import PageAdminRequestsList from '../../Pages/PageAdmin/components/PageAdminRequestsList';
import {
  $requestsList,
  acceptRegistrationRequestFx,
  rejectRegistrationRequestFx,
} from '../../Store/adminPageStore';

function PageAdminRequestsListContainer() {
  const requests = useStore($requestsList);

  const handleRequestAccept = (id: number) => {
    acceptRegistrationRequestFx(id);
  };
  const handleRequestReject = (id: number) => {
    rejectRegistrationRequestFx(id);
  };

  return (
    <PageAdminRequestsList
      requests={requests}
      onAccept={handleRequestAccept}
      onReject={handleRequestReject}
    />
  );
}

export default PageAdminRequestsListContainer;
