import { useState } from 'react';
import clsx from 'clsx';

import Button from '../../../Components/Button';
import Checkbox from '../../../Components/Checkbox/Checkbox';
import DatePicker from '../../../Components/DatePicker';
import { DatePickerTypes } from '../../../Components/DatePicker/DatePicker.interface';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton';
import Modal from '../../../Components/Modal';
import Select from '../../../Components/Select';
import {
  modalWaterTypesOptionsList,
  strangeValueWarningMessage,
} from '../constants';
import { ReportModalProps } from '../PageLaboratoryTests.interface';

import AdditionalPropertiesSection from './AdditionalPropertiesSection';
import ReportPropertiesTable from './ReportPropertiesTable';

import styles from '../PageLaboratoryTests.module.scss';

function ReportModal(props: ReportModalProps) {
  const {
    reportDate,
    researchType,
    standard,
    standardsOptionsList,
    properties,
    availablePropertiesOptionsList,
    selectedAdditionalProperties,
    isSaveAvailable,
    isWarningShown,
    isWarningCheckboxChecked,
    onWarningCheckboxValueChange,
    onModalClose,
    onReportDateChange,
    onReportResearchTypeChange,
    onStandardChange,
    onPropertiesValuesChange,
    onSelectedAdditionalPropertiesChange,
    onReportSave,
    isInEditMode,
    queueNumber,
    reportMaxDate,
    reportMinDate,
  } = props;

  const [isModalToBeClosed, setIsModalToBeClosed] = useState<boolean>(false);

  const handleModalClose = () => {
    setIsModalToBeClosed(false);
    onModalClose();
  };

  const handleCloseButtonClick = () => {
    setIsModalToBeClosed(true);
  };

  return (
    <Modal
      isToBeClosed={isModalToBeClosed}
      onClose={handleModalClose}
      className={styles.report_modal}
    >
      <div className={styles.report_modal_content}>
        <div className={styles.modal_header}>
          <div className={styles.modal_title}>
            {isInEditMode ? 'Редактирование ' : 'Ввод '}результатов исследования
            <br />
            для очереди {queueNumber}
          </div>
          <IconButton
            iconName={IconsNames.Cross}
            className={styles.cross_icon_button}
            isClear
            onClick={handleCloseButtonClick}
          />
        </div>
        <div className={styles.modal_body_wrapper}>
          <div className={styles.modal_date_section}>
            <div className={styles.modal_subtitle}>
              Дата и время проведения химического анализа:
            </div>
            <div className={styles.modal_datepicker}>
              <DatePicker
                type={DatePickerTypes.SingleDateTime}
                selectedDate={reportDate}
                onSingleDateChange={onReportDateChange}
                minDate={reportMinDate}
                maxDate={reportMaxDate}
              />
            </div>
          </div>
          <div className={styles.modal_report_type_section}>
            <div className={styles.report_type_col}>
              <div className={styles.modal_subtitle}>Тип отчета:</div>
              <Select
                value={researchType}
                options={modalWaterTypesOptionsList}
                onChange={onReportResearchTypeChange}
                className={styles.report_type_buttons_switcher}
                isDisabled={isInEditMode}
              />
            </div>
            <div className={styles.report_type_col}>
              <div className={styles.modal_subtitle}>
                Стандарт исследований:
              </div>
              <Select
                options={standardsOptionsList}
                value={standard}
                onChange={onStandardChange}
                isSearchable
                isDisabled={isInEditMode}
                placeholder="Пусто"
              />
            </div>
          </div>
          <div className={styles.modal_properties_section}>
            <div className={styles.modal_subtitle}>
              Данные по результатам анализа химического состава поверхностной
              воды:
            </div>
            <ReportPropertiesTable
              properties={properties}
              isWarningShown={isWarningShown}
              onChange={onPropertiesValuesChange}
            />
          </div>
          <div className={styles.modal_additional_properties_section}>
            <AdditionalPropertiesSection
              availablePropertiesOptionsList={availablePropertiesOptionsList}
              selectedProperties={selectedAdditionalProperties}
              onPropertiesChange={onSelectedAdditionalPropertiesChange}
            />
          </div>
        </div>
        <div
          className={clsx(styles.modal_warning_section, {
            [styles.modal_warning_section_hidden]: !isWarningShown,
          })}
        >
          <Checkbox
            checked={isWarningCheckboxChecked}
            className={styles.modal_warning_section_checkbox}
            onChange={onWarningCheckboxValueChange}
          />
          <div className={styles.modal_warning_section_message}>
            {strangeValueWarningMessage}
          </div>
        </div>
        <div className={styles.modal_button_section}>
          <Button
            onClick={onReportSave}
            className={styles.save_report_button}
            isDisabled={!isSaveAvailable}
          >
            Сохранить результаты
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ReportModal;
