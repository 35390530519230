import React from 'react';
import clsx from 'clsx';

import { IconsNames } from '../../Icon/constants';
import IconButton from '../../IconButton';
import { ChevronButtonProps } from '../Accordion.interface';

import styles from '../Accordion.module.scss';

function ChevronButton(props: ChevronButtonProps) {
  const { isOpen, onClick } = props;

  return (
    <IconButton
      iconName={IconsNames.Chevron}
      onClick={onClick}
      className={clsx(styles.chevron_button, {
        [styles.chevron_button_open]: isOpen,
      })}
    />
  );
}

export default ChevronButton;
