import { useMemo } from 'react';
import clsx from 'clsx';

import { WaterTypes } from '../../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';

import { WaterOptionLabelsProps } from './OptionLabels.interface';

import styles from './OptionLabels.module.scss';

function WaterOptionLabels(props: WaterOptionLabelsProps) {
  const { isPlural = false, waterType } = props;

  const iconText: string = useMemo(() => {
    switch (waterType) {
      case WaterTypes.Potable:
        return 'пит';
      case WaterTypes.Surface:
        return 'пов';
      case WaterTypes.Intermediate:
        return 'пром';
      case WaterTypes.All:
        return 'все';
      default:
        return '';
    }
  }, [waterType]);

  const optionText: string = useMemo(() => {
    switch (waterType) {
      case WaterTypes.Potable:
        return isPlural ? 'Питьевые' : 'Питьевой';
      case WaterTypes.Surface:
        return isPlural ? 'Поверхностные' : 'Поверхностный';
      case WaterTypes.Intermediate:
        return isPlural ? 'Промежуточные' : 'Промежуточный';
      default:
        return 'Все отчеты';
    }
  }, [waterType]);

  return (
    <div className={styles.option} title={optionText}>
      {iconText && (
        <div
          className={clsx(
            styles.option_icon,
            styles.water_icon,
            styles[waterType],
          )}
        >
          {iconText}
        </div>
      )}
      <div className={styles.option_text}>{optionText}</div>
    </div>
  );
}

export default WaterOptionLabels;
