export enum IconsNames {
  Cross = 'Cross',
  Calendar = 'Calendar',
  Chevron = 'Chevron',
  CircleAll = 'CircleAll',
  CircleCheck = 'CircleCheck',
  CircleCross = 'CircleCross',
  CircleWarning = 'CircleWarning',
  CircleClock = 'CircleClock',
  Clock = 'Clock',
  CheckedCheckbox = 'CheckedCheckbox',
  UncheckedCheckbox = 'UncheckedCheckbox',
  ExportArrow = 'ExportArrow',
  Flask = 'Flask',
  Note = 'Note',
  Save = 'Save',
  RightArrow = 'RightArrow',
  EyeOpen = 'EyeOpen',
  EyeClose = 'EyeClose',
  Plus = 'Plus',
  CollapseAll = 'CollapseAll',
  ExpandAll = 'ExpandAll',
  Help = 'Help',
  Delete = 'Delete',
  OpenBook = 'OpenBook',
  WaterDrop = 'WaterDrop',
  NotificationCheck = 'NotificationCheck',
  NotificationCross = 'NotificationCross',
  NotificationExclamation = 'NotificationExclamation',
  NotificationInfo = 'NotificationInfo',
  Document = 'Document',
  Edit = 'Edit',
  CirclePlus = 'CirclePlus',
  Info = 'Info',
  Logout = 'Logout',
  Minus = 'Minus',
  EditDisabled = 'EditDisabled',
  Dropper = 'Dropper',
  NoteGraph = 'NoteGraph',
  SyncClock = 'SyncClock',
  Users = 'Users',
  Queue = 'Queue',
  ClearFilters = 'ClearFilters',
  Settings = 'Settings',
  TestTube = 'TestTube',
}
