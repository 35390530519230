import React from 'react';
import clsx from 'clsx';

import SkeletonPageStandardsTreeRow from './SkeletonPageStandardsTreeRow';
import SkeletonPageStandardsTreeRowOpen from './SkeletonPageStandardsTreeRowOpen';

import styles from './Skeleton.module.scss';

function SkeletonPageStandardsTree() {
  return (
    <div className={styles.skeleton_standards_tree}>
      <div className={styles.skeleton_standards_tree_buttons_row}>
        <div className={clsx(styles.skeleton_line, styles.skeleton_button)} />
        <div className={clsx(styles.skeleton_line, styles.skeleton_icon)} />
        <div className={clsx(styles.skeleton_line, styles.skeleton_icon)} />
      </div>
      <div className={styles.skeleton_standards_tree_table}>
        <SkeletonPageStandardsTreeRow />
        <SkeletonPageStandardsTreeRowOpen />
        <SkeletonPageStandardsTreeRow />
        <SkeletonPageStandardsTreeRow />
        <SkeletonPageStandardsTreeRow />
        <SkeletonPageStandardsTreeRow />
      </div>
    </div>
  );
}

export default SkeletonPageStandardsTree;
