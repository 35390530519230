import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'effector-react';

import Button from '../../../Components/Button';
import Icon from '../../../Components/Icon';
import { IconsNames } from '../../../Components/Icon/constants';
import Input from '../../../Components/Input';
import { InputTypes } from '../../../Components/Input/constants';
import {
  $isChangePasswordFormShown,
  $isChangePasswordRequestFormShown,
  changePasswordFx,
  checkTokenValidityFx,
  createPasswordChangeRequestFx,
  logInFx,
  setIsChangePasswordFormShown,
  setIsChangePasswordRequestFormShown,
} from '../../../Store/authStore';
import {
  changePasswordRequestModalTitle,
  changePasswordRequestWrongTokenModalTitle,
  defaultLoginFormValues,
} from '../constants';
import {
  FormValuesType,
  LoginFormProps,
  PageTypes,
} from '../PageAuth.interface';

import ChangePasswordModal from './ChangePasswordModal';
import ChangePasswordRequestModal from './ChangePasswordRequestModal';

import styles from '../PageAuth.module.scss';

function LoginForm(props: LoginFormProps) {
  const { onLinkClick, pageType } = props;
  const { token } = useParams();
  const [formValues, setFormValues] = useState<FormValuesType>(
    defaultLoginFormValues,
  );
  const isChangePasswordRequestFormShown: boolean = useStore(
    $isChangePasswordRequestFormShown,
  );
  const isChangePasswordFormShown: boolean = useStore(
    $isChangePasswordFormShown,
  );

  const handleFormValuesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const isSubmitEnabled = useMemo(
    () =>
      Object.values(formValues).every(
        (formValue) => formValue !== '' && formValue !== null,
      ),
    [formValues],
  );

  const handleLoginSubmit = () => {
    const { login, password } = formValues;
    logInFx({ login, password });
  };

  const handleForgotPasswordButtonClick = () => {
    setIsChangePasswordRequestFormShown(true);
  };

  const modalTitle: string = useMemo(
    () =>
      pageType === PageTypes.Login
        ? changePasswordRequestModalTitle
        : changePasswordRequestWrongTokenModalTitle,
    [pageType],
  );

  const handlePasswordChangeRequestSubmit = (login: string, email: string) => {
    createPasswordChangeRequestFx({ login, email });
  };
  const handlePasswordChangeRequestModalClose = () => {
    setIsChangePasswordRequestFormShown(false);
  };

  const handlePasswordChangeSubmit = (password: string) => {
    changePasswordFx({ password, token });
  };
  const handlePasswordChangeModalClose = () => {
    setIsChangePasswordFormShown(false);
  };
  const handlePasswordFieldKeyPress = (
    event: KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter' && isSubmitEnabled) {
      handleLoginSubmit();
    }
  };

  useEffect(() => {
    if (token && pageType === PageTypes.ChangePassword) {
      checkTokenValidityFx(token);
    }
  }, [token, pageType]);

  return (
    <div className={styles.form}>
      <form autoComplete="off">
        <div className={styles.input_row}>
          <div className={styles.input_label}>Логин</div>
          <Input
            type={InputTypes.Text}
            value={formValues.login}
            onChange={handleFormValuesChange}
            name="login"
            className={styles.input}
          />
        </div>
        <div className={styles.input_row}>
          <div className={styles.input_label}>Пароль</div>
          <Input
            type={InputTypes.Password}
            value={formValues.password}
            onChange={handleFormValuesChange}
            className={styles.input}
            name="password"
            onKeyPress={handlePasswordFieldKeyPress}
          />
          <button
            type="button"
            className={styles.forgot_password}
            onClick={handleForgotPasswordButtonClick}
            tabIndex={-1}
          >
            Забыли пароль?
          </button>
        </div>
        <Button
          onClick={handleLoginSubmit}
          className={styles.submit_button}
          isDisabled={!isSubmitEnabled}
          tabIndex={0}
        >
          Войти
        </Button>
      </form>
      <div className={styles.link_section}>
        Новый пользователь?
        <button
          type="button"
          className={styles.link_button}
          onClick={onLinkClick}
        >
          Зарегистрироваться <Icon name={IconsNames.RightArrow} />
        </button>
      </div>
      {isChangePasswordRequestFormShown && (
        <ChangePasswordRequestModal
          title={modalTitle}
          onSubmit={handlePasswordChangeRequestSubmit}
          onClose={handlePasswordChangeRequestModalClose}
        />
      )}
      {isChangePasswordFormShown && (
        <ChangePasswordModal
          onSubmit={handlePasswordChangeSubmit}
          onClose={handlePasswordChangeModalClose}
        />
      )}
    </div>
  );
}

export default LoginForm;
