export function maskPhoneNumber(phone: string) {
  const destructuredPhoneNumber = phone
    .replace(/\D/g, '')
    .match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);

  return `+${destructuredPhoneNumber[1]} (${destructuredPhoneNumber[2]}) ${destructuredPhoneNumber[3]}-${destructuredPhoneNumber[4]}-${destructuredPhoneNumber[5]}`;
}

export function maskPhoneNumberInput(value: string) {
  const phone = value.replace(/\D/g, '');
  if (!phone.length) {
    return '';
  }
  if (phone.length <= 3) {
    return `(${phone}`;
  }
  if (phone.length <= 6) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}`;
  }
  if (phone.length <= 8) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 8)}`;
  }
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(
    6,
    8,
  )}-${phone.slice(8, 10)}`;
}
