import { revertCreateAddReagentParamsAdapter } from '../Adapters/fetchReagentsListAdapter';
import { revertChangeLinkingParamsAdapter } from '../Adapters/fetchReportsSynchDataAdapter';
import {
  revertAddDoseParamsAdapter,
  revertEditDoseParamsAdapter,
  revertMonitoringReportsParamsAdapter,
} from '../Adapters/monitoringPageAdapters';
import { revertSendRegistrationRequestAdapter } from '../Adapters/registrationRequestAdapters';
import { revertAddClassifierParamsAdapter } from '../Adapters/revertAddClassifierAdapter';
import revertChangeStandardAdapter from '../Adapters/revertChangeStandardAdapter';
import { revertCreateStandardAdapter } from '../Adapters/revertCreateStandardAdapter';
import {
  revertAddResearchReportsParamsAdapter,
  revertEditResearchReportsParamsAdapter,
  revertResearchReportsParamsAdapter,
} from '../Adapters/revertResearchReportsAdapter';
import {
  BackendAddDoseParamsType,
  BackendChangeLinkingParamsType,
  BackendChangeStandardParamsType,
  BackendCreateClassifierParamsType,
  BackendMonitoringReportByIdDataType,
  BackendMonitoringReportsListDataType,
  BackendRegistrationRequestParamsType,
} from '../Adapters/types';
import {
  MonitoringReportsListParamsType,
  MonitoringReportsSummaryParamsType,
} from '../Containers/PageMonitoringContainers/PageMonitoringContainers.interface';
import {
  AddResearchReportParamsType,
  EditResearchReportParamsType,
  ResearchReportsListParamsType,
  ResearchReportSummaryParamsType,
} from '../Containers/ResearchReportsContainers/ResearchReportsContainers.interface';
import {
  IntermediateWaterSubTypes,
  WaterTypes,
} from '../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';
import {
  ChangeReagentDocumentParamsType,
  CreateAddReagentParamsType,
} from '../Pages/PageReagents/PageReagents.interface';
import { ChangeStandardParamsType } from '../Pages/PageStandards/PageStandards.interface';
import {
  AddDoseParamsType,
  ChangeLinkingParamsType,
  ChangePasswordParamsType,
  CreateClassifierParamsType,
  CreatePasswordChangeRequestParamsType,
  CreateStandardParamsType,
  EditDoseParamsType,
  RegistrationRequestParamsType,
  SetVodokanalPredictionType,
} from '../Store/types';

import { links } from './apiLinks';
import { getData, postData } from './services';

// catalogues
function getReagentsList() {
  return getData(links.reagents.all);
}
function getStandardsList(
  waterType: WaterTypes,
  waterSubType: IntermediateWaterSubTypes = null,
) {
  const params = {
    standard_type: waterType,
    sub_type: waterSubType,
  };
  return getData(links.standards.all, params);
}
function getWorkingStandardsList(
  waterType: WaterTypes,
  waterSubType: IntermediateWaterSubTypes = null,
) {
  const params = {
    standard_type: waterType,
    sub_type: waterSubType,
  };
  return getData(links.standards.working, params);
}
function getDefaultStandardsList(
  waterType: WaterTypes = null,
  waterSubType: IntermediateWaterSubTypes = null,
) {
  const params = {
    standard_type: waterType,
    sub_type: waterSubType,
  };
  return getData(links.standards.default, params);
}

function getClassifiersList() {
  return getData(links.classifiers.all);
}
function getMeasuresList() {
  return getData(links.catalogue.measures);
}
function getWaterCanalsList() {
  return getData(links.catalogue.waterCanals);
}

// research
function getResearchReportsList(params: ResearchReportsListParamsType) {
  const revertAdaptedParams = revertResearchReportsParamsAdapter(params);

  return getData(links.research.show, revertAdaptedParams);
}
function getResearchClassifiersById(id: number) {
  return getData(links.research.showById, { id });
}
function exportResearchReportsList(params: ResearchReportSummaryParamsType) {
  return getData(links.research.export, params, 'arraybuffer');
}
function addResearchReport(params: AddResearchReportParamsType) {
  const revertAdaptedParams = revertAddResearchReportsParamsAdapter(params);

  return postData(links.research.add, revertAdaptedParams);
}

function editResearchReport(params: EditResearchReportParamsType) {
  const revertAdaptedParams = revertEditResearchReportsParamsAdapter(params);

  return postData(links.research.change, revertAdaptedParams);
}

function deleteResearchReport(id: number) {
  const params = { id };

  return postData(links.research.delete, params);
}

// standards
function getStandardsTree() {
  return getData(links.standards.tree);
}
function getStandardWithClassifiersById(standardId: number) {
  const params = {
    id: standardId,
  };
  return getData(links.standards.byIdWithClassifiers, params);
}
function addClassifier(params: CreateClassifierParamsType) {
  const revertAdaptedParams: BackendCreateClassifierParamsType =
    revertAddClassifierParamsAdapter(params);

  return postData(links.classifiers.add, revertAdaptedParams);
}

function changeStandard(params: ChangeStandardParamsType) {
  const revertAdaptedParams: BackendChangeStandardParamsType =
    revertChangeStandardAdapter(params);

  return postData(links.standards.change, revertAdaptedParams);
}
function copyStandard(standardId: number) {
  const params = {
    id: standardId,
  };

  return postData(links.standards.copy, params);
}
function deleteStandard(standardId: number) {
  const params = {
    id: standardId,
  };

  return postData(links.standards.delete, params);
}
function getRecommendedClassifiers() {
  return getData(links.classifiers.recommended);
}
function createStandard(params: CreateStandardParamsType) {
  const revertAdaptedParams = revertCreateStandardAdapter(params);

  return postData(links.standards.add, revertAdaptedParams);
}

function setDefaultStandard(id: number) {
  return postData(links.standards.setDefault, { id });
}

// reagents
function addReagent(params: CreateAddReagentParamsType) {
  const revertAdaptedParams = revertCreateAddReagentParamsAdapter(params);
  return postData(links.reagents.add, revertAdaptedParams);
}
function changeReagent(params: CreateAddReagentParamsType) {
  const revertAdaptedParams = revertCreateAddReagentParamsAdapter(params);
  return postData(links.reagents.change, revertAdaptedParams);
}
function deleteReagent(reagentId: number) {
  const params = {
    id: reagentId,
  };
  return postData(links.reagents.delete, params);
}
function getReagentDocument(reagentId: number) {
  const params = {
    id: reagentId,
  };
  return getData(links.reagents.document.show, params);
}
function changeReagentDocument(params: ChangeReagentDocumentParamsType) {
  const { id, file } = params;
  const revertAdaptedParams = {
    reagent_id: id,
    file,
  };
  return postData(
    links.reagents.document.change,
    revertAdaptedParams,
    'multipart/form-data',
  );
}
function deleteReagentDocument(id: number) {
  return postData(links.reagents.document.remove, { id });
}
function addReagentDocument(params: ChangeReagentDocumentParamsType) {
  const { id, file } = params;
  const revertAdaptedParams = {
    reagent_id: id,
    file,
  };
  return postData(
    links.reagents.document.add,
    revertAdaptedParams,
    'multipart/form-data',
  );
}

// monitoring
function exportMonitoringReports(params: MonitoringReportsSummaryParamsType) {
  return getData(links.monitoring.export, params, 'arraybuffer');
}

function getMonitoringReportsList(params: MonitoringReportsListParamsType) {
  const revertAdaptedParams = revertMonitoringReportsParamsAdapter(params);

  return getData(links.monitoring.show, revertAdaptedParams);
}

function getMonitoringReportById(id: number) {
  return getData<BackendMonitoringReportByIdDataType>(
    links.monitoring.showById,
    { id },
  );
}

function getMonitoringReportsDetailed(params: MonitoringReportsListParamsType) {
  const revertAdaptedParams = revertMonitoringReportsParamsAdapter(params);

  return getData<BackendMonitoringReportsListDataType>(
    links.monitoring.showAllDetailed,
    revertAdaptedParams,
  );
}

function getRecommendedReagentsList(id: number) {
  const params = { id };
  return getData(links.monitoring.recommended, params);
}

function addDose(params: AddDoseParamsType) {
  const revertAdaptedParams: BackendAddDoseParamsType =
    revertAddDoseParamsAdapter(params);

  return postData(links.monitoring.addDose, revertAdaptedParams);
}

function editDose(params: EditDoseParamsType) {
  const revertAdaptedParams = revertEditDoseParamsAdapter(params);

  return postData(links.monitoring.editDose, revertAdaptedParams);
}

function deleteDose(id: number) {
  const params = { id };

  return postData(links.monitoring.deleteDose, params);
}

function skipDose(id: number) {
  const params = { id };

  return postData(links.monitoring.skipDose, params);
}

// auth

function logIn(login: string, password: string) {
  const params = {
    login,
    password,
  };
  return postData(
    links.auth.login,
    params,
    'application/x-www-form-urlencoded',
  );
}

function logOut(refreshToken: string) {
  const params = {
    refresh_token: refreshToken,
  };
  return postData(links.auth.logout, params);
}

function refresh(refreshToken: string) {
  const params = {
    refresh_token: refreshToken,
  };
  return postData(links.auth.refresh, params);
}

function getCurrentUser() {
  return getData(links.users.info);
}

function updateQueueConfig(queueNumber: number) {
  const params = {
    queue_number: queueNumber,
    is_read: true,
  };
  return postData(links.users.updateQueueConfig, params);
}

function updateSubTypesConfig(waterSubTypes: string[]) {
  const params = {
    sub_types: waterSubTypes,
  };
  return postData(links.users.updateSubTypesConfig, params);
}

// admin
function getUsersList() {
  return getData(links.admin.users);
}

function changeUserRights(userId: number, roleId: number, toggle: boolean) {
  const params = {
    employee_id: userId,
    role_id: roleId,
    toggle,
  };
  return postData(links.admin.changeRights, params);
}

function deleteUser(id: number) {
  const params = { id };

  return postData(links.admin.delete, params);
}

// registration
function sendRegistrationRequest(params: RegistrationRequestParamsType) {
  const revertAdaptedParams: BackendRegistrationRequestParamsType =
    revertSendRegistrationRequestAdapter(params);

  return postData(links.registration.register, revertAdaptedParams);
}

function acceptRegistrationRequest(id: number) {
  const params = { id };

  return postData(links.registration.accept, params);
}
function rejectRegistrationRequest(id: number) {
  const params = { id };

  return postData(links.registration.reject, params);
}

// linking
function getLinking(date: string, queueNumber: number) {
  const body = { date, queue_number: queueNumber };
  return getData(links.linking.show, body);
}

function changeInterval(waterTreatment: number, timeLag: number) {
  const params = {
    water_treatment: waterTreatment,
    time_lag: timeLag,
  };

  return postData(links.linking.changeInterval, params);
}

function changeLinking(params: ChangeLinkingParamsType) {
  const revertAdaptedParams: BackendChangeLinkingParamsType =
    revertChangeLinkingParamsAdapter(params);
  return postData(links.linking.changeLinking, revertAdaptedParams);
}

// change password
function checkTokenValidity(token: string) {
  const params = { token };
  return getData(links.changePassword.check, params);
}

function createPasswordChangeRequest(
  params: CreatePasswordChangeRequestParamsType,
) {
  return postData(links.changePassword.request, params);
}

function changePassword(params: ChangePasswordParamsType) {
  return postData(links.changePassword.change, params);
}

// info
function getApiVersion() {
  return getData(links.info.about);
}

// vodokanals
function getPredictionType(queueNumber: number) {
  const params = {
    queue_number: queueNumber,
  };
  return getData<{ queue_number: number; prediction_type: string }>(
    links.vodokanals.getPredictionType,
    params,
  );
}

function setPredictionType(params: SetVodokanalPredictionType) {
  const transformedParams = {
    queue_number: params.queueNumber,
    prediction_type: params.predictionType,
  };
  return postData(links.vodokanals.setPredictionType, transformedParams);
}

export {
  acceptRegistrationRequest,
  addClassifier,
  addDose,
  addReagent,
  addReagentDocument,
  addResearchReport,
  changeInterval,
  changeLinking,
  changePassword,
  changeReagent,
  changeReagentDocument,
  changeStandard,
  changeUserRights,
  checkTokenValidity,
  copyStandard,
  createPasswordChangeRequest,
  createStandard,
  deleteDose,
  deleteReagent,
  deleteReagentDocument,
  deleteResearchReport,
  deleteStandard,
  deleteUser,
  editDose,
  editResearchReport,
  exportMonitoringReports,
  exportResearchReportsList,
  getApiVersion,
  getClassifiersList,
  getCurrentUser,
  getDefaultStandardsList,
  getLinking,
  getMeasuresList,
  getMonitoringReportById,
  getMonitoringReportsDetailed,
  getMonitoringReportsList,
  getPredictionType,
  getReagentDocument,
  getReagentsList,
  getRecommendedClassifiers,
  getRecommendedReagentsList,
  getResearchClassifiersById,
  getResearchReportsList,
  getStandardsList,
  getStandardsTree,
  getStandardWithClassifiersById,
  getUsersList,
  getWaterCanalsList,
  getWorkingStandardsList,
  logIn,
  logOut,
  refresh,
  rejectRegistrationRequest,
  sendRegistrationRequest,
  setDefaultStandard,
  setPredictionType,
  skipDose,
  updateQueueConfig,
  updateSubTypesConfig,
};
