import React, { useMemo } from 'react';
import { useStore } from 'effector-react';

import { RolesList } from '../../Static/rolesList';
import {
  $refreshToken,
  $userInitials,
  $userRoles,
  logOutFx,
} from '../../Store/authStore';
import { IconsNames } from '../Icon/constants';
import IconButton from '../IconButton';
import Logo from '../Logo';
import NavigationLinks from '../NavigationLinks';
import { NavigationLinkType } from '../NavigationLinks/NavigationLinks.interface';
import {
  adminNavigationLinks,
  technologistNavigationLinks,
  userNavigationLinks,
} from '../Routes/AppRoutes.interface';

import styles from './TopBar.module.scss';

function TopBar() {
  const refreshToken = useStore($refreshToken);
  const userInitials = useStore($userInitials);
  const currentUserRole = useStore($userRoles);

  const linksList: NavigationLinkType[] = useMemo(() => {
    if (currentUserRole.includes(RolesList.ADMIN)) {
      return adminNavigationLinks;
    }
    if (currentUserRole.includes(RolesList.TECHNOLOGIST)) {
      return technologistNavigationLinks;
    }
    return userNavigationLinks;
  }, [currentUserRole]);

  const handleLogoutButtonClick = () => {
    logOutFx(refreshToken);
  };

  return (
    <div className={styles.top_bar_wrapper}>
      <Logo className={styles.logo} />
      <NavigationLinks navigationLinks={linksList} />
      <div className={styles.profile}>
        {userInitials && (
          <div className={styles.user_initials}>{userInitials}</div>
        )}
        <IconButton
          iconName={IconsNames.Logout}
          onClick={handleLogoutButtonClick}
          isClear
        />
      </div>
    </div>
  );
}

export default TopBar;
