import { MouseEvent, useEffect, useRef, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import clsx from 'clsx';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import Button from '../Button';

import CustomInput from './components/CustomInput';
import CustomTimeInput from './components/CustomTimeInput';
import useHandlers from './hooks/useHandlers';
import { getDatePickerProps } from './utils/getDatePickerProps';
import { getRenderFunctions } from './utils/getRenderFunctions';
import {
  DatePickerCalendarView,
  DatePickerProps,
  DatePickerTypes,
  WeekDaysShort,
} from './DatePicker.interface';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import styles from './DatePicker.module.scss';

function DatePicker(props: DatePickerProps) {
  const {
    selectedDate = new Date(),
    startDate = new Date(),
    endDate = null,
    type,
    onSingleDateChange,
    onDateRangeChange,
    disabled = false,
    maxDate,
    minDate,
  } = props;

  const [calendarView, setCalendarView] = useState(DatePickerCalendarView.Days);
  const [isOpen, setIsOpen] = useState(false);

  const datePickerRef = useRef(null);

  registerLocale('ru', ru);

  const {
    handleChange,
    handleChangeRaw,
    handleHeaderMonthClick,
    handleHeaderYearClick,
  } = useHandlers(
    calendarView,
    type,
    datePickerRef,
    selectedDate,
    onSingleDateChange,
    onDateRangeChange,
    setCalendarView,
  );

  const { renderCalendarContainer, renderCustomHeader } = getRenderFunctions(
    calendarView,
    type,
    handleHeaderMonthClick,
    handleHeaderYearClick,
  );

  const { dateFormat, selectedDateProp, isRangeSelect, yearItemNumber } =
    getDatePickerProps(type, calendarView, startDate, selectedDate);

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLAreaElement;
    if (!target.matches('.rs-picker-date-menu, .rs-picker-date-menu *')) {
      setIsOpen(false);
    }
  };

  const handleTodayButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    const currentDate = new Date() as Date & Date[];
    handleChange(currentDate);
  };

  const handleTimeChange = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const newDate = new Date(selectedDate);
    newDate.setHours(hours, minutes);
    handleChange(newDate as Date & Date[]);
  };

  useEffect(() => {
    if (
      minDate !== undefined &&
      selectedDate.getDate() === minDate.getDate() &&
      selectedDate.getTime() < minDate.getTime()
    ) {
      handleChange(minDate as Date & Date[]);
    }
    if (
      maxDate !== undefined &&
      selectedDate.getDate() === maxDate.getDate() &&
      selectedDate.getTime() > maxDate.getTime()
    ) {
      handleChange(maxDate as Date & Date[]);
    }
  }, [selectedDate]);

  const handleInputClick = () => {
    if (!disabled) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    switch (type) {
      case DatePickerTypes.MonthYear:
        setCalendarView(DatePickerCalendarView.Months);
        break;
      case DatePickerTypes.Year:
        setCalendarView(DatePickerCalendarView.Years);
        break;
      default:
        break;
    }
  }, [type]);

  const isTodayButtonShown =
    calendarView === DatePickerCalendarView.Days && !isRangeSelect;
  const isTimeInputShown =
    calendarView === DatePickerCalendarView.Days &&
    type === DatePickerTypes.SingleDateTime;

  return (
    <div
      className={clsx(styles.date_picker, {
        [styles.disabled]: disabled,
      })}
    >
      <ReactDatePicker
        locale="ru"
        dateFormat={dateFormat}
        ref={datePickerRef}
        shouldCloseOnSelect={false}
        showPopperArrow={false}
        className={clsx(styles.date_picker_input)}
        selected={selectedDateProp}
        onInputClick={handleInputClick}
        customInput={
          <CustomInput
            selectedDate={selectedDate}
            startDate={startDate}
            endDate={endDate}
            type={type}
            disabled={disabled}
          />
        }
        renderCustomHeader={renderCustomHeader}
        onChange={handleChange}
        formatWeekDay={(nameOfDay) => WeekDaysShort.get(nameOfDay.getDay())}
        selectsRange={isRangeSelect}
        startDate={startDate}
        endDate={endDate}
        showMonthYearPicker={calendarView === DatePickerCalendarView.Months}
        showYearPicker={calendarView === DatePickerCalendarView.Years}
        yearItemNumber={yearItemNumber}
        showFourColumnMonthYearPicker
        calendarContainer={renderCalendarContainer}
        disabled={disabled}
        showTimeInput={isTimeInputShown}
        openToDate={selectedDate}
        timeInputLabel=""
        customTimeInput={
          <CustomTimeInput
            value={selectedDate}
            onTimeChange={handleTimeChange}
            minTimePickerDate={
              minDate && minDate.getDate() === selectedDate.getDate()
                ? format(minDate, 'HH:mm')
                : undefined
            }
            maxTimePickerDate={
              maxDate && maxDate.getDate() === selectedDate.getDate()
                ? format(maxDate, 'HH:mm')
                : undefined
            }
          />
        }
        todayButton={
          isTodayButtonShown && (
            <Button
              isSecondary
              className={styles.today_button}
              onClick={handleTodayButtonClick}
            >
              Текущее время
            </Button>
          )
        }
        popperPlacement="bottom-end"
        open={isOpen}
        onClickOutside={handleOutsideClick}
        onChangeRaw={handleChangeRaw}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
}

export default DatePicker;
