import { UserDataType } from '../Store/types';

import {
  BackendCurrentUserInfoDataType,
  BackendCurrentUserRoleDataType,
} from './types';

export function fetchCurrentUserDataAdapter(
  userInfo: BackendCurrentUserInfoDataType,
): UserDataType {
  const {
    id,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    vodokanal_id: waterCanalId,
    roles,
    config,
  } = userInfo;

  const rolesList: number[] = roles.map(
    (role: BackendCurrentUserRoleDataType) => role.id,
  );

  const adaptedConfig = Object.entries(config).reduce(
    (accumulator, current) => {
      const [key, value] = current;
      accumulator = key.startsWith('queue')
        ? {
            ...accumulator,
            [+key.split('_')[1]]: value,
          }
        : {
            ...accumulator,
            subTypes: value,
          };
      return accumulator;
    },
    {},
  );

  return {
    id,
    firstName,
    middleName,
    lastName,
    waterCanalId,
    roles: rolesList,
    config: adaptedConfig,
  };
}
