import { parse } from 'date-fns';

import { DateFormats } from '../../../Components/DatePicker/DatePicker.interface';
import LoaderDrop from '../../../Components/LoaderDrop';
import { PageMonitoringPanelBodyProps } from '../PageMonitoring.interface';

import PageMonitoringPanelBodyClassifiers from './PageMonitoringPanelBodyClassifiers';
import PageMonitoringPanelBodyReagents from './PageMonitoringPanelBodyReagents';

import styles from '../PageMonitoring.module.scss';

function PageMonitoringPanelBody(props: PageMonitoringPanelBodyProps) {
  const { inputType, cardData, isToBeFilled, onReagentsSkip, onReagentsAdd } =
    props;
  const {
    datetime,
    classifiers,
    graphData,
    reagents,
    comment,
    id,
    performance,
  } = cardData;

  const handleAddReagentsButtonClick = () => {
    const minDate = parse(datetime, DateFormats.ddMMyyyyHHmm, new Date());
    onReagentsAdd(id, minDate);
  };
  const handleSkipReagentsButtonClick = () => {
    const date = parse(datetime, DateFormats.ddMMyyyyHHmm, new Date());
    onReagentsSkip(id, date);
  };

  return (
    <div className={styles.table_panel_body_wrapper}>
      {comment !== undefined ? (
        <>
          <PageMonitoringPanelBodyClassifiers
            datetime={datetime}
            classifiers={classifiers}
            graphData={graphData}
            performance={performance}
          />
          <PageMonitoringPanelBodyReagents
            reagents={reagents}
            comment={comment}
            inputType={inputType}
            isToBeFilled={isToBeFilled}
            onReagentsAdd={handleAddReagentsButtonClick}
            onReagentsSkip={handleSkipReagentsButtonClick}
          />
        </>
      ) : (
        <div className={styles.loader_wrapper}>
          <LoaderDrop />
        </div>
      )}
    </div>
  );
}

export default PageMonitoringPanelBody;
