import React, { useEffect } from 'react';

import Card from '../../Components/Card';
import StandardsTreeSectionContainer from '../../Containers/PageStandardsContainers/StandardsTreeSectionContainer';
import StandardTableSectionContainer from '../../Containers/PageStandardsContainers/StandardTableSectionContainer';
import { setIsStandardEditActive } from '../../Store/standardsPageStore';

import styles from './PageStandards.module.scss';

function PageStandards() {
  useEffect(() => {
    return () => {
      setIsStandardEditActive(false);
    };
  }, []);

  return (
    <div className={styles.page_wrapper}>
      <Card className={styles.card}>
        <StandardsTreeSectionContainer />
        <StandardTableSectionContainer />
      </Card>
    </div>
  );
}

export default PageStandards;
