import React, { useState } from 'react';

import Button from '../../../Components/Button/Button';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton/IconButton';
import Modal from '../../../Components/Modal';
import { StandardEditWarningModalProps } from '../PageStandards.interface';

import styles from '../PageStandards.module.scss';

function StandardEditWarningModal(props: StandardEditWarningModalProps) {
  const { onConfirm, onClose } = props;
  const [isModalToBeClosed, setIsModalToBeClosed] = useState<boolean>(false);

  const handleCancelButtonClick = () => {
    setIsModalToBeClosed(true);
  };
  const handleConfirmButtonClick = () => {
    setIsModalToBeClosed(true);
    onConfirm();
  };

  const handleModalClose = () => {
    setIsModalToBeClosed(false);
    onClose();
  };

  return (
    <Modal
      isToBeClosed={isModalToBeClosed}
      onClose={handleModalClose}
      className={styles.standard_modal}
    >
      <div className={styles.standard_warning_modal_content}>
        <div className={styles.modal_header}>
          <div className={styles.modal_title}>
            Вы уверены, что хотите выйти из редактирования? Все несохраненные
            данные будут удалены
          </div>
          <IconButton
            iconName={IconsNames.Cross}
            isClear
            onClick={handleCancelButtonClick}
            className={styles.modal_cross_icon}
          />
        </div>
        <div className={styles.warning_modal_buttons_row}>
          <Button
            onClick={handleCancelButtonClick}
            isSecondary
            className={styles.warning_modal_button}
          >
            Отмена
          </Button>
          <Button
            onClick={handleConfirmButtonClick}
            className={styles.warning_modal_button}
          >
            Сохранить и продолжить
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default StandardEditWarningModal;
