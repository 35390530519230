export enum FileFormats {
  XLS = 'xls',
  XLSX = 'xlsx',
  PDF = 'pdf',
}

function parseContentDisposition(
  contentDisposition: string,
  fileFormat: FileFormats,
) {
  const filenameRegex = new RegExp(`filename="(.*?).${fileFormat}"`);
  const matches = filenameRegex.exec(contentDisposition);
  const fileName = matches[1];

  return decodeURI(fileName);
}

export default parseContentDisposition;
