import axios, { ResponseType } from 'axios';

import { $authHeader } from '../Store/userStore';
import getAPIPath from '../Utils/getAPIPath';

const HOST = getAPIPath();

export async function getData<ReponseType = any>(
  link: string,
  params?: Record<string, any>,
  responseType: ResponseType = 'json',
) {
  const authHeader = $authHeader.getState();

  const url: string = `${HOST}${link}`;
  if (params) {
    return axios
      .get<ReponseType>(url, {
        params,
        responseType,
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => response);
  }
  return axios
    .get<ReponseType>(url, {
      headers: {
        Authorization: authHeader,
      },
    })
    .then((response) => response);
}

export async function postData(
  link: string,
  data?: Record<string, any>,
  contentType: string = 'application/json',
) {
  const url: string = `${HOST}${link}`;
  const authHeader = $authHeader.getState();

  const config = {
    headers: {
      accept: 'application/json',
      'Content-Type': contentType,
      Authorization: authHeader,
    },
  };
  return axios.post(url, data, config);
}
