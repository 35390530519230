import React from 'react';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import startOfMonth from 'date-fns/startOfMonth';

import { SwitcherButtonType } from '../../Components/ButtonsSwitcher/ButtonsSwitcher.interface';
import {
  StandardsOptionLabels,
  StatusesOptionLabels,
  WaterOptionLabels,
} from '../../Components/OptionLabels';
import { RangeType } from '../../Components/PeriodPickerSection/PeriodPickerSection.interface';
import {
  CommonOptionsType,
  OptionType,
} from '../../Components/Select/Select.interface';
import { StatusesList } from '../../Static/statusesList';

import {
  DeleteResearchData,
  IntermediateWaterSubTypes,
  IntermediateWaterSubTypesLabel,
  WaterTypes,
  WaterTypesLabels,
} from './PageLaboratoryTests.interface';

import styles from './PageLaboratoryTests.module.scss';

export const defaultStandardOption: OptionType = {
  value: null,
  label: (
    <StandardsOptionLabels waterType={WaterTypes.All} name="Все стандарты" />
  ),
};

export const waterTypesOptionsList: CommonOptionsType[] = [
  {
    value: null,
    label: <WaterOptionLabels waterType={WaterTypes.All} />,
  },
  {
    value: WaterTypes.Potable,
    label: <WaterOptionLabels waterType={WaterTypes.Potable} isPlural />,
  },
  {
    value: WaterTypes.Surface,
    label: <WaterOptionLabels waterType={WaterTypes.Surface} isPlural />,
  },
  {
    value: WaterTypes.Intermediate,
    label: <WaterOptionLabels waterType={WaterTypes.Intermediate} isPlural />,
  },
  {
    label: <div className={styles.empty_group_label} />,
    options: [
      {
        value: IntermediateWaterSubTypes.Filters,
        label: IntermediateWaterSubTypesLabel.get(
          IntermediateWaterSubTypes.Filters,
        ),
      },
      {
        value: IntermediateWaterSubTypes.Sump,
        label: IntermediateWaterSubTypesLabel.get(
          IntermediateWaterSubTypes.Sump,
        ),
      },
      {
        value: IntermediateWaterSubTypes.PostPotable,
        label: IntermediateWaterSubTypesLabel.get(
          IntermediateWaterSubTypes.PostPotable,
        ),
      },
    ],
  },
];

export const modalWaterTypesOptionsList: CommonOptionsType[] = [
  {
    value: WaterTypes.Potable,
    label: <WaterOptionLabels waterType={WaterTypes.Potable} />,
  },
  {
    value: WaterTypes.Surface,
    label: <WaterOptionLabels waterType={WaterTypes.Surface} />,
  },
  {
    label: <WaterOptionLabels waterType={WaterTypes.Intermediate} />,
    options: [
      {
        value: IntermediateWaterSubTypes.Filters,
        label: IntermediateWaterSubTypesLabel.get(
          IntermediateWaterSubTypes.Filters,
        ),
      },
      {
        value: IntermediateWaterSubTypes.Sump,
        label: IntermediateWaterSubTypesLabel.get(
          IntermediateWaterSubTypes.Sump,
        ),
      },
      {
        value: IntermediateWaterSubTypes.PostPotable,
        label: IntermediateWaterSubTypesLabel.get(
          IntermediateWaterSubTypes.PostPotable,
        ),
      },
    ],
  },
];

export const defaultWaterTypeOption: OptionType = {
  value: null,
  label: <WaterOptionLabels waterType={WaterTypes.All} />,
};

export const defaultModalWaterTypeOption: OptionType = {
  value: WaterTypes.Potable,
  label: <WaterOptionLabels waterType={WaterTypes.Potable} />,
};

export const statusesOptionsList: OptionType[] = [
  {
    value: null,
    label: <StatusesOptionLabels status={null} />,
  },
  {
    value: StatusesList.Success,
    label: <StatusesOptionLabels status={StatusesList.Success} />,
  },
  {
    value: StatusesList.Warning,
    label: <StatusesOptionLabels status={StatusesList.Warning} />,
  },
  {
    value: StatusesList.Critical,
    label: <StatusesOptionLabels status={StatusesList.Critical} />,
  },
];

export const defaultDateRange: RangeType = {
  startDate: startOfMonth(new Date()),
  endDate: lastDayOfMonth(new Date()),
};

export const tableColumnsList: string[] = [
  'Номер отчета',
  'Дата и время',
  'Тип отчета',
  'Стандарт',
  'Автор',
  'Точка отбора проб',
];

export const valueViaSubType = {
  [IntermediateWaterSubTypes.Filters]: 'Скорые фильтры',
  [IntermediateWaterSubTypes.Sump]: 'Горизонт. отстойники',
  [IntermediateWaterSubTypes.PostPotable]: 'Город',
};

export const propertiesTableColumnsList: string[] = [
  'Показатель',
  'Значение',
  'Предыдущее значение',
  'Изменение',
  'Распределение',
];

export const waterTypesSwitcherOptionsList: SwitcherButtonType[] = [
  {
    children: WaterTypesLabels.get(WaterTypes.Surface),
    value: WaterTypes.Surface,
  },
  {
    children: WaterTypesLabels.get(WaterTypes.Potable),
    value: WaterTypes.Potable,
  },
  {
    children: WaterTypesLabels.get(WaterTypes.Intermediate),
    value: WaterTypes.Intermediate,
  },
];

export const deleteResearchDataDefault: DeleteResearchData = {
  id: null,
  isModalShown: false,
};

export const strangeValueWarningMessage: string =
  'Вы ввели значения, сильно отличащиеся от средних значений текущих показателей. Подтвердите правильность введённых данных для продолжения.';
