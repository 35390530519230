import React, { useState } from 'react';
import clsx from 'clsx';

import Button from '../../../Components/Button/Button';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton/IconButton';
import Modal from '../../../Components/Modal';
import { ResearchDeleteModalProps } from '../PageLaboratoryTests.interface';

import styles from '../PageLaboratoryTests.module.scss';

function ResearchDeleteModal(props: ResearchDeleteModalProps) {
  const { id, onConfirm, onClose } = props;

  const [isModalToBeClosed, setIsModalToBeClosed] = useState<boolean>(false);

  const handleCancelButtonClick = () => {
    setIsModalToBeClosed(true);
  };
  const handleConfirmButtonClick = () => {
    setIsModalToBeClosed(true);
    onConfirm();
  };

  const handleModalClose = () => {
    setIsModalToBeClosed(false);
    onClose();
  };

  return (
    <Modal
      isToBeClosed={isModalToBeClosed}
      onClose={handleModalClose}
      className={styles.report_modal}
    >
      <div
        className={clsx(
          styles.report_modal_content,
          styles.delete_report_modal_content,
        )}
      >
        <div className={clsx(styles.modal_header, styles.delete_modal_header)}>
          <div className={clsx(styles.modal_title, styles.delete_modal_title)}>
            Вы уверены, что хотите удалить отчет по лабораторному исследованию №{' '}
            {id}?
          </div>
          <IconButton
            iconName={IconsNames.Cross}
            isClear
            onClick={handleCancelButtonClick}
            className={styles.cross_icon_button}
          />
        </div>
        <div className={styles.delete_modal_button_section}>
          <Button
            onClick={handleCancelButtonClick}
            className={styles.warning_modal_button}
            isSecondary
          >
            Отменить
          </Button>
          <Button
            onClick={handleConfirmButtonClick}
            className={styles.warning_modal_button}
          >
            Удалить
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ResearchDeleteModal;
