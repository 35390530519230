import {
  checkIsNameValid,
  checkIsPhoneSymbolValid,
} from '../../../Utils/checkIsValueValid';
import { ignoreSymbolsList, RegisterFieldsNamesList } from '../constants';

export function checkIsSymbolValid(
  key: string,
  fieldName: RegisterFieldsNamesList,
) {
  let isSymbolValid = true;

  if (ignoreSymbolsList.includes(key)) {
    return isSymbolValid;
  }

  switch (fieldName) {
    case RegisterFieldsNamesList.FirstName:
    case RegisterFieldsNamesList.LastName:
    case RegisterFieldsNamesList.SecondName:
      isSymbolValid = checkIsNameValid(key);
      break;
    case RegisterFieldsNamesList.Phone:
      isSymbolValid = checkIsPhoneSymbolValid(key);
      break;
    default:
      break;
  }

  return isSymbolValid;
}
