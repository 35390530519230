import React from 'react';
import clsx from 'clsx';

import Icon from '../Icon';
import { IconsNames } from '../Icon/constants';

import { CheckboxProps } from './Checkbox.interface';

import styles from './Checkbox.module.scss';

function Checkbox(props: CheckboxProps) {
  const {
    checked,
    onChange,
    name,
    readOnly,
    disabled,
    className,
    value,
    checkedIcon,
    uncheckedIcon,
    caption,
  } = props;

  const getCheckboxMarker = (isChecked: boolean) => {
    const checkedContent = checkedIcon || (
      <Icon name={IconsNames.CheckedCheckbox} />
    );
    const uncheckedContent = uncheckedIcon || (
      <Icon name={IconsNames.UncheckedCheckbox} />
    );
    return (
      <div
        className={clsx(styles.checkbox_marker, {
          [styles.disabled]: disabled,
        })}
      >
        {isChecked ? checkedContent : uncheckedContent}
      </div>
    );
  };

  return (
    <label className={clsx(styles.checkbox_label, className)} htmlFor={name}>
      <input
        type="checkbox"
        name={name}
        readOnly={readOnly}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        value={value}
        className={styles.checkbox_input}
      />
      {getCheckboxMarker(checked)}
      {caption && <span className={styles.checkbox_caption}>{caption}</span>}
    </label>
  );
}

export default Checkbox;
