import { StatusesList } from '../../Static/statusesList';

export enum CardTypes {
  Input = 'input',
  Result = 'result',
}

export type PageMappingCardProps = {
  id: string;
  status: StatusesList;
  title: string;
  name: string;
  date: Date;
  type: CardTypes;
  isConnected: boolean;
  connectionsList: number[];
  isEditDisabled: boolean;
  minAreaDate?: Date;
  maxAreaDate?: Date;
  className?: string;
  performance: number;
};

export type PageMappingTimeLineProps = {
  currentDate: Date;
  cards: PageMappingCardProps[];
  connections: CardsConnectionDataType[];
  isTimeLagShown: boolean;
  isDragActive: boolean;
};

export type PageMappingCardWrapperProps = {
  card: PageMappingCardProps;
  isTimeLagShown: boolean;
  previousDate: Date;
};

export enum ArrowPositions {
  Left = 'left',
  Top = 'top',
  Bottom = 'bottom',
}

export type CardsConnectionDataType = {
  id: number;
  startCardId: string;
  endCardId: string;
};

export type PageMappingDraggableCardPointProps = {
  id: string;
  connectionId: number;
  anchor: ArrowPositions;
  isConnected: boolean;
  onDragStart?: () => void;
};

export type PageMappingDroppableContainerProps = {
  id: string;
  type: CardTypes;
  isConnected: boolean;
  connectionsList: number[];
};
