import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import reactDom from 'react-dom';
import clsx from 'clsx';

import { IconsNames } from '../Icon/constants';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton';
import Prompt from '../Prompt';

import { ToastProps, ToastTypes } from './Toast.interface';

import styles from './Toast.module.scss';

function Toast(props: ToastProps) {
  const {
    isShown,
    message,
    toastType,
    className,
    onClose,
    title,
    closeDelay,
    promptProps,
  } = props;
  const modalsContainerRef = useRef(document.querySelector('#root_modals'));

  const [isToBeClosed, setIsToBeClosed] = useState<boolean>(false);

  const toastRef = useRef(null);

  const toastClassNames = clsx(styles.toast, {
    [styles.toast_close]: isToBeClosed || !isShown,
  });

  const toastIconName: IconsNames = useMemo(() => {
    switch (toastType) {
      case ToastTypes.Success:
        return IconsNames.NotificationCheck;
      case ToastTypes.Warning:
        return IconsNames.NotificationExclamation;
      case ToastTypes.Critical:
        return IconsNames.NotificationCross;
      default:
        return IconsNames.NotificationInfo;
    }
  }, [toastType]);

  const handleAnimationEnd = useCallback(() => {
    if (isToBeClosed) {
      onClose();
    }
    setIsToBeClosed(false);
  }, [isToBeClosed]);

  const handleCloseButtonClick = () => {
    setIsToBeClosed(true);
  };

  useEffect(() => {
    if (!closeDelay) {
      return;
    }
    setTimeout(() => {
      setIsToBeClosed(true);
    }, closeDelay);
  }, [closeDelay]);

  return reactDom.createPortal(
    <div
      ref={toastRef}
      className={clsx(
        styles.toast_wrapper,
        styles[toastType],
        className,
        toastClassNames,
      )}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className={clsx(styles.toast_icon_wrapper, styles[toastType])}>
        <Icon name={toastIconName} className={styles.toast_icon} />
      </div>
      <div className={styles.toast_text}>
        {title && <div className={styles.toast_title}>{title}</div>}
        <div className={styles.toast_message}>
          {promptProps !== undefined ? (
            <Prompt
              message={promptProps.message}
              submitButtonLabel={promptProps.submitButtonLabel}
              cancelButtonLabel={promptProps.cancelButtonLabel}
              onSubmitButtonClick={promptProps.onSubmitButtonClick}
              onCancelButtonClick={handleCloseButtonClick}
            />
          ) : (
            message
          )}
        </div>
      </div>
      <IconButton
        iconName={IconsNames.Cross}
        onClick={handleCloseButtonClick}
        className={styles.close_button}
        isClear
      />
    </div>,
    modalsContainerRef.current,
  );
}

export default Toast;
