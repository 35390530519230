import React, { KeyboardEvent, useMemo, useState } from 'react';
import clsx from 'clsx';

import { IconsNames } from '../Icon/constants';
import IconButton from '../IconButton';

import { ignoreNumberInputSymbolsList, InputTypes } from './constants';
import { InputProps } from './Input.interface';

import styles from './Input.module.scss';

function Input(props: InputProps) {
  const {
    value,
    onChange,
    type,
    min,
    max,
    className,
    disabled,
    readOnly,
    ref,
    placeholder,
    name,
    step,
    isErrorShown,
    errorMessage,
    tabIndex,
    onKeyPress,
    onBlur,
  } = props;

  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);

  const isEyeButtonVisible: boolean = type === InputTypes.Password;

  const iconName: IconsNames = isPasswordShown
    ? IconsNames.EyeOpen
    : IconsNames.EyeClose;

  const handleEyeButtonClick = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const inputType = useMemo(() => {
    if (type !== InputTypes.Password) {
      return type;
    }
    if (isPasswordShown) {
      return InputTypes.Text;
    }
    return InputTypes.Password;
  }, [type, isPasswordShown]);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      inputType === InputTypes.Number &&
      ignoreNumberInputSymbolsList.includes(event.key)
    ) {
      event.preventDefault();
    }
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  return (
    <div className={clsx(styles.input_wrapper, className)}>
      <input
        value={value}
        onChange={onChange}
        type={inputType}
        min={min}
        max={max}
        step={step}
        className={clsx(styles.input, {
          [styles.error_shown]: isErrorShown,
        })}
        disabled={disabled}
        readOnly={readOnly}
        ref={ref}
        placeholder={placeholder}
        name={name}
        onKeyDown={handleKeyPress}
        onBlur={onBlur}
        tabIndex={tabIndex}
      />
      {isEyeButtonVisible && (
        <IconButton
          iconName={iconName}
          onClick={handleEyeButtonClick}
          className={styles.eye_button}
          isClear
          tabIndex={-1}
        />
      )}
      {isErrorShown && <div className={styles.error}>{errorMessage}</div>}
    </div>
  );
}

export default Input;
