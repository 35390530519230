import React, { ChangeEvent, useMemo } from 'react';
import clsx from 'clsx';
import { useStore } from 'effector-react';

import Button from '../../../Components/Button';
import Icon from '../../../Components/Icon';
import { IconsNames } from '../../../Components/Icon/constants';
import Input from '../../../Components/Input';
import { InputTypes } from '../../../Components/Input/constants';
import Select from '../../../Components/Select';
import { OptionType } from '../../../Components/Select/Select.interface';
import {
  $formValues,
  $waterCanal,
  setFormValues,
  setWaterCanal,
} from '../../../Store/authStore';
import { RegistrationRequestParamsType } from '../../../Store/types';
import { RegisterFieldsNames, RegisterFieldsNamesList } from '../constants';
import {
  RegisterFormProps,
  ValidatedFormValuesType,
} from '../PageAuth.interface';
import { checkIsSymbolValid } from '../utils/checkIsSymbolValid';
import { checkIsValueValid } from '../utils/checkIsValueValid';
import maskValues from '../utils/maskValues';

import styles from '../PageAuth.module.scss';

function RegisterForm(props: RegisterFormProps) {
  const { onLinkClick, onRequestSubmit, waterCanalsOptionsList } = props;

  const formValues: ValidatedFormValuesType = useStore($formValues);
  const waterCanal: OptionType = useStore($waterCanal);

  const handleFormValuesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const inputValue = maskValues(value, name as RegisterFieldsNamesList);
    const isValueValid = checkIsValueValid(
      inputValue,
      name as RegisterFieldsNamesList,
    );

    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value: inputValue,
        isErrorShown: !isValueValid,
      },
    });
  };

  const handleFormKeyPress = (event: any) => {
    const { key } = event;
    const { name } = event.target;

    const isSymbolValid = checkIsSymbolValid(
      key,
      name as RegisterFieldsNamesList,
    );

    if (!isSymbolValid) {
      event.preventDefault();
    }
  };

  const handleWaterCanalValueChange = (selectedOption: OptionType) => {
    setWaterCanal(selectedOption);
  };

  const handleRequestSubmit = () => {
    const { email, firstName, lastName, secondName, phone } = formValues;
    const params: RegistrationRequestParamsType = {
      waterCanalId: Number(waterCanal.value),
      email: email.value,
      firstName: firstName.value,
      lastName: lastName.value,
      middleName: secondName.value,
      phone: phone.value,
    };
    onRequestSubmit(params);
  };

  const phoneNumberHelper = (
    <span className={styles.phone_number_helper}>+7</span>
  );

  const formRows = useMemo(
    () =>
      Object.keys(formValues).map((formValueKey) => {
        const { isRequired, value, isErrorShown, errorMessage } =
          formValues[formValueKey];

        const isPhoneNumber = formValueKey === RegisterFieldsNamesList.Phone;

        return (
          <div className={styles.input_row} key={formValueKey}>
            <div
              className={clsx(styles.input_label, {
                [styles.required]: isRequired,
              })}
            >
              {RegisterFieldsNames.get(formValueKey)}
            </div>
            <Input
              type={InputTypes.Text}
              value={value}
              onChange={handleFormValuesChange}
              name={formValueKey}
              className={clsx(styles.input, {
                [styles.phone_number_input]: isPhoneNumber,
              })}
              isErrorShown={isErrorShown}
              errorMessage={errorMessage}
              onKeyPress={handleFormKeyPress}
            />
            {isPhoneNumber && phoneNumberHelper}
          </div>
        );
      }),
    [formValues],
  );

  const isSubmitEnabled = useMemo(() => {
    const isTextFieldListFilled = Object.values(formValues).every(
      (formValue) => {
        const { isErrorShown, value, isRequired } = formValue;

        return (
          !isErrorShown && ((value !== '' && value !== null) || !isRequired)
        );
      },
    );
    const isWaterCanalChosen = waterCanal !== null;

    return isTextFieldListFilled && isWaterCanalChosen;
  }, [formValues, waterCanal]);

  return (
    <div className={styles.form}>
      <form>
        {formRows}
        <div className={styles.input_row}>
          <div className={clsx(styles.input_label, styles.required)}>
            Водоканал
          </div>
          <Select
            value={waterCanal}
            onChange={handleWaterCanalValueChange}
            className={styles.input}
            options={waterCanalsOptionsList}
            placeholder="Выберите водоканал"
            isSearchable
          />
        </div>
        <Button
          onClick={handleRequestSubmit}
          className={styles.submit_button}
          isDisabled={!isSubmitEnabled}
        >
          Зарегистрироваться
        </Button>
      </form>
      <div className={styles.link_section}>
        Уже есть аккаунт?
        <button
          type="button"
          className={styles.link_button}
          onClick={onLinkClick}
        >
          Войти <Icon name={IconsNames.RightArrow} />
        </button>
      </div>
    </div>
  );
}

export default RegisterForm;
