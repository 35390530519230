import React, { useEffect, useMemo } from 'react';
import Xarrow, { useXarrow, Xwrapper } from 'react-xarrows';
import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';

import { DateFormats } from '../../../Components/DatePicker/DatePicker.interface';
import {
  ArrowPositions,
  CardsConnectionDataType,
  PageMappingCardProps,
  PageMappingTimeLineProps,
} from '../PageMapping.interface';

import PageMappingCardWrapper from './PageMappingCardWrapper';

import styles from '../PageMapping.module.scss';

function PageMappingTimeLine(props: PageMappingTimeLineProps) {
  const { currentDate, cards, isTimeLagShown, connections, isDragActive } =
    props;
  const timeColumns: JSX.Element[] = useMemo(() => {
    const columnsList: JSX.Element[] = [];

    for (let k = 0; k < 3; k += 1) {
      let date = new Date(2023, 0, 1, 0, 0);
      for (let i = 0; i < 24; i += 1) {
        columnsList.push(
          <div className={styles.timeline_background_time_column}>
            <div className={styles.timeline_background_time_column_title}>
              {format(date, DateFormats.HHmm)}
            </div>
            <div className={styles.timeline_background_time_column_content} />
          </div>,
        );
        date = addHours(date, 1);
      }
    }

    return columnsList;
  }, []);

  const { previousDate, nextDate } = useMemo(() => {
    const previousDateValue: Date = subDays(currentDate, 1);
    const nextDateValue: Date = addDays(currentDate, 1);

    return { previousDate: previousDateValue, nextDate: nextDateValue };
  }, [currentDate]);

  const timeLineBgSections: JSX.Element = useMemo(() => {
    const currentDateFormatted: string = format(
      currentDate,
      DateFormats.ddMMyyyy,
    );
    const nextDateFormatted: string = format(nextDate, DateFormats.ddMMyyyy);
    const previousDateFormatted: string = format(
      previousDate,
      DateFormats.ddMMyyyy,
    );

    return (
      <div className={styles.timeline_background}>
        <div className={styles.timeline_background_header}>
          <div className={styles.timeline_background_header_day}>
            {previousDateFormatted}
          </div>
          <div className={styles.timeline_background_header_day}>
            {currentDateFormatted}
          </div>
          <div className={styles.timeline_background_header_day}>
            {nextDateFormatted}
          </div>
        </div>
        <div className={styles.timeline_background_body}>{timeColumns}</div>
      </div>
    );
  }, [currentDate, previousDate, nextDate, timeColumns]);

  const cardsList: JSX.Element[] = useMemo(
    () =>
      cards.map((card: PageMappingCardProps) => {
        const { id } = card;

        return (
          <div key={id}>
            <PageMappingCardWrapper
              card={card}
              isTimeLagShown={isTimeLagShown}
              previousDate={previousDate}
            />
          </div>
        );
      }),
    [cards, previousDate, isTimeLagShown],
  );

  const arrowsList: JSX.Element[] = useMemo(
    () =>
      connections.map((connection: CardsConnectionDataType) => {
        const { id, startCardId, endCardId } = connection;
        const startId: string = startCardId.toString();
        const endId: string = endCardId.toString();
        return (
          <Xarrow
            key={id}
            start={startId}
            end={endId}
            startAnchor={ArrowPositions.Top}
            endAnchor={ArrowPositions.Bottom}
            color="#797A7B"
            strokeWidth={1}
            headSize={9}
            zIndex={10}
          />
        );
      }),
    [connections],
  );

  const updateXarrow = useXarrow();

  useEffect(() => {
    updateXarrow();
  }, [cards, connections, isDragActive]);

  return (
    <div className={styles.page_mapping_timeline}>
      {timeLineBgSections}
      <Xwrapper>
        <div className={styles.page_mapping_cards_wrapper}>{cardsList}</div>
        {arrowsList}
      </Xwrapper>
    </div>
  );
}

export default PageMappingTimeLine;
