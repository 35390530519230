import React from 'react';
import clsx from 'clsx';

import { TextareaProps } from './Textarea.interface';

import styles from './Textarea.module.scss';

function Textarea(props: TextareaProps) {
  const {
    value,
    onChange,
    disabled,
    name,
    readOnly,
    cols,
    rows,
    className,
    placeholder,
  } = props;

  return (
    <textarea
      value={value}
      onChange={onChange}
      rows={rows}
      cols={cols}
      className={clsx(className, styles.textarea)}
      disabled={disabled}
      name={name}
      readOnly={readOnly}
      placeholder={placeholder}
    />
  );
}

export default Textarea;
