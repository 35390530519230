import React from 'react';
import clsx from 'clsx';

import { ButtonProps } from './Button.interface';

import styles from './Button.module.scss';

function Button(props: ButtonProps) {
  const { onClick, className, children, isSecondary, isDisabled, tabIndex } =
    props;

  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(styles.button, className, {
        [styles.secondary]: isSecondary,
      })}
      disabled={isDisabled}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
}

export default Button;
