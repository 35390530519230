import { AxiosError, AxiosResponse } from 'axios';
import { createEffect } from 'effector';

import { MonitoringReportsSummaryParamsType } from '../Containers/PageMonitoringContainers/PageMonitoringContainers.interface';
import { ResearchReportSummaryParamsType } from '../Containers/ResearchReportsContainers/ResearchReportsContainers.interface';
import {
  exportMonitoringReports,
  exportResearchReportsList,
} from '../DataAccessLayer/apiServices';
import downloadXLSXFile from '../Utils/downloadFile';
import handleError from '../Utils/handleError';
import parseContentDisposition, {
  FileFormats,
} from '../Utils/parseContentDisposition';

// research reports
const exportResearchReportsListFx = createEffect<
  ResearchReportSummaryParamsType,
  void,
  Error
>();

const exportResearchReportsListAction = async (
  params: ResearchReportSummaryParamsType,
) => {
  await exportResearchReportsList(params)
    .then((response: AxiosResponse) => {
      const fileName = parseContentDisposition(
        response.headers['content-disposition'],
        FileFormats.XLSX,
      );
      downloadXLSXFile(
        response.data,
        fileName,
        response.headers['content-type'],
      );
    })

    .catch((error: AxiosError) => {
      handleError({
        error,
        request: exportResearchReportsListFx,
        params,
        shouldShowToast: true,
        toastMessage: 'Не удалось скачать результаты исследований',
      });

      return error.response;
    });
};

exportResearchReportsListFx.use(exportResearchReportsListAction);

// monitoring reports
const exportMonitoringReportsListFx = createEffect<
  MonitoringReportsSummaryParamsType,
  void,
  Error
>();

const exportMonitoringReportsListAction = async (
  params: MonitoringReportsSummaryParamsType,
) => {
  await exportMonitoringReports(params)
    .then((response: AxiosResponse) => {
      const fileName = parseContentDisposition(
        response.headers['content-disposition'],
        FileFormats.XLSX,
      );

      downloadXLSXFile(
        response.data,
        fileName,
        response.headers['content-type'],
      );
    })

    .catch((error: AxiosError) => {
      handleError({
        error,
        request: exportMonitoringReportsListFx,
        params,
        shouldShowToast: true,
        toastMessage: 'Не удалось скачать результаты исследований',
      });

      return error.response;
    });
};

exportMonitoringReportsListFx.use(exportMonitoringReportsListAction);

export { exportMonitoringReportsListFx, exportResearchReportsListFx };
