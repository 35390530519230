import React, { useMemo } from 'react';
import clsx from 'clsx';

import Panel from './components/Panel';
import { AccordionProps } from './Accordion.interface';

import styles from './Accordion.module.scss';

function Accordion(props: AccordionProps) {
  const {
    panels,
    openPanelsIds,
    onOpenPanelsIdsListChange,
    className,
    isDeleteActive,
    onDelete,
    onEdit,
  } = props;

  const panelsList = useMemo(
    () =>
      panels.map((panel) => {
        const { headerContent, bodyContent, id, status, createdAt } = panel;

        const isPanelOpen = openPanelsIds.includes(id);

        const handlePanelClick = () => {
          let newOpenPanelsIdsList: number[] = openPanelsIds.slice();

          if (isPanelOpen) {
            newOpenPanelsIdsList = newOpenPanelsIdsList.filter(
              (panelId) => panelId !== id,
            );
          } else {
            newOpenPanelsIdsList.push(id);
          }

          onOpenPanelsIdsListChange(newOpenPanelsIdsList);
        };

        return (
          <div className={styles.panel_wrapper} key={id}>
            <Panel
              panelType="research"
              id={id}
              headerContent={headerContent}
              bodyContent={bodyContent}
              isOpen={isPanelOpen}
              onClick={handlePanelClick}
              status={status}
              isDeleteActive={isDeleteActive}
              onDeleteButtonClick={onDelete}
              createdAt={createdAt}
              onEditButtonClick={onEdit}
            />
          </div>
        );
      }),
    [panels, openPanelsIds, onOpenPanelsIdsListChange],
  );

  return <div className={clsx(styles.accordion, className)}>{panelsList}</div>;
}

export default Accordion;
