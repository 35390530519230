import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';

import PageAdminModalConfirm from '../../Pages/PageAdmin/components/PageAdminModalConfirm';
import PageAdminTable from '../../Pages/PageAdmin/components/PageAdminTable';
import {
  UserDataType,
  UserToDelete,
} from '../../Pages/PageAdmin/PageAdmin.interface';
import { RolesList } from '../../Static/rolesList';
import {
  $usersList,
  changeUserRightsFx,
  deleteUserFx,
  getAdminDataFx,
} from '../../Store/adminPageStore';
import { $userId } from '../../Store/authStore';
import { ChangeUserRightsParamsType } from '../../Store/types';

import { userToDeleteDefault } from './constants';

function PageAdminTableContainer() {
  const users: UserDataType[] = useStore($usersList);
  const [isConfirmModalShown, setIsConfirmModalShown] =
    useState<boolean>(false);
  const [userToDelete, setUserToDelete] =
    useState<UserToDelete>(userToDeleteDefault);
  const userId = useStore($userId);

  const handleDeleteButtonClick = (user: UserToDelete) => {
    setUserToDelete(user);
    setIsConfirmModalShown(true);
  };

  const handleModalClose = () => {
    setUserToDelete(null);
    setIsConfirmModalShown(false);
  };

  const handleDeleteConfirm = () => {
    deleteUserFx(userToDelete.id);
  };

  const handleRoleChange = (id: number, roleId: number, isChecked: boolean) => {
    const isAllowed: boolean = !(
      id === userId &&
      roleId === RolesList.ADMIN &&
      !isChecked
    );

    if (isAllowed) {
      const params: ChangeUserRightsParamsType = {
        userId: id,
        roleId,
        toggle: isChecked,
      };

      changeUserRightsFx(params);
    }
  };

  useEffect(() => {
    getAdminDataFx();
  }, []);

  return (
    <>
      <PageAdminTable
        users={users}
        onUserDelete={handleDeleteButtonClick}
        onRoleChange={handleRoleChange}
      />

      {isConfirmModalShown && (
        <PageAdminModalConfirm
          onClose={handleModalClose}
          onConfirm={handleDeleteConfirm}
          name={userToDelete.name}
        />
      )}
    </>
  );
}

export default PageAdminTableContainer;
