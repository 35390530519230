import React from 'react';

import SkeletonPageHeader from './SkeletonPageHeader';
import SkeletonPageTable from './SkeletonPageTable';

function SkeletonPage() {
  return (
    <div>
      <SkeletonPageHeader />
      <SkeletonPageTable />
    </div>
  );
}

export default SkeletonPage;
