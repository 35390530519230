import React, { useEffect } from 'react';
import { useStore } from 'effector-react';

import { OptionType } from '../../Components/Select/Select.interface';
import LoginForm from '../../Pages/PageAuth/components/LoginForm';
import RegisterForm from '../../Pages/PageAuth/components/RegisterForm';
import { PageTypes } from '../../Pages/PageAuth/PageAuth.interface';
import { sendRegistrationRequestFx } from '../../Store/authStore';
import {
  $waterCanals,
  getWaterCanalsListFx,
} from '../../Store/cataloguesStore';
import { RegistrationRequestParamsType } from '../../Store/types';

import { AuthFormContainerProps } from './AuthFormContainer.interface';

function AuthFormContainer(props: AuthFormContainerProps) {
  const { onLoginLinkClick, onRegisterLinkClick, pageType } = props;
  const waterCanalsOptionsList: OptionType[] = useStore($waterCanals);

  const handleRequestSubmit = (params: RegistrationRequestParamsType) => {
    sendRegistrationRequestFx(params);
  };

  const FormComponent =
    pageType === PageTypes.Register ? (
      <RegisterForm
        onLinkClick={onLoginLinkClick}
        waterCanalsOptionsList={waterCanalsOptionsList}
        onRequestSubmit={handleRequestSubmit}
      />
    ) : (
      <LoginForm onLinkClick={onRegisterLinkClick} pageType={pageType} />
    );

  useEffect(() => {
    getWaterCanalsListFx();
  }, []);

  return FormComponent;
}

export default AuthFormContainer;
