import { AxiosError, AxiosResponse } from 'axios';
import { createEffect, createEvent, createStore } from 'effector';

import { fetchCurrentUserDataAdapter } from '../Adapters/fetchUsersListAdapter';
import { OptionType } from '../Components/Select/Select.interface';
import { ToastTypes } from '../Components/Toast/Toast.interface';
import {
  changePassword,
  checkTokenValidity,
  createPasswordChangeRequest,
  getCurrentUser,
  getPredictionType,
  logIn,
  logOut,
  refresh,
  sendRegistrationRequest,
  setPredictionType,
  updateQueueConfig,
  updateSubTypesConfig,
} from '../DataAccessLayer/apiServices';
import { defaultRegisterFormValues } from '../Pages/PageAuth/constants';
import { ValidatedFormValuesType } from '../Pages/PageAuth/PageAuth.interface';
import { ErrorsList } from '../Static/errorsList';
import { getShortFullName } from '../Utils/getFullName';

import { setToastData } from './toastStore';
import {
  ChangePasswordParamsType,
  CreatePasswordChangeRequestParamsType,
  LoginParamsType,
  RefreshTokenParamsType,
  RegistrationRequestParamsType,
  SetVodokanalPredictionType,
  UserConfigType,
  UserDataType,
} from './types';
import { setAuthHeader } from './userStore';

const resetAuthData = createEvent();

let refreshTimeout;
let logoutTimeout;

const setUserInitials = createEvent<string>();
const $userInitials = createStore<string>('')
  .on(setUserInitials, (_, userInitials: string) => userInitials)
  .reset(resetAuthData);
const setUserId = createEvent<number>();
const $userId = createStore<number>(null)
  .on(setUserId, (_, userId: number) => userId)
  .reset(resetAuthData);
const setUserRoles = createEvent<number[]>();
const $userRoles = createStore<number[]>([])
  .on(setUserRoles, (_, userRoles: number[]) => userRoles)
  .reset(resetAuthData);

const $userVodokanalId = createStore<number>(0);
const setUserVodokanalId = createEvent<number>();
$userVodokanalId.on(setUserVodokanalId, (_, payload) => payload);

const $userConfig = createStore<UserConfigType>({});
const setUserConfig = createEvent<UserConfigType>();
$userConfig.on(setUserConfig, (_, payload) => payload);

const setIsAuthenticated = createEvent<boolean>();
const $isAuthenticated = createStore<boolean>(false)
  .on(setIsAuthenticated, (_, isAuthenticated: boolean) => isAuthenticated)
  .reset(resetAuthData);

const setAccessToken = createEvent<string>();
const $accessToken = createStore<string>(null)
  .on(setAccessToken, (_, accessToken: string) => {
    localStorage.setItem('access', accessToken);
    return accessToken;
  })
  .reset(resetAuthData);

const setRefreshToken = createEvent<string>();
const $refreshToken = createStore<string>(null)
  .on(setRefreshToken, (_, refreshToken: string) => {
    localStorage.setItem('refresh', refreshToken);
    return refreshToken;
  })
  .reset(resetAuthData);

const setExpiresIn = createEvent<number>();
const $expiresIn = createStore<number>(null)
  .on(setExpiresIn, (_, expiresIn: number) => expiresIn)
  .reset(resetAuthData);

const setRefreshExpiresIn = createEvent<number>();
const $refreshExpiresIn = createStore<number>(null)
  .on(setRefreshExpiresIn, (_, refreshExpiresIn: number) => refreshExpiresIn)
  .reset(resetAuthData);

const refreshTokenFx = createEffect<RefreshTokenParamsType, void, Error>();
const logOutFx = createEffect<string, void, Error>();

const clearRefreshTimeout = () => {
  clearTimeout(refreshTimeout);
  clearTimeout(logoutTimeout);
};

const setRefreshTimeout = (
  refreshToken: string,
  expiresIn: number,
  refreshExpiresIn: number,
) => {
  clearRefreshTimeout();

  refreshTimeout = setTimeout(() => {
    refreshTokenFx({ refreshToken });
  }, expiresIn * 1000);

  logoutTimeout = setTimeout(() => {
    logOutFx(refreshToken);
  }, refreshExpiresIn * 1000);
};

const updateAuthData = (response: AxiosResponse) => {
  const {
    access_token: accessToken,
    refresh_token: refreshToken,
    expires_in: expiresIn,
    refresh_expires_in: refreshExpiresIn,
    token_type: tokenType,
  } = response.data;
  setIsAuthenticated(true);
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  setExpiresIn(expiresIn);
  setRefreshExpiresIn(refreshExpiresIn);
  setAuthHeader(`${tokenType} ${accessToken}`);
  setRefreshTimeout(refreshToken, expiresIn, refreshExpiresIn);
};

const refreshTokenAction = async (params: RefreshTokenParamsType) => {
  const { refreshToken, request, params: requestParams } = params;

  await refresh(refreshToken)
    .then((response: AxiosResponse) => {
      updateAuthData(response);

      if (request) {
        request(requestParams);
      }
    })
    .catch(() => {
      resetAuthData();
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      window.location.href = '/login';
    });
};
refreshTokenFx.use(refreshTokenAction);

const logOutAction = async (refreshToken: string) => {
  await logOut(refreshToken)
    .then(() => {
      resetAuthData();
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
    })
    .catch(() => {
      setToastData({
        toastType: ToastTypes.Critical,
        title: 'Что-то пошло не так',
        message: 'Не удалось выйти. Попробуйте ещё раз',
        isShown: true,
        closeDelay: 5000,
      });
    });
};
logOutFx.use(logOutAction);

const logInFx = createEffect<LoginParamsType, void, Error>();
const logInAction = async (params: LoginParamsType) => {
  const { login, password } = params;

  await logIn(login, password)
    .then((response: AxiosResponse) => {
      updateAuthData(response);
    })
    .catch((error) => {
      if (error?.response?.status === 400) {
        setToastData({
          isShown: true,
          toastType: ToastTypes.Critical,
          title: 'Не удалось войти',
          message: 'Неверный логин или пароль',
          closeDelay: 5000,
        });
      } else {
        setToastData({
          toastType: ToastTypes.Critical,
          title: 'Что-то пошло не так',
          message: 'Не удалось войти. Попробуйте ещё раз',
          isShown: true,
          closeDelay: 5000,
        });
      }
    });
};
logInFx.use(logInAction);

const resetForm = createEvent();
const setFormValues = createEvent<ValidatedFormValuesType>();
const $formValues = createStore<ValidatedFormValuesType>(
  defaultRegisterFormValues,
)
  .on(setFormValues, (_, formValues: ValidatedFormValuesType) => formValues)
  .reset(resetForm);
const setWaterCanal = createEvent<OptionType>();
const $waterCanal = createStore<OptionType>(null)
  .on(setWaterCanal, (_, waterCanal: OptionType) => waterCanal)
  .reset(resetForm);
const sendRegistrationRequestFx = createEffect<
  RegistrationRequestParamsType,
  void,
  Error
>();
const sendRegistrationRequestAction = async (
  params: RegistrationRequestParamsType,
) => {
  await sendRegistrationRequest(params)
    .then(() => {
      resetForm();
      setToastData({
        toastType: ToastTypes.Success,
        title: 'Заявка отправлена',
        message: 'Перейдите по ссылке в письме, отправленном на почту',
        isShown: true,
        closeDelay: 5000,
      });
    })
    .catch((error: AxiosError) => {
      if (error?.response?.data[0]?.type === ErrorsList.RequestAlreadyExists) {
        setToastData({
          toastType: ToastTypes.Critical,
          title: 'Что-то пошло не так',
          message: 'Заявка на регистрацию с указанной почтой уже существует',
          isShown: true,
          closeDelay: 5000,
        });
      } else {
        setToastData({
          toastType: ToastTypes.Critical,
          title: 'Что-то пошло не так',
          message:
            'Не удалось создать запрос на регистрацию. Попробуйте ещё раз',
          isShown: true,
          closeDelay: 5000,
        });
      }
    });
};
sendRegistrationRequestFx.use(sendRegistrationRequestAction);

const getCurrentUserFx = createEffect<void, void, Error>();
const getCurrentUserAction = async () => {
  await getCurrentUser()
    .then((response: AxiosResponse) => {
      const adaptedUserInfo: UserDataType = fetchCurrentUserDataAdapter(
        response.data,
      );

      const {
        firstName,
        middleName,
        lastName,
        id,
        roles,
        waterCanalId,
        config,
      } = adaptedUserInfo;

      const initials: string = getShortFullName(
        firstName,
        middleName,
        lastName,
      );
      setUserId(id);
      setUserInitials(initials);
      setUserRoles(roles);
      setUserVodokanalId(waterCanalId);
      setUserConfig(config);
    })
    .catch(() => {
      setUserInitials('');
    });
};

getCurrentUserFx.use(getCurrentUserAction);

const updateQueueConfigFx = createEffect<number, void>().use(async (queue) => {
  updateQueueConfig(queue).then(() => getCurrentUserFx());
});

const updateSubTypesConfigFx = createEffect<string[], void>().use(
  async (subTypes) => {
    updateSubTypesConfig(subTypes).then(() => getCurrentUserFx());
  },
);

// location pathname

const setLocationPathname = createEvent<string>();
const $locationPathname = createStore<string>('').on(
  setLocationPathname,
  (_, locationPathname: string) => locationPathname,
);

const setIsChangePasswordRequestFormShown = createEvent<boolean>();
const $isChangePasswordRequestFormShown = createStore<boolean>(false).on(
  setIsChangePasswordRequestFormShown,
  (_, isChangePasswordRequestFormShown: boolean) =>
    isChangePasswordRequestFormShown,
);
const setIsChangePasswordFormShown = createEvent<boolean>();
const $isChangePasswordFormShown = createStore<boolean>(false).on(
  setIsChangePasswordFormShown,
  (_, isChangePasswordFormShown: boolean) => isChangePasswordFormShown,
);

// change password
const setIsTokenValid = createEvent<boolean>();
const $isTokenValid = createStore<boolean>(null).on(
  setIsTokenValid,
  (_, isTokenValid: boolean) => isTokenValid,
);

const checkTokenValidityFx = createEffect<string, void, Error>();
const checkTokenValidityAction = async (token: string) => {
  await checkTokenValidity(token)
    .then(() => {
      setIsChangePasswordFormShown(true);
    })
    .catch((error) => {
      setIsTokenValid(false);
      const errorType: string = error?.response?.data[0]?.type;
      if (errorType === ErrorsList.WrongToken) {
        setIsChangePasswordRequestFormShown(true);
      } else {
        setToastData({
          toastType: ToastTypes.Critical,
          title: 'Что-то пошло не так',
          message:
            'Не удалось проверить валидность токена. Попробуйте перезагрузить страницу',
          isShown: true,
          closeDelay: 5000,
        });
      }
    });
};
checkTokenValidityFx.use(checkTokenValidityAction);

const createPasswordChangeRequestFx = createEffect<
  CreatePasswordChangeRequestParamsType,
  void,
  Error
>();
const createPasswordChangeRequestAction = async (
  params: CreatePasswordChangeRequestParamsType,
) => {
  await createPasswordChangeRequest(params)
    .then(() => {
      setToastData({
        toastType: ToastTypes.Success,
        title: 'Заявка на смену пароля создана',
        message: 'Перейдите по ссылке в письме, отправленном на почту',
        isShown: true,
        closeDelay: 5000,
      });
      setIsChangePasswordRequestFormShown(false);
    })
    .catch((error: AxiosError) => {
      const errorType: string = error?.response?.data[0]?.type;
      let errorTitle: string = '';
      let errorMessage: string = '';

      if (errorType === ErrorsList.NoUser) {
        errorTitle = 'Пользователь не найден';
        errorMessage = 'Проверьте форму ввода и попробуйте еще раз';
      } else {
        errorTitle = 'Что-то пошло не так';
        errorMessage =
          'Не удалось создать запрос на смену пароля. Попробуйте ещё раз';
      }

      setToastData({
        toastType: ToastTypes.Critical,
        title: errorTitle,
        message: errorMessage,
        isShown: true,
        closeDelay: 5000,
      });
    });
};
createPasswordChangeRequestFx.use(createPasswordChangeRequestAction);

const changePasswordFx = createEffect<ChangePasswordParamsType, void, Error>();
const changePasswordAction = async (params: ChangePasswordParamsType) => {
  await changePassword(params)
    .then(() => {
      setToastData({
        toastType: ToastTypes.Success,
        title: 'Пароль успешно изменен',
        message: 'Вы можете войти в приложение с новым паролем',
        isShown: true,
        closeDelay: 5000,
      });
      setIsChangePasswordFormShown(false);
    })
    .catch((error: AxiosError) => {
      const errorType: string = error?.response?.data[0]?.type;
      let errorTitle: string = '';
      let errorMessage: string = '';

      if (errorType === ErrorsList.WrongToken) {
        setIsChangePasswordFormShown(false);
        setIsChangePasswordRequestFormShown(true);
        errorTitle = 'Истек срок годности токена';
        errorMessage =
          'Введите данные, указанные при регистрации, для получения новой ссылки на восстановление пароля';
      } else {
        errorTitle = 'Что-то пошло не так';
        errorMessage = 'Не удалось изменить пароль, попробуйте еще раз';
      }
      setToastData({
        toastType: ToastTypes.Critical,
        title: errorTitle,
        message: errorMessage,
        isShown: true,
        closeDelay: 5000,
      });
    });
};
changePasswordFx.use(changePasswordAction);

// prediction type

const setPredictionTypeByQueues =
  createEvent<{ queueNumber: number; predictionType: string }>();

const getPredictionTypeFx = createEffect<number, void>().use(
  async (queueNumber) => {
    getPredictionType(queueNumber).then((response) => {
      setPredictionTypeByQueues({
        queueNumber,
        predictionType: response.data.prediction_type,
      });
    });
  },
);

const setPredictionTypeFx = createEffect<
  SetVodokanalPredictionType,
  void
>().use(async (params) => {
  setPredictionType(params).then(() => {
    getPredictionTypeFx(params.queueNumber);
  });
});

const $predictionTypesByQueues = createStore<{ [key: number]: string }>({
  1: '',
  2: '',
}).on(setPredictionTypeByQueues, (state, { queueNumber, predictionType }) => {
  return {
    ...state,
    [queueNumber]: predictionType,
  };
});

export {
  $accessToken,
  $expiresIn,
  $formValues,
  $isAuthenticated,
  $isChangePasswordFormShown,
  $isChangePasswordRequestFormShown,
  $isTokenValid,
  $locationPathname,
  $predictionTypesByQueues,
  $refreshExpiresIn,
  $refreshToken,
  $userConfig,
  $userId,
  $userInitials,
  $userRoles,
  $userVodokanalId,
  $waterCanal,
  changePasswordFx,
  checkTokenValidityFx,
  createPasswordChangeRequestFx,
  getCurrentUserFx,
  getPredictionTypeFx,
  logInFx,
  logOutFx,
  refreshTokenFx,
  resetAuthData,
  sendRegistrationRequestFx,
  setAccessToken,
  setExpiresIn,
  setFormValues,
  setIsAuthenticated,
  setIsChangePasswordFormShown,
  setIsChangePasswordRequestFormShown,
  setIsTokenValid,
  setLocationPathname,
  setPredictionTypeFx,
  setRefreshExpiresIn,
  setRefreshToken,
  setUserInitials,
  setUserVodokanalId,
  setWaterCanal,
  updateQueueConfigFx,
  updateSubTypesConfigFx,
};
