import React, { useEffect, useMemo, useState } from 'react';
import { clsx } from 'clsx';
import endOfDay from 'date-fns/endOfDay';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import lastDayOfYear from 'date-fns/lastDayOfYear';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfYear from 'date-fns/startOfYear';

import DatePicker from '../DatePicker';
import { DatePickerTypes } from '../DatePicker/DatePicker.interface';
import Select from '../Select';

import { periodTypesOptionsList } from './constants';
import {
  PeriodPickerSectionProps,
  RangeType,
} from './PeriodPickerSection.interface';

import styles from './PeriodPickerSection.module.scss';

function PeriodPickerSection(props: PeriodPickerSectionProps) {
  const {
    range,
    periodType,
    onPeriodTypeChange,
    onDateRangeChange,
    className,
  } = props;

  const [dateRangeValue, setDateRangeValue] = useState<RangeType>(range);

  const handleDateRangeChange = () => {
    const dateRange = {
      startDate: null,
      endDate: null,
    };

    switch (periodType.value) {
      case DatePickerTypes.SingleDate:
        dateRange.startDate = startOfDay(dateRangeValue.startDate);
        dateRange.endDate = endOfDay(dateRangeValue.startDate);
        break;
      case DatePickerTypes.DateRange:
        dateRange.startDate = startOfDay(dateRangeValue.startDate);
        dateRange.endDate = dateRangeValue.endDate
          ? endOfDay(dateRangeValue.endDate)
          : dateRangeValue.endDate;
        break;
      case DatePickerTypes.MonthYear:
        dateRange.startDate = startOfDay(
          startOfMonth(dateRangeValue.startDate),
        );
        dateRange.endDate = endOfDay(lastDayOfMonth(dateRangeValue.endDate));
        break;
      case DatePickerTypes.Year:
        dateRange.startDate = startOfDay(startOfYear(dateRangeValue.startDate));
        dateRange.endDate = endOfDay(lastDayOfYear(dateRangeValue.endDate));
        break;
      default:
        break;
    }

    onDateRangeChange(dateRange);
  };

  const handleDatePickerSingleValueChange = (value: Date) => {
    setDateRangeValue({ startDate: value, endDate: value });
  };
  const handleDatePickerDateRangeChange = (values: Date[]) => {
    setDateRangeValue({ startDate: values[0], endDate: values[1] });
  };

  useEffect(() => {
    handleDateRangeChange();
  }, [periodType, dateRangeValue]);

  const datePickerDateRange = useMemo(
    () =>
      periodType.value === DatePickerTypes.DateRange
        ? range
        : { startDate: null, endDate: null },
    [range, periodType.value],
  );

  return (
    <div className={clsx(styles.period_picker_section, className)}>
      <Select
        options={periodTypesOptionsList}
        value={periodType}
        onChange={onPeriodTypeChange}
        className={styles.select}
      />
      <DatePicker
        type={periodType.value as DatePickerTypes}
        selectedDate={range.startDate}
        startDate={datePickerDateRange.startDate}
        endDate={datePickerDateRange.endDate}
        onSingleDateChange={handleDatePickerSingleValueChange}
        onDateRangeChange={handleDatePickerDateRangeChange}
      />
    </div>
  );
}

export default PeriodPickerSection;
