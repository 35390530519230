import React, { useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';

import { StandardCardDataType } from '../../Components/StandardsTree/StandardsTree.interface';
import StandardDeleteModal from '../../Pages/PageStandards/components/StandardDeleteModal';
import StandardTableSection from '../../Pages/PageStandards/components/StandardTableSection';
import {
  ChangeStandardParamsType,
  StandardTableDataType,
} from '../../Pages/PageStandards/PageStandards.interface';
import {
  $activeStandardData,
  $activeStandardId,
  $isStandardEditActive,
  $standardsTreeData,
  changeStandardFx,
  deleteStandardFx,
  getActiveStandardDataFx,
  resetClassifierModal,
  setActiveStandardData,
  setIsStandardEditActive,
} from '../../Store/standardsPageStore';

import StandardClassifierModalContainer from './StandardClassifierModalContainer';

function StandardTableSectionContainer() {
  const activeStandardId: number = useStore($activeStandardId);
  const standardData: StandardTableDataType = useStore($activeStandardData);
  const isEditActive: boolean = useStore($isStandardEditActive);
  const standardsList: StandardCardDataType[] = useStore($standardsTreeData);
  const [isClassifierModalShown, setIsClassifierModalShown] =
    useState<boolean>(false);
  const [isDeleteModalShown, setIsDeleteModalShown] = useState<boolean>(false);

  const handleStandardChange = (newStandardData: StandardTableDataType) => {
    setActiveStandardData(newStandardData);
  };

  const handleEditSave = () => {
    const { id, name, description, standardType, subType, classifiers } =
      standardData;
    const params: ChangeStandardParamsType = {
      id,
      name,
      description: description || '',
      standardType,
      classifiers,
      subType,
    };
    changeStandardFx(params);
  };

  const handleEditCancel = () => {
    getActiveStandardDataFx(activeStandardId);
    setIsStandardEditActive(false);
  };

  const handleEditButtonClick = () => {
    setIsStandardEditActive(true);
  };
  const handleDeleteButtonClick = () => {
    setIsDeleteModalShown(true);
  };

  const handleClassifierModalClose = () => {
    setIsClassifierModalShown(false);
    resetClassifierModal();
  };

  const handleAddClassifierButtonClick = () => {
    setIsClassifierModalShown(true);
  };
  const handleStandardDeleteModalClose = () => {
    setIsDeleteModalShown(false);
  };
  const handleStandardDeleteModalConfirm = () => {
    deleteStandardFx(activeStandardId);
  };

  const isTableSectionShown: boolean = useMemo(
    () => standardData && standardsList?.length !== 0,
    [standardData, standardsList],
  );

  useEffect(() => {
    if (activeStandardId) {
      getActiveStandardDataFx(activeStandardId);
    }
  }, [activeStandardId]);

  return (
    <>
      {isTableSectionShown && (
        <StandardTableSection
          standardData={standardData}
          isEditActive={isEditActive}
          onEditSave={handleEditSave}
          onDeleteButtonClick={handleDeleteButtonClick}
          onEditCancel={handleEditCancel}
          onEditButtonClick={handleEditButtonClick}
          onStandardChange={handleStandardChange}
          onClassifierAdd={handleAddClassifierButtonClick}
        />
      )}
      {isClassifierModalShown && (
        <StandardClassifierModalContainer
          onClose={handleClassifierModalClose}
        />
      )}
      {isDeleteModalShown && (
        <StandardDeleteModal
          onClose={handleStandardDeleteModalClose}
          onConfirm={handleStandardDeleteModalConfirm}
        />
      )}
    </>
  );
}

export default StandardTableSectionContainer;
