import { PromptPropsType } from '../Prompt/Prompt.interface';

export enum ToastTypes {
  Success = 'success',
  Warning = 'warning',
  Critical = 'critical',
  Info = 'info',
}

export type ToastProps = {
  isShown: boolean;
  message: string;
  toastType: ToastTypes;
  title?: string;
  closeDelay?: number;
  onClose?: () => void;
  className?: string;
  promptProps?: PromptPropsType;
};
