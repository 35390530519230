import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { useStore } from 'effector-react';

import Accordion from '../../../Components/Accordion';
import { Panel } from '../../../Components/Accordion/Accordion.interface';
import { IconsNames } from '../../../Components/Icon/constants';
import IconWarningSection from '../../../Components/IconWarningSection';
import PanelTableHeaderContainer from '../../../Containers/ResearchReportsContainers/PanelTableHeaderContainer';
import { $userRoles } from '../../../Store/authStore';
import {
  $offset,
  getResearchClassifiersByIdFx,
} from '../../../Store/researchReportsPageStore';
import { RolesList } from '../../PageAdmin/PageAdmin.interface';
import { deleteResearchDataDefault, valueViaSubType } from '../constants';
import {
  DeleteResearchData,
  PageTableProps,
  ReportsDataType,
} from '../PageLaboratoryTests.interface';

import PanelBody from './PanelBody';
import PanelHeader from './PanelHeader';
import ResearchDeleteModal from './ResearchDeleteModal';

import styles from '../PageLaboratoryTests.module.scss';

function PageTable(props: PageTableProps) {
  const {
    tableColumnsList,
    reportsList,
    isShowMoreButtonVisible,
    onShowMoreButtonClick,
    onResearchDelete,
    onResearchEdit,
    onTestCreate,
  } = props;
  const tableBodyRef: MutableRefObject<HTMLDivElement> = useRef(null);
  const offset = useStore($offset);

  const currentUserRoles: number[] = useStore($userRoles);

  const isDeleteButtonShown: boolean = useMemo(
    () => currentUserRoles.includes(RolesList.ASSISTANT),
    [currentUserRoles],
  );

  const [openPanelsIds, setOpenPanelsIds] = useState<number[]>([]);
  const [deleteResearchData, setDeleteResearchData] =
    useState<DeleteResearchData>(deleteResearchDataDefault);
  const handleOpenPanelsIdsListChange = (ids: number[]) => {
    const lastAddedId = ids[ids.length - 1];

    if (ids.length !== 0 && openPanelsIds.length < ids.length) {
      getResearchClassifiersByIdFx(lastAddedId);
    }

    setOpenPanelsIds(ids);
  };

  const handleDeleteButtonClick = (researchId: number) => {
    setDeleteResearchData({ id: researchId, isModalShown: true });
  };
  const handleResearchDeleteModalClose = () => {
    setDeleteResearchData(deleteResearchDataDefault);
  };
  const handleResearchDeleteModalConfirm = () => {
    onResearchDelete(deleteResearchData.id);
  };

  const panelsList: Panel[] = useMemo(
    () =>
      reportsList.map((report: ReportsDataType) => {
        const {
          id,
          name,
          date,
          waterType,
          standard,
          author,
          status,
          properties,
          createdAt,
          subType,
        } = report;

        const columnsList: string[] = [
          name,
          date,
          waterType,
          standard,
          author,
          valueViaSubType[subType],
        ];

        const actualColumnsList = columnsList;

        const actualTableColumnsListList =
          subType !== null
            ? tableColumnsList
            : tableColumnsList.slice(0, tableColumnsList.length - 1);
        return {
          headerContent: (
            <PanelHeader
              columnsList={actualColumnsList}
              tableColumnsList={actualTableColumnsListList}
            />
          ),
          bodyContent: <PanelBody propertiesList={properties} />,
          id,
          status,
          createdAt,
        };
      }),
    [reportsList],
  );

  const isWarningShown: boolean = useMemo(
    () => !reportsList || reportsList.length === 0,
    [reportsList],
  );

  useEffect(() => {
    if (tableBodyRef.current && offset !== 0) {
      tableBodyRef.current.scrollTo({
        top: tableBodyRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [panelsList.length]);

  return (
    <div className={styles.table_wrapper}>
      <div className={styles.table_header}>
        <PanelTableHeaderContainer onTestCreate={onTestCreate} />
      </div>
      {isWarningShown ? (
        <IconWarningSection
          iconName={IconsNames.Note}
          message="Исследования не найдены"
          className={styles.warning_section}
        />
      ) : (
        <div className={styles.table_body} ref={tableBodyRef}>
          <Accordion
            openPanelsIds={openPanelsIds}
            onOpenPanelsIdsListChange={handleOpenPanelsIdsListChange}
            panels={panelsList}
            isDeleteActive={isDeleteButtonShown}
            onDelete={handleDeleteButtonClick}
            onEdit={onResearchEdit}
          />
          <div
            className={clsx(styles.button_wrapper, {
              [styles.button_wrapper_small]: !isShowMoreButtonVisible,
            })}
          >
            {isShowMoreButtonVisible && (
              <button
                type="button"
                className={styles.show_more_button}
                onClick={onShowMoreButtonClick}
              >
                Показать ещё
              </button>
            )}
          </div>
        </div>
      )}
      {deleteResearchData.isModalShown && (
        <ResearchDeleteModal
          id={deleteResearchData.id}
          onClose={handleResearchDeleteModalClose}
          onConfirm={handleResearchDeleteModalConfirm}
        />
      )}
    </div>
  );
}

export default PageTable;
