import format from 'date-fns/format';

import { DateFormats } from '../Components/DatePicker/DatePicker.interface';
import { StandardCardDataType } from '../Components/StandardsTree/StandardsTree.interface';
import {
  ClassifierDataType,
  StandardClassifierDataType,
  StandardTableDataType,
} from '../Pages/PageStandards/PageStandards.interface';
import { getShortFullName } from '../Utils/getFullName';

import {
  BackendClassifierDataType,
  BackendStandardDataType,
  BackendStandardsTreeItemDataType,
  StandardsSettingsData,
} from './types';

export function fetchStandardsTreeAdapter(
  standardsTree: BackendStandardsTreeItemDataType[],
): StandardCardDataType[] {
  return standardsTree.map((standard: BackendStandardsTreeItemDataType) => {
    const {
      id,
      name,
      standard_type: standardType,
      is_const: isConst,
      children,
      history,
      description,
      created_at: createdAt,
      updated_at: updatedAt,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      is_default: isDefault,
    } = standard;

    const childrenList = children ? fetchStandardsTreeAdapter(children) : [];
    const historyList = history ? fetchStandardsTreeAdapter(history) : [];
    const author = getShortFullName(firstName, middleName, lastName);
    const createdAtFormatted = createdAt
      ? format(new Date(createdAt), DateFormats.ddMMyyyyHHmm)
      : '';
    const updatedAtFormatted = updatedAt
      ? format(new Date(updatedAt), DateFormats.ddMMyyyyHHmm)
      : '';

    const datetime = updatedAtFormatted || createdAtFormatted;

    return {
      id,
      name,
      standardType,
      isConst,
      isDefault,
      children: childrenList,
      history: historyList,
      datetime,
      description,
      author,
    };
  });
}

export function fetchActiveStandardTableDataAdapter(
  standardData: BackendStandardDataType,
): StandardTableDataType {
  const {
    id,
    name,
    standard_type: standardType,
    sub_type: subType,
    updated_at: updatedAt,
    created_at: createdAt,
    is_history: isHistory,
    classifiers,
    is_const: isConst,
    description,
    first_name: firstName,
    last_name: lastName,
    middle_name: middleName,
  } = standardData;
  const lastUpdateAuthor = getShortFullName(firstName, middleName, lastName);

  const classifiersList: StandardClassifierDataType[] = classifiers.map(
    (classifier: BackendClassifierDataType) => {
      const {
        id: classifierId,
        name: classifierName,
        measure_name: measureName,
        description: classifierDescription,
        lsl,
        lcl,
        ucl,
        usl,
        is_required: isRequired,
      } = classifier;

      return {
        id: classifierId,
        name: classifierName,
        description: classifierDescription,
        units: measureName,
        lsl,
        lcl,
        ucl,
        usl,
        isRequired,
      };
    },
  );
  const updatedAtFormatted = updatedAt
    ? format(new Date(updatedAt), DateFormats.ddMMyyyyHHmm)
    : '';
  const createdAtFormatted = createdAt
    ? format(new Date(createdAt), DateFormats.ddMMyyyyHHmm)
    : '';

  const lastUpdate: string = updatedAtFormatted || createdAtFormatted;

  return {
    id,
    name,
    lastUpdateAuthor,
    lastUpdate,
    standardType,
    classifiers: classifiersList,
    isConst,
    isHistory,
    description,
    subType,
  };
}

export function fetchClassifiersDataAdapter(
  classifiers: BackendClassifierDataType[],
): ClassifierDataType[] {
  return classifiers.map((classifier: BackendClassifierDataType) => {
    const { id, name, measure_name: units, description } = classifier;

    return {
      id,
      name,
      units,
      description,
    };
  });
}

export function fetchStandardsSettingsAdapter(
  standards: BackendStandardDataType[],
): StandardsSettingsData[] {
  return standards.map(({ id, name, is_default: isDefault }) => ({
    id,
    title: name,
    isActive: isDefault,
  }));
}
