import React from 'react';

import * as Icons from '../../Assets/Icons';

import { IconProps } from './Icon.interface';

function Icon(props: IconProps) {
  const { name, className } = props;
  const Image = Icons[name];
  return Image ? <Image className={className} /> : <div />;
}

export default Icon;
