import clsx from 'clsx';

import { LoaderDropProps } from './LoaderDrop.interface';

import styles from './LoaderDrop.module.scss';

function LoaderDrop({ backgroundColor = 'default' }: LoaderDropProps) {
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.transparent]: backgroundColor === 'transparent',
      })}
    >
      <div className={styles.loader} />
    </div>
  );
}

export default LoaderDrop;
