import { WaterTypes } from '../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';

export const waterTypesMap = new Map([
  [WaterTypes.Potable, 'Питьевой'],
  [WaterTypes.Surface, 'Поверхностный'],
  [WaterTypes.Intermediate, 'Промежуточный'],
]);

function getWaterTypeName(waterType: WaterTypes): string {
  return waterTypesMap.get(waterType);
}

export default getWaterTypeName;
