import { AxiosResponse } from 'axios';
import format from 'date-fns/format';
import { createEffect, createEvent, createStore } from 'effector';

import fetchReportsSynchDataAdapter from '../Adapters/fetchReportsSynchDataAdapter';
import { DateFormats } from '../Components/DatePicker/DatePicker.interface';
import { ToastTypes } from '../Components/Toast/Toast.interface';
import {
  changeInterval,
  changeLinking,
  getLinking,
} from '../DataAccessLayer/apiServices';
import {
  CardsConnectionDataType,
  PageMappingCardProps,
} from '../Pages/PageMapping/PageMapping.interface';

import { $queueNumber } from './cataloguesStore';
import { setToastData } from './toastStore';
import {
  Bounds,
  ChangeLinkingIntervalParamsType,
  ChangeLinkingParamsType,
  ReportsSynchDataType,
} from './types';

const defaultBounds: Bounds = {
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
};

const setConnections = createEvent<CardsConnectionDataType[]>();
const $connections = createStore<CardsConnectionDataType[]>([]).on(
  setConnections,
  (_, connectionsList: CardsConnectionDataType[]) => connectionsList,
);
const setCards = createEvent<PageMappingCardProps[]>();
const $cards = createStore<PageMappingCardProps[]>([]).on(
  setCards,
  (_, cards: PageMappingCardProps[]) => cards,
);

const setIsDragActive = createEvent<boolean>();
const setIsDragComplete = createEvent<boolean>();

const $isDragActive = createStore<boolean>(false).on(
  setIsDragActive,
  (_, isDragActive: boolean) => isDragActive,
);
const $isDragComplete = createStore<boolean>(false).on(
  setIsDragComplete,
  (_, isDragComplete: boolean) => isDragComplete,
);
const setCurrentDragSourceId = createEvent<number | string>();
const $currentDragSourceId = createStore<number | string>(null).on(
  setCurrentDragSourceId,
  (_, currentDragSourceId: number | string) => currentDragSourceId,
);

const setBounds = createEvent<Bounds>();
const $bounds = createStore<Bounds>(defaultBounds).on(
  setBounds,
  (_, bounds: Bounds) => bounds,
);

const resetReportsSynch = createEvent();

const setWaterTreatment = createEvent<number>();
const $waterTreatment = createStore<number>(null)
  .on(setWaterTreatment, (_, waterTreatment: number) => waterTreatment)
  .reset(resetReportsSynch);

const setTimeLag = createEvent<number>();
const $timeLag = createStore<number>(null)
  .on(setTimeLag, (_, timeLag: number) => timeLag)
  .reset(resetReportsSynch);

const setIsTimeLagShown = createEvent<boolean>();
const $isTimeLagShown = createStore<boolean>(true)
  .on(setIsTimeLagShown, (_, isTimeLagShown: boolean) => isTimeLagShown)
  .reset(resetReportsSynch);

const setDate = createEvent<Date>();
const $date = createStore<Date>(new Date())
  .on(setDate, (_, date: Date) => date)
  .reset(resetReportsSynch);

const getLinkingDataFx = createEffect<Date, void, Error>();
const getLinkingDataAction = async (date: Date) => {
  const queueNumber = $queueNumber.getState();
  await getLinking(format(date, DateFormats.yyyy_MM_dd), queueNumber).then(
    (response: AxiosResponse) => {
      const {
        waterTreatment,
        timeLag,
        connections,
        cards,
      }: ReportsSynchDataType = fetchReportsSynchDataAdapter(response.data);

      setWaterTreatment(waterTreatment);
      setTimeLag(timeLag);
      setConnections(connections);
      setCards(cards);
    },
  );
};

getLinkingDataFx.use(getLinkingDataAction);

const changeIntervalFx = createEffect<
  ChangeLinkingIntervalParamsType,
  void,
  Error
>();
const changeIntervalAction = async (
  params: ChangeLinkingIntervalParamsType,
) => {
  const { waterTreatment, timeLag, date } = params;
  await changeInterval(waterTreatment, timeLag)
    .then(() => {
      getLinkingDataFx(date);
    })
    .catch(() => {
      setToastData({
        toastType: ToastTypes.Critical,
        title: 'Что-то пошло не так',
        message:
          'Не удалось изменить значения водоподготовки и временного лага. Попробуйте ещё раз',
        isShown: true,
        closeDelay: 5000,
      });
    });
};

changeIntervalFx.use(changeIntervalAction);

const changeLinkingFx = createEffect<ChangeLinkingParamsType, void, Error>();
const changeLinkingAction = async (params: ChangeLinkingParamsType) => {
  const { date } = params;
  await changeLinking(params)
    .then(() => {
      getLinkingDataFx(date);
    })
    .catch(() => {
      setToastData({
        toastType: ToastTypes.Critical,
        title: 'Что-то пошло не так',
        message:
          'Не удалось изменить значения водоподготовки и временного лага. Попробуйте ещё раз',
        isShown: true,
        closeDelay: 5000,
      });
    });
};
changeLinkingFx.use(changeLinkingAction);

export {
  $bounds,
  $cards,
  $connections,
  $currentDragSourceId,
  $date,
  $isDragActive,
  $isDragComplete,
  $isTimeLagShown,
  $timeLag,
  $waterTreatment,
  changeIntervalFx,
  changeLinkingFx,
  getLinkingDataFx,
  resetReportsSynch,
  setBounds,
  setCards,
  setConnections,
  setCurrentDragSourceId,
  setDate,
  setIsDragActive,
  setIsDragComplete,
  setIsTimeLagShown,
  setTimeLag,
  setWaterTreatment,
};
