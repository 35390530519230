import { useCallback, useEffect, useMemo } from 'react';
import format from 'date-fns/format';
import { useStore } from 'effector-react';

import Card from '../../Components/Card';
import { DateFormats } from '../../Components/DatePicker/DatePicker.interface';
import { RangeType } from '../../Components/PeriodPickerSection/PeriodPickerSection.interface';
import { OptionType } from '../../Components/Select/Select.interface';
import { SkeletonPage } from '../../Components/Skeleton';
import { WaterTypes } from '../../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';
import { StatusesList } from '../../Static/statusesList';
import {
  $queueNumber,
  getDefaultStandardsFx,
  getStandardsListFx,
  getWaterCanalsListFx,
} from '../../Store/cataloguesStore';
import { $isLoading } from '../../Store/loadingStore';
import {
  $dateRange,
  $limit,
  $offset,
  $researchReports,
  $standard,
  $status,
  $waterType,
  getResearchReportsListFx,
  resetData,
  setOffset,
} from '../../Store/researchReportsPageStore';

import ReportsHeaderContainer from './ReportsHeaderContainer';
import ReportsTableContainer from './ReportsTableContainer';
import {
  PageResearchReportsContainerProps,
  ResearchReportsListParamsType,
} from './ResearchReportsContainers.interface';

function PageResearchReportsContainer(
  props: PageResearchReportsContainerProps,
) {
  const { onTestCreate, onTestEdit, cardClassName } = props;

  const isLoading = useStore($isLoading);
  const waterType: OptionType = useStore($waterType);
  const standard: OptionType = useStore($standard);
  const offset: number = useStore($offset);
  const limit: number = useStore($limit);
  const { startDate, endDate }: RangeType = useStore($dateRange);
  const reportsList = useStore($researchReports);
  const status: OptionType = useStore($status);
  const queueNumber = useStore($queueNumber);

  const getReportsList = useCallback(
    (shouldReset: boolean) => {
      const params: ResearchReportsListParamsType = {
        dateStart: startDate,
        dateEnd: endDate,
        limit,
        offset: shouldReset ? 0 : offset,
        researchType: waterType.value as WaterTypes,
        status: status.value as StatusesList,
        standardId: standard.value !== null ? Number(standard.value) : null,
        queueNumber,
      };
      const functionData = {
        params,
        shouldReset,
        previousValue: reportsList,
      };
      getResearchReportsListFx(functionData);
    },
    [
      startDate,
      offset,
      endDate,
      status,
      limit,
      waterType,
      standard,
      reportsList,
      queueNumber,
    ],
  );

  const formattedDateRange = useMemo(() => {
    return {
      dateStart: startDate ? format(startDate, DateFormats.ddMMyyyy) : '',
      dateEnd: endDate ? format(endDate, DateFormats.ddMMyyyy) : '',
    };
  }, [startDate, endDate]);

  useEffect(() => {
    if (!!startDate === !!endDate) {
      getReportsList(true);
      setOffset(0);
    }
  }, [
    waterType,
    standard,
    formattedDateRange.dateEnd,
    formattedDateRange.dateStart,
    limit,
    status,
    queueNumber,
  ]);

  useEffect(() => {
    if (offset !== 0) {
      getReportsList(false);
    }
  }, [offset]);

  useEffect(() => {
    getReportsList(true);
    getWaterCanalsListFx();
    getStandardsListFx();
    getDefaultStandardsFx();
    return () => {
      resetData();
    };
  }, []);

  if (isLoading) {
    return <SkeletonPage />;
  }

  return (
    <>
      <ReportsHeaderContainer />
      <Card className={cardClassName}>
        <ReportsTableContainer
          onTestEdit={onTestEdit}
          onTestCreate={onTestCreate}
        />
      </Card>
    </>
  );
}

export default PageResearchReportsContainer;
