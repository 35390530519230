import { MouseEvent, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { addHours, parse } from 'date-fns';

import { getMonitoringReportById } from '../../../DataAccessLayer/apiServices';
import { StatusesList } from '../../../Static/statusesList';
import Icon from '../../Icon';
import { IconsNames } from '../../Icon/constants';
import IconButton from '../../IconButton/IconButton';
import { PanelProps } from '../Accordion.interface';

import ChevronButton from './ChevronButton';

import styles from '../Accordion.module.scss';

function Panel(props: PanelProps) {
  const {
    id,
    panelId,
    isDeleteActive,
    onDeleteButtonClick,
    headerContent,
    bodyContent,
    isOpen,
    onClick,
    status,
    createdAt,
    onEditButtonClick,
    panelType,
  } = props;

  const handleDeleteButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (panelType === 'monitoring') {
      const monitoringPanelId = +panelId.split('-')[1];
      getMonitoringReportById(monitoringPanelId).then((response) =>
        onDeleteButtonClick(response.data.dose_id, monitoringPanelId),
      );
    } else {
      onDeleteButtonClick(id, null);
    }
  };

  const handleEditButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onEditButtonClick(panelType === 'monitoring' ? +panelId.split('-')[1] : id);
  };

  const [isEditButtonEnabled, setIsEditButtonEnabled] = useState(true);

  useEffect(() => {
    if (panelType === 'research') {
      const createdAtDate = parse(createdAt, 'dd.MM.yyyy  HH:mm', new Date());
      const HOURS_EDITING_ENABLED = 12;

      const dateOfExpirationEditing = addHours(
        createdAtDate,
        HOURS_EDITING_ENABLED,
      );

      const timeOfExpirationEditing = dateOfExpirationEditing.getTime();
      const currentTime = new Date().getTime();

      const timeRemainingForEditing = timeOfExpirationEditing - currentTime;

      const editionButtonTimeout = setTimeout(() => {
        setIsEditButtonEnabled(false);
      }, timeRemainingForEditing);

      return () => {
        clearTimeout(editionButtonTimeout);
      };
    }
    return () => {};
  }, []);

  const iconName: IconsNames = useMemo(() => {
    switch (status) {
      case StatusesList.Critical:
        return IconsNames.CircleCross;
      case StatusesList.Warning:
        return IconsNames.CircleWarning;
      case StatusesList.Success:
        return IconsNames.CircleCheck;
      case StatusesList.Pending:
        return IconsNames.CircleClock;
      case StatusesList.ToBeFilled:
        return IconsNames.CirclePlus;
      default:
        return null;
    }
  }, [status]);

  const iconHint = useMemo(() => {
    switch (status) {
      case StatusesList.ToBeFilled:
        return 'Ожидание ввода данных';
      default:
        return '';
    }
  }, [status]);

  return (
    <div className={clsx(styles.panel, styles[status])}>
      <button
        type="button"
        className={styles.panel_header_wrapper}
        onClick={onClick}
        id={panelId}
      >
        {headerContent}
        <div className={styles.left_side_content}>
          <div className={styles.icon_hint}>{iconHint}</div>
          <Icon name={iconName} className={styles.status_icon} />

          {onEditButtonClick && (
            <span>
              <IconButton
                iconName={IconsNames.Edit}
                className={clsx(styles.controls, styles.edit_button)}
                onClick={handleEditButtonClick}
                isDisabled={!isEditButtonEnabled}
                isClear
              />
            </span>
          )}

          {isDeleteActive && (
            <IconButton
              iconName={IconsNames.Delete}
              className={clsx(styles.controls, styles.delete_button, {
                [styles.delete_button_hidden]:
                  status === StatusesList.ToBeFilled,
              })}
              onClick={handleDeleteButtonClick}
              isClear
            />
          )}
          <ChevronButton onClick={onClick} isOpen={isOpen} />
        </div>
      </button>
      {isOpen && <div className={styles.panel_body_wrapper}>{bodyContent}</div>}
    </div>
  );
}

export default Panel;
