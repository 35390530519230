import React, { useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';

import { OptionType } from '../../Components/Select/Select.interface';
import ConfirmDeleteModal from '../../Pages/PageReagents/components/ConfirmDeleteModal';
import CreateEditModal from '../../Pages/PageReagents/components/CreateEditModal';
import PageReagentsTable from '../../Pages/PageReagents/components/PageReagentsTable';
import {
  CreateAddReagentParamsType,
  ModalModes,
  ModalReagentDataType,
  ReagentDataType,
} from '../../Pages/PageReagents/PageReagents.interface';
import { $measures } from '../../Store/cataloguesStore';
import {
  $deleteModalData,
  $editCreateModalData,
  $reagents,
  addReagentFx,
  changeReagentDocumentFx,
  changeReagentFx,
  defaultEditCreateModalData,
  deleteReagentDocumentFx,
  deleteReagentFx,
  getReagentDocumentFx,
  getReagentsListFx,
  resetDeleteModalData,
  resetEditCreateModalData,
  setDeleteModalData,
  setEditCreateModalData,
} from '../../Store/reagentsPageStore';

function PageReagentTableContainer() {
  const measuresOptionsList: OptionType[] = useStore($measures);
  const reagents = useStore($reagents);
  const editCreateModalData = useStore($editCreateModalData);
  const {
    isShown: isEditCreateModalShown,
    reagentData,
    modalMode,
  } = editCreateModalData;
  const deleteModalData = useStore($deleteModalData);
  const {
    id: activeReagentId,
    isShown: isDeleteModalShown,
    name: reagentToDeleteName,
  } = deleteModalData;

  const handleReagentEdit = (reagent: ReagentDataType) => {
    const { id, name, description, unitCost, fileName, units } = reagent;

    const newReagentData: ModalReagentDataType = {
      id,
      name,
      description,
      fileName,
      cost: unitCost,
      units,
    };

    setEditCreateModalData({
      ...defaultEditCreateModalData,
      modalMode: ModalModes.Edit,
      isShown: true,
      reagentData: newReagentData,
    });
  };

  const handleReagentDelete = (id: number, name: string) => {
    setDeleteModalData({ isShown: true, id, name });
  };

  const handleReagentCreate = () => {
    setEditCreateModalData({ ...defaultEditCreateModalData, isShown: true });
  };

  const handleDeleteModalClose = () => {
    resetDeleteModalData();
  };

  const handleDeleteModalConfirm = () => {
    deleteReagentFx(activeReagentId);
  };

  const handleReagentFileButtonClick = (id: number) => {
    getReagentDocumentFx(id);
  };

  const handleEditCreateModalConfirm = () => {
    const { id, name, cost, description, units, file, fileName } = reagentData;
    const params: CreateAddReagentParamsType = {
      name,
      price: cost,
      description,
      measureId: Number(units.value),
      file,
    };

    if (modalMode === ModalModes.Create) {
      addReagentFx(params);
    } else {
      params.id = id;
      changeReagentFx(params);
      if (!(file || fileName || fileName !== '')) {
        deleteReagentDocumentFx(id);
      }
      if (file) {
        changeReagentDocumentFx({ id, file });
      }
    }
  };

  const handleEditCreateModalClose = () => {
    resetEditCreateModalData();
  };

  const handleReagentDataChange = (newReagentData: ModalReagentDataType) => {
    setEditCreateModalData({
      ...editCreateModalData,
      reagentData: newReagentData,
    });
  };

  const handleDocumentShow = (id: number) => {
    getReagentDocumentFx(id);
  };

  const isConfirmButtonDisabled: boolean = useMemo(() => {
    const { name, units, cost, description } = reagentData;
    return !(name && units && cost && description);
  }, [reagentData]);

  useEffect(() => {
    getReagentsListFx();
  }, []);

  return (
    <>
      <PageReagentsTable
        reagents={reagents}
        onReagentCreate={handleReagentCreate}
        onReagentDelete={handleReagentDelete}
        onReagentEdit={handleReagentEdit}
        onReagentFileButtonClick={handleReagentFileButtonClick}
      />
      {isDeleteModalShown && (
        <ConfirmDeleteModal
          name={reagentToDeleteName}
          onClose={handleDeleteModalClose}
          onConfirm={handleDeleteModalConfirm}
        />
      )}
      {isEditCreateModalShown && (
        <CreateEditModal
          onConfirm={handleEditCreateModalConfirm}
          onClose={handleEditCreateModalClose}
          onReagentDataChange={handleReagentDataChange}
          onDocumentShow={handleDocumentShow}
          modalMode={modalMode}
          reagentData={reagentData}
          isConfirmButtonDisabled={isConfirmButtonDisabled}
          measuresOptionsList={measuresOptionsList}
        />
      )}
    </>
  );
}

export default PageReagentTableContainer;
