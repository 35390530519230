import React from 'react';

import { ReactComponent as LogoIcon } from '../../Assets/logo.svg';

import { LogoProps } from './Logo.interface';

function Logo(props: LogoProps) {
  const { className } = props;
  return <LogoIcon className={className} />;
}

export default Logo;
