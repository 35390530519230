import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';

import Card from '../../Components/Card';
import Checkbox from '../../Components/Checkbox';
import Counter from '../../Components/Counter';
import DatePicker from '../../Components/DatePicker/DatePicker';
import { DatePickerTypes } from '../../Components/DatePicker/DatePicker.interface';
import DragScrollableContainer from '../../Components/DragScrollableContainer';
import Icon from '../../Components/Icon';
import { IconsNames } from '../../Components/Icon/constants';
import SwitcherQueque from '../../Components/SwitcherQueque';
import { $userRoles } from '../../Store/authStore';
import {
  $queueCount,
  $queueNumber,
  setQueueNumber,
} from '../../Store/cataloguesStore';
import {
  $cards,
  $connections,
  $date,
  $isDragActive,
  $isTimeLagShown,
  $timeLag,
  $waterTreatment,
  changeIntervalFx,
  getLinkingDataFx,
  setBounds,
  setDate,
  setIsTimeLagShown,
  setTimeLag,
  setWaterTreatment,
} from '../../Store/pageMappingStore';
import { Bounds, ChangeLinkingIntervalParamsType } from '../../Store/types';
import { RolesList } from '../PageAdmin/PageAdmin.interface';

import PageMappingTimeLine from './components/PageMappingTimeLine';
import {
  maxTimeLag,
  maxWaterTreatment,
  minTimeLag,
  minWaterTreatment,
  timeLineContainerId,
} from './constants';
import {
  CardsConnectionDataType,
  PageMappingCardProps,
} from './PageMapping.interface';

import styles from './PageMapping.module.scss';

function PageMapping() {
  const cards: PageMappingCardProps[] = useStore($cards);
  const connections: CardsConnectionDataType[] = useStore($connections);
  const isDragActive: boolean = useStore($isDragActive);
  const timeLag: number = useStore($timeLag);
  const waterTreatment: number = useStore($waterTreatment);
  const isTimeLagShown: boolean = useStore($isTimeLagShown);
  const date: Date = useStore($date);
  const currentUserRoles: number[] = useStore($userRoles);
  const queueNumber = useStore($queueNumber);
  const queueCount = useStore($queueCount);

  const draggableContainer: Element = document.querySelector(
    `#${timeLineContainerId}`,
  );

  const handleWaterTreatmentChange = (value: number) => {
    setWaterTreatment(value);
  };
  const handleTimeLagChange = (value: number) => {
    setTimeLag(value);
  };
  const handleTimeLagCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setIsTimeLagShown(event.target.checked);
  };
  const handleDateChange = (value: Date) => {
    setDate(value);
  };

  const isEditEnabled: boolean = useMemo(
    () =>
      currentUserRoles.includes(RolesList.ADMIN) ||
      currentUserRoles.includes(RolesList.TECHNOLOGIST),
    [currentUserRoles],
  );

  useEffect(() => {
    if (draggableContainer) {
      const { bottom, top, left, right } =
        draggableContainer.getBoundingClientRect();
      const bounds: Bounds = { bottom, top, left, right };

      setBounds(bounds);
    }
  }, [draggableContainer]);

  useEffect(() => {
    if (date) {
      getLinkingDataFx(date);
    }
  }, [date, queueNumber]);

  useEffect(() => {
    if (waterTreatment && timeLag && date) {
      const params: ChangeLinkingIntervalParamsType = {
        waterTreatment,
        timeLag,
        date,
      };
      changeIntervalFx(params);
    }
  }, [waterTreatment, timeLag]);

  return (
    <div className={styles.page_wrapper}>
      <div className={styles.header_row}>
        <div className={styles.header_row__section}>
          <div className={styles.counter_section_column}>
            <div className={styles.counter_section_column_title}>
              Водоподготовка:
            </div>
            <Counter
              value={waterTreatment}
              minValue={minWaterTreatment}
              maxValue={maxWaterTreatment}
              onChange={handleWaterTreatmentChange}
              isDisabled={!isEditEnabled}
            />
          </div>
          <div className={styles.counter_section_column}>
            <Checkbox
              checked={isTimeLagShown}
              onChange={handleTimeLagCheckboxChange}
              className={styles.counter_section_checkbox}
            />
            <div className={styles.counter_section_column_title}>
              Временной лаг:
            </div>
            <Counter
              value={timeLag}
              minValue={minTimeLag}
              maxValue={maxTimeLag}
              onChange={handleTimeLagChange}
              isDisabled={!isEditEnabled}
            />
          </div>
        </div>
        <div className={styles.header_row__section}>
          <DatePicker
            type={DatePickerTypes.SingleDate}
            selectedDate={date}
            onSingleDateChange={handleDateChange}
          />
        </div>
      </div>

      <Card className={styles.page_card}>
        <div className={styles.counter_section}>
          <SwitcherQueque
            queueCount={queueCount}
            activeQueue={queueNumber}
            onChangeQueue={setQueueNumber}
          />
          <div className={styles.hint}>
            <Icon name={IconsNames.Info} className={styles.hint_icon} />
            <div className={styles.hint_message}>
              Вы не можете редактировать связи отчетов спустя 24 часа после их
              создания
            </div>
          </div>
        </div>

        <DragScrollableContainer
          isDisabled={isDragActive}
          id={timeLineContainerId}
          className={styles.timeline_container}
          currentValue={date}
        >
          <PageMappingTimeLine
            currentDate={date}
            cards={cards}
            connections={connections}
            isTimeLagShown={isTimeLagShown}
            isDragActive={isDragActive}
          />
        </DragScrollableContainer>
      </Card>
    </div>
  );
}

export default PageMapping;
