const COMMA_FOR_BACKEND = '.';
const COUNT_AFTER_DOT = 3;

function writeFloatInputValue(value: string) {
  // eslint-disable-next-line
  // TODO: временно оставил только здесь. мб подобную логику стои перенести в компонент Input
  value = value
    .replace(
      /[A-Za-zА-Яа-яЁё№!@#$%^&*()/"'?`~_+\-=|\\<>{}:;]/g,
      '',
      // eslint-disable-next-line
    ).replace(/[.,]/g, COMMA_FOR_BACKEND);
  if (value) {
    if (value.startsWith(COMMA_FOR_BACKEND))
      value = `0${COMMA_FOR_BACKEND}${value}`;
    const splitedValue = value.split(COMMA_FOR_BACKEND);
    if (splitedValue.length > 1) {
      let partAfterDot = splitedValue.slice(1).join('');
      if (partAfterDot.length > COUNT_AFTER_DOT) {
        partAfterDot = partAfterDot.slice(0, COUNT_AFTER_DOT);
      }
      value = `${splitedValue[0]}${COMMA_FOR_BACKEND}${partAfterDot}`;
    }
  }
  return value;
}

export default writeFloatInputValue;
