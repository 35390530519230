import { AxiosError, AxiosResponse } from 'axios';
import { createEffect, createEvent, createStore } from 'effector';

import {
  fetchActiveStandardTableDataAdapter,
  fetchClassifiersDataAdapter,
  fetchStandardsSettingsAdapter,
  fetchStandardsTreeAdapter,
} from '../Adapters/fetchStandardsAdapter';
import {
  BackendClassifierDataType,
  BackendStandardDataType,
  BackendStandardsTreeItemDataType,
  StandardsSettingsData,
} from '../Adapters/types';
import { OptionType } from '../Components/Select/Select.interface';
import { StandardCardDataType } from '../Components/StandardsTree/StandardsTree.interface';
import { ToastTypes } from '../Components/Toast/Toast.interface';
import {
  addClassifier,
  changeStandard,
  copyStandard,
  createStandard,
  deleteStandard,
  getRecommendedClassifiers,
  getStandardsList,
  getStandardsTree,
  getStandardWithClassifiersById,
  setDefaultStandard,
} from '../DataAccessLayer/apiServices';
import {
  ClassifierTypes,
  defaultModalWaterTypeOption,
} from '../Pages/PageStandards/constants';
import {
  ChangeStandardParamsType,
  ClassifierDataType,
  StandardClassifierDataType,
  StandardTableDataType,
} from '../Pages/PageStandards/PageStandards.interface';
import handleError from '../Utils/handleError';

import { getClassifiersListFx } from './cataloguesStore';
import { setIsLoading } from './loadingStore';
import { setToastData } from './toastStore';
import {
  CreateClassifierParamsType,
  CreateStandardParamsType,
  ResponseType,
  StandardBufferActionType,
} from './types';

const resetStandardsData = createEvent();
const setIsTableLoading = createEvent<boolean>();
const $isTableLoading = createStore<boolean>(false).on(
  setIsTableLoading,
  (_, isTableLoading: boolean) => isTableLoading,
);
const setOpenChildrenCardsIdsList = createEvent<number[]>();
const addOpenCardId = createEvent<number>();
const $openChildrenCardsIdsList = createStore<number[]>([])
  .on(
    setOpenChildrenCardsIdsList,
    (_, openChildrenCardsIdsList: number[]) => openChildrenCardsIdsList,
  )
  .on(addOpenCardId, (idsList: number[], cardId: number) => {
    if (idsList.includes(cardId)) {
      return idsList;
    }
    const newIdsList: number[] = idsList.slice();
    newIdsList.push(cardId);

    return newIdsList;
  })
  .reset(resetStandardsData);

const setIsStandardEditActive = createEvent<boolean>();
const $isStandardEditActive = createStore<boolean>(false)
  .on(
    setIsStandardEditActive,
    (_, isStandardEditActive) => isStandardEditActive,
  )
  .reset(resetStandardsData);

const defaultStandardBufferAction: StandardBufferActionType = {
  actionType: null,
  standardId: null,
  path: '',
  isWarningModalShown: false,
};
const setStandardBufferAction = createEvent<StandardBufferActionType>();
const clearStandardBufferAction = createEvent();
const $standardBufferAction = createStore<StandardBufferActionType>(
  defaultStandardBufferAction,
)
  .on(
    setStandardBufferAction,
    (_, standardBufferAction: StandardBufferActionType) => standardBufferAction,
  )
  .reset(clearStandardBufferAction);

const setActiveStandardId = createEvent<number>();
const $activeStandardId = createStore<number>(null)
  .on(setActiveStandardId, (_, activeStandardId: number) => activeStandardId)
  .reset(resetStandardsData);

const getStandardsTreeFx = createEffect<boolean, void, Error>();
const setStandardsTreeData = createEvent<StandardCardDataType[]>();

const getStandardsTreeAction = async (isSkeletonToBeShown: boolean) => {
  if (isSkeletonToBeShown) {
    setIsLoading(true);
  }
  await getStandardsTree()
    .then((response: AxiosResponse) => {
      const standardsTreeData: BackendStandardsTreeItemDataType[] =
        response.data;

      const standardsTreeAdaptedData: StandardCardDataType[] =
        fetchStandardsTreeAdapter(standardsTreeData);

      if (
        $activeStandardId.getState() == null &&
        standardsTreeAdaptedData.length !== 0
      ) {
        const standardId: number = standardsTreeAdaptedData[0].id;
        setActiveStandardId(standardId);
      }

      setStandardsTreeData(standardsTreeAdaptedData);
    })
    .catch((error: AxiosError) => {
      handleError({ error, request: getStandardsTreeFx });
    })
    .finally(() => {
      if (isSkeletonToBeShown) {
        setIsLoading(false);
      }
    });
};

getStandardsTreeFx.use(getStandardsTreeAction);

const $standardsTreeData = createStore<StandardCardDataType[]>([])
  .on(
    setStandardsTreeData,
    (_, standardsTreeData: StandardCardDataType[]) => standardsTreeData,
  )
  .on(
    getStandardsTreeFx.done,
    (_, response: ResponseType<StandardCardDataType[]>) => response.result,
  )
  .reset(resetStandardsData);

const getActiveStandardDataFx = createEffect<number, void, Error>();
const setActiveStandardData = createEvent<StandardTableDataType>();

const getActiveStandardDataAction = async (standardId: number) => {
  setIsTableLoading(true);
  await getStandardWithClassifiersById(standardId)
    .then((response: AxiosResponse) => {
      const standardData: BackendStandardDataType = response.data;
      const standardAdaptedData: StandardTableDataType =
        fetchActiveStandardTableDataAdapter(standardData);
      setActiveStandardData(standardAdaptedData);
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: getActiveStandardDataFx,
        params: standardId,
      });
    })
    .finally(() => {
      setIsTableLoading(false);
    });
};

getActiveStandardDataFx.use(getActiveStandardDataAction);

const $activeStandardData = createStore<StandardTableDataType>(null)
  .on(
    setActiveStandardData,
    (_, activeStandardData: StandardTableDataType) => activeStandardData,
  )
  .on(
    getActiveStandardDataFx.done,
    (_, response: ResponseType<StandardTableDataType>) => response.result,
  )
  .reset(resetStandardsData);

const changeStandardFx = createEffect<ChangeStandardParamsType, void, Error>();

const changeStandardAction = async (params: ChangeStandardParamsType) => {
  await changeStandard(params)
    .then(() => {
      const { id, onSuccess } = params;
      getStandardsTreeFx(false);
      getActiveStandardDataFx(id);
      setIsStandardEditActive(false);
      onSuccess();
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: changeStandardFx,
        params,
        shouldShowToast: true,
        toastMessage: 'Не удалось изменить данные стандарта',
      });
    });
};

changeStandardFx.use(changeStandardAction);

// add classifier modal
const resetClassifierModal = createEvent();
const setClassifierType = createEvent<ClassifierTypes>();

const $classifierType = createStore<ClassifierTypes>(
  ClassifierTypes.ExistingClassifier,
)
  .on(setClassifierType, (_, classifierType: ClassifierTypes) => classifierType)
  .reset(resetClassifierModal);

const setSelectedClassifier = createEvent<OptionType>();
const $selectedClassifier = createStore<OptionType>(null)
  .on(
    setSelectedClassifier,
    (_, selectedClassifier: OptionType) => selectedClassifier,
  )
  .reset(resetClassifierModal);

const setSelectedMeasure = createEvent<OptionType>();
const $selectedMeasure = createStore<OptionType>(null)
  .on(setSelectedMeasure, (_, selectedMeasure: OptionType) => selectedMeasure)
  .reset(resetClassifierModal);

const setClassifierDescription = createEvent<string>();
const $classifierDescription = createStore<string>('')
  .on(
    setClassifierDescription,
    (_, classifierDescription: string) => classifierDescription,
  )
  .reset(resetClassifierModal);

const setClassifierName = createEvent<string>();
const $classifierName = createStore<string>('')
  .on(setClassifierName, (_, classifierName: string) => classifierName)
  .reset(resetClassifierModal);

const addClassifierFx = createEffect<CreateClassifierParamsType, void, Error>();
const addClassifierAction = async (params: CreateClassifierParamsType) => {
  await addClassifier(params)
    .then((response: AxiosResponse) => {
      const { data } = response;
      const { id: classifierId } = data;
      const { name, description, standardData, units } = params;

      const newClassifierId: number = classifierId;
      const newClassifierData: StandardClassifierDataType = {
        id: newClassifierId,
        name,
        description,
        units,
        lsl: 0,
        lcl: 0,
        ucl: 0,
        usl: 0,
        isRequired: true,
      };

      const newClassifiersList: StandardClassifierDataType[] =
        standardData.classifiers.slice();
      newClassifiersList.push(newClassifierData);

      setActiveStandardData({
        ...standardData,
        classifiers: newClassifiersList,
      });
      getClassifiersListFx();

      resetClassifierModal();
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: addClassifierFx,
        params,
        shouldShowToast: true,
        toastMessage: 'Не удалось добавить новый параметр',
      });
    });
};
addClassifierFx.use(addClassifierAction);

const copyStandardFx = createEffect<number, void, Error>();
const copyStandardAction = async (standardId: number) => {
  await copyStandard(standardId)
    .then((response: AxiosResponse) => {
      const { id: copyStandardId } = response.data;
      setActiveStandardId(copyStandardId);
      addOpenCardId(standardId);
      getStandardsTreeFx(false);
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: copyStandardFx,
        params: standardId,
        shouldShowToast: true,
        toastMessage: 'Не удалось скопировать стандарт',
      });
    });
};
copyStandardFx.use(copyStandardAction);

const deleteStandardFx = createEffect<number, void, Error>();
const deleteStandardAction = async (standardId: number) => {
  await deleteStandard(standardId)
    .then(() => {
      getStandardsTreeFx(false);
      setToastData({
        isShown: true,
        toastType: ToastTypes.Success,
        title: 'Стандарт успешно удален',
        message: '',
        closeDelay: 5000,
      });
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: deleteStandardFx,
        params: standardId,
        shouldShowToast: true,
        toastMessage: 'Не удалось удалить стандарт',
      });
    });
};
deleteStandardFx.use(deleteStandardAction);

// create standard modal
const getRecommendedClassifiersFx = createEffect<
  void,
  ClassifierDataType[],
  Error
>();

const getRecommendedClassifiersAction = async () => {
  const classifiersList: BackendClassifierDataType[] = await (
    await getRecommendedClassifiers().catch((error: AxiosError) => {
      handleError({ error, request: getRecommendedClassifiersFx });
      return error.response;
    })
  ).data;
  const adaptedClassifiersList: ClassifierDataType[] =
    fetchClassifiersDataAdapter(classifiersList);
  return adaptedClassifiersList;
};

getRecommendedClassifiersFx.use(getRecommendedClassifiersAction);

const resetCreateStandardModal = createEvent();

const setStandardType = createEvent<OptionType>();
const $standardType = createStore<OptionType>(defaultModalWaterTypeOption)
  .on(setStandardType, (_, standardType: OptionType) => standardType)
  .reset(resetCreateStandardModal);

const setStandardName = createEvent<string>();
const $standardName = createStore<string>('')
  .on(setStandardName, (_, standardName: string) => standardName)
  .reset(resetCreateStandardModal);

const setStandardDefaultClassifiersList = createEvent<ClassifierDataType[]>();
const $standardDefaultClassifiersList = createStore<ClassifierDataType[]>([])
  .on(
    setStandardDefaultClassifiersList,
    (_, standardDefaultClassifiersList: ClassifierDataType[]) =>
      standardDefaultClassifiersList,
  )
  .on(
    getRecommendedClassifiersFx.done,
    (_, response: ResponseType<ClassifierDataType[]>) => response.result,
  )
  .reset(resetCreateStandardModal);

const setStandardAddedClassifiersIdsList = createEvent<number[]>();
const $standardAddedClassifiersIdsList = createStore<number[]>([])
  .on(
    setStandardAddedClassifiersIdsList,
    (_, standardAddedClassifiersIdsList: number[]) =>
      standardAddedClassifiersIdsList,
  )
  .reset(resetCreateStandardModal);

const createStandardFx = createEffect<CreateStandardParamsType, void, Error>();
const createStandardAction = async (params: CreateStandardParamsType) => {
  await createStandard(params)
    .then((response: AxiosResponse) => {
      const { id: newStandardId } = response.data;
      getStandardsTreeFx(false);
      setActiveStandardId(newStandardId);
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: createStandardFx,
        params,
        shouldShowToast: true,
        toastMessage: 'Не удалось создать новый стандарт',
      });
    });
};
createStandardFx.use(createStandardAction);

// default standards

const $standardsSettings = createStore<StandardsSettingsData[]>([]);

const getStandardsSettingsFx = createEffect<
  void,
  StandardsSettingsData[]
>().use(async () => {
  const standardsListAll: BackendStandardDataType[] = await (
    await getStandardsList(null).catch((error: AxiosError) => {
      handleError({ error, request: getStandardsSettingsFx });

      return error.response;
    })
  ).data;
  return fetchStandardsSettingsAdapter(standardsListAll).sort(
    (a, b) => a.id - b.id,
  );
});

$standardsSettings.on(getStandardsSettingsFx.doneData, (_, payload) => payload);

const toggleDefaultStandardFx = createEffect<number, void>().use(async (id) => {
  setDefaultStandard(id).then(() => {
    getStandardsSettingsFx();
  });
});

export {
  $activeStandardData,
  $activeStandardId,
  $classifierDescription,
  $classifierName,
  $classifierType,
  $isStandardEditActive,
  $isTableLoading,
  $openChildrenCardsIdsList,
  $selectedClassifier,
  $selectedMeasure,
  $standardAddedClassifiersIdsList,
  $standardBufferAction,
  $standardDefaultClassifiersList,
  $standardName,
  $standardsSettings,
  $standardsTreeData,
  $standardType,
  addClassifierFx,
  changeStandardFx,
  clearStandardBufferAction,
  copyStandardFx,
  createStandardFx,
  deleteStandardFx,
  getActiveStandardDataFx,
  getRecommendedClassifiersFx,
  getStandardsSettingsFx,
  getStandardsTreeFx,
  resetClassifierModal,
  resetCreateStandardModal,
  resetStandardsData,
  setActiveStandardData,
  setActiveStandardId,
  setClassifierDescription,
  setClassifierName,
  setClassifierType,
  setIsStandardEditActive,
  setOpenChildrenCardsIdsList,
  setSelectedClassifier,
  setSelectedMeasure,
  setStandardAddedClassifiersIdsList,
  setStandardBufferAction,
  setStandardDefaultClassifiersList,
  setStandardName,
  setStandardsTreeData,
  setStandardType,
  toggleDefaultStandardFx,
};
