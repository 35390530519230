import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useStore } from 'effector-react';

import Button from '../../../Components/Button';
import { IconsNames } from '../../../Components/Icon/constants';
import Icon from '../../../Components/Icon/Icon';
import { $userRoles } from '../../../Store/authStore';
import { RolesList } from '../../PageAdmin/PageAdmin.interface';
import {
  PageMonitoringReagentsMessageProps,
  ReagentsInputTypes,
} from '../PageMonitoring.interface';

import styles from '../PageMonitoring.module.scss';

function PageMonitoringReagentsMessage(
  props: PageMonitoringReagentsMessageProps,
) {
  const { inputType, onReagentsSkip, onReagentsAdd } = props;
  const currentUserRoles: number[] = useStore($userRoles);

  const isButtonShown: boolean = useMemo(
    () => currentUserRoles.includes(RolesList.TECHNOLOGIST),
    [currentUserRoles],
  );

  const { messageTitle, messageSubtitle, messageButtonTitle, messageIconName } =
    useMemo(
      () =>
        inputType === ReagentsInputTypes.Intermediate
          ? {
              messageTitle:
                'Появился промежуточный отчет по лабораторным исследованиям показателей воды',
              messageSubtitle:
                'Хотите ли вы внести корректировку ввода расчетных доз реагентов?',
              messageButtonTitle: 'Внести корректировку',
              messageIconName: IconsNames.Dropper,
            }
          : {
              messageTitle:
                'Появился новый отчет по лабораторным исследованиям показателей воды',
              messageSubtitle:
                'Нажмите на кнопку ниже, чтобы ввести расчетные активные дозы реагентов',
              messageButtonTitle: 'Ввести расчетные дозы реагентов',
              messageIconName: IconsNames.Flask,
            },
      [inputType],
    );

  return (
    <div className={styles.reagents_message}>
      <div className={styles.reagents_message_title}>{messageTitle}</div>
      {isButtonShown ? (
        <>
          <div className={styles.reagents_message_subtitle}>
            {messageSubtitle}
          </div>
          <Button
            className={styles.reagents_message_button}
            onClick={onReagentsAdd}
          >
            {messageButtonTitle}
          </Button>
          <Button
            className={clsx(
              styles.reagents_message_button,
              styles.corrective_reagents_message_button,
            )}
            onClick={onReagentsSkip}
            isSecondary
          >
            Оставить текущее
          </Button>
        </>
      ) : (
        <div className={styles.reagents_message_subtitle}>
          Ожидается ввод реагентов
        </div>
      )}

      <Icon name={messageIconName} className={styles.reagents_message_icon} />
    </div>
  );
}

export default PageMonitoringReagentsMessage;
