import { Dispatch, SetStateAction } from 'react';

import {
  DatePickerCalendarView,
  DatePickerRef,
  DatePickerTypes,
} from '../DatePicker.interface';

function useHandlers(
  calendarView: DatePickerCalendarView,
  type: DatePickerTypes,
  datePickerRef: DatePickerRef,
  selectedValue: Date,
  onSingleDateChange: (value: Date) => void,
  onDateRangeChange: (values: Array<Date>) => void,
  setCalendarView: Dispatch<SetStateAction<DatePickerCalendarView>>,
) {
  const handleChange = (value: Date & Array<Date>) => {
    const newValue: Date = selectedValue;

    switch (calendarView) {
      case DatePickerCalendarView.Months:
        if (type === DatePickerTypes.MonthYear) {
          newValue.setMonth(value.getMonth());
          newValue.setFullYear(value.getFullYear());
          onSingleDateChange(newValue);
          datePickerRef.current.setOpen(false);
          break;
        }
        setCalendarView(DatePickerCalendarView.Days);
        return;
      case DatePickerCalendarView.Years:
        if (type === DatePickerTypes.Year) {
          newValue.setFullYear(value.getFullYear());
          onSingleDateChange(newValue);
          datePickerRef.current.setOpen(false);
          break;
        }
        setCalendarView(DatePickerCalendarView.Months);
        return;
      default:
        onSingleDateChange(newValue);
        break;
    }

    switch (type) {
      case DatePickerTypes.SingleDate:
      case DatePickerTypes.SingleDateTime:
        if (calendarView === DatePickerCalendarView.Days) {
          onSingleDateChange(value);
          datePickerRef.current.setOpen(false);
        }

        break;
      case DatePickerTypes.DateRange:
        if (calendarView === DatePickerCalendarView.Days) {
          onDateRangeChange(value);
          if (value[1]) {
            datePickerRef.current.setOpen(false);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleHeaderMonthClick = () => {
    setCalendarView(DatePickerCalendarView.Months);
  };

  const handleHeaderYearClick = () => {
    setCalendarView(DatePickerCalendarView.Years);
  };

  const handleChangeRaw = (event) => {
    const { ariaSelected, tabIndex } = event.target;
    const isSelected: boolean = ariaSelected || tabIndex === 0;

    if (isSelected) {
      handleChange(selectedValue as Date & Date[]);
    }
  };

  return {
    handleChangeRaw,
    handleChange,
    handleHeaderMonthClick,
    handleHeaderYearClick,
  };
}

export default useHandlers;
