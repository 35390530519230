import { AxiosError } from 'axios';
import { Effect } from 'effector';

import { ToastTypes } from '../Components/Toast/Toast.interface';
import { refreshTokenFx } from '../Store/authStore';
import { setToastData } from '../Store/toastStore';

const TOKEN_TIMEOUT_ERROR_CODE: number = 401;
const TOAST_ERROR_TITLE: string = 'Что-то пошло не так';
const TOAST_ERROR_CLOSE_DELAY: number = 5000;

export type HandleErrorParamsType = {
  error: AxiosError;
  request?: Effect<any, any, Error>;
  params?: any;
  shouldShowToast?: boolean;
  toastMessage?: string;
};

function handleError({
  error,
  request,
  params,
  shouldShowToast = false,
  toastMessage = '',
}: HandleErrorParamsType) {
  if (error) {
    const { status } = error.response.request;
    if (status === TOKEN_TIMEOUT_ERROR_CODE) {
      const refreshToken = localStorage.getItem('refresh');
      refreshTokenFx({ refreshToken, request, params });
    }

    if (shouldShowToast) {
      setToastData({
        isShown: true,
        toastType: ToastTypes.Critical,
        title: TOAST_ERROR_TITLE,
        message: toastMessage,
        closeDelay: TOAST_ERROR_CLOSE_DELAY,
      });
    }
  }
  throw error;
}

export default handleError;
