import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';

import { StandardCardDataType } from '../../Components/StandardsTree/StandardsTree.interface';
import StandardEditWarningModal from '../../Pages/PageStandards/components/StandardEditWarningModal';
import StandardsTreeSection from '../../Pages/PageStandards/components/StandardsTreeSection';
import {
  ChangeStandardParamsType,
  StandardTableDataType,
} from '../../Pages/PageStandards/PageStandards.interface';
import {
  $activeStandardData,
  $activeStandardId,
  $isStandardEditActive,
  $openChildrenCardsIdsList,
  $standardBufferAction,
  $standardsSettings,
  $standardsTreeData,
  changeStandardFx,
  clearStandardBufferAction,
  copyStandardFx,
  getStandardsSettingsFx,
  getStandardsTreeFx,
  setActiveStandardId,
  setOpenChildrenCardsIdsList,
  setStandardBufferAction,
  toggleDefaultStandardFx,
} from '../../Store/standardsPageStore';
import {
  StandardBufferActions,
  StandardBufferActionType,
} from '../../Store/types';

import StandardCreateModalContainer from './StandardCreateModalContainer';

function StandardsTreeSectionContainer() {
  const activeStandardId: number = useStore($activeStandardId);
  const {
    isWarningModalShown,
    standardId,
    actionType,
  }: StandardBufferActionType = useStore($standardBufferAction);
  const standardsList: StandardCardDataType[] = useStore($standardsTreeData);
  const isStandardEditActive: boolean = useStore($isStandardEditActive);
  const standardData: StandardTableDataType = useStore($activeStandardData);
  const openChildrenCardsIdsList = useStore($openChildrenCardsIdsList);
  const settings = useStore($standardsSettings);
  const [isCreateStandardModalShown, setIsCreateStandardModalShown] =
    useState<boolean>(false);

  const handleCardClick = (cardId: number) => {
    if (!isStandardEditActive) {
      setActiveStandardId(cardId);
    } else {
      setStandardBufferAction({
        actionType: StandardBufferActions.StandardClick,
        standardId: cardId,
        isWarningModalShown: true,
        path: '',
      });
    }
  };

  const handleStandardCopy = (cardId: number) => {
    if (isStandardEditActive) {
      setStandardBufferAction({
        actionType: StandardBufferActions.StandardCopy,
        standardId: cardId,
        isWarningModalShown: true,
        path: '',
      });
    } else {
      copyStandardFx(cardId);
    }
  };

  const handleNewStandardAdd = () => {
    if (isStandardEditActive) {
      setStandardBufferAction({
        actionType: StandardBufferActions.StandardCreate,
        standardId: null,
        isWarningModalShown: true,
        path: '',
      });
    } else {
      setIsCreateStandardModalShown(true);
    }
  };

  const handleEditRequestSuccess = () => {
    switch (actionType) {
      case StandardBufferActions.StandardClick:
        setActiveStandardId(standardId);
        break;
      case StandardBufferActions.StandardCopy:
        copyStandardFx(standardId);
        break;
      case StandardBufferActions.StandardCreate:
        setIsCreateStandardModalShown(true);
        break;
      default:
        break;
    }
  };
  const handleWarningModalConfirm = () => {
    const { id, name, description, standardType, subType, classifiers } =
      standardData;
    const params: ChangeStandardParamsType = {
      id,
      name,
      description: description || '',
      standardType,
      classifiers,
      subType,
      onSuccess: handleEditRequestSuccess,
    };
    changeStandardFx(params);
  };

  const handleWarningModalClose = () => {
    clearStandardBufferAction();
  };

  const handleOpenChildrenCardsIdsListChange = (ids: number[]) => {
    setOpenChildrenCardsIdsList(ids);
  };

  useEffect(() => {
    getStandardsTreeFx(true);
    getStandardsSettingsFx();
  }, []);

  return (
    <>
      <StandardsTreeSection
        standardsList={standardsList}
        activeCardId={activeStandardId}
        openChildrenCardsIdsList={openChildrenCardsIdsList}
        onOpenChildrenCardsIdsListChange={handleOpenChildrenCardsIdsListChange}
        onCardClick={handleCardClick}
        onCardCopy={handleStandardCopy}
        onNewStandardAdd={handleNewStandardAdd}
        settings={settings}
        onSettingsChange={toggleDefaultStandardFx}
      />
      {isWarningModalShown && (
        <StandardEditWarningModal
          onClose={handleWarningModalClose}
          onConfirm={handleWarningModalConfirm}
        />
      )}
      {isCreateStandardModalShown && (
        <StandardCreateModalContainer
          onClose={() => {
            setIsCreateStandardModalShown(false);
          }}
        />
      )}
    </>
  );
}

export default StandardsTreeSectionContainer;
