import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';

import Card from '../../Components/Card';
import { SkeletonNavBar, SkeletonPage } from '../../Components/Skeleton';
import { $isAuthenticated, $locationPathname } from '../../Store/authStore';

import styles from './PageLoading.module.scss';

function PageLoading() {
  const navigate = useNavigate();
  const isAuthenticated = useStore($isAuthenticated);
  const locationPathname = useStore($locationPathname);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(locationPathname);
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className={styles.navbar_wrapper}>
        <SkeletonNavBar />
      </div>

      <div className={styles.page_wrapper}>
        <Card className={styles.card}>
          <SkeletonPage />
        </Card>
      </div>
    </>
  );
}

export default PageLoading;
