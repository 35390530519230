import { createEffect, createEvent, createStore, sample } from 'effector';

import { ToastTypes } from '../Components/Toast/Toast.interface';
import { getApiVersion } from '../DataAccessLayer/apiServices';

import { setToastData } from './toastStore';

const $apiVersion = createStore<string | null>(null);

export const getApiVersionInitialFx = createEffect<void, string>().use(
  async () => {
    return getApiVersion().then((response) => response.data.version);
  },
);

$apiVersion.on(getApiVersionInitialFx.doneData, (_, payload) => payload);

export const checkForUpdates = createEvent();

const checkForUpdatesFx = createEffect<string, void>().use(
  async (localApiVersion) => {
    getApiVersion().then((response) => {
      const actualApiVersion = response.data.version;

      if (localApiVersion !== actualApiVersion) {
        setToastData({
          title: 'Доступна новая версия приложения',
          message: '',
          toastType: ToastTypes.Info,
          isShown: true,
          promptProps: {
            message: `Для её использования необходима перезагрузка страницы.\nВы хотите выполнить перезагрузку прямо сейчас?`,
            submitButtonLabel: 'Перезагрузить',
            onSubmitButtonClick: () => window.location.reload(),
            cancelButtonLabel: 'Позже',
            onCancelButtonClick: () => {},
          },
        });
      }
    });
  },
);

sample({
  clock: checkForUpdates,
  source: $apiVersion,
  target: checkForUpdatesFx,
});
