import { createEvent, createStore } from 'effector';

const resetLoading = createEvent();

const setIsLoading = createEvent<boolean>();
const $isLoading = createStore<boolean>(true)
  .on(setIsLoading, (_, isLoading: boolean) => isLoading)
  .reset(resetLoading);

export { $isLoading, resetLoading, setIsLoading };
