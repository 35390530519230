import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useStore } from 'effector-react';

import Icon from '../../../Components/Icon';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton/IconButton';
import IconWarningSection from '../../../Components/IconWarningSection/IconWarningSection';
import Popover from '../../../Components/Popover';
import {
  PopoverPlacements,
  PopoverTriggerEvents,
} from '../../../Components/Popover/constants';
import { $userRoles } from '../../../Store/authStore';
import { RolesList } from '../../PageAdmin/PageAdmin.interface';
import { emptyValue, reagentTableColumnsList } from '../constants';
import {
  PageReagentsTableProps,
  ReagentDataType,
} from '../PageReagents.interface';

import styles from '../PageReagents.module.scss';

function PageReagentsTable(props: PageReagentsTableProps) {
  const {
    reagents,
    onReagentDelete,
    onReagentCreate,
    onReagentEdit,
    onReagentFileButtonClick,
  } = props;

  const currentUserRoles: number[] = useStore($userRoles);

  const handleDeleteButtonClick = (id: number, name: string) => () => {
    onReagentDelete(id, name);
  };

  const handleEditButtonClick = (reagent: ReagentDataType) => () => {
    onReagentEdit(reagent);
  };

  const handleReagentFileButtonClick = (id: number) => () => {
    onReagentFileButtonClick(id);
  };

  const isButtonShown: boolean = useMemo(
    () => currentUserRoles.includes(RolesList.ADMIN),
    [currentUserRoles],
  );

  const reagentsTableRows: JSX.Element[] | JSX.Element = useMemo(
    () =>
      reagents.map((reagent: ReagentDataType) => {
        const {
          id,
          name,
          description,
          yearConsumption,
          monthConsumption,
          dayConsumption,
          unitCost,
          fileName,
        } = reagent;

        const yearConsumptionValue: string = yearConsumption
          ? yearConsumption.toLocaleString('ru', { minimumFractionDigits: 2 })
          : emptyValue;
        const monthConsumptionValue: string = monthConsumption
          ? monthConsumption.toLocaleString('ru', { minimumFractionDigits: 2 })
          : emptyValue;
        const dayConsumptionValue: string = dayConsumption
          ? dayConsumption.toLocaleString('ru', { minimumFractionDigits: 2 })
          : emptyValue;
        const unitCostValue: string = unitCost
          ? unitCost.toLocaleString('ru', { minimumFractionDigits: 2 })
          : emptyValue;

        return (
          <div className={styles.reagent_table_row} key={id}>
            <div className={styles.reagent_cell}>
              {name}
              {description && (
                <Popover
                  placement={PopoverPlacements.Right}
                  trigger={PopoverTriggerEvents.Hover}
                  content={
                    <div className={styles.reagent_description}>
                      {description}
                    </div>
                  }
                >
                  <span className={styles.hint_icon}>
                    <Icon name={IconsNames.Help} />
                  </span>
                </Popover>
              )}
            </div>
            <div className={styles.reagent_cell}>{yearConsumptionValue}</div>
            <div className={styles.reagent_cell}>{monthConsumptionValue}</div>
            <div className={styles.reagent_cell}>{dayConsumptionValue}</div>
            <div className={styles.reagent_cell}>{unitCostValue}</div>
            <div className={styles.reagent_cell}>
              <button
                type="button"
                onClick={handleReagentFileButtonClick(id)}
                className={styles.file_download_button}
                disabled={fileName === null}
              >
                <Icon
                  name={IconsNames.Document}
                  className={styles.file_download_button_icon}
                />
                <span
                  className={styles.file_name}
                  title={fileName || 'Документ не добавлен'}
                >
                  {fileName || 'Документ не добавлен'}
                </span>
              </button>
              {isButtonShown && (
                <div className={styles.edit_delete_buttons}>
                  <IconButton
                    iconName={IconsNames.Edit}
                    onClick={handleEditButtonClick(reagent)}
                    className={styles.edit_button}
                    isClear
                  />
                  <IconButton
                    iconName={IconsNames.Delete}
                    onClick={handleDeleteButtonClick(id, name)}
                    className={styles.delete_button}
                    isClear
                  />
                </div>
              )}
            </div>
          </div>
        );
      }),
    [reagents],
  );

  const reagentTableColumns: JSX.Element[] = reagentTableColumnsList.map(
    (columnName: string) => (
      <div className={styles.reagent_table_column} key={columnName}>
        {columnName}
      </div>
    ),
  );

  const isReagentsListEmpty: boolean = useMemo(
    () => reagents === null || reagents.length === 0,
    [reagents],
  );

  return (
    <>
      {!isReagentsListEmpty ? (
        <>
          <div className={styles.reagent_table_header}>
            {reagentTableColumns}
          </div>
          <div className={styles.reagents_table_body}>{reagentsTableRows}</div>
        </>
      ) : (
        <IconWarningSection
          iconName={IconsNames.Flask}
          message="Реагенты не найдены"
          className={styles.icon_warning_section}
        />
      )}

      {isButtonShown && (
        <button
          type="button"
          className={clsx(styles.add_reagent_button, {
            [styles.add_reagent_button_standard]: isReagentsListEmpty,
          })}
          onClick={onReagentCreate}
        >
          Добавить новый реагент
        </button>
      )}
    </>
  );
}

export default PageReagentsTable;
