import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

import { ComplianceGraphProps } from './ComplianceGraph.interface';

function ComplianceGraph(props: ComplianceGraphProps) {
  const {
    value,
    minStandardValue,
    maxStandardValue,
    minControlValue,
    maxControlValue,
    status,
  } = props;

  const [fontSize, setFontSize] = useState<number>(10);

  let color: string;
  if (status === 'DONE') {
    color = '#099A71'; // 🟩
  } else if (status === 'BORDER') {
    color = '#FCB53B'; // 🟧
  } else {
    color = '#E0222C'; // 🟥
  }

  const maxValue: number = Math.max(
    value,
    maxControlValue,
    maxStandardValue,
    minControlValue,
    minStandardValue,
  );

  const handleResize = () => {
    if (document.documentElement.clientWidth < 1500) {
      setFontSize(10);
    } else {
      setFontSize(11);
    }
  };

  const option = {
    grid: {
      height: 5,
      top: 30,
      left: 10,
      right: 20,
    },
    color: [color],
    xAxis: {
      type: 'value',
      min: 0,
      splitNumber: 6,
      axisLabel: {
        color: '#262626',
        fontFamily: 'Rubik',
        fontSize: fontSize.toString(),
        margin: 5,
        formatter: (labelValue) => labelValue.toLocaleString(),
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: true,
      },
    },
    yAxis: {
      type: 'category',
      show: false,
      data: [''],
    },
    series: [
      {
        data: [value],
        type: 'line',
        symbolSize: 10,
        symbolOffset: [0, '-2'],
        z: 10,
        symbol: 'triangle',
        symbolRotate: 180,

        label: {
          distance: -4,
          silent: true,
          show: true,
          backgroundColor: color,
          color: '#fff',
          fontFamily: 'Rubik',
          fontSize: '10',
          padding: [4, 8],
          borderRadius: 2,
          formatter: (params) => params.value.toLocaleString(),
        },
      },

      {
        data: [
          minStandardValue,
          maxStandardValue,
          minControlValue,
          maxControlValue,
          maxValue,
        ],
        type: 'line',
        showSymbol: false,
        lineStyle: {
          color: 'transparent',
        },
        silent: true,
        markLine: {
          symbol: ['none', 'none'],
          silent: true,
          data: [
            [
              { name: 'line mark', coord: [minStandardValue, 0] },
              { coord: [maxStandardValue, 0] },
            ],
          ],
          lineStyle: {
            color: 'rgba(217, 240, 245, 1)',
            width: 5,
            type: 'solid',
          },
          label: {
            show: false,
          },
        },
      },
    ],
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ReactEcharts
      option={option}
      style={{ height: '100%', width: '100%', wordBreak: 'keep-all' }}
    />
  );
}

export default ComplianceGraph;
