import React, { useCallback, useEffect, useRef, useState } from 'react';
import reactDom from 'react-dom';
import clsx from 'clsx';

import ModalProps from './Modal.interface';

import styles from './Modal.module.scss';

function Modal({
  children,
  className,
  isToBeClosed,
  onClose,
  windowClassName,
}: ModalProps) {
  const [isShown, setIsShown] = useState<boolean>(true);

  const modalsContainerRef = useRef(document.querySelector('#root_modals'));

  const backgroundClassNames = clsx(
    styles.background,
    { [styles.background_closed]: !isShown },
    className,
  );

  const windowClassNames = clsx(
    styles.window,
    {
      [styles.window_closed]: !isShown,
    },
    windowClassName,
  );

  const handleAnimationEnd = useCallback(() => {
    if (!isShown) {
      onClose();
    }
  }, [isShown, onClose]);

  useEffect(() => {
    if (isToBeClosed) {
      setIsShown(false);
    }
  }, [isToBeClosed]);

  return reactDom.createPortal(
    <div className={backgroundClassNames} onAnimationEnd={handleAnimationEnd}>
      <div className={windowClassNames}>{children}</div>
    </div>,
    modalsContainerRef.current,
  );
}

export default Modal;
