import React, { DragEvent, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useStore } from 'effector-react';

import {
  $connections,
  $currentDragSourceId,
  $date,
  $isDragActive,
  changeLinkingFx,
  setIsDragActive,
  setIsDragComplete,
} from '../../../Store/pageMappingStore';
import { ChangeLinkingParamsType } from '../../../Store/types';
import {
  CardsConnectionDataType,
  PageMappingDroppableContainerProps,
} from '../PageMapping.interface';

import styles from '../PageMapping.module.scss';

function PageMappingDroppableContainer(
  props: PageMappingDroppableContainerProps,
) {
  const { isConnected, type, id } = props;

  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  const date: Date = useStore($date);
  const connections: CardsConnectionDataType[] = useStore($connections);
  const currentDragSourceId: string | number = useStore($currentDragSourceId);
  const isDragActive: boolean = useStore($isDragActive);

  const isDropDisabled: boolean = useMemo(
    () => isConnected && currentDragSourceId?.toString() !== id.toString(),
    [currentDragSourceId, isConnected, id],
  );

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    if (!isDropDisabled) {
      event.preventDefault();
    }
  };

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    if (!isDropDisabled) {
      event.preventDefault();
      setIsDragOver(true);
    }
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    setIsDragComplete(true);
    setIsDragOver(false);
    setIsDragActive(false);

    const initialCardId: string = event.dataTransfer.getData('initialCardId');

    if (id.toString() === initialCardId) {
      return;
    }
    const connectionId: number = Number(
      event.dataTransfer.getData('connectionId'),
    );

    const initialConnection: CardsConnectionDataType = connections.find(
      (connection: CardsConnectionDataType) =>
        connectionId === Number(connection.id),
    );

    const doseId = Number(id.split('-')[1]);
    const initialCardDoseId = Number(initialCardId.split('-')[1]);
    const researchId = Number(initialConnection.startCardId.split('-')[1]);
    const changeLinkingParams: ChangeLinkingParamsType = {
      add: {
        doseId,
        researchId,
      },
      date,
    };

    if (connectionId !== 0) {
      changeLinkingParams.delete = {
        doseId: initialCardDoseId,
        researchId,
      };
    }

    changeLinkingFx(changeLinkingParams);
  };

  return (
    <div
      className={clsx(styles.draggable_hint_container, styles[type], {
        [styles.connected]: isConnected,
        [styles.drag_over]: isDragOver,
        [styles.disabled]: isDropDisabled,
        [styles.drag_active]: isDragActive,
      })}
    >
      <div
        className={clsx(
          styles.draggable_hint,
          styles.draggable_hint_bottom,
          styles[type],
        )}
        id={`hint-${type}-${id}-bottom`}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      />
      <div
        className={clsx(
          styles.draggable_hint,
          styles.draggable_hint_top,
          styles[type],
        )}
        id={`hint-${type}-${id}-top`}
      />
    </div>
  );
}

export default PageMappingDroppableContainer;
