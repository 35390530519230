import React from 'react';
import { useStore } from 'effector-react';

import { RangeType } from '../../Components/PeriodPickerSection/PeriodPickerSection.interface';
import { OptionType } from '../../Components/Select/Select.interface';
import PageHeader from '../../Pages/PageLaboratoryTests/components/PageHeader';
import {
  defaultStandardOption,
  waterTypesOptionsList,
} from '../../Pages/PageLaboratoryTests/constants';
import {
  IntermediateWaterSubTypes,
  WaterTypes,
} from '../../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';
import { $workingStandards } from '../../Store/cataloguesStore';
import {
  $dateRange,
  $periodType,
  $standard,
  $standardsOptionsList,
  $status,
  $waterType,
  resetData,
  setDateRange,
  setPeriodType,
  setStandard,
  setStandardsOptionsList,
  setStatus,
  setWaterType,
} from '../../Store/researchReportsPageStore';
import { StandardsListType } from '../../Store/types';

function ReportsHeaderContainer() {
  const waterType: OptionType = useStore($waterType);
  const standard: OptionType = useStore($standard);
  const status: OptionType = useStore($status);
  const dateRange: RangeType = useStore($dateRange);
  const standardsOptionsList: OptionType[] = useStore($standardsOptionsList);
  const standardsList: StandardsListType = useStore($workingStandards);
  const periodType: OptionType = useStore($periodType);

  const handleWaterTypeChange = (option: OptionType) => {
    setWaterType(option);
    let optionsList: OptionType[] = [];

    switch (option.value) {
      case WaterTypes.Potable:
        optionsList = standardsList.potable.slice();
        break;
      case WaterTypes.Surface:
        optionsList = standardsList.surface.slice();
        break;
      case WaterTypes.Intermediate:
        optionsList = standardsList.intermediate.slice();
        break;
      case IntermediateWaterSubTypes.Filters:
        optionsList = standardsList.filters.slice();
        break;
      case IntermediateWaterSubTypes.Sump:
        optionsList = standardsList.sump.slice();
        break;
      default:
        optionsList = standardsList.all.slice();
        break;
    }

    setStandardsOptionsList(optionsList);
    setStandard(defaultStandardOption);
  };

  const handleStandardChange = (option: OptionType) => {
    setStandard(option);
  };

  const handleStatusChange = (option: OptionType) => {
    setStatus(option);
  };

  const handleDateRangeChange = (value: RangeType) => {
    setDateRange(value);
  };

  const handlePeriodTypeChange = (option: OptionType) => {
    setPeriodType(option);
  };

  return (
    <PageHeader
      onClearFilters={resetData}
      waterType={waterType}
      standard={standard}
      status={status}
      dateRange={dateRange}
      standardsOptionsList={standardsOptionsList}
      waterTypesOptionsList={waterTypesOptionsList}
      periodType={periodType}
      onPeriodTypeChange={handlePeriodTypeChange}
      onWaterTypeChange={handleWaterTypeChange}
      onStandardChange={handleStandardChange}
      onDateRangeChange={handleDateRangeChange}
      onStatusChange={handleStatusChange}
    />
  );
}

export default ReportsHeaderContainer;
