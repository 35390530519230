import React, { useMemo } from 'react';
import clsx from 'clsx';

import Icon from '../../../Components/Icon';
import { IconsNames } from '../../../Components/Icon/constants';
import MonitoringGraph from '../../../Components/MonitoringGraph/MonitoringGraph';
import { classifiersTableColumnsList } from '../constants';
import {
  ClassifierValueDataType,
  PageMonitoringPanelBodyClassifiersProps,
} from '../PageMonitoring.interface';

import styles from '../PageMonitoring.module.scss';

function PageMonitoringPanelBodyClassifiers(
  props: PageMonitoringPanelBodyClassifiersProps,
) {
  const { datetime, classifiers, graphData, performance } = props;
  const { dates, values } = graphData;

  const classifiersTableColumns: JSX.Element[] =
    classifiersTableColumnsList.map((column: string) => (
      <div className={styles.classifiers_table_column} key={column}>
        {column}
      </div>
    ));
  const classifiersTableRows: JSX.Element[] = useMemo(
    () =>
      classifiers.map((classifier: ClassifierValueDataType) => {
        const { id, name, measureName, value, valueDifference, status } =
          classifier;
        const valueFormatted: string =
          value !== null
            ? value.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '';
        const valueDifferenceSign: string = valueDifference >= 0 ? '+' : '';

        const valueDifferenceFormatted: string =
          valueDifference !== null
            ? `${valueDifferenceSign}${valueDifference.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : '';

        return (
          <div
            className={clsx(styles.classifiers_table_row, styles[status])}
            key={id}
          >
            <div
              className={styles.classifiers_table_cell}
              title={`${name}, ${measureName}`}
            >
              {name}, {measureName}
            </div>
            <div className={styles.classifiers_table_cell}>
              {valueFormatted}
            </div>
            <div className={styles.classifiers_table_cell}>
              {valueDifferenceFormatted}
            </div>
          </div>
        );
      }),
    [classifiers],
  );

  return (
    <div className={styles.panel_classifiers_section}>
      <div className={styles.panel_classifiers_section_header}>
        <div className={styles.panel_classifiers_section_header_section}>
          <Icon name={IconsNames.Note} />
          <span className={styles.panel_classifiers_section_header_title}>
            Показатели воды за {datetime}
          </span>
        </div>
        <div className={styles.panel_classifiers_section_header_section}>
          Производ-ть очереди, тыс.куб.м/сут
          <div
            className={styles.panel_classifiers_section_header_section_value}
          >
            {performance || '-'}
          </div>
        </div>
      </div>
      <div className={styles.panel_classifiers_section_body}>
        <div className={styles.classifiers_table}>
          <div className={styles.classifiers_table_header}>
            {classifiersTableColumns}
          </div>
          <div className={styles.classifiers_table_body}>
            {classifiersTableRows}
          </div>
        </div>
        <div className={styles.classifiers_graph_wrapper}>
          <MonitoringGraph dates={dates} values={values} />
        </div>
      </div>
    </div>
  );
}

export default PageMonitoringPanelBodyClassifiers;
