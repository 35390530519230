import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import Icon from '../Icon/Icon';

import { NavigationLinksProps } from './NavigationLinks.interface';

import styles from './NavigationLinks.module.scss';

function NavigationLinks(props: NavigationLinksProps) {
  const { navigationLinks, className } = props;

  const location = useLocation();

  const getIsLinkActive = (link: string) => {
    return link === location.pathname;
  };

  return (
    <nav className={clsx(styles.navigation_links, className)}>
      <ul className={styles.links_list}>
        {navigationLinks.map((link) => (
          <li key={link.caption} className={styles.links_list_item}>
            <NavLink
              to={link.to}
              className={clsx(styles.item_link, {
                [styles.item_link_active]: getIsLinkActive(link.to),
              })}
            >
              <Icon name={link.iconName} className={styles.link_item_icon} />
              <span className={styles.caption}>{link.caption}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default NavigationLinks;
