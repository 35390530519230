import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';

import { OptionType } from '../../Components/Select/Select.interface';
import StandardClassifierModal from '../../Pages/PageStandards/components/StandardClassifierModal';
import { ClassifierTypes } from '../../Pages/PageStandards/constants';
import {
  ClassifierDataType,
  StandardClassifierDataType,
  StandardTableDataType,
} from '../../Pages/PageStandards/PageStandards.interface';
import {
  $classifiers,
  $classifiersData,
  $measures,
} from '../../Store/cataloguesStore';
import {
  $activeStandardData,
  $classifierDescription,
  $classifierName,
  $classifierType,
  $selectedClassifier,
  $selectedMeasure,
  addClassifierFx,
  resetClassifierModal,
  setActiveStandardData,
  setClassifierDescription,
  setClassifierName,
  setClassifierType,
  setSelectedClassifier,
  setSelectedMeasure,
} from '../../Store/standardsPageStore';
import { CreateClassifierParamsType } from '../../Store/types';

import { StandardsModalContainerProps } from './PageStandardsContainers.interface';

function StandardClassifierModalContainer(props: StandardsModalContainerProps) {
  const { onClose } = props;

  const measuresList: OptionType[] = useStore($measures);
  const classifiersList: OptionType[] = useStore($classifiers);
  const classifierType: ClassifierTypes = useStore($classifierType);
  const selectedClassifier: OptionType = useStore($selectedClassifier);
  const selectedMeasure: OptionType = useStore($selectedMeasure);
  const classifierDescription: string = useStore($classifierDescription);
  const classifierName: string = useStore($classifierName);
  const classifiersData: ClassifierDataType[] = useStore($classifiersData);
  const standardData: StandardTableDataType = useStore($activeStandardData);

  const selectedClassifierData: ClassifierDataType = useMemo(() => {
    if (selectedClassifier) {
      const classifierData: ClassifierDataType = classifiersData.find(
        (classifier: ClassifierDataType) =>
          classifier.id === selectedClassifier.value,
      );
      return classifierData;
    }
    return null;
  }, [selectedClassifier, classifiersData]);

  const availableClassifiersList: OptionType[] = useMemo(
    () =>
      classifiersList.filter(
        (classifier: OptionType) =>
          !standardData?.classifiers.some(
            (addedClassifier) => addedClassifier.id === classifier.value,
          ),
      ),
    [standardData?.classifiers, classifiersList],
  );

  const addedClassifierDescription: string = useMemo(
    () =>
      classifierType === ClassifierTypes.ExistingClassifier
        ? selectedClassifierData?.description
        : classifierDescription,
    [classifierType, selectedClassifierData, classifierDescription],
  );

  const handleClassifierModalClose = () => {
    onClose();
    resetClassifierModal();
  };

  const handleClassifierTypeChange = (type: ClassifierTypes) => {
    setClassifierType(type);
  };

  const handleSelectedClassifierChange = (classifierOption: OptionType) => {
    setSelectedClassifier(classifierOption);
  };

  const handleSelectedMeasureChange = (measureOption: OptionType) => {
    setSelectedMeasure(measureOption);
  };

  const handleClassifierDescriptionChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    setClassifierDescription(value);
  };

  const handleClassifierNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setClassifierName(value);
  };

  const handleClassifierModalConfirm = () => {
    let addedClassifier: StandardClassifierDataType;

    if (classifierType === ClassifierTypes.ExistingClassifier) {
      const { id, name, units, description } = selectedClassifierData;
      addedClassifier = {
        id,
        name,
        units,
        description,
        lsl: 0,
        lcl: 0,
        ucl: 0,
        usl: 0,
        isRequired: true,
      };

      const newClassifiersList: StandardClassifierDataType[] =
        standardData.classifiers.slice();
      newClassifiersList.push(addedClassifier);

      setActiveStandardData({
        ...standardData,
        classifiers: newClassifiersList,
      });

      resetClassifierModal();
    } else {
      const params: CreateClassifierParamsType = {
        measureId: Number(selectedMeasure.value),
        name: classifierName,
        description: classifierDescription,
        standardData,
        units: selectedMeasure.label as string,
      };
      addClassifierFx(params);
    }

    onClose();
  };

  useEffect(() => {
    if (selectedMeasure === null && measuresList?.length !== 0) {
      setSelectedMeasure(measuresList[0]);
    }
  }, [selectedMeasure, measuresList]);

  useEffect(() => {
    if (selectedClassifier === null && availableClassifiersList?.length !== 0) {
      setSelectedClassifier(availableClassifiersList[0]);
    }
  }, [selectedClassifier, availableClassifiersList]);

  return (
    <StandardClassifierModal
      classifierType={classifierType}
      selectedClassifier={selectedClassifier}
      selectedMeasure={selectedMeasure}
      classifierDescription={addedClassifierDescription}
      classifierName={classifierName}
      measuresOptionsList={measuresList}
      classifiersOptionsList={availableClassifiersList}
      onClose={handleClassifierModalClose}
      onConfirm={handleClassifierModalConfirm}
      onClassifierNameChange={handleClassifierNameChange}
      onClassifierDescriptionChange={handleClassifierDescriptionChange}
      onClassifierTypeChange={handleClassifierTypeChange}
      onSelectedClassifierChange={handleSelectedClassifierChange}
      onSelectedMeasureChange={handleSelectedMeasureChange}
    />
  );
}

export default StandardClassifierModalContainer;
