import React from 'react';
import clsx from 'clsx';

import Icon from '../Icon';
import { IconsNames } from '../Icon/constants';

import { SwitcherQuequeProps } from './SwitcherQueque.interface';

import styles from './SwitcherQueque.module.scss';

function SwitcherQueque({
  onChangeQueue,
  queueCount,
  activeQueue,
  queueConfig,
}: SwitcherQuequeProps) {
  const items = new Array(queueCount).fill(0).map((_, index) => {
    const currentQueue = index + 1;
    const onClickQueue = () => onChangeQueue(currentQueue);
    return (
      <button
        type="button"
        key={index}
        className={clsx(styles.switcher__item, {
          [styles.switcher__item_active]: currentQueue === activeQueue,
          [styles.switcher__item_unviewed]:
            currentQueue !== activeQueue &&
            queueConfig &&
            !queueConfig[currentQueue],
        })}
        onClick={onClickQueue}
      >
        <Icon name={IconsNames.Queue} />
        Очередь {currentQueue}
      </button>
    );
  });

  return (
    <div className={styles.switcher}>
      {items.length !== 0 ? items : 'Нет очередей'}
    </div>
  );
}

export default SwitcherQueque;
