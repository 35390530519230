import React from 'react';

import { StandardsOptionLabels } from '../Components/OptionLabels';
import { OptionType } from '../Components/Select/Select.interface';
import { StandardsListType } from '../Store/types';

import {
  BackendClassifierDataType,
  BackendOptionType,
  BackendStandardDataType,
} from './types';

function fetchOptionsListAdapter(
  optionsList: BackendOptionType[],
): OptionType[] {
  return optionsList.map((option: BackendOptionType) => {
    const { id, name } = option;

    return {
      value: id,
      label: name,
    };
  });
}

export function fetchStandardsOptionsListAdapter(
  optionsList: BackendStandardDataType[],
): OptionType[] {
  return optionsList.map((option: BackendStandardDataType) => {
    const { id, name, standard_type: waterType } = option;
    const label = <StandardsOptionLabels waterType={waterType} name={name} />;
    return {
      value: id,
      label,
    };
  });
}

export function fetchStandardsListAdapter(
  potableStandardsList: BackendStandardDataType[],
  surfaceStandardsList: BackendStandardDataType[],
  intermediateStandardsList: BackendStandardDataType[],
  filtersStandardsList: BackendStandardDataType[],
  sumpStandardsList: BackendStandardDataType[],
  postPotableStandardList: BackendStandardDataType[],
  allStandardsList: BackendStandardDataType[],
): StandardsListType {
  const adaptedPotableStandardsList =
    fetchStandardsOptionsListAdapter(potableStandardsList);

  const adaptedSurfaceStandardsList =
    fetchStandardsOptionsListAdapter(surfaceStandardsList);
  const adaptedIntermediateStandardsList = fetchStandardsOptionsListAdapter(
    intermediateStandardsList,
  );
  const adaptedFiltersStandardsList =
    fetchStandardsOptionsListAdapter(filtersStandardsList);
  const adaptedSumpStandardsList =
    fetchStandardsOptionsListAdapter(sumpStandardsList);

  const adaptedAllStandardsList =
    fetchStandardsOptionsListAdapter(allStandardsList);

  const adaptedPostPotableStandardsList = fetchStandardsOptionsListAdapter(
    postPotableStandardList,
  );
  return {
    post_potable: adaptedPostPotableStandardsList,
    potable: adaptedPotableStandardsList,
    surface: adaptedSurfaceStandardsList,
    intermediate: adaptedIntermediateStandardsList,
    filters: adaptedFiltersStandardsList,
    sump: adaptedSumpStandardsList,
    all: adaptedAllStandardsList,
  };
}

export function fetchDefaultStandardsListAdapter(
  potableStandardsList: BackendStandardDataType[],
  surfaceStandardsList: BackendStandardDataType[],
  intermediateStandardsList: BackendStandardDataType[],
  filtersStandardsList: BackendStandardDataType[],
  sumpStandardsList: BackendStandardDataType[],
  postPotableStandardList: BackendStandardDataType[],
  allStandardsList: BackendStandardDataType[],
): StandardsListType {
  const filterDefaultStandards = (data: BackendStandardDataType[]) =>
    data.filter((item) => item.is_default);

  const adaptedPotableStandardsList = fetchStandardsOptionsListAdapter(
    filterDefaultStandards(potableStandardsList),
  );

  const adaptedSurfaceStandardsList = fetchStandardsOptionsListAdapter(
    filterDefaultStandards(surfaceStandardsList),
  );
  const adaptedIntermediateStandardsList = fetchStandardsOptionsListAdapter(
    filterDefaultStandards(intermediateStandardsList),
  );
  const adaptedFiltersStandardsList = fetchStandardsOptionsListAdapter(
    filterDefaultStandards(filtersStandardsList),
  );
  const adaptedSumpStandardsList = fetchStandardsOptionsListAdapter(
    filterDefaultStandards(sumpStandardsList),
  );

  const adaptedAllStandardsList = fetchStandardsOptionsListAdapter(
    filterDefaultStandards(allStandardsList),
  );

  const adaptedPostPotableStandardsList = fetchStandardsOptionsListAdapter(
    filterDefaultStandards(postPotableStandardList),
  );
  return {
    post_potable: adaptedPostPotableStandardsList,
    potable: adaptedPotableStandardsList,
    surface: adaptedSurfaceStandardsList,
    intermediate: adaptedIntermediateStandardsList,
    filters: adaptedFiltersStandardsList,
    sump: adaptedSumpStandardsList,
    all: adaptedAllStandardsList,
  };
}

export function fetchClassifiersOptionsListAdapter(
  classifiersList: BackendClassifierDataType[],
): OptionType[] {
  return classifiersList.map((classifier: BackendClassifierDataType) => {
    const { id, name, measure_name: measureName } = classifier;

    return {
      value: id,
      label: `${name}, ${measureName}`,
    };
  });
}

export default fetchOptionsListAdapter;
