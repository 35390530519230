import { useEffect, useState } from 'react';
import { format, getHours, getMinutes, setMinutes } from 'date-fns';
import TimePicker from 'rsuite/DatePicker';

import { CustomTimeInputProps } from '../DatePicker.interface';

import 'rsuite/dist/rsuite.min.css';

/**
 * Timepicker на базе библиотеки rsuite,
 * с самописным фуункционалом минимальной даты (если значение не валидно)
 * @prop {Date} value - текущее значение времени, храняющееся в datepicker'е;
 * оно обновляется при вызове onTimeChange; на самом деле это не Date, a string
 * Видимо, React-Datepicker форматирует дату сам в формат времени :c
 * @prop {Date | undefined} minDate - минимальная дата для валидации
 * @prop {Date | undefined} maxDate - максимальная дата для валидации
 * @prop {Date} onTimeChange - функция submit'a времени (даты)
 */

function CustomTimeInput(props: CustomTimeInputProps) {
  const {
    value,
    onTimeChange,
    minTimePickerDate: minDate,
    maxTimePickerDate: maxDate,
  } = props;

  const [selectedHour, setSelectedHour] = useState<number>(
    +value.toString().split(':')[0],
  );

  const minDateString = minDate !== undefined ? minDate.split(':') : [0, 0];
  const maxDateString = maxDate !== undefined ? maxDate.split(':') : [23, 59];

  const minDateHour = +minDateString[0];
  const minDateMinute = +minDateString[1];

  const maxDateHour = +maxDateString[0];
  const maxDateMinute = +maxDateString[1];

  const locale = {
    hours: 'Часы',
    minutes: 'Минуты',
    ok: 'Применить',
  };

  // beacause value is string (yap, it's strange)
  const dateFromValue = new Date(format(new Date(), `yyyy-MM-dd ${value}:00`));

  useEffect(() => {
    const date = new Date(format(new Date(), `yyyy-MM-dd ${value}:00`));

    const currentHour = getHours(date);
    const currentMinute = getMinutes(date);

    if (minDate !== undefined) {
      if (currentHour === minDateHour && currentMinute < minDateMinute) {
        const validatedDate = setMinutes(date, minDateMinute);
        onTimeChange(validatedDate);
      }
    }

    if (maxDate !== undefined) {
      if (currentHour === maxDateHour && currentMinute > maxDateMinute) {
        const validatedDate = setMinutes(date, maxDateMinute);
        onTimeChange(validatedDate);
      }
    }
  }, [value, onTimeChange]);

  return (
    <TimePicker
      format="HH:mm"
      cleanable={false}
      editable={false}
      placement="leftEnd"
      locale={locale}
      onChange={onTimeChange}
      value={dateFromValue}
      disabledHours={(hour) => {
        const isInValidByMin =
          minDate !== undefined ? hour < minDateHour : false;
        const isInValidByMax =
          maxDate !== undefined ? hour > maxDateHour : false;
        return isInValidByMin || isInValidByMax;
      }}
      disabledMinutes={(minutes) => {
        if (minDate === undefined && maxDate === undefined) {
          return false;
        }

        if (selectedHour === maxDateHour) {
          return minutes > maxDateMinute;
        }

        if (selectedHour === minDateHour) {
          return minutes < minDateMinute;
        }

        return false;
      }}
      onSelect={(date) => {
        const currentHour = getHours(date);
        const currentMinute = getMinutes(date);

        setSelectedHour(currentHour);

        if (minDate !== undefined) {
          if (currentHour === minDateHour && currentMinute < minDateMinute) {
            const validatedDate = setMinutes(date, minDateMinute);
            onTimeChange(validatedDate);
          }
        }

        if (maxDate !== undefined) {
          if (currentHour === maxDateHour && currentMinute > maxDateMinute) {
            const validatedDate = setMinutes(date, maxDateMinute);
            onTimeChange(validatedDate);
          }
        }
      }}
    />
  );
}

export default CustomTimeInput;
