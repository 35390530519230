export const links = {
  catalogue: {
    waterCanals: '/vodokanals/show_all',
    measures: '/measures/show_all',
  },
  standards: {
    all: '/standards/show_all',
    working: '/standards/show_working',
    allWithClassifiers: '/standards/show_all_with_classifiers',
    byIdWithClassifiers: '/standards/show_by_id_with_classifiers',
    tree: '/standards/show_tree',
    add: '/standards/add',
    change: '/standards/change',
    copy: '/standards/copy',
    delete: '/standards/delete',
    default: '/standards/show_default',
    setDefault: '/standards/set_default',
  },
  classifiers: {
    all: '/classifiers/show_all',
    add: '/classifiers/add',
    recommended: '/classifiers/show_recommended',
  },
  reagents: {
    all: '/reagents/show_all',
    add: '/reagents/add',
    change: '/reagents/change',
    delete: '/reagents/delete',
    document: {
      show: '/reagents/show_document',
      add: '/reagents/add_document',
      remove: '/reagents/remove_document',
      change: '/reagents/change_document',
    },
  },
  research: {
    show: '/research/show_all',
    showById: '/research/show_by_id',
    add: '/research/add',
    delete: '/research/delete',
    export: '/research/get_summary_report',
    change: '/research/change',
  },
  monitoring: {
    show: '/monitoring/show_all',
    showById: '/monitoring/show_by_id',
    showAllDetailed: '/monitoring/show_detailed',
    export: '/monitoring/get_summary_report',
    addDose: '/doses/add',
    deleteDose: '/doses/delete',
    recommended: '/doses/show_recommended',
    editDose: '/doses/change',
    skipDose: '/doses/skip',
  },
  users: {
    show: '/users/show_all',
    info: '/users/show_info',
    updateQueueConfig: '/users/update_config_queue',
    updateSubTypesConfig: '/users/update_config_sub_types',
  },
  admin: {
    users: '/admin/show_all',
    changeRights: '/admin/change_rights',
    delete: '/admin/delete',
  },
  auth: {
    login: '/auth/login',
    logout: '/auth/logout',
    refresh: '/auth/refresh',
  },
  registration: {
    register: '/registration/register',
    accept: '/registration/accept',
    reject: '/registration/reject',
  },
  linking: {
    show: '/linking/show_all',
    changeLinking: '/linking/change',
    changeInterval: '/linking/interval',
  },
  changePassword: {
    check: '/change_password/check',
    change: '/change_password/change',
    request: '/change_password/create_request',
  },
  info: {
    about: '/info/show_about',
  },
  vodokanals: {
    getPredictionType: '/vodokanals/get_prediction_type',
    setPredictionType: '/vodokanals/set_prediction_type',
  },
};
