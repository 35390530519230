import React, { useRef, useState } from 'react';

import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton';
import MonitoringModalSettings from '../../../Components/MonitoringModalSettings/MonitoringModalSettings';
import PeriodPickerSection from '../../../Components/PeriodPickerSection';
import Select from '../../../Components/Select';
import { useHandleClickOutside } from '../../../Utils/useHandleClickOuside';
import { IntermediateWaterSubTypes } from '../../PageLaboratoryTests/PageLaboratoryTests.interface';
import { statusesOptionsList } from '../constants';
import { PageMonitoringFiltersProps } from '../PageMonitoring.interface';

import styles from '../PageMonitoring.module.scss';

function PageMonitoringFilters(props: PageMonitoringFiltersProps) {
  const {
    dateRange,
    onDateRangeChange,
    onPeriodTypeChange,
    onStatusChange,
    periodType,
    status,
    onChangeSettings,
    subTypes,
  } = props;

  const [isSettingsModalShown, setIsSettingsModalShown] = useState(false);
  const handleSettingsButtonClick = () => {
    setIsSettingsModalShown(!isSettingsModalShown);
  };

  const [activeSubTypes, setActiveSubTypes] = useState<string[]>(subTypes);

  const settings = {
    potable: [
      {
        title: 'Промежуточный / Город',
        subType: IntermediateWaterSubTypes.PostPotable,
        isActive: activeSubTypes.includes(
          IntermediateWaterSubTypes.PostPotable,
        ),
      },
    ],
    surface: [
      {
        title: 'Промежуточный / Скорые фильтры',
        subType: IntermediateWaterSubTypes.Filters,
        isActive: activeSubTypes.includes(IntermediateWaterSubTypes.Filters),
      },
      {
        title: 'Промежуточный / Горизонт. отстойники',
        subType: IntermediateWaterSubTypes.Sump,
        isActive: activeSubTypes.includes(IntermediateWaterSubTypes.Sump),
      },
    ],
  };

  const toggleActiveSetting = (subType: string) => {
    if (activeSubTypes.includes(subType)) {
      const filteredSettings = activeSubTypes.filter(
        (item) => item !== subType,
      );
      setActiveSubTypes(filteredSettings);
      onChangeSettings(filteredSettings);
    } else {
      const newSettings = [...activeSubTypes, subType];
      setActiveSubTypes(newSettings);
      onChangeSettings(newSettings);
    }
  };

  const settingsModalButtonRef = useRef(null);
  const settingsModalRef = useRef(null);

  useHandleClickOutside(
    settingsModalRef,
    () => setIsSettingsModalShown(false),
    settingsModalButtonRef,
  );

  return (
    <div className={styles.filters_wrapper}>
      <div className={styles.filters_wrapper__item}>
        <PeriodPickerSection
          range={dateRange}
          periodType={periodType}
          onPeriodTypeChange={onPeriodTypeChange}
          onDateRangeChange={onDateRangeChange}
        />
      </div>
      <div className={styles.filters_wrapper__item}>
        <div className={styles.status_select_wrapper}>
          <Select
            options={statusesOptionsList}
            value={status}
            onChange={onStatusChange}
            className={styles.status_select}
            placeholder="Выберите статус отчета"
          />
        </div>
        <div ref={settingsModalButtonRef}>
          <IconButton
            iconName={IconsNames.Settings}
            onClick={handleSettingsButtonClick}
            className={styles.settings_button}
          />
        </div>
      </div>
      {isSettingsModalShown && (
        <MonitoringModalSettings
          ref={settingsModalRef}
          settings={settings}
          onChangeSettings={toggleActiveSetting}
        />
      )}
    </div>
  );
}
export default PageMonitoringFilters;
