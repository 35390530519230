import React from 'react';
import clsx from 'clsx';

import Icon from '../../Icon';
import { IconsNames } from '../../Icon/constants';
import { CustomInputProps } from '../DatePicker.interface';
import { getInputContent } from '../utils/getInputContent';

import styles from '../DatePicker.module.scss';

function CustomInput(props: CustomInputProps) {
  const {
    selectedDate,
    startDate,
    endDate,
    type,
    ref,
    onClick,
    disabled = false,
  } = props;

  const inputContent = getInputContent(type, selectedDate, startDate, endDate);

  return (
    <button
      type="button"
      ref={ref}
      onClick={onClick}
      className={clsx(styles.custom_input, {
        [styles.disabled]: disabled,
      })}
    >
      {inputContent}
      <div className={styles.custom_input_icon_group}>
        <Icon
          name={IconsNames.Calendar}
          className={clsx(styles.calendar, styles.input_icon)}
        />
      </div>
    </button>
  );
}

export default CustomInput;
