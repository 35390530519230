import { createEvent, createStore } from 'effector';

import { toastDataDefault } from '../Components/Toast/constants';
import { ToastProps } from '../Components/Toast/Toast.interface';

const setToastData = createEvent<ToastProps>();
const clearToastData = createEvent();

const $toastStore = createStore<ToastProps>(toastDataDefault)
  .on(setToastData, (_, newToastData: ToastProps) => newToastData)
  .reset(clearToastData);

export { $toastStore, clearToastData, setToastData };
