import { useMemo } from 'react';
import clsx from 'clsx';

import { WaterTypes } from '../../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';

import { StandardsOptionLabelsProps } from './OptionLabels.interface';

import styles from './OptionLabels.module.scss';

function StandardsOptionLabels(props: StandardsOptionLabelsProps) {
  const { waterType, name } = props;

  const iconText: string = useMemo(() => {
    switch (waterType) {
      case WaterTypes.Potable:
        return 'пит';
      case WaterTypes.Surface:
        return 'пов';
      case WaterTypes.Intermediate:
        return 'пром';
      case WaterTypes.All:
        return 'все';
      default:
        return '';
    }
  }, [waterType]);

  return (
    <div className={styles.option} title={name}>
      {iconText && (
        <div
          className={clsx(
            styles.option_icon,
            styles.water_icon,
            styles[waterType],
          )}
        >
          {iconText}
        </div>
      )}
      <div className={styles.option_text}>{name}</div>
    </div>
  );
}

export default StandardsOptionLabels;
