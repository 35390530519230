import React, { useState } from 'react';
import clsx from 'clsx';

import Button from '../../../Components/Button/Button';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton/IconButton';
import { InputTypes } from '../../../Components/Input/constants';
import Input from '../../../Components/Input/Input';
import Modal from '../../../Components/Modal';
import Select from '../../../Components/Select';
import { modalWaterTypesOptionsList } from '../constants';
import { StandardCreateModalProps } from '../PageStandards.interface';

import StandardAddedClassifiersList from './StandardAddedClassifiersList';
import StandardDefaultClassifiersList from './StandardDefaultClassifiersList';

import styles from '../PageStandards.module.scss';

function StandardCreateModal(props: StandardCreateModalProps) {
  const {
    standardType,
    standardName,
    standardDefaultClassifiersList,
    standardAddedClassifiersList,
    standardAddedClassifiersIds,
    availableClassifiersOptionsList,
    isConfirmButtonDisabled,
    onStandardNameChange,
    onStandardTypeChange,
    onConfirm,
    onClose,
    onStandardDefaultClassifiersListChange,
    onStandardAddedClassifiersListChange,
  } = props;

  const [isModalToBeClosed, setIsModalToBeClosed] = useState<boolean>(false);

  const handleCancelButtonClick = () => {
    setIsModalToBeClosed(true);
  };
  const handleConfirmButtonClick = () => {
    setIsModalToBeClosed(true);
    onConfirm();
  };

  const handleModalClose = () => {
    setIsModalToBeClosed(false);
    onClose();
  };

  return (
    <Modal
      isToBeClosed={isModalToBeClosed}
      onClose={handleModalClose}
      className={styles.standard_modal}
    >
      <div className={styles.standard_warning_modal_content}>
        <div className={styles.modal_header}>
          <div className={styles.modal_title}>Новый стандарт</div>
          <IconButton
            iconName={IconsNames.Cross}
            isClear
            onClick={handleCancelButtonClick}
            className={styles.modal_cross_icon}
          />
        </div>
        <div className={styles.standard_modal_body}>
          <div className={styles.modal_form_row}>
            <div className={styles.modal_form_row_title}>Тип стандарта:</div>
            <Select
              value={standardType}
              options={modalWaterTypesOptionsList}
              onChange={onStandardTypeChange}
              className={styles.modal_buttons_switcher}
            />
          </div>
          <div
            className={clsx(styles.modal_form_row, styles.modal_name_form_row)}
          >
            <div className={styles.modal_form_row_title}>
              Наименование стандарта:
            </div>
            <Input
              value={standardName}
              onChange={onStandardNameChange}
              type={InputTypes.Text}
              placeholder="Введите наименование стандарта"
            />
          </div>
          <div className={styles.modal_classifiers_section_wrapper}>
            <div className={styles.modal_form_row}>
              <div className={styles.modal_form_row_title}>
                Показатели по умолчанию:
              </div>
              <StandardDefaultClassifiersList
                classifiersList={standardDefaultClassifiersList}
                onClassifiersListChange={onStandardDefaultClassifiersListChange}
              />
            </div>
            <StandardAddedClassifiersList
              classifiersIds={standardAddedClassifiersIds}
              classifiersList={standardAddedClassifiersList}
              availableClassifiersOptionsList={availableClassifiersOptionsList}
              onClassifiersListChange={onStandardAddedClassifiersListChange}
            />
          </div>
        </div>

        <div
          className={clsx(
            styles.warning_modal_buttons_row,
            styles.create_modal_buttons_row,
          )}
        >
          <Button
            onClick={handleConfirmButtonClick}
            className={styles.warning_modal_button}
            isDisabled={isConfirmButtonDisabled}
          >
            Создать стандарт
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default StandardCreateModal;
