import React, { ChangeEvent, useMemo } from 'react';

import Input from '../../../Components/Input';
import { InputTypes } from '../../../Components/Input/constants';
import writeFloatInputValue from '../../../Utils/writeFloatInputValue';
import {
  ReportPropertiesTableProps,
  ReportPropertiesType,
  ReportPropertyType,
} from '../PageLaboratoryTests.interface';

import styles from '../PageLaboratoryTests.module.scss';

function ReportPropertiesTable(props: ReportPropertiesTableProps) {
  const { properties, isWarningShown, onChange } = props;

  const isErrorShow = (value: string, minValue: number, maxValue: number) => {
    return Number(value) > maxValue * 2 || Number(value) < minValue / 2;
  };

  const handlePropertyChange =
    (minValue: number, maxValue: number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name } = event.target;
      let { value } = event.target;
      value = writeFloatInputValue(value);
      const isErrorShown: boolean = isErrorShow(value, minValue, maxValue);

      const newPropertiesValue: ReportPropertiesType = {
        ...properties,
        [name]: {
          ...properties[name],
          value,
          isErrorShown,
        },
      };

      onChange(newPropertiesValue);
    };

  const propertiesRows = useMemo(
    () =>
      Object.keys(properties).map((propertyKey: string) => {
        const {
          id,
          name,
          units,
          value,
          isErrorShown,
          minValue,
          maxValue,
        }: ReportPropertyType = properties[propertyKey];

        return (
          <div key={id} className={styles.report_modal_property_row}>
            <div className={styles.report_modal_property_name}>
              <span className={styles.property_name}>{name}</span>, {units}
            </div>
            <div className={styles.report_modal_property_value}>
              <Input
                type={InputTypes.Text}
                value={value?.replace(/[.,]/g, ',')}
                name={propertyKey}
                onChange={handlePropertyChange(minValue, maxValue)}
                min={0}
                step={0.1}
                isErrorShown={isWarningShown && isErrorShown}
              />
            </div>
          </div>
        );
      }),
    [properties, isWarningShown],
  );

  return (
    <div className={styles.report_modal_properties_table}>
      {propertiesRows?.length !== 0 ? (
        propertiesRows
      ) : (
        <div className={styles.no_properties_warning}>
          У данного стандарта пока нет параметров
        </div>
      )}
    </div>
  );
}

export default ReportPropertiesTable;
