function downloadXLSXFile(file: File, fileName: string, contentType: string) {
  const blob = new Blob([file], {
    type: contentType,
  });
  const downloadUrl = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export async function downloadFileWithUrl(
  downloadUrl: string,
  fileName: string,
) {
  const blob = await fetch(downloadUrl).then((result) => result.blob());
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export default downloadXLSXFile;
