import {
  PropertyType,
  ReportsClassifiersDataType,
} from '../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';
import { StatusesList } from '../Static/statusesList';

import {
  BackendClassifierType,
  BackendResearchReportDetailedType,
} from './types';

function fetchResearchClassifiersByIdAdapter(
  classifersData: BackendResearchReportDetailedType,
): ReportsClassifiersDataType {
  const {
    classifiers,
    external_classifiers: externalClassifiers,
    id,
  } = classifersData;

  const classifiersList: PropertyType[] = classifiers.map(
    (classifier: BackendClassifierType) => {
      const {
        id: classifierId,
        usl,
        lsl,
        ucl,
        lcl,
        name: classifierName,
        value,
        last_value: lastValue,
        diff,
        measure_name: measureName,
        status,
      } = classifier;

      const lslValue = lsl === null ? 0 : lsl;
      const lclValue = lcl === null ? lslValue : lcl;
      const uslValue = usl === null ? Infinity : usl;
      const uclValue = ucl === null ? uslValue : ucl;

      return {
        id: classifierId,
        name: classifierName,
        value,
        previousValue: lastValue,
        valueDifference: diff,
        minControlValue: lclValue,
        maxControlValue: uclValue,
        minStandardValue: lslValue,
        maxStandardValue: uslValue,
        measureName,
        isAdditionalProperty: false,
        status,
      };
    },
  );
  const externalClassifiersList: PropertyType[] = externalClassifiers.map(
    (classifier: BackendClassifierType) => {
      const {
        id: classifierId,
        name: classifierName,
        value,
        measure_name: measureName,
      } = classifier;

      return {
        id: classifierId,
        name: classifierName,
        value,
        previousValue: null,
        valueDifference: null,
        minControlValue: null,
        maxControlValue: null,
        minStandardValue: null,
        maxStandardValue: null,
        isAdditionalProperty: true,
        measureName,
        status: StatusesList.Success,
      };
    },
  );

  const properties: PropertyType[] = classifiersList.concat(
    externalClassifiersList,
  );

  return {
    id,
    properties,
  };
}

export default fetchResearchClassifiersByIdAdapter;
