import { IconsNames } from '../Icon/constants';
import { NavigationLinkType } from '../NavigationLinks/NavigationLinks.interface';

export enum PathUrl {
  LaboratoryTests = '/laboratory_tests',
  Monitoring = '/monitoring',
  Reagents = '/reagents',
  Standards = '/standards',
  Login = '/login',
  Register = '/register',
  ChangePassword = '/change_password/:token',
  Statistics = '/statistics',
  Dev = '/dev',
  Loading = '/loading',
  Admin = '/admin',
  ReportsSynch = '/reports_synch',
}

export const adminNavigationLinks: NavigationLinkType[] = [
  {
    caption: 'Лабораторные исследования',
    to: PathUrl.LaboratoryTests,
    iconName: IconsNames.Note,
  },
  {
    caption: 'Мониторинг',
    to: PathUrl.Monitoring,
    iconName: IconsNames.NoteGraph,
  },
  {
    caption: 'Реагенты',
    to: PathUrl.Reagents,
    iconName: IconsNames.Flask,
  },
  {
    caption: 'Стандарты',
    to: PathUrl.Standards,
    iconName: IconsNames.OpenBook,
  },
  {
    caption: 'Синхронизация отчётности',
    to: PathUrl.ReportsSynch,
    iconName: IconsNames.SyncClock,
  },
  {
    caption: 'Панель администратора',
    to: PathUrl.Admin,
    iconName: IconsNames.Users,
  },
];

export const userNavigationLinks: NavigationLinkType[] = [
  {
    caption: 'Лабораторные исследования',
    to: PathUrl.LaboratoryTests,
    iconName: IconsNames.Note,
  },
  {
    caption: 'Мониторинг',
    to: PathUrl.Monitoring,
    iconName: IconsNames.Note,
  },
  {
    caption: 'Реагенты',
    to: PathUrl.Reagents,
    iconName: IconsNames.Note,
  },
  {
    caption: 'Стандарты',
    to: PathUrl.Standards,
    iconName: IconsNames.Note,
  },
];
export const technologistNavigationLinks: NavigationLinkType[] = [
  {
    caption: 'Лабораторные исследования',
    to: PathUrl.LaboratoryTests,
    iconName: IconsNames.Note,
  },
  {
    caption: 'Мониторинг',
    to: PathUrl.Monitoring,
    iconName: IconsNames.Note,
  },
  {
    caption: 'Реагенты',
    to: PathUrl.Reagents,
    iconName: IconsNames.Note,
  },
  {
    caption: 'Стандарты',
    to: PathUrl.Standards,
    iconName: IconsNames.Note,
  },
  {
    caption: 'Синхронизация отчётности',
    to: PathUrl.ReportsSynch,
    iconName: IconsNames.Note,
  },
];
