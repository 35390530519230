import React, { useState } from 'react';
import clsx from 'clsx';

import Button from '../../../Components/Button';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton';
import Modal from '../../../Components/Modal';
import { PageMonitoringDeleteModalProps } from '../PageMonitoring.interface';

import styles from '../PageMonitoring.module.scss';

function PageMonitoringDeleteModal(props: PageMonitoringDeleteModalProps) {
  const { id, onConfirm, onClose } = props;

  const [isModalToBeClosed, setIsModalToBeClosed] = useState<boolean>(false);

  const handleCancelButtonClick = () => {
    setIsModalToBeClosed(true);
  };
  const handleConfirmButtonClick = () => {
    setIsModalToBeClosed(true);
    onConfirm();
  };

  const handleModalClose = () => {
    setIsModalToBeClosed(false);
    onClose();
  };

  return (
    <Modal
      isToBeClosed={isModalToBeClosed}
      onClose={handleModalClose}
      className={clsx(styles.reagents_modal, styles.delete_modal)}
    >
      <div className={clsx(styles.reagents_modal_content, styles.delete_modal)}>
        <div className={styles.modal_header}>
          <div className={styles.modal_title}>
            Вы уверены, что хотите удалить ввод активных доз реагентов № {id}?
          </div>
          <IconButton
            iconName={IconsNames.Cross}
            isClear
            onClick={handleCancelButtonClick}
            className={styles.modal_cross_icon}
          />
        </div>
        <div className={styles.reagents_modal_buttons_row}>
          <Button
            onClick={handleCancelButtonClick}
            className={styles.reagents_modal_button}
            isSecondary
          >
            Отменить
          </Button>
          <Button
            onClick={handleConfirmButtonClick}
            className={styles.reagents_modal_button}
          >
            Удалить
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default PageMonitoringDeleteModal;
