import { StatusesOptionLabels } from '../../Components/OptionLabels';
import { OptionType } from '../../Components/Select/Select.interface';
import { StatusesList } from '../../Static/statusesList';

import { DeleteInputData } from './PageMonitoring.interface';

export const columnsList: string[] = [
  'Дата и время',
  'Автор',
  'Номер лаб. отчета',
  'Тип отчета',
  'Точка отбора проб',
];

export const classifiersTableColumnsList: string[] = [
  'Показатель',
  'Значение',
  'Изменение',
];
export const reagentsTableColumnsList: string[] = [
  'Реагент',
  'Рек. значение',
  'Изменение',
  'Уст. значение',
  'Изменение',
];

export const statusesOptionsList: OptionType[] = [
  {
    value: null,
    label: <StatusesOptionLabels status={null} />,
  },
  {
    value: StatusesList.Success,
    label: <StatusesOptionLabels status={StatusesList.Success} />,
  },
  {
    value: StatusesList.Warning,
    label: <StatusesOptionLabels status={StatusesList.Warning} />,
  },
  {
    value: StatusesList.Critical,
    label: <StatusesOptionLabels status={StatusesList.Critical} />,
  },
  {
    value: StatusesList.Pending,
    label: <StatusesOptionLabels status={StatusesList.Pending} />,
  },
  {
    value: StatusesList.ToBeFilled,
    label: <StatusesOptionLabels status={StatusesList.ToBeFilled} />,
  },
];

export const deleteInputDataDefault: DeleteInputData = {
  id: null,
  isModalShown: false,
  researchId: null,
};

export const strangeValueWarningMessage: string =
  'Вы ввели значения, сильно отличащиеся от средних значений расчётных доз текущих реагентов. Подтвердите правильность введённых данных для продолжения.';

export enum PredictionTypes {
  ALUMINUM_SULFATE = 'ALUMINUM_SULFATE',
  ALUMINUM_OXYCHLORIDE = 'ALUMINUM_OXYCHLORIDE',
}

export const PREDICTION_TYPES_OPTIONS = [
  {
    value: PredictionTypes.ALUMINUM_SULFATE,
    label: 'Сульфат алюминия',
  },
  {
    value: PredictionTypes.ALUMINUM_OXYCHLORIDE,
    label: 'Оксихлорид алюминия',
  },
];
