import PageMonitoringContainer from '../../Containers/PageMonitoringContainers/PageMonitoringContainer';

import styles from './PageMonitoring.module.scss';

function PageMonitoring() {
  return (
    <div className={styles.page_wrapper}>
      <PageMonitoringContainer cardClassName={styles.card} />
    </div>
  );
}

export default PageMonitoring;
