import React, { useMemo } from 'react';
import { useStore } from 'effector-react';

import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton/IconButton';
import Toggle from '../../../Components/Toggle/Toggle';
import { $userId } from '../../../Store/authStore';
import { tableColumnsList } from '../constants';
import {
  PageAdminTableProps,
  RolesList,
  UserDataType,
} from '../PageAdmin.interface';

import styles from '../PageAdmin.module.scss';

function PageAdminTable(props: PageAdminTableProps) {
  const { users, onUserDelete, onRoleChange } = props;
  const userId = useStore($userId);

  const handleDeleteButtonClick = (id: number, name: string) => () => {
    onUserDelete({ id, name });
  };

  const handleToggleChange =
    (id: number, roleId: number) => (isChecked: boolean) => {
      onRoleChange(id, roleId, isChecked);
    };
  const tableColumns: JSX.Element[] = tableColumnsList.map((column: string) => (
    <div key={column} className={styles.table_column}>
      {column}
    </div>
  ));

  const tableRows: JSX.Element[] = useMemo(
    () =>
      users.map((user: UserDataType) => {
        const {
          id,
          initials,
          phone,
          email,
          hasAdminRights,
          hasLaboratoryAssistantRights,
          hasTechnologistRights,
        } = user;

        const isDeleteEnabled: boolean = id !== userId;

        return (
          <div className={styles.table_row} key={id}>
            <div className={styles.table_cell}>{initials}</div>
            <div className={styles.table_cell}>{phone}</div>
            <div className={styles.table_cell}>{email}</div>
            <div className={styles.table_cell}>
              <Toggle
                isChecked={hasLaboratoryAssistantRights}
                onChange={handleToggleChange(id, RolesList.ASSISTANT)}
              />
            </div>
            <div className={styles.table_cell}>
              <Toggle
                isChecked={hasTechnologistRights}
                onChange={handleToggleChange(id, RolesList.TECHNOLOGIST)}
              />
            </div>
            <div className={styles.table_cell}>
              <Toggle
                isChecked={hasAdminRights}
                onChange={handleToggleChange(id, RolesList.ADMIN)}
              />
              {isDeleteEnabled && (
                <IconButton
                  iconName={IconsNames.Delete}
                  onClick={handleDeleteButtonClick(id, initials)}
                  className={styles.delete_button}
                  isClear
                />
              )}
            </div>
          </div>
        );
      }),
    [users, userId],
  );

  return (
    <div className={styles.table_wrapper}>
      <div className={styles.table_columns_list}>{tableColumns}</div>
      <div className={styles.table_rows_list}>{tableRows}</div>
    </div>
  );
}

export default PageAdminTable;
