import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { IconsNames } from '../Icon/constants';
import IconButton from '../IconButton/IconButton';
import { InputTypes } from '../Input/constants';
import Input from '../Input/Input';

import { CounterProps } from './Counter.interface';

import styles from './Counter.module.scss';

function Counter(props: CounterProps) {
  const {
    value,
    onChange,
    isDisabled = false,
    minValue = null,
    maxValue = null,
  } = props;

  const [currentValue, setCurrentValue] = useState<string>();

  const handleMinusButtonClick = () => {
    const newValue: number = value - 1;
    onChange(newValue);
  };

  const handlePlusButtonClick = () => {
    const newValue: number = value + 1;
    onChange(newValue);
  };

  const handleInputValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setCurrentValue(inputValue);
  };

  const handleInputBlur = (event: ChangeEvent<HTMLInputElement>) => {
    let inputValue: number = Number(event.target.value);
    if (minValue !== null && inputValue < minValue) {
      inputValue = minValue;
    } else if (maxValue !== null && inputValue > maxValue) {
      inputValue = maxValue;
    }
    setCurrentValue(inputValue?.toString());

    if (inputValue) {
      onChange(inputValue);
    }
  };

  const isMinusButtonDisabled: boolean = useMemo(
    () => value - 1 < minValue || isDisabled,
    [value, minValue, isDisabled],
  );
  const isPlusButtonDisabled: boolean = useMemo(
    () => value + 1 > maxValue || isDisabled,
    [value, maxValue, isDisabled],
  );

  useEffect(() => {
    setCurrentValue(value?.toString());
  }, [value]);

  return (
    <div className={styles.counter}>
      <IconButton
        iconName={IconsNames.Minus}
        onClick={handleMinusButtonClick}
        isDisabled={isMinusButtonDisabled}
        isClear
        className={styles.counter_button}
      />
      <Input
        value={currentValue}
        type={InputTypes.Number}
        onChange={handleInputValueChange}
        onBlur={handleInputBlur}
        min={minValue}
        max={maxValue}
        disabled={isDisabled}
        className={styles.counter_input}
      />
      <IconButton
        iconName={IconsNames.Plus}
        onClick={handlePlusButtonClick}
        isDisabled={isPlusButtonDisabled}
        isClear
        className={styles.counter_button}
      />
    </div>
  );
}

export default Counter;
