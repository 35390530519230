import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import Logo from '../../Components/Logo';
import { PathUrl } from '../../Components/Routes/AppRoutes.interface';
import AuthFormContainer from '../../Containers/AuthFormContainer/AuthFormContainer';

import { PageAuthProps } from './PageAuth.interface';

import styles from './PageAuth.module.scss';

function PageAuth(props: PageAuthProps) {
  const { pageType } = props;
  const [isFormShown, setIsFormShown] = useState(true);

  const navigate = useNavigate();

  const handleLinkClick = (link: string) => {
    setIsFormShown(false);

    setTimeout(() => {
      setIsFormShown(true);
      navigate(link);
    }, 700);
  };

  const handleRegisterLinkClick = () => {
    handleLinkClick(PathUrl.Register);
  };
  const handleLoginLinkClick = () => {
    handleLinkClick(PathUrl.Login);
  };

  return (
    <div className={styles.login_page_wrapper}>
      <div
        className={clsx(styles.form_col, {
          [styles.form_col_hidden]: !isFormShown,
        })}
      >
        <Logo className={styles.logo} />
        <AuthFormContainer
          pageType={pageType}
          onLoginLinkClick={handleLoginLinkClick}
          onRegisterLinkClick={handleRegisterLinkClick}
        />
      </div>
    </div>
  );
}

export default PageAuth;
