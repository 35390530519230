import { ChangeEvent, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';

import { getMonitoringReportById } from '../../DataAccessLayer/apiServices';
import PageMonitoringReagentsModal from '../../Pages/PageMonitoring/components/PageMonitoringReagentsModal';
import { ModalReagentDataType } from '../../Pages/PageMonitoring/PageMonitoring.interface';
import { $queueNumber } from '../../Store/cataloguesStore';
import {
  $activeCardId,
  $activeCardMinDate,
  $activeDoseId,
  $isWarningCheckboxChecked,
  $isWarningShown,
  $reagentsModalComment,
  $reagentsModalDate,
  $reagentsModalPerformance,
  $reagentsModalReagentsData,
  addDoseFx,
  editDoseFx,
  getRecommendedReagentsFx,
  resetReagentsModal,
  setIsWarningCheckboxChecked,
  setIsWarningShown,
  setReagentsModalComment,
  setReagentsModalDate,
  setReagentsModalPerformance,
  setReagentsModalReagentsData,
} from '../../Store/monitoringPageStore';
import { AddDoseParamsType } from '../../Store/types';

import { PageMonitoringReagentsModalContainerProps } from './PageMonitoringContainers.interface';

function PageMonitoringReagentsModalContainer(
  props: PageMonitoringReagentsModalContainerProps,
) {
  const { onClose, isInEditMode = false } = props;

  const date: Date = useStore($reagentsModalDate);
  const comment: string = useStore($reagentsModalComment);
  const performance: number | string = useStore($reagentsModalPerformance);
  const reagentsData: ModalReagentDataType[] = useStore(
    $reagentsModalReagentsData,
  );
  const activeCardId = useStore($activeCardId);
  const minDate = useStore($activeCardMinDate);
  const isWarningShown = useStore($isWarningShown);
  const isWarningCheckboxChecked = useStore($isWarningCheckboxChecked);
  const queueNumber = useStore($queueNumber);

  const activeDoseId = useStore($activeDoseId);

  const handleDateChange = (newDate: Date) => {
    setReagentsModalDate(newDate);
  };
  const handleCommentValueChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    setReagentsModalComment(value);
  };
  const handlePerformanceValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setReagentsModalPerformance(value);
  };
  const handleReagentsDataChange = (reagents: ModalReagentDataType[]) => {
    setReagentsModalReagentsData(reagents);
  };

  const handleClose = () => {
    resetReagentsModal();
    onClose();
  };
  const hasErrorRows: boolean = useMemo(
    () =>
      reagentsData.some((reagent: ModalReagentDataType) => {
        const { queues } = reagent;
        return Object.keys(queues).some(
          (key: string) => queues[key].isErrorShown,
        );
      }),

    [reagentsData],
  );

  const handleConfirm = () => {
    if (!hasErrorRows || (isWarningShown && isWarningCheckboxChecked)) {
      if (!isInEditMode) {
        const params: AddDoseParamsType = {
          researchId: activeCardId,
          comment,
          performance: Number(performance),
          datetime: date,
          reagents: reagentsData,
        };

        addDoseFx(params).then(() => getMonitoringReportById(activeCardId));
      } else {
        const params = {
          doseId: activeDoseId,
          comment,
          performance: Number(performance),
          datetime: date,
          reagents: reagentsData,
        };

        editDoseFx(params).then(() => getMonitoringReportById(activeCardId));
      }
    }
  };

  const handleWarningCheckboxValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    setIsWarningCheckboxChecked(checked);
  };

  const isRecommendedValueChanged: boolean = useMemo(() => {
    let isChanged = false;

    reagentsData.forEach((reagent: ModalReagentDataType) => {
      const { queues } = reagent;
      Object.keys(queues).forEach((key: string) => {
        if (!queues[key].isRecommended) {
          isChanged = true;
        }
      });
    });
    return isChanged;
  }, [reagentsData]);

  const isConfirmButtonDisabled: boolean = useMemo(() => {
    const isPerformanceFilled: boolean = !!performance;
    const isDateFilled: boolean = !!date;
    const isCommentFilled: boolean = !isRecommendedValueChanged || !!comment;
    let isReagentsFieldsFilled: boolean = true;
    reagentsData.forEach((reagent) => {
      let isFilled = true;
      Object.keys(reagent.queues).forEach((key) => {
        isFilled &&= reagent.queues[key].value !== '';
      });
      if (!isFilled) {
        isReagentsFieldsFilled = false;
      }
    });
    return (
      !(
        isPerformanceFilled &&
        isDateFilled &&
        isCommentFilled &&
        isReagentsFieldsFilled
      ) ||
      (isWarningShown && !isWarningCheckboxChecked)
    );
  }, [
    isRecommendedValueChanged,
    isWarningShown,
    isWarningCheckboxChecked,
    reagentsData,
    date,
    comment,
    performance,
  ]);

  useEffect(() => {
    if (!isInEditMode) {
      setReagentsModalDate(new Date());
      getRecommendedReagentsFx(activeCardId);
    }
  }, [activeCardId]);

  useEffect(() => {
    setIsWarningCheckboxChecked(false);
    setIsWarningShown(hasErrorRows);
  }, [hasErrorRows]);

  return (
    <PageMonitoringReagentsModal
      date={date}
      minDate={minDate || undefined}
      comment={comment}
      performance={performance}
      reagents={reagentsData}
      isRecommendedValueChanged={isRecommendedValueChanged}
      isConfirmButtonDisabled={isConfirmButtonDisabled}
      isWarningShown={isWarningShown}
      isWarningCheckboxChecked={isWarningCheckboxChecked}
      onWarningCheckboxValueChange={handleWarningCheckboxValueChange}
      onClose={handleClose}
      onCommentValueChange={handleCommentValueChange}
      onPerformanceValueChange={handlePerformanceValueChange}
      onReagentsDataChange={handleReagentsDataChange}
      onDateChange={handleDateChange}
      onConfirm={handleConfirm}
      queueNumber={queueNumber}
    />
  );
}

export default PageMonitoringReagentsModalContainer;
