import React from 'react';
import { useStore } from 'effector-react';

import Toast from '../Components/Toast/Toast';
import { $toastStore, clearToastData } from '../Store/toastStore';

function ToastContainer() {
  const { isShown, title, message, toastType, closeDelay, promptProps } =
    useStore($toastStore);

  const handleToastClose = () => {
    clearToastData();
  };

  return (
    isShown && (
      <Toast
        isShown={isShown}
        title={title}
        message={message}
        toastType={toastType}
        closeDelay={closeDelay}
        onClose={handleToastClose}
        promptProps={promptProps}
      />
    )
  );
}

export default ToastContainer;
