import { useStore } from 'effector-react';

import { RangeType } from '../../Components/PeriodPickerSection/PeriodPickerSection.interface';
import PanelTableHeader from '../../Pages/PageLaboratoryTests/components/PanelTableHeader';
import {
  $queueCount,
  $queueNumber,
  setQueueNumber,
} from '../../Store/cataloguesStore';
import { exportResearchReportsListFx } from '../../Store/exportStore';
import { $dateRange } from '../../Store/researchReportsPageStore';

import {
  PanelTableHeaderContainerProps,
  ResearchReportSummaryParamsType,
} from './ResearchReportsContainers.interface';

function PanelTableHeaderContainer({
  onTestCreate,
}: PanelTableHeaderContainerProps) {
  const queueNumber = useStore($queueNumber);
  const dateRange: RangeType = useStore($dateRange);
  const queueCount: number = useStore($queueCount);

  const { startDate, endDate } = dateRange;

  const handleExportButtonClick = () => {
    if (startDate && endDate) {
      const params: ResearchReportSummaryParamsType = {
        year: dateRange.startDate.getFullYear(),
      };

      exportResearchReportsListFx(params);
    }
  };
  return (
    <PanelTableHeader
      onTestCreate={onTestCreate}
      onExport={handleExportButtonClick}
      activeQueue={queueNumber}
      queueCount={queueCount}
      onChangeQueue={setQueueNumber}
    />
  );
}

export default PanelTableHeaderContainer;
