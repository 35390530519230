import React, { useEffect, useRef, useState } from 'react';
import { useUnit } from 'effector-react';

import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton/IconButton';
import MonitoringPredictionPicker from '../../../Components/MonitoringPredictionPicker';
import Popover from '../../../Components/Popover';
import {
  PopoverPlacements,
  PopoverTriggerEvents,
} from '../../../Components/Popover/constants';
import SwitcherQueque from '../../../Components/SwitcherQueque';
import {
  $predictionTypesByQueues,
  getPredictionTypeFx,
  setPredictionTypeFx,
} from '../../../Store/authStore';
import { useHandleClickOutside } from '../../../Utils/useHandleClickOuside';
import { PREDICTION_TYPES_OPTIONS } from '../constants';
import { PageMonitoringHeaderProps } from '../PageMonitoring.interface';

import styles from '../PageMonitoring.module.scss';

function PageMonitoringHeader(props: PageMonitoringHeaderProps) {
  const {
    onExportButtonClick,
    onHideAllButtonClick,
    onShowAllButtonClick,
    onChangeQueue,
    activeQueue,
    queueCount,
    queueConfig,
  } = props;

  useEffect(() => {
    getPredictionTypeFx(1);
    getPredictionTypeFx(2);
  }, []);

  const { 1: queue1PredictionType, 2: queue2PredictionType } = useUnit(
    $predictionTypesByQueues,
  );

  const [isPredictionModalShown, setIsPredictionModalShown] = useState(false);
  const handlePredictionButtonClick = () => {
    setIsPredictionModalShown(!isPredictionModalShown);
  };

  const predictionModalButtonRef = useRef(null);
  const predictionModalRef = useRef(null);

  useHandleClickOutside(
    predictionModalRef,
    () => setIsPredictionModalShown(false),
    predictionModalButtonRef,
  );

  const [sulfateType, oxychlorideType] = PREDICTION_TYPES_OPTIONS;

  const transformedPredictionTypesOptions = {
    [sulfateType.value]: sulfateType,
    [oxychlorideType.value]: oxychlorideType,
  };

  const queuesPredictionData = [
    {
      number: 1,
      onChange: (option) => {
        setPredictionTypeFx({ queueNumber: 1, predictionType: option.value });
      },
      options: PREDICTION_TYPES_OPTIONS,
      value: transformedPredictionTypesOptions[queue1PredictionType],
    },
    {
      number: 2,
      onChange: (option) => {
        setPredictionTypeFx({ queueNumber: 2, predictionType: option.value });
      },
      options: PREDICTION_TYPES_OPTIONS,
      value: transformedPredictionTypesOptions[queue2PredictionType],
    },
  ];

  return (
    <div className={styles.page_header_wrapper}>
      <SwitcherQueque
        queueCount={queueCount}
        activeQueue={activeQueue}
        onChangeQueue={onChangeQueue}
        queueConfig={queueConfig}
      />
      <div className={styles.page_header_content_left}>
        <div className={styles.buttons_wrapper}>
          <Popover
            placement={PopoverPlacements.Top}
            trigger={PopoverTriggerEvents.Hover}
            content={<div className={styles.tooltip_text}>Свернуть все</div>}
          >
            <span>
              <IconButton
                iconName={IconsNames.CollapseAll}
                onClick={onHideAllButtonClick}
                className={styles.collapse_expand_tree_button}
              />
            </span>
          </Popover>
          <Popover
            placement={PopoverPlacements.Top}
            trigger={PopoverTriggerEvents.Hover}
            content={<div className={styles.tooltip_text}>Развернуть все</div>}
          >
            <span>
              <IconButton
                iconName={IconsNames.ExpandAll}
                onClick={onShowAllButtonClick}
                className={styles.collapse_expand_tree_button}
              />
            </span>
          </Popover>
        </div>
        <div ref={predictionModalButtonRef}>
          <IconButton
            iconName={IconsNames.TestTube}
            onClick={handlePredictionButtonClick}
            className={styles.export_button}
          />
        </div>
        <IconButton
          iconName={IconsNames.ExportArrow}
          onClick={onExportButtonClick}
          className={styles.export_button}
        />
      </div>
      {isPredictionModalShown && (
        <MonitoringPredictionPicker
          ref={predictionModalRef}
          queues={queuesPredictionData}
        />
      )}
    </div>
  );
}

export default PageMonitoringHeader;
