import { useEffect, useMemo, useState } from 'react';
import format from 'date-fns/format';
import ReactEcharts from 'echarts-for-react';

import { DateFormats } from '../DatePicker/DatePicker.interface';

import { MonitoringGraphProps } from './MonitoringGraph.interface';

import styles from './MonitoringGraph.module.scss';

const ANIMATION_DURATION = 700;

function MonitoringGraph(props: MonitoringGraphProps) {
  const { dates, values } = props;
  const [interval, setInterval] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(dates.length - 1);
  const [isAnimationActive, setIsAnimationActive] = useState<boolean>(true);
  const [fontSize, setFontSize] = useState<number>(11);
  const [isScreenSmall, setIsScreenSmall] = useState<boolean>(false);

  const handleResize = () => {
    if (document.documentElement.clientWidth < 1500) {
      setFontSize(9);
    } else {
      setFontSize(11);
    }

    if (window.innerWidth <= 1500) {
      setIsScreenSmall(true);
    } else {
      setIsScreenSmall(false);
    }
  };

  const updateInterval = () => {
    if (window.innerWidth <= 1450) {
      setInterval(2);
    } else if (window.innerWidth <= 1700) {
      setInterval(1);
    } else {
      setInterval(0);
    }
  };

  const valuesCount: number = useMemo(() => dates?.length || 0, [dates]);

  const source = useMemo(
    () =>
      dates.map((date: string, index: number) => {
        const sourceItem: Array<string | number> = [date];
        values.forEach((value: number[]) => {
          sourceItem.push(value[index]);
        });

        return sourceItem;
      }),
    [dates, values],
  );

  const xAxisList = useMemo(
    () =>
      values.map((_, index) => {
        if (index === 0) {
          return {
            type: 'category',
            gridIndex: 0,
            position: 'top',
            offset: 10,
            axisLine: {
              onZero: false,
              lineStyle: {
                color: 'rgba(212, 212, 212, 1)',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: 'rgba(38, 38, 38, 1)',
              showMaxLabel: true,
              interval: interval || 0,
              rich: {
                bold: {
                  fontWeight: 'bold',
                  fontSize: fontSize.toString(),
                },
              },
              fontFamily: 'Rubik',
              formatter: (value, i) => {
                const formattedDate = format(new Date(value), DateFormats.ddMM);
                if (i === source.length - 1) {
                  return `{bold| ${formattedDate}}`;
                }
                return formattedDate;
              },
              fontSize: fontSize.toString(),
            },
          };
        }
        return {
          type: 'category',
          gridIndex: index,
          position: 'top',
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'rgba(212, 212, 212, 1)',
              type: 'dashed',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        };
      }),
    [source.length, interval, values],
  );

  const tooltipData = useMemo(
    () =>
      values.map((sourceItem) => {
        return sourceItem[currentIndex]?.toLocaleString();
      }),
    [values, currentIndex],
  );

  const yAxisList = useMemo(
    () =>
      values.map((_, index) => {
        return {
          gridIndex: index,
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          min(value) {
            return value.min - value.max * 0.3;
          },
          max(value) {
            return value.max * 1.2;
          },
          rich: {
            bold: {
              fontWeight: 'bold',
              fontSize: fontSize.toString(),
            },
          },
          name: tooltipData[index],
          nameTextStyle: {
            height: '100%',
            verticalAlign: 'middle',
            color: 'rgba(38, 38, 38, 1)',
            fontFamily: 'Rubik',
            fontSize: fontSize.toString(),
          },
          nameLocation: 'mid',
          offset: 10,
          position: 'right',
          z: 10,
        };
      }),
    [values, tooltipData],
  );

  const gridsList = useMemo(
    () =>
      values.map((_, index) => {
        const ITEM_HEIGHT = !isScreenSmall ? 50 : 37.5;
        const ITEMS_GAP = 5;

        // non constant values are a really magic, just intuitively

        const gapFromStart = !isScreenSmall ? 20 : 17.5;

        const firstTop = !isScreenSmall ? 30 : 28;
        const othersTop =
          index * (ITEM_HEIGHT + ITEMS_GAP) + gapFromStart + ITEMS_GAP / 2;
        const top = index === 0 ? firstTop : othersTop;
        return {
          top,
          height: ITEM_HEIGHT,
          left: 5,
        };
      }),
    [isScreenSmall, values],
  );

  const seriesList = useMemo(
    () =>
      values.map((_, index) => {
        return {
          name: `series ${index}`,
          type: 'line',
          symbolSize: 5,
          xAxisIndex: index,
          yAxisIndex: index,
          markPoint: {
            data: [
              {
                name: 'maximum',
                type: 'max',
                symbol: 'emptyCircle',
                symbolSize: 6,
                label: {
                  show: false,
                  silent: true,
                },
                itemStyle: {
                  color: 'rgba(235, 87, 87, 1)',
                },
                silent: true,
              },
              {
                name: 'minimum',
                type: 'min',
                symbol: 'emptyCircle',
                symbolSize: 6,
                label: {
                  show: false,
                  silent: true,
                },
                itemStyle: {
                  color: 'rgba(9, 154, 113, 1)',
                },
              },
            ],
          },
        };
      }),
    [values],
  );

  const option = {
    animation: isAnimationActive,
    animationDuration: ANIMATION_DURATION,
    color: ['rgba(140, 140, 140, 1)'],
    tooltip: {
      trigger: 'axis',
      showContent: false,
    },
    axisPointer: {
      link: [
        {
          xAxisIndex: 'all',
        },
      ],
      snap: true,
      z: 0,
    },
    dataset: {
      source,
    },
    xAxis: xAxisList,
    yAxis: yAxisList,
    grid: gridsList,
    series: seriesList,
  };

  useEffect(() => {
    updateInterval();
    handleResize();
    window.addEventListener('resize', updateInterval);
    setIsAnimationActive(true);
    window.addEventListener('resize', handleResize);

    setTimeout(() => {
      setIsAnimationActive(false);
    }, ANIMATION_DURATION);

    return () => {
      window.removeEventListener('resize', updateInterval);
      window.removeEventListener('resize', handleResize);

      setIsAnimationActive(false);
    };
  }, []);

  const handleHighlight = (index: number) => {
    if (index !== currentIndex) {
      setCurrentIndex(index);
    }
  };

  const onEvents = {
    highlight: (params) => {
      if (isAnimationActive) {
        setIsAnimationActive(false);
      }

      const { dataIndex } = params.batch[0];

      if (dataIndex !== currentIndex) {
        handleHighlight(dataIndex);
      }
    },
    downplay: () => {
      if (isAnimationActive) {
        setIsAnimationActive(false);
      }
      setCurrentIndex(valuesCount - 1);
    },
  };

  if (valuesCount <= 2) {
    return (
      <div className={styles.no_data_message}>
        Недостаточно данных
        <br />
        для отображения графика
      </div>
    );
  }

  return (
    <ReactEcharts
      option={option}
      style={{
        height: '100%',
        width: '100%',
        wordBreak: 'keep-all',
      }}
      onEvents={onEvents}
      notMerge={false}
    />
  );
}

export default MonitoringGraph;
