import React, { useMemo } from 'react';
import clsx from 'clsx';

import Button from '../../../Components/Button/Button';
import {
  PageAdminRequestsListProps,
  RequestDataType,
} from '../PageAdmin.interface';

import styles from '../PageAdmin.module.scss';

function PageAdminRequestsList(props: PageAdminRequestsListProps) {
  const { requests, onAccept, onReject } = props;

  const handleRequestAccept = (id: number) => () => {
    onAccept(id);
  };

  const handleRequestReject = (id: number) => () => {
    onReject(id);
  };

  const requestsList: JSX.Element[] = useMemo(
    () =>
      requests.map((request: RequestDataType) => {
        const { id, fullName, phone, email } = request;

        return (
          <div key={id} className={styles.request_card}>
            <div
              className={styles.request_card_header}
            >{`Заявка на регистрацию № ${id}`}</div>
            <div className={styles.request_fields}>
              <div className={styles.request_field}>{fullName}</div>
              <div className={styles.request_field}>{phone}</div>
              <div className={styles.request_field}>{email}</div>
            </div>
            <div className={styles.request_buttons_row}>
              <Button onClick={handleRequestReject(id)} isSecondary>
                Отклонить
              </Button>
              <Button onClick={handleRequestAccept(id)}>Принять</Button>
            </div>
          </div>
        );
      }),
    [requests],
  );

  return (
    <div
      className={clsx(styles.requests_list_wrapper, {
        [styles.requests_list_empty]:
          !requestsList || requestsList.length === 0,
      })}
    >
      {requestsList}
    </div>
  );
}

export default PageAdminRequestsList;
