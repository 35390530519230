import { maskPhoneNumberInput } from '../../../Utils/maskPhoneNumber';
import { RegisterFieldsNamesList } from '../constants';

export function maskInitials(value: string) {
  const maskedValue = value.replace(/[^a-zA-Zа-яА-Я-]/g, '');
  return maskedValue;
}

export default function maskValues(
  value: string,
  fieldName: RegisterFieldsNamesList,
) {
  let maskedValue = value;

  switch (fieldName) {
    case RegisterFieldsNamesList.FirstName:
    case RegisterFieldsNamesList.LastName:
    case RegisterFieldsNamesList.SecondName:
      maskedValue = maskInitials(maskedValue);
      break;
    case RegisterFieldsNamesList.Phone:
      if (maskedValue.length >= 10 && maskedValue.slice(0, 2) === '+7') {
        maskedValue = maskedValue.slice(2, value.length);
      }
      maskedValue = maskPhoneNumberInput(maskedValue);
      break;
    default:
      break;
  }

  return maskedValue;
}
