import { useMemo } from 'react';
import { useStore } from 'effector-react';

import PageMonitoringTable from '../../Pages/PageMonitoring/components/PageMonitoringTable';
import {
  $count,
  $limit,
  $monitoringCardsList,
  $offset,
  $openPanelsIdsList,
  deleteDoseFx,
  getFilteredMonitoringReportsListFx,
  requestReportDetailsById,
  setActiveCardId,
  setActiveCardMinDate,
  setIsReagentsModalShown,
  setOffset,
  setOpenPanelsIdsList,
  skipDoseFx,
} from '../../Store/monitoringPageStore';

function PageMonitoringTableContainer() {
  const cards = useStore($monitoringCardsList);
  const openPanelsIds: number[] = useStore($openPanelsIdsList);
  const offset: number = useStore($offset);
  const count: number = useStore($count);
  const limit: number = useStore($limit);
  const isTableLoading: boolean = useStore(
    getFilteredMonitoringReportsListFx.pending,
  );

  const handleOpenPanelsIdsListChange = (ids: number[]) => {
    const lastAddedId = ids[ids.length - 1];

    if (ids.length !== 0 && openPanelsIds.length < ids.length) {
      requestReportDetailsById(lastAddedId);
    }

    setOpenPanelsIdsList(ids);
  };

  const handleReagentsAdd = (id: number, minDate: Date) => {
    setActiveCardId(id);
    setActiveCardMinDate(minDate);
    setIsReagentsModalShown(true);
  };

  const handleReagentsSkip = (id: number) => {
    skipDoseFx(id).then(() => {
      setOpenPanelsIdsList(openPanelsIds.filter((item) => item !== id));
    });
  };

  const handleShowMoreButtonClick = () => {
    setOffset(offset + limit);
  };

  const handleInputDelete = (id: number, researchId: number) => {
    deleteDoseFx(id).then(() =>
      setOpenPanelsIdsList(openPanelsIds.filter((item) => item !== researchId)),
    );
  };

  const isShowMoreButtonVisible: boolean = useMemo(() => {
    return cards?.length < count && !isTableLoading;
  }, [cards, count]);

  return (
    <PageMonitoringTable
      cards={cards}
      openPanelsIds={openPanelsIds}
      isShowMoreButtonVisible={isShowMoreButtonVisible}
      onInputDelete={handleInputDelete}
      onShowMoreButtonClick={handleShowMoreButtonClick}
      onOpenPanelsIdsListChange={handleOpenPanelsIdsListChange}
      onReagentsAdd={handleReagentsAdd}
      onReagentsSkip={handleReagentsSkip}
      isLoading={isTableLoading}
    />
  );
}

export default PageMonitoringTableContainer;
