import { useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useStore } from 'effector-react';

import Button from '../../../Components/Button';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton';
import IconWarningSection from '../../../Components/IconWarningSection/IconWarningSection';
import SettingsModal from '../../../Components/SettingsModal/SettingsModal';
import StandardsTree from '../../../Components/StandardsTree';
import { $userRoles } from '../../../Store/authStore';
import { useHandleClickOutside } from '../../../Utils/useHandleClickOuside';
import { RolesList } from '../../PageAdmin/PageAdmin.interface';
import { StandardsTreeSectionProps } from '../PageStandards.interface';

import styles from '../PageStandards.module.scss';

function StandardsTreeSection(props: StandardsTreeSectionProps) {
  const {
    standardsList,
    activeCardId,
    openChildrenCardsIdsList,
    onOpenChildrenCardsIdsListChange,
    onCardClick,
    onCardCopy,
    onNewStandardAdd,
    settings,
    onSettingsChange,
  } = props;

  const currentUserRoles: number[] = useStore($userRoles);

  const [openHistoryCardsIdsList, setOpenHistoryCardsIdsList] = useState<
    number[]
  >([]);

  const [isSettingsModalShown, setIsSettingsModalShown] = useState(false);

  const handleOpenChildrenCardsIdsListChange = (cardsIds: number[]) => {
    onOpenChildrenCardsIdsListChange(cardsIds);
  };
  const handleOpenHistoryCardsIdsListChange = (cardsIds: number[]) => {
    setOpenHistoryCardsIdsList(cardsIds);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsModalShown(!isSettingsModalShown);
  };

  const isWarningShown: boolean = useMemo(
    () => standardsList?.length === 0,
    [standardsList],
  );

  const isCreateCopyEnabled: boolean = useMemo(
    () => currentUserRoles.includes(RolesList.ADMIN),
    [currentUserRoles],
  );

  const isSettingsEnabled = useMemo(
    () => currentUserRoles.includes(RolesList.TECHNOLOGIST),
    [currentUserRoles],
  );

  const settingsModalRef = useRef(null);
  const settingsModalButtonRef = useRef(null);

  useHandleClickOutside(
    settingsModalRef,
    () => setIsSettingsModalShown(false),
    settingsModalButtonRef,
  );

  return (
    <div
      className={clsx(styles.standards_tree_section, {
        [styles.no_standards_found]: isWarningShown,
      })}
    >
      <div className={styles.standards_tree_buttons_row}>
        <Button
          onClick={onNewStandardAdd}
          className={styles.add_standard_button}
          isDisabled={!isCreateCopyEnabled}
        >
          Создать новый стандарт
        </Button>

        <div ref={settingsModalButtonRef}>
          <IconButton
            iconName={IconsNames.Settings}
            onClick={handleSettingsButtonClick}
            className={styles.settings_button}
            isDisabled={!isSettingsEnabled}
          />
        </div>
        {isSettingsModalShown && (
          <SettingsModal
            settings={settings}
            onSettingsChange={onSettingsChange}
            canBeAllUnchecked={false}
            ref={settingsModalRef}
          />
        )}
      </div>
      {isWarningShown && (
        <IconWarningSection
          iconName={IconsNames.OpenBook}
          message="Стандарты не найдены"
          className={styles.no_standard_warning}
        />
      )}
      <div className={styles.standards_tree_wrapper}>
        <StandardsTree
          standards={standardsList}
          openChildrenCardsIdsList={openChildrenCardsIdsList}
          openHistoryCardsIdsList={openHistoryCardsIdsList}
          activeCardId={activeCardId}
          isCopyDisabled={!isCreateCopyEnabled}
          onOpenChildrenCardsIdsListChange={
            handleOpenChildrenCardsIdsListChange
          }
          onOpenHistoryCardsIdsListChange={handleOpenHistoryCardsIdsListChange}
          onCardClick={onCardClick}
          onCardCopy={onCardCopy}
        />
      </div>
    </div>
  );
}

export default StandardsTreeSection;
