import differenceInHours from 'date-fns/differenceInHours';
import subHours from 'date-fns/subHours';

import { connectionHelper } from '../Pages/PageMapping/constants';
import {
  CardsConnectionDataType,
  CardTypes,
  PageMappingCardProps,
} from '../Pages/PageMapping/PageMapping.interface';
import { ChangeLinkingParamsType, ReportsSynchDataType } from '../Store/types';
import { getShortFullName } from '../Utils/getFullName';

import {
  BackendChangeLinkingParamsType,
  BackendDoseDataType,
  BackendLinkingDataType,
  BackendLinkingResearchDataType,
  BackendReportsSynchDataType,
} from './types';

function mapLinkingList(
  linking: BackendLinkingResearchDataType[],
  waterTreatment: number,
  timeLag: number,
) {
  const cards: PageMappingCardProps[] = [];
  const connections: CardsConnectionDataType[] = [];
  const connectedDosesIds: string[] = [];

  linking.forEach((linkingItem: BackendLinkingResearchDataType) => {
    const {
      research_id: researchId,
      datetime: researchDateTime,
      first_name: researchAuthorFirstName,
      middle_name: researchAuthorMiddleName,
      last_name: researchAuthorLastName,
      status: researchStatus,
      doses: linkingDoses,
      created_at: researchCreatedAt,
      number,
    } = linkingItem;
    const researchDate: Date = new Date(researchDateTime);
    const researchCreatedAtDate: Date = new Date(
      researchCreatedAt || researchDateTime,
    );

    const researchTitle: string = `Отчёт по пит. воде ${number}`;
    const researchAuthorInitials: string = getShortFullName(
      researchAuthorFirstName,
      researchAuthorMiddleName,
      researchAuthorLastName,
    );
    const isResearchEditDisabled: boolean =
      differenceInHours(new Date(), researchCreatedAtDate) > 24;
    const researchIdTitle: string = `research-${researchId}`;
    const isResearchCardConnected: boolean = linkingDoses.length > 0;
    const researchConnectionsList: number[] = [];
    linkingDoses.forEach((linkingDose: BackendDoseDataType) => {
      const {
        id: doseId,
        linking_id: linkingId,
        datetime: doseDateTime,
        first_name: doseAuthorFirstName,
        middle_name: doseAuthorMiddleName,
        last_name: doseAuthorLastName,
        status: doseStatus,
        created_at: doseCreatedAt,
        performance,
        number: doseNumber,
      } = linkingDose;
      const doseCreatedAtDate: Date = new Date(doseCreatedAt || doseDateTime);
      const doseIdTitle: string = `dose-${doseId}`;
      connectedDosesIds.push(doseIdTitle);
      researchConnectionsList.push(linkingId);

      connections.push({
        id: linkingId,
        startCardId: researchIdTitle,
        endCardId: doseIdTitle,
      });

      const doseDate: Date = new Date(doseDateTime);

      const doseTitle: string = `Ввод хим. реагентов ${doseNumber}`;
      const isDoseEditDisabled: boolean =
        differenceInHours(new Date(), doseCreatedAtDate) > 24;
      const doseAuthorInitials: string = getShortFullName(
        doseAuthorFirstName,
        doseAuthorMiddleName,
        doseAuthorLastName,
      );

      cards.push({
        id: doseIdTitle,
        date: doseDate,
        title: doseTitle,
        name: doseAuthorInitials,
        isConnected: true,
        status: doseStatus,
        type: CardTypes.Input,
        connectionsList: [linkingId],
        isEditDisabled: isResearchEditDisabled || isDoseEditDisabled,
        performance,
      });
    });
    const minAreaDate: Date = subHours(
      researchDate,
      waterTreatment + timeLag / 2,
    );
    const maxAreaDate: Date = subHours(
      researchDate,
      waterTreatment - timeLag / 2,
    );
    cards.push({
      id: researchIdTitle,
      date: researchDate,
      title: researchTitle,
      name: researchAuthorInitials,
      isConnected: isResearchCardConnected,
      status: researchStatus,
      type: CardTypes.Result,
      connectionsList: researchConnectionsList,
      minAreaDate,
      maxAreaDate,
      isEditDisabled: isResearchEditDisabled,
      performance: 0,
    });
  });

  return { cards, connectedDosesIds, connections };
}

function mapDoses(
  doses: BackendDoseDataType[],
  connectedDosesIds: string[],
): PageMappingCardProps[] {
  const cards: PageMappingCardProps[] = [];
  doses.forEach((dose: BackendDoseDataType) => {
    const {
      id: doseId,
      datetime: doseDateTime,
      first_name: doseAuthorFirstName,
      middle_name: doseAuthorMiddleName,
      last_name: doseAuthorLastName,
      status: doseStatus,
      created_at: doseCreatedAt,
      performance,
      number: doseNumber,
    } = dose;
    const doseIdTitle: string = `dose-${doseId}`;
    const doseCreatedAtDate: Date = new Date(doseCreatedAt || doseDateTime);
    if (!connectedDosesIds.includes(doseIdTitle)) {
      const doseDate: Date = new Date(doseDateTime);
      const doseTitle: string = `Ввод хим. реагентов ${doseNumber}`;
      const isEditDisabled: boolean =
        differenceInHours(new Date(), doseCreatedAtDate) > 24;
      const doseAuthorInitials: string = getShortFullName(
        doseAuthorFirstName,
        doseAuthorMiddleName,
        doseAuthorLastName,
      );
      cards.push({
        id: doseIdTitle,
        date: doseDate,
        title: doseTitle,
        name: doseAuthorInitials,
        isConnected: false,
        status: doseStatus,
        type: CardTypes.Input,
        connectionsList: [],
        isEditDisabled,
        performance,
      });
    }
  });

  return cards;
}

function fetchReportsSynchDataAdapter(
  reportsSynchData: BackendReportsSynchDataType,
): ReportsSynchDataType {
  const {
    water_treatment: waterTreatment,
    time_lag: timeLag,
    linking,
    doses,
  } = reportsSynchData;

  const {
    cards: linkedCards,
    connections,
    connectedDosesIds,
  } = mapLinkingList(linking, waterTreatment, timeLag);

  const dosesCards: PageMappingCardProps[] = mapDoses(doses, connectedDosesIds);
  const cards: PageMappingCardProps[] = linkedCards.concat(dosesCards);

  connections.push(connectionHelper);

  return { cards, connections, waterTreatment, timeLag };
}

export function revertChangeLinkingParamsAdapter(
  params: ChangeLinkingParamsType,
): BackendChangeLinkingParamsType {
  const addLinkingData: BackendLinkingDataType = params.add
    ? {
        dose_id: params.add.doseId,
        research_id: params.add.researchId,
      }
    : null;
  const deleteLinkingData: BackendLinkingDataType = params.delete
    ? { dose_id: params.delete.doseId, research_id: params.delete.researchId }
    : null;

  return {
    add: addLinkingData,
    delete: deleteLinkingData,
  };
}

export default fetchReportsSynchDataAdapter;
