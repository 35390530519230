import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore } from 'effector';

import fetchOptionsListAdapter, {
  fetchClassifiersOptionsListAdapter,
  fetchDefaultStandardsListAdapter,
  fetchStandardsListAdapter,
} from '../Adapters/fetchOptionsListAdapter';
import { fetchClassifiersDataAdapter } from '../Adapters/fetchStandardsAdapter';
import {
  BackendClassifierDataType,
  BackendOptionType,
  BackendStandardDataType,
  BackendWaterCanalsType,
} from '../Adapters/types';
import { OptionType } from '../Components/Select/Select.interface';
import {
  getClassifiersList,
  getDefaultStandardsList,
  getMeasuresList,
  getStandardsList,
  getWaterCanalsList,
  getWorkingStandardsList,
} from '../DataAccessLayer/apiServices';
import {
  IntermediateWaterSubTypes,
  WaterTypes,
} from '../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';
import { ClassifierDataType } from '../Pages/PageStandards/PageStandards.interface';
import handleError from '../Utils/handleError';

import { $userVodokanalId } from './authStore';
import { ResponseType, StandardsListType } from './types';

const $queueCount = createStore<number>(0);
const setQueueCount = createEvent<number>();
$queueCount.on(setQueueCount, (_, payload) => payload);

// water canals
const getWaterCanalsListFx = createEffect<void, OptionType[], Error>();

const getWaterCanalsListAction = async () => {
  const waterCanalsList: BackendWaterCanalsType[] = await (
    await getWaterCanalsList().catch((error: AxiosError) => {
      handleError({ error, request: getWaterCanalsListFx });

      return error.response;
    })
  ).data;

  const vodokanalId = $userVodokanalId.getState();

  if (vodokanalId !== 0) {
    const { queue_count: queueCount } = waterCanalsList.find(
      (item) => item.id === vodokanalId,
    );

    setQueueCount(queueCount);
  }

  const adaptedWaterCanalsList = fetchOptionsListAdapter(waterCanalsList);

  return adaptedWaterCanalsList;
};

getWaterCanalsListFx.use(getWaterCanalsListAction);

const $waterCanals = createStore<OptionType[]>([]).on(
  getWaterCanalsListFx.done,
  (_, response: ResponseType<OptionType[]>) => {
    return response.result;
  },
);

// standards
const defaultStandardsList: StandardsListType = {
  potable: [],
  surface: [],
  intermediate: [],
  filters: [],
  sump: [],
  post_potable: [],
  all: [],
};

const getStandardsListFx = createEffect<void, StandardsListType, Error>();
const getWorkingStandardsListFx = createEffect<
  void,
  StandardsListType,
  Error
>();

const getStandardsListAction = async () => {
  const standardsListAll: BackendStandardDataType[] = await (
    await getStandardsList(null).catch((error: AxiosError) => {
      handleError({ error, request: getStandardsListFx });

      return error.response;
    })
  ).data;
  const standardsListPotable: BackendStandardDataType[] = await (
    await getStandardsList(WaterTypes.Potable).catch((error: AxiosError) => {
      handleError({ error, request: getStandardsListFx });

      return error.response;
    })
  ).data;
  const standardsListSurface: BackendStandardDataType[] = await (
    await getStandardsList(WaterTypes.Surface).catch((error: AxiosError) => {
      handleError({ error, request: getStandardsListFx });

      return error.response;
    })
  ).data;
  const standardsListIntermediate: BackendStandardDataType[] = await (
    await getStandardsList(WaterTypes.Intermediate).catch(
      (error: AxiosError) => {
        handleError({ error, request: getStandardsListFx });

        return error.response;
      },
    )
  ).data;
  const standardsListFilters: BackendStandardDataType[] = await (
    await getStandardsList(
      WaterTypes.Intermediate,
      IntermediateWaterSubTypes.Filters,
    ).catch((error: AxiosError) => {
      handleError({ error, request: getStandardsListFx });

      return error.response;
    })
  ).data;
  const standardsListSump: BackendStandardDataType[] = await (
    await getStandardsList(
      WaterTypes.Intermediate,
      IntermediateWaterSubTypes.Sump,
    ).catch((error: AxiosError) => {
      handleError({ error, request: getStandardsListFx });

      return error.response;
    })
  ).data;
  const standardsListPostPotable: BackendStandardDataType[] = await (
    await getStandardsList(
      WaterTypes.Intermediate,
      IntermediateWaterSubTypes.PostPotable,
    ).catch((error: AxiosError) => {
      handleError({ error, request: getStandardsListFx });

      return error.response;
    })
  ).data;

  const standardsList: StandardsListType = fetchStandardsListAdapter(
    standardsListPotable,
    standardsListSurface,
    standardsListIntermediate,
    standardsListFilters,
    standardsListSump,
    standardsListPostPotable,
    standardsListAll,
  );

  return standardsList;
};

const getWorkingStandardsListAction = async () => {
  const standardsListAll: BackendStandardDataType[] = await (
    await getWorkingStandardsList(null).catch((error: AxiosError) => {
      handleError({ error, request: getWorkingStandardsListFx });

      return error.response;
    })
  ).data;
  const standardsListPotable: BackendStandardDataType[] = await (
    await getWorkingStandardsList(WaterTypes.Potable).catch(
      (error: AxiosError) => {
        handleError({ error, request: getWorkingStandardsListFx });

        return error.response;
      },
    )
  ).data;
  const standardsListSurface: BackendStandardDataType[] = await (
    await getWorkingStandardsList(WaterTypes.Surface).catch(
      (error: AxiosError) => {
        handleError({ error, request: getWorkingStandardsListFx });

        return error.response;
      },
    )
  ).data;
  const standardsListIntermediate: BackendStandardDataType[] = await (
    await getWorkingStandardsList(WaterTypes.Intermediate).catch(
      (error: AxiosError) => {
        handleError({ error, request: getWorkingStandardsListFx });

        return error.response;
      },
    )
  ).data;
  const standardsListFilters: BackendStandardDataType[] = await (
    await getWorkingStandardsList(
      WaterTypes.Intermediate,
      IntermediateWaterSubTypes.Filters,
    ).catch((error: AxiosError) => {
      handleError({ error, request: getWorkingStandardsListFx });

      return error.response;
    })
  ).data;
  const standardsListSump: BackendStandardDataType[] = await (
    await getWorkingStandardsList(
      WaterTypes.Intermediate,
      IntermediateWaterSubTypes.Sump,
    ).catch((error: AxiosError) => {
      handleError({ error, request: getWorkingStandardsListFx });

      return error.response;
    })
  ).data;
  const standardsListPostPotable: BackendStandardDataType[] = await (
    await getWorkingStandardsList(
      WaterTypes.Intermediate,
      IntermediateWaterSubTypes.PostPotable,
    ).catch((error: AxiosError) => {
      handleError({ error, request: getWorkingStandardsListFx });

      return error.response;
    })
  ).data;
  const standardsList: StandardsListType = fetchStandardsListAdapter(
    standardsListPotable,
    standardsListSurface,
    standardsListIntermediate,
    standardsListFilters,
    standardsListSump,
    standardsListPostPotable,
    standardsListAll,
  );
  return standardsList;
};

const getDefaultStandardsFx = createEffect<void, any>().use(async () => {
  const standardsListAll: BackendStandardDataType[] = await (
    await getDefaultStandardsList(null).catch((error: AxiosError) => {
      handleError({ error, request: getDefaultStandardsFx });

      return error.response;
    })
  ).data;
  const standardsListPotable: BackendStandardDataType[] = await (
    await getDefaultStandardsList(WaterTypes.Potable).catch(
      (error: AxiosError) => {
        handleError({ error, request: getDefaultStandardsFx });

        return error.response;
      },
    )
  ).data;
  const standardsListSurface: BackendStandardDataType[] = await (
    await getDefaultStandardsList(WaterTypes.Surface).catch(
      (error: AxiosError) => {
        handleError({ error, request: getDefaultStandardsFx });

        return error.response;
      },
    )
  ).data;
  const standardsListIntermediate: BackendStandardDataType[] = await (
    await getDefaultStandardsList(WaterTypes.Intermediate).catch(
      (error: AxiosError) => {
        handleError({ error, request: getDefaultStandardsFx });

        return error.response;
      },
    )
  ).data;
  const standardsListFilters: BackendStandardDataType[] = await (
    await getDefaultStandardsList(
      WaterTypes.Intermediate,
      IntermediateWaterSubTypes.Filters,
    ).catch((error: AxiosError) => {
      handleError({ error, request: getDefaultStandardsFx });

      return error.response;
    })
  ).data;
  const standardsListSump: BackendStandardDataType[] = await (
    await getDefaultStandardsList(
      WaterTypes.Intermediate,
      IntermediateWaterSubTypes.Sump,
    ).catch((error: AxiosError) => {
      handleError({ error, request: getDefaultStandardsFx });

      return error.response;
    })
  ).data;
  const standardsListPostPotable: BackendStandardDataType[] = await (
    await getDefaultStandardsList(
      WaterTypes.Intermediate,
      IntermediateWaterSubTypes.PostPotable,
    ).catch((error: AxiosError) => {
      handleError({ error, request: getDefaultStandardsFx });

      return error.response;
    })
  ).data;
  const standardsList: StandardsListType = fetchDefaultStandardsListAdapter(
    standardsListPotable,
    standardsListSurface,
    standardsListIntermediate,
    standardsListFilters,
    standardsListSump,
    standardsListPostPotable,
    standardsListAll,
  );
  return standardsList;
});

getStandardsListFx.use(getStandardsListAction);
getWorkingStandardsListFx.use(getWorkingStandardsListAction);

const $standards = createStore<StandardsListType>(defaultStandardsList).on(
  getStandardsListFx.done,
  (_, response: ResponseType<StandardsListType>) => response.result,
);

const $workingStandards = createStore<StandardsListType>(
  defaultStandardsList,
).on(
  getWorkingStandardsListFx.done,
  (_, response: ResponseType<StandardsListType>) => response.result,
);

const $defaultStandards = createStore<StandardsListType>(
  defaultStandardsList,
).on(getDefaultStandardsFx.doneData, (_, payload) => payload);

// classifiers

const setClassifiersData = createEvent<ClassifierDataType[]>();
const $classifiersData = createStore<ClassifierDataType[]>([]).on(
  setClassifiersData,
  (_, classifiersData: ClassifierDataType[]) => classifiersData,
);

const getClassifiersListFx = createEffect<void, OptionType[], Error>();

const getClassifiersListAction = async () => {
  const classifiersList: BackendClassifierDataType[] = await (
    await getClassifiersList().catch((error: AxiosError) => {
      handleError({ error, request: getClassifiersListFx });

      return error.response;
    })
  ).data;
  const adaptedClassifiersOptionsList: OptionType[] =
    fetchClassifiersOptionsListAdapter(classifiersList);
  const adaptedClassifiersData: ClassifierDataType[] =
    fetchClassifiersDataAdapter(classifiersList);

  setClassifiersData(adaptedClassifiersData);

  return adaptedClassifiersOptionsList;
};

getClassifiersListFx.use(getClassifiersListAction);

const setClassifiersList = createEvent<OptionType[]>();

const $classifiers = createStore<OptionType[]>([])
  .on(setClassifiersList, (_, classifiersList: OptionType[]) => classifiersList)
  .on(
    getClassifiersListFx.done,
    (_, response: ResponseType<OptionType[]>) => response.result,
  );

// measures
const getMeasuresListFx = createEffect<void, OptionType[], Error>();

const getMeasuresListAction = async () => {
  const measuresList: BackendOptionType[] = await (
    await getMeasuresList().catch((error: AxiosError) => {
      handleError({ error, request: getMeasuresListFx });

      return error.response;
    })
  ).data;
  const adaptedMeasuresList: OptionType[] =
    fetchOptionsListAdapter(measuresList);

  return adaptedMeasuresList;
};

getMeasuresListFx.use(getMeasuresListAction);

const setMeasuresList = createEvent<OptionType[]>();

const $measures = createStore<OptionType[]>([])
  .on(setMeasuresList, (_, measuresList: OptionType[]) => measuresList)
  .on(
    getMeasuresListFx.done,
    (_, response: ResponseType<OptionType[]>) => response.result,
  );

const $queueNumber = createStore<number>(1);
const setQueueNumber = createEvent<number>();
$queueNumber.on(setQueueNumber, (_, payload) => payload);

export {
  $classifiers,
  $classifiersData,
  $defaultStandards,
  $measures,
  $queueCount,
  $queueNumber,
  $standards,
  $waterCanals,
  $workingStandards,
  getClassifiersListFx,
  getDefaultStandardsFx,
  getMeasuresListFx,
  getStandardsListFx,
  getWaterCanalsListFx,
  getWorkingStandardsListFx,
  setQueueCount,
  setQueueNumber,
};
