import React from 'react';
import getYear from 'date-fns/getYear';

import Icon from '../../Icon';
import { IconsNames } from '../../Icon/constants';
import { HeaderYearsProps } from '../DatePicker.interface';

import styles from '../DatePicker.module.scss';

function HeaderYears(props: HeaderYearsProps) {
  const { date, handleDecreaseClick, handleIncreaseClick } = props;

  const DEFAULT_VISIBLE_YEARS = 12;

  // get two limit year values for current year
  const getYearsPickerRangeStart = () => {
    const dateYear = getYear(date);
    let rangeStart = dateYear;
    let count = 1;
    while (!Number.isInteger((dateYear - count) / DEFAULT_VISIBLE_YEARS)) {
      count += 1;
    }
    rangeStart = dateYear - count + 1;
    return [rangeStart, rangeStart + DEFAULT_VISIBLE_YEARS - 1];
  };

  const yearsRange = getYearsPickerRangeStart();

  return (
    <>
      <button
        type="button"
        onClick={handleDecreaseClick}
        className={styles.change_year_button}
      >
        <Icon
          name={IconsNames.Chevron}
          className={styles.change_month_chevron_left}
        />
      </button>
      <div
        className={styles.years_range}
      >{`${yearsRange[0]}-${yearsRange[1]}`}</div>
      <button
        type="button"
        onClick={handleIncreaseClick}
        className={styles.change_year_button}
      >
        <Icon
          name={IconsNames.Chevron}
          className={styles.change_month_chevron_right}
        />
      </button>
    </>
  );
}

export default HeaderYears;
