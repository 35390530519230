import {
  IntermediateWaterSubTypes,
  intermediateWaterSubTypesList,
  WaterTypes,
} from '../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';
import { CreateStandardParamsType } from '../Store/types';

import { BackendCreateStandardParamsType } from './types';

export function revertCreateStandardAdapter(
  params: CreateStandardParamsType,
): BackendCreateStandardParamsType {
  const { standardName, standardType, classifiers } = params;
  let subTypeValue: IntermediateWaterSubTypes = null;
  let researchTypeValue: WaterTypes | IntermediateWaterSubTypes = standardType;
  if (intermediateWaterSubTypesList.includes(standardType)) {
    researchTypeValue = WaterTypes.Intermediate;
    subTypeValue = standardType as IntermediateWaterSubTypes;
  }
  return {
    standard_type: researchTypeValue,
    sub_type: subTypeValue,
    name: standardName,
    classifiers,
  };
}
