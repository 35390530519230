export enum PopoverPlacements {
  TopStart = 'topStart',
  Top = 'top',
  TopEnd = 'topEnd',
  RightStart = 'rightStart',
  Right = 'right',
  RightEnd = 'rightEnd',
  BottomEnd = 'bottomEnd',
  Bottom = 'bottom',
  BottomStart = 'bottomStart',
  LeftEnd = 'leftEnd',
  Left = 'left',
  LeftStart = 'leftStart',
}

export enum PopoverTriggerEvents {
  Click = 'click',
  ContextMenu = 'contextMenu',
  Focus = 'focus',
  Active = 'active',
  Hover = 'hover',
  None = 'none',
}
