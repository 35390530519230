import React from 'react';
import Tooltip from 'rsuite/esm/Tooltip';
import Whisper from 'rsuite/Whisper';

import { PopoverProps } from './Popover.interface';

function Popover(props: PopoverProps) {
  const { children, content, placement, trigger } = props;

  return (
    <Whisper
      placement={placement}
      trigger={trigger}
      speaker={<Tooltip>{content}</Tooltip>}
    >
      {children}
    </Whisper>
  );
}

export default Popover;
