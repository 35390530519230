import format from 'date-fns/format';

import { DateFormats } from '../Components/DatePicker/DatePicker.interface';
import {
  ReportsDataType,
  WaterTypes,
} from '../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';
import { getShortFullName } from '../Utils/getFullName';
import getWaterTypeName from '../Utils/getWaterTypeName';

import { BackendResearchReportListType } from './types';

function fetchResearchReportsListAdapter(
  reportsList: BackendResearchReportListType[],
): ReportsDataType[] {
  return reportsList.map((report) => {
    const {
      id,
      name,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      status,
      standard_name: standardName,
      standard_id: standardId,
      research_type: researchType,
      datetime,
      created_at: createdAt,
      sub_type: subType,
    } = report;

    return {
      id,
      name,
      date: format(new Date(datetime), DateFormats.ddMMyyyyHHmm),
      createdAt: format(new Date(createdAt), DateFormats.ddMMyyyyHHmm),
      waterType: getWaterTypeName(researchType as WaterTypes),
      waterTypeValue: researchType as WaterTypes,
      standard: standardName,
      standardId,
      author: getShortFullName(firstName, middleName, lastName),
      status,
      properties: [],
      subType,
    };
  });
}

export default fetchResearchReportsListAdapter;
