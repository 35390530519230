import React, { useMemo } from 'react';
import clsx from 'clsx';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import startOfDay from 'date-fns/startOfDay';

import { ReactComponent as DashedBackground } from '../../../Assets/dashed_bg.svg';
import {
  CardTypes,
  PageMappingCardWrapperProps,
} from '../PageMapping.interface';

import PageMappingCard from './PageMappingCard';

import styles from '../PageMapping.module.scss';

const MINUTES_IN_THREE_DAYS: number = 4320;

function PageMappingCardWrapper(props: PageMappingCardWrapperProps) {
  const { card, isTimeLagShown, previousDate } = props;
  const {
    name,
    title,
    status,
    type,
    date,
    id,
    minAreaDate,
    maxAreaDate,
    isConnected,
    connectionsList,
    isEditDisabled,
    performance,
  } = card;

  const minutesDiff: number = useMemo(
    () => differenceInMinutes(date, startOfDay(previousDate)),
    [date, previousDate],
  );

  const preparationAreaStyles: React.CSSProperties = useMemo(() => {
    if (!!minAreaDate && !!maxAreaDate) {
      const minAreaDateMinutesDiff: number = differenceInMinutes(
        minAreaDate,
        date,
      );
      const maxAreaDateMinutesDiff: number = differenceInMinutes(
        maxAreaDate,
        minAreaDate,
      );
      const areaStyles = {
        left: `calc(100% / ${MINUTES_IN_THREE_DAYS} * ${minAreaDateMinutesDiff})`,
        width: `calc(100% / ${MINUTES_IN_THREE_DAYS} * ${maxAreaDateMinutesDiff})`,
      };

      return areaStyles;
    }

    return {};
  }, [date, minAreaDate, maxAreaDate]);

  const cardWrapperStyles: React.CSSProperties = useMemo(() => {
    const wrapperStyles = {
      left: `calc(100% / ${MINUTES_IN_THREE_DAYS} * ${minutesDiff})`,
    };

    return wrapperStyles;
  }, [minutesDiff]);

  const isAreaShown: boolean = useMemo(
    () =>
      isTimeLagShown &&
      type === CardTypes.Result &&
      !!minAreaDate &&
      !!maxAreaDate,
    [isTimeLagShown, minAreaDate, maxAreaDate, type],
  );

  return (
    <div
      className={clsx(styles.card_wrapper, styles[type], styles[status])}
      style={cardWrapperStyles}
      key={id}
    >
      <div className={styles.card_wrapper_line} />
      <PageMappingCard
        id={id}
        title={title}
        name={name}
        status={status}
        type={type}
        date={date}
        className={styles.card}
        isConnected={isConnected}
        connectionsList={connectionsList}
        isEditDisabled={isEditDisabled}
        performance={performance}
      />
      {isAreaShown && (
        <div className={styles.preparation_area} style={preparationAreaStyles}>
          <DashedBackground className={styles.dashed_bg} />
        </div>
      )}
    </div>
  );
}

export default PageMappingCardWrapper;
