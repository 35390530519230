import { MutableRefObject } from 'react';

const DRAG_COEF = 1.0;

function useEventListeners(containerRef: MutableRefObject<HTMLDivElement>) {
  let isDown = false;
  let startX;
  let scrollLeft;
  let velocity = 0;

  const smooth = () => {
    if (Math.abs(velocity) > 0) {
      if (Math.abs(velocity) < DRAG_COEF) {
        velocity = 0;
      }
      if (velocity > 0) {
        velocity -= DRAG_COEF;
      } else {
        velocity += DRAG_COEF;
      }
      containerRef.current.scrollLeft -= velocity;
      requestAnimationFrame(smooth);
    }
  };

  const handleMouseDown = (e) => {
    velocity = 0;
    isDown = true;
    containerRef.current.style.userSelect = 'none';

    startX = e.pageX - containerRef.current.offsetLeft;
    scrollLeft = containerRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDown = false;
    containerRef.current.style.removeProperty('user-select');
  };

  const handleMouseUp = () => {
    isDown = false;
    containerRef.current.style.removeProperty('user-select');
    requestAnimationFrame(smooth);
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const lastX = containerRef.current.scrollLeft;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;

    velocity = lastX - containerRef.current.scrollLeft;
  };

  const handleScrollToMiddle = () => {
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'smooth';
      const scrollToValue =
        (containerRef.current.scrollWidth - containerRef.current.clientWidth) /
        2;

      containerRef.current.scrollTo(scrollToValue, 0);
      containerRef.current.style.scrollBehavior = 'auto';
    }
  };

  const handleWheel = (e) => {
    e.preventDefault();
    containerRef.current.scrollLeft += e.deltaY;
  };

  const addListeners = () => {
    if (containerRef.current) {
      containerRef.current.addEventListener('mousedown', handleMouseDown);

      containerRef.current.addEventListener('mouseleave', handleMouseLeave);

      containerRef.current.addEventListener('mouseup', handleMouseUp);

      containerRef.current.addEventListener('mousemove', handleMouseMove);

      containerRef.current.addEventListener('wheel', handleWheel);
    }
  };

  const removeListeners = () => {
    if (containerRef.current) {
      containerRef.current.removeEventListener('mousedown', handleMouseDown);

      containerRef.current.removeEventListener('mouseleave', handleMouseLeave);

      containerRef.current.removeEventListener('mouseup', handleMouseUp);

      containerRef.current.removeEventListener('mousemove', handleMouseMove);

      containerRef.current.removeEventListener('wheel', handleWheel);
    }
  };

  return { addListeners, removeListeners, handleScrollToMiddle };
}

export default useEventListeners;
