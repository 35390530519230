import React, { DragEvent, useEffect, useState } from 'react';
import { useXarrow } from 'react-xarrows';
import clsx from 'clsx';
import { useStore } from 'effector-react';

import {
  $bounds,
  $cards,
  $connections,
  $date,
  $isDragActive,
  $isDragComplete,
  changeLinkingFx,
  setCards,
  setConnections,
  setCurrentDragSourceId,
  setIsDragActive,
  setIsDragComplete,
} from '../../../Store/pageMappingStore';
import { Bounds, ChangeLinkingParamsType } from '../../../Store/types';
import { containerBoundsMargin } from '../constants';
import {
  CardsConnectionDataType,
  PageMappingCardProps,
  PageMappingDraggableCardPointProps,
} from '../PageMapping.interface';

import styles from '../PageMapping.module.scss';

const DEFAULT_STYLES: React.CSSProperties = {
  position: 'absolute',
};

function PageMappingDraggableCardPoint(
  props: PageMappingDraggableCardPointProps,
) {
  const { isConnected = false, connectionId, id, anchor, onDragStart } = props;

  const updateXarrow = useXarrow();

  const [position, setPosition] = useState<React.CSSProperties>(DEFAULT_STYLES);

  const date: Date = useStore($date);
  const isDragActive: boolean = useStore($isDragActive);
  const isDragComplete: boolean = useStore($isDragComplete);
  const cards: PageMappingCardProps[] = useStore($cards);
  const connections: CardsConnectionDataType[] = useStore($connections);
  const { left, top, right, bottom }: Bounds = useStore($bounds);

  const handleMouseEnter = () => {
    setIsDragActive(true);
  };

  const handleMouseLeave = () => {
    setIsDragActive(false);
  };

  const handleDragStart = (event: DragEvent<HTMLDivElement>) => {
    if (onDragStart) {
      onDragStart();
    }

    setIsDragActive(true);
    setIsDragComplete(false);
    setCurrentDragSourceId(id);

    event.dataTransfer.setData('initialCardId', `${id}`);
    event.dataTransfer.setData('connectionId', `${connectionId}`);
    event.dataTransfer.setData('ddStatus', 'dragStart');
  };

  const handleDrag = (event: DragEvent<HTMLDivElement>) => {
    const { clientX, clientY } = event;
    const leftPosition = Math.min(
      Math.max(left + containerBoundsMargin.left, clientX),
      right - containerBoundsMargin.right,
    );
    const topPosition = Math.min(
      Math.max(top + containerBoundsMargin.top, clientY),
      bottom - containerBoundsMargin.bottom,
    );

    setPosition({
      position: 'fixed',
      left: leftPosition,
      top: topPosition,
      transform: 'none',
      opacity: 0,
    });
  };

  const handleNewConnectionCancel = () => {
    let startCardId: string = '';

    const newConnectionsList: CardsConnectionDataType[] = connections.map(
      (connection: CardsConnectionDataType) => {
        if (connection.id === 0) {
          startCardId = connection.startCardId;

          return {
            ...connection,
            startCardId: '-',
            endCardId: '-',
          };
        }

        return connection;
      },
    );

    const newCardsList: PageMappingCardProps[] = cards.map((card) => {
      if (card.id.toString() === startCardId) {
        const newCardConnectionsList: number[] = card.connectionsList.filter(
          (connection) => connection !== connectionId,
        );
        const newCard: PageMappingCardProps = {
          ...card,
          isConnected: newCardConnectionsList.length > 0,
          connectionsList: newCardConnectionsList,
        };

        return newCard;
      }

      return card;
    });

    setConnections(newConnectionsList);
    setCards(newCardsList);
  };

  const handleDragEnd = () => {
    if (!isDragComplete) {
      const connectionToDelete: CardsConnectionDataType = connections.find(
        (connection: CardsConnectionDataType) =>
          Number(connectionId) === Number(connection.id),
      );

      if (connectionId === 0) {
        handleNewConnectionCancel();
        setPosition(DEFAULT_STYLES);
      } else {
        const doseId = Number(connectionToDelete.endCardId.split('-')[1]);
        const researchId = Number(connectionToDelete.startCardId.split('-')[1]);
        const changeLinkingParams: ChangeLinkingParamsType = {
          delete: {
            doseId,
            researchId,
          },
          date,
        };
        changeLinkingFx(changeLinkingParams);
      }
    }

    setIsDragActive(false);
    setIsDragComplete(false);
    setPosition(DEFAULT_STYLES);
  };

  useEffect(() => {
    updateXarrow();
  }, [position, connectionId]);

  return (
    <div
      aria-hidden="true"
      className={clsx(styles.active_drag_point, styles[anchor], {
        [styles.connected]: isConnected,
        [styles.drag_active]: isDragActive,
      })}
      style={position}
      id={`${id}`}
      onMouseDown={handleMouseEnter}
      onMouseUp={handleMouseLeave}
      draggable
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
    >
      <div className={styles.active_drag_point_inner} />
    </div>
  );
}

export default PageMappingDraggableCardPoint;
