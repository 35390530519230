export function getFullName(
  firstName: string,
  middleName: string,
  lastName: string,
): string {
  let fullName: string = `${lastName} ${firstName}`;

  if (middleName) {
    fullName = `${fullName} ${middleName}`;
  }

  return fullName;
}

export function getShortFullName(
  firstName: string,
  middleName: string,
  lastName: string,
): string {
  let fullName: string = `${lastName} ${firstName[0]}.`;

  if (middleName) {
    fullName = `${fullName} ${middleName[0]}.`;
  }

  return fullName;
}
