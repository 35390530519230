import { Bounds } from '../../Store/types';

import { CardsConnectionDataType } from './PageMapping.interface';

export const newDragPointId = 'newDragPoint';

export const connectionHelper: CardsConnectionDataType = {
  id: 0,
  startCardId: '-',
  endCardId: '-',
};

export const timeLineContainerId = 'mappingTimeLineContainer';

export const containerBoundsMargin: Bounds = {
  right: 30,
  left: 30,
  top: 50,
  bottom: 30,
};

export const minWaterTreatment: number = 4;
export const minTimeLag: number = 1;
export const maxWaterTreatment: number = 16;
export const maxTimeLag: number = 4;
