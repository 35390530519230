import React, { ChangeEvent, useMemo, useState } from 'react';
import clsx from 'clsx';

import Button from '../../../Components/Button';
import FileSelector from '../../../Components/FileSelector';
import { ChangeFileDataType } from '../../../Components/FileSelector/FileSelector.interface';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton';
import { InputTypes } from '../../../Components/Input/constants';
import Input from '../../../Components/Input/Input';
import Modal from '../../../Components/Modal';
import Select from '../../../Components/Select/Select';
import { OptionType } from '../../../Components/Select/Select.interface';
import Textarea from '../../../Components/Textarea/Textarea';
import {
  CreateEditModalProps,
  ModalModes,
  ModalReagentDataType,
} from '../PageReagents.interface';

import styles from '../PageReagents.module.scss';

function CreateEditModal(props: CreateEditModalProps) {
  const {
    onClose,
    onConfirm,
    onReagentDataChange,
    onDocumentShow,
    modalMode,
    reagentData,
    isConfirmButtonDisabled,
    measuresOptionsList,
  } = props;

  const { id, name, cost, units, description, fileName, downloadUrl } =
    reagentData;

  const [isModalToBeClosed, setIsModalToBeClosed] = useState<boolean>(false);

  const handleCancelButtonClick = () => {
    setIsModalToBeClosed(true);
  };

  const handleConfirmButtonClick = () => {
    setIsModalToBeClosed(true);
    onConfirm();
  };

  const handleModalClose = () => {
    setIsModalToBeClosed(false);
    onClose();
  };

  const handleInputValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name: valueName } = event.target;
    const newReagentData: ModalReagentDataType = {
      ...reagentData,
      [valueName]: value,
    };

    onReagentDataChange(newReagentData);
  };

  const handleUnitsValueChange = (option: OptionType) => {
    const newReagentData: ModalReagentDataType = {
      ...reagentData,
      units: option,
    };

    onReagentDataChange(newReagentData);
  };

  const handleDescriptionValueChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    const newReagentData: ModalReagentDataType = {
      ...reagentData,
      description: value,
    };

    onReagentDataChange(newReagentData);
  };

  const handleFileValueChange = (fileData: ChangeFileDataType) => {
    const {
      fileName: newFileName,
      arrayBuffer,
      downloadUrl: newDownloadUrl,
      file,
    } = fileData;

    const newReagentData: ModalReagentDataType = {
      ...reagentData,
      fileName: newFileName,
      file,
      fileArrayBuffer: arrayBuffer,
      downloadUrl: newDownloadUrl,
    };

    onReagentDataChange(newReagentData);
  };

  const handleDocumentClick = () => {
    onDocumentShow(id);
  };

  const modalTitle: string = useMemo(
    () =>
      modalMode === ModalModes.Create
        ? 'Новый реагент'
        : 'Редактирование реагента',
    [modalMode],
  );
  const confirmButtonTitle: string = useMemo(
    () =>
      modalMode === ModalModes.Create
        ? 'Добавить новый реагент'
        : 'Сохранить реагент',
    [modalMode],
  );

  return (
    <Modal
      isToBeClosed={isModalToBeClosed}
      onClose={handleModalClose}
      className={styles.reagent_modal}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <div className={styles.modal_title}>{modalTitle}</div>
          <IconButton
            iconName={IconsNames.Cross}
            isClear
            onClick={handleCancelButtonClick}
            className={styles.modal_cross_icon}
          />
        </div>
        <div className={styles.modal_form}>
          <div className={styles.modal_form_row}>
            <div className={styles.modal_form_row_title}>
              Наименование реагента:
            </div>
            <Input
              value={name}
              onChange={handleInputValueChange}
              type={InputTypes.Text}
              placeholder="Введите наименование реагента"
              name="name"
            />
          </div>
          <div className={styles.modal_form_row}>
            <div className={styles.modal_form_row_title}>
              Единицы измерения:
            </div>
            <Select
              value={units}
              options={measuresOptionsList}
              onChange={handleUnitsValueChange}
              placeholder="Выберите единицы измерения"
            />
          </div>
          <div className={styles.modal_form_row}>
            <div className={styles.modal_form_row_title}>
              Стоимость, руб без НДС/т:
            </div>
            <Input
              value={cost}
              onChange={handleInputValueChange}
              type={InputTypes.Number}
              placeholder="Введите стоимость"
              name="cost"
            />
          </div>
          <div
            className={clsx(styles.modal_form_row, styles.file_selector_row)}
          >
            <div className={styles.modal_form_row_title}>Опросный лист:</div>
            <FileSelector
              fileName={fileName}
              downloadUrl={downloadUrl}
              onChange={handleFileValueChange}
              accept=".pdf"
              fileType="application/pdf"
              onFileClick={handleDocumentClick}
            />
          </div>
          <div className={styles.modal_form_row}>
            <div className={styles.modal_form_row_title}>
              Описание реагента:
            </div>
            <Textarea
              value={description}
              onChange={handleDescriptionValueChange}
              placeholder="Введите описание реагента"
              className={styles.description_textarea}
            />
          </div>
        </div>
        <div className={styles.modal_buttons_row}>
          <Button
            onClick={handleConfirmButtonClick}
            className={styles.modal_button}
            isDisabled={isConfirmButtonDisabled}
          >
            {confirmButtonTitle}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CreateEditModal;
