import React, { useEffect, useMemo } from 'react';
import { useXarrow } from 'react-xarrows';
import clsx from 'clsx';
import format from 'date-fns/format';
import { useStore } from 'effector-react';

import { DateFormats } from '../../../Components/DatePicker/DatePicker.interface';
import { IconsNames } from '../../../Components/Icon/constants';
import Icon from '../../../Components/Icon/Icon';
import { StatusesList } from '../../../Static/statusesList';
import { $userRoles } from '../../../Store/authStore';
import {
  $cards,
  $connections,
  setCards,
  setConnections,
} from '../../../Store/pageMappingStore';
import { RolesList } from '../../PageAdmin/PageAdmin.interface';
import { newDragPointId } from '../constants';
import {
  ArrowPositions,
  CardsConnectionDataType,
  CardTypes,
  PageMappingCardProps,
} from '../PageMapping.interface';

import PageMappingDraggableCardPoint from './PageMappingDraggableCardPoint';
import PageMappingDroppableContainer from './PageMappingDroppableContainer';

import styles from '../PageMapping.module.scss';

function PageMappingCard(props: PageMappingCardProps) {
  const {
    status,
    name,
    date,
    title,
    className,
    type,
    id,
    isConnected,
    connectionsList,
    isEditDisabled,
    performance,
  } = props;

  const cards: PageMappingCardProps[] = useStore($cards);
  const connections: CardsConnectionDataType[] = useStore($connections);
  const currentUserRoles: number[] = useStore($userRoles);

  const updateXarrow = useXarrow();

  const isEditEnabled: boolean = useMemo(
    () =>
      !isEditDisabled &&
      (currentUserRoles.includes(RolesList.ADMIN) ||
        currentUserRoles.includes(RolesList.TECHNOLOGIST)),
    [currentUserRoles],
  );

  const handleDragStart = () => {
    const newConnectionsList: CardsConnectionDataType[] = connections.map(
      (connection) => {
        if (connection.id === 0) {
          return {
            ...connection,
            startCardId: id.toString(),
            endCardId: `${newDragPointId}-${id}`,
          };
        }
        return connection;
      },
    );

    const newCardsList: PageMappingCardProps[] = cards.map((card) => {
      if (card.id === id) {
        return {
          ...card,
          isConnected: true,
          connectionsList: card.connectionsList.concat([0]),
        };
      }

      return card;
    });

    setCards(newCardsList);
    setConnections(newConnectionsList);
  };

  const iconName: IconsNames = useMemo(() => {
    switch (status) {
      case StatusesList.Success:
        return IconsNames.CircleCheck;
      case StatusesList.Critical:
        return IconsNames.CircleCross;
      case StatusesList.Warning:
        return IconsNames.CircleWarning;
      default:
        return IconsNames.CircleClock;
    }
  }, [status]);

  const isDraggablePointShown: boolean = useMemo(
    () => type === CardTypes.Input && isConnected,
    [type, isConnected],
  );

  const anchor: ArrowPositions = useMemo(
    () =>
      type === CardTypes.Input ? ArrowPositions.Bottom : ArrowPositions.Top,
    [type],
  );

  const isNewConnectionAllowed: boolean = useMemo(
    () =>
      connectionsList.filter((connection) => connection !== 0).length < 3 &&
      type === CardTypes.Result,
    [connectionsList, type],
  );

  useEffect(() => {
    updateXarrow();
  }, []);

  return (
    <div className={clsx(styles.page_mapping_card, styles[status], className)}>
      <div className={styles.page_mapping_card_header}>
        <div className={styles.page_mapping_card_title}>{title}</div>
        <Icon name={iconName} className={styles.page_mapping_card_icon} />
      </div>
      {id.split('-')[0] === 'dose' && (
        <div>
          Расход воды:{' '}
          {performance ? (
            <span>
              {performance} тыс. м<sup>3</sup>/сут
            </span>
          ) : (
            '-'
          )}
        </div>
      )}
      <div className={styles.page_mapping_card_info}>{name}</div>
      <div className={styles.page_mapping_card_info}>
        {format(date, DateFormats.ddMMyyyyHHmm)}
      </div>
      <PageMappingDroppableContainer
        id={id}
        type={type}
        isConnected={isConnected}
        connectionsList={connectionsList}
      />
      {isDraggablePointShown && isEditEnabled ? (
        <PageMappingDraggableCardPoint
          id={`${id}`}
          connectionId={connectionsList[0]}
          isConnected={isConnected}
          anchor={anchor}
        />
      ) : (
        <div
          className={clsx(
            styles.active_drag_point,
            styles[type],
            styles[anchor],
            {
              [styles.connected]: isConnected,
            },
            {
              [styles.disabled]: !isEditEnabled,
            },
          )}
          id={`${id}`}
        />
      )}
      {isNewConnectionAllowed && isEditEnabled && (
        <PageMappingDraggableCardPoint
          id={`${newDragPointId}-${id}`}
          isConnected
          connectionId={0}
          anchor={ArrowPositions.Top}
          onDragStart={handleDragStart}
        />
      )}
    </div>
  );
}

export default PageMappingCard;
