import { AxiosError, AxiosResponse } from 'axios';
import { parse, subHours } from 'date-fns';
import { createEffect, createEvent, createStore, sample } from 'effector';

import { fetchClassifiersListAdapter } from '../Adapters/fetchReportClassifiersListAdapter';
import fetchResearchClassifiersByIdAdapter from '../Adapters/fetchResearchClassifiersByIdAdapter';
import fetchResearchReportsListAdapter from '../Adapters/fetchResearchReportsListAdapter';
import { BackendStandardDataType } from '../Adapters/types';
import { defaultPeriodTypeOption } from '../Components/PeriodPickerSection/constants';
import { RangeType } from '../Components/PeriodPickerSection/PeriodPickerSection.interface';
import { OptionType } from '../Components/Select/Select.interface';
import { ToastTypes } from '../Components/Toast/Toast.interface';
import {
  AddResearchReportParamsType,
  EditResearchReportParamsType,
  ResearchReportsListParamsType,
} from '../Containers/ResearchReportsContainers/ResearchReportsContainers.interface';
import {
  addResearchReport,
  deleteResearchReport,
  editResearchReport,
  getResearchClassifiersById,
  getResearchReportsList,
  getStandardWithClassifiersById,
} from '../DataAccessLayer/apiServices';
import {
  defaultDateRange,
  defaultModalWaterTypeOption,
  defaultStandardOption,
  defaultWaterTypeOption,
  modalWaterTypesOptionsList,
  statusesOptionsList,
} from '../Pages/PageLaboratoryTests/constants';
import {
  ReportPropertiesType,
  ReportsClassifiersDataType,
  ReportsDataType,
  WaterTypes,
} from '../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';
import handleError from '../Utils/handleError';

import { $queueNumber, $standards } from './cataloguesStore';
import { setIsLoading } from './loadingStore';
import { setToastData } from './toastStore';
import {
  ResearchReportsListFxParams,
  ResponseType,
  StandardsListType,
} from './types';

const resetData = createEvent();
const DEFAULT_LIMIT: number = 10;
// filters

const setWaterType = createEvent<OptionType>();
const $waterType = createStore<OptionType>(defaultWaterTypeOption)
  .on(setWaterType, (_, newWaterType: OptionType) => newWaterType)
  .reset(resetData);

const setStatus = createEvent<OptionType>();
const $status = createStore<OptionType>(statusesOptionsList[0])
  .on(setStatus, (_, status: OptionType) => status)
  .reset(resetData);

const setStandard = createEvent<OptionType>();
const $standard = createStore<OptionType>(defaultStandardOption)
  .on(setStandard, (_, newStandard: OptionType) => newStandard)
  .reset(resetData);

const setStandardsOptionsList = createEvent<OptionType[]>();
const $standardsOptionsList = createStore<OptionType[]>([])
  .on(setStandardsOptionsList, (_, newStandardsList: OptionType[]) => {
    const standardsList = newStandardsList.slice();
    standardsList.unshift(defaultStandardOption);
    return standardsList;
  })
  .reset(resetData);

const setPeriodType = createEvent<OptionType>();
const $periodType = createStore<OptionType>(defaultPeriodTypeOption)
  .on(setPeriodType, (_, periodType: OptionType) => periodType)
  .reset(resetData);

const setDateRange = createEvent<RangeType>();
const $dateRange = createStore<RangeType>(defaultDateRange)
  .on(setDateRange, (_, newDateRange: RangeType) => newDateRange)
  .reset(resetData);

const setOffset = createEvent<number>();
const $offset = createStore<number>(0)
  .on(setOffset, (_, newOffset: number) => newOffset)
  .reset(resetData);
const setCount = createEvent<number>();
const $count = createStore<number>(0)
  .on(setCount, (_, count: number) => count)
  .reset(resetData);
const $limit = createStore<number>(DEFAULT_LIMIT);

// reports list
const $researchReports = createStore<ReportsDataType[]>([]);

const setResearchReportsList = createEvent<ReportsDataType[]>();

$researchReports
  .on(
    setResearchReportsList,
    (_, researchReportsList: ReportsDataType[]) => researchReportsList,
  )
  .reset(resetData);

const getResearchReportsListFx = createEffect<
  ResearchReportsListFxParams,
  void,
  Error
>();

const getResearchReportsListAction = async (
  data: ResearchReportsListFxParams,
) => {
  const { params, previousValue, shouldReset } = data;
  if (shouldReset) {
    setIsLoading(true);
  }

  return getResearchReportsList(params)
    .then((response: AxiosResponse) => {
      const { data: researchReportsList } = response;
      const adaptedResearchReportsList = fetchResearchReportsListAdapter(
        researchReportsList.research,
      );

      setCount(researchReportsList.count);

      if (shouldReset) {
        setResearchReportsList(adaptedResearchReportsList);
      } else {
        setResearchReportsList(
          previousValue.concat(adaptedResearchReportsList),
        );
      }
    })
    .catch((error: AxiosError) => {
      handleError({ error, request: getResearchReportsListFx, params: data });
      setResearchReportsList([]);
    })
    .finally(() => {
      if (shouldReset) {
        setIsLoading(false);
      }
    });
};

getResearchReportsListFx.use(getResearchReportsListAction);

const getResearchClassifiersByIdFx = createEffect<
  number,
  ReportsClassifiersDataType,
  Error
>();

const getResearchClassifiersByIdAction = async (id: number) => {
  return getResearchClassifiersById(id).then((response) => {
    const adaptedData = fetchResearchClassifiersByIdAdapter(response.data);
    return adaptedData;
  });
};

getResearchClassifiersByIdFx.use(getResearchClassifiersByIdAction);

// add report modal

const setIsWarningShown = createEvent<boolean>();
const $isWarningShown = createStore<boolean>(false)
  .on(setIsWarningShown, (_, isWarningShown: boolean) => isWarningShown)
  .reset(resetData);
const setIsWarningCheckboxChecked = createEvent<boolean>();
const $isWarningCheckboxChecked = createStore<boolean>(false)
  .on(
    setIsWarningCheckboxChecked,
    (_, isWarningCheckboxChecked: boolean) => isWarningCheckboxChecked,
  )
  .reset(resetData);

$researchReports
  .on(
    setResearchReportsList,
    (_, researchReportsList: ReportsDataType[]) => researchReportsList,
  )
  .on(getResearchClassifiersByIdFx.doneData, (state, payload) => {
    const stateCopy = [...state];

    const updatingElementIndex = stateCopy.findIndex(
      (item) => item.id === payload.id,
    );
    stateCopy[updatingElementIndex] = {
      ...stateCopy[updatingElementIndex],
      properties: payload.properties,
    };
    return stateCopy;
  })
  .reset(resetData);

// classifiers by standard
const getClassifiersListByIdFx = createEffect<
  number,
  ReportPropertiesType,
  Error
>();

const getClassifiersListByIdAction = async (standardId: number) => {
  const standardData: BackendStandardDataType = await (
    await getStandardWithClassifiersById(standardId).catch(
      (error: AxiosError) => {
        handleError({
          error,
          request: getClassifiersListByIdFx,
          params: standardId,
        });
        return error.response;
      },
    )
  ).data;
  const adaptedStandardClassifiersList: ReportPropertiesType =
    fetchClassifiersListAdapter(standardData.classifiers);

  return adaptedStandardClassifiersList;
};

getClassifiersListByIdFx.use(getClassifiersListByIdAction);

// modal form state
const resetReportModal = createEvent();
const setReportClassifiersList = createEvent<ReportPropertiesType>();
const $reportClassifiersList = createStore<ReportPropertiesType>({})
  .on(
    setReportClassifiersList,
    (_, reportClassifiersList: ReportPropertiesType) => reportClassifiersList,
  )
  .on(
    getClassifiersListByIdFx.done,
    (as, response: ResponseType<ReportPropertiesType>) => {
      // TODO: check ResponseType by void
      const res: any = response.result;
      Object.keys(res).forEach((key) => {
        const prevValue = as[key];
        if (prevValue) {
          res[key].value = prevValue.value;
        }
      });
      return res;
    },
  )
  .reset(resetReportModal);

const setReportExternalClassifiersList = createEvent<ReportPropertiesType>();
const $reportExternalClassifiersList = createStore<ReportPropertiesType>({})
  .on(
    setReportExternalClassifiersList,
    (_, reportExternalClassifiersList: ReportPropertiesType) =>
      reportExternalClassifiersList,
  )
  .reset(resetReportModal);

const setReportDate = createEvent<Date>();
const $reportDate = createStore<Date>(new Date())
  .on(setReportDate, (_, reportDate: Date) => reportDate)
  .reset(resetReportModal);

export const $reportEditMinDate = createStore<Date | null>(null);
const setReportEditMinDate = createEvent<Date>();
$reportEditMinDate.on(setReportEditMinDate, (_, payload) => payload);

export const $reportEditMaxDate = createStore<Date | null>(null);
const setReportEditMaxDate = createEvent<Date>();
$reportEditMaxDate.on(setReportEditMaxDate, (_, payload) => payload);

const setReportStandard = createEvent<OptionType>();
const $reportStandard = createStore<OptionType>(null)
  .on(setReportStandard, (_, reportStandard: OptionType) => reportStandard)
  .reset(resetReportModal);

const setReportResearchType = createEvent<OptionType>();
const $reportResearchType = createStore<OptionType>(defaultModalWaterTypeOption)
  .on(
    setReportResearchType,
    (_, reportResearchType: OptionType) => reportResearchType,
  )
  .reset(resetReportModal);

// modal state for editing
const setModalIsInEditMode = createEvent<boolean>();
const $isModalInEditMode = createStore<boolean>(false)
  .on(setModalIsInEditMode, (_, payload) => payload)
  .reset(resetReportModal);
const setIdOfEditingReport = createEvent<number>();
const $idOfEditingReport = createStore<number | null>(null)
  .on(setIdOfEditingReport, (_, payload) => payload)
  .reset(resetReportModal);

const spreadDataToModalForm = createEvent<number>();

type MinimalModalData = {
  researchReport: ReportsDataType;
  standards: StandardsListType;
};

const spreadModalDataFx = createEffect<MinimalModalData, void>().use(
  (params) => {
    const { researchReport, standards } = params;
    setIdOfEditingReport(researchReport.id);

    const chosenStandard = standards.all.find(
      (item) => item.value === researchReport.standardId,
    );
    setReportStandard(chosenStandard);

    const dateFromReport = parse(
      researchReport.date,
      'dd.MM.yyyy  HH:mm',
      new Date(),
    );
    setReportDate(dateFromReport);

    setReportEditMaxDate(dateFromReport);

    const HOURS_MINUS_RANGE = 4;
    setReportEditMinDate(subHours(dateFromReport, HOURS_MINUS_RANGE));

    const [potableReportType, surfaceReportType, intermediateReportType] =
      modalWaterTypesOptionsList;

    if (researchReport.waterTypeValue === WaterTypes.Potable) {
      setReportResearchType(potableReportType as OptionType);
    } else if (researchReport.waterTypeValue === WaterTypes.Surface) {
      setReportResearchType(surfaceReportType as OptionType);
    } else {
      const convertedIntermediateReportType = {
        label: intermediateReportType.label,
        value: researchReport.waterTypeValue,
      };
      setReportResearchType(convertedIntermediateReportType);
    }

    const { commonClassifiers, externalClassifiers } =
      researchReport.properties.reduce(
        (accumulator, current) => {
          const {
            id,
            isAdditionalProperty,
            maxStandardValue,
            minStandardValue,
            name,
            value,
            measureName,
          } = current;

          const transformedItem = {
            [id]: {
              id,
              maxValue: maxStandardValue * 2,
              minValue: minStandardValue / 2,
              name,
              value: value.toString(),
              units: measureName,
            },
          };

          if (isAdditionalProperty) {
            accumulator.externalClassifiers = {
              ...accumulator.externalClassifiers,
              ...transformedItem,
            };
          } else {
            accumulator.commonClassifiers = {
              ...accumulator.commonClassifiers,
              ...transformedItem,
            };
          }

          return accumulator;
        },
        {
          commonClassifiers: {},
          externalClassifiers: {},
        },
      );

    setReportClassifiersList(commonClassifiers);
    setReportExternalClassifiersList(externalClassifiers);
  },
);

sample({
  clock: spreadDataToModalForm,
  source: {
    researchReport: $researchReports,
    standards: $standards,
    id: $idOfEditingReport,
  },
  target: spreadModalDataFx,
  fn: ({ researchReport, standards }, reportId) => {
    return {
      researchReport: researchReport.find((item) => item.id === reportId),
      standards,
    };
  },
});

// on report save
const addResearchReportFx = createEffect<
  AddResearchReportParamsType,
  void,
  Error
>();

const addResearchReportAction = async (params: AddResearchReportParamsType) => {
  await addResearchReport(params)
    .then(() => {
      const dateRange = $dateRange.getState();
      const standard = $standard.getState();
      const reportType = $waterType.getState();
      const queueNumber = $queueNumber.getState();

      const requestParams: ResearchReportsListParamsType = {
        limit: DEFAULT_LIMIT,
        offset: 0,
        dateStart: dateRange.startDate,
        dateEnd: dateRange.endDate,
        standardId: standard.value ? Number(standard.value) : null,
        researchType: (reportType.value as WaterTypes) || null,
        queueNumber,
      };

      const functionData = {
        params: requestParams,
        shouldReset: true,
        previousValue: [],
      };

      getResearchReportsListFx(functionData);
      setOffset(0);
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: addResearchReportFx,
        params,
        shouldShowToast: true,
        toastMessage: 'Не удалось добавить новый отчет',
      });
    });
};

addResearchReportFx.use(addResearchReportAction);

const editResearchReportFx = createEffect<
  EditResearchReportParamsType,
  void,
  Error
>();

const editResearchReportAction = async (
  params: EditResearchReportParamsType,
) => {
  await editResearchReport(params)
    .then(() => {
      const dateRange = $dateRange.getState();
      const standard = $standard.getState();
      const reportType = $waterType.getState();
      const queueNumber = $queueNumber.getState();

      const requestParams: ResearchReportsListParamsType = {
        limit: DEFAULT_LIMIT,
        offset: 0,
        dateStart: dateRange.startDate,
        dateEnd: dateRange.endDate,
        standardId: standard.value ? Number(standard.value) : null,
        researchType: (reportType.value as WaterTypes) || null,
        queueNumber,
      };

      const functionData = {
        params: requestParams,
        shouldReset: true,
        previousValue: [],
      };

      getResearchReportsListFx(functionData);
      setOffset(0);
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: editResearchReportFx,
        params,
        shouldShowToast: true,
        toastMessage: 'Не удалось отредактировать отчет',
      });
    });
};

editResearchReportFx.use(editResearchReportAction);

const deleteResearchReportFx = createEffect<number, void, Error>();
const deleteResearchReportAction = async (id: number) => {
  await deleteResearchReport(id)
    .then(() => {
      const dateRange = $dateRange.getState();
      const standard = $standard.getState();
      const reportType = $waterType.getState();
      const queueNumber = $queueNumber.getState();

      const requestParams: ResearchReportsListParamsType = {
        limit: DEFAULT_LIMIT,
        offset: 0,
        dateStart: dateRange.startDate,
        dateEnd: dateRange.endDate,
        standardId: standard.value ? Number(standard.value) : null,
        researchType: (reportType.value as WaterTypes) || null,
        queueNumber,
      };

      const functionData = {
        params: requestParams,
        shouldReset: true,
        previousValue: [],
      };

      getResearchReportsListFx(functionData);
      setOffset(0);
      setToastData({
        isShown: true,
        toastType: ToastTypes.Success,
        title: 'Отчет успешно удален',
        message: '',
        closeDelay: 5000,
      });
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: deleteResearchReportFx,
        params: id,
        shouldShowToast: true,
        toastMessage: 'Не удалось удалить отчет',
      });
    });
};
deleteResearchReportFx.use(deleteResearchReportAction);

export {
  $count,
  $dateRange,
  $idOfEditingReport,
  $isModalInEditMode,
  $isWarningCheckboxChecked,
  $isWarningShown,
  $limit,
  $offset,
  $periodType,
  $reportClassifiersList,
  $reportDate,
  $reportExternalClassifiersList,
  $reportResearchType,
  $reportStandard,
  $researchReports,
  $standard,
  $standardsOptionsList,
  $status,
  $waterType,
  addResearchReportFx,
  deleteResearchReportFx,
  editResearchReportFx,
  getClassifiersListByIdFx,
  getResearchClassifiersByIdFx,
  getResearchReportsListFx,
  resetData,
  resetReportModal,
  setDateRange,
  setIsWarningCheckboxChecked,
  setIsWarningShown,
  setModalIsInEditMode,
  setOffset,
  setPeriodType,
  setReportClassifiersList,
  setReportDate,
  setReportExternalClassifiersList,
  setReportResearchType,
  setReportStandard,
  setStandard,
  setStandardsOptionsList,
  setStatus,
  setWaterType,
  spreadDataToModalForm,
};
