import React from 'react';
import clsx from 'clsx';

import { CardProps } from './Card.interface';

import styles from './Card.module.scss';

function Card(props: CardProps) {
  const { children, className } = props;
  return <div className={clsx(styles.card, className)}>{children}</div>;
}

export default Card;
