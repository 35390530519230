import React, { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react';
import clsx from 'clsx';

import Button from '../../../Components/Button/Button';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton/IconButton';
import { InputTypes } from '../../../Components/Input/constants';
import Input from '../../../Components/Input/Input';
import Modal from '../../../Components/Modal/Modal';
import {
  defaultResetPasswordFormValues,
  RegisterFieldsNames,
  RegisterFieldsNamesList,
} from '../constants';
import {
  ChangePasswordModalProps,
  PasswordValidationDataType,
  ValidatedFormValuesType,
} from '../PageAuth.interface';
import { checkIsValueValid } from '../utils/checkIsValueValid';

import styles from '../PageAuth.module.scss';

function ChangePasswordModal(props: ChangePasswordModalProps) {
  const { onSubmit, onClose } = props;

  const [isModalToBeClosed, setIsModalToBeClosed] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<ValidatedFormValuesType>(
    defaultResetPasswordFormValues,
  );

  const handleCancelButtonClick = () => {
    setIsModalToBeClosed(true);
  };

  const handleConfirmButtonClick = () => {
    onSubmit(formValues.password.value);
  };

  const handleModalClose = () => {
    setIsModalToBeClosed(false);
    onClose();
  };

  const handleFormValuesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const isValueValid = checkIsValueValid(
      value,
      name as RegisterFieldsNamesList,
      formValues.password.value,
    );

    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value,
        isErrorShown: !isValueValid,
      },
    });
  };

  const isConfirmButtonDisabled: boolean = useMemo(
    () =>
      !formValues.password.value ||
      !formValues.passwordConfirm.value ||
      formValues.password.isErrorShown ||
      formValues.passwordConfirm.isErrorShown,
    [formValues],
  );

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isConfirmButtonDisabled) {
      handleConfirmButtonClick();
    }
  };

  const {
    hasEightSymbols,
    hasDigits,
    hasLowerCase,
    hasSpecialSymbols,
    hasUpperCase,
  }: PasswordValidationDataType = useMemo(() => {
    const { value } = formValues.password;

    const hasEightSymbolsValue: boolean = /\S{8,}/.test(value);
    const hasDigitsValue: boolean = /\d/.test(value);
    const hasUpperCaseValue: boolean = /[А-ЯA-Z]+/.test(value);
    const hasLowerCaseValue: boolean = /[а-яa-z]+/.test(value);
    const hasSpecialSymbolsValue: boolean = /[!@#$%^&*]+/.test(value);

    return {
      hasEightSymbols: hasEightSymbolsValue,
      hasDigits: hasDigitsValue,
      hasLowerCase: hasLowerCaseValue,
      hasUpperCase: hasUpperCaseValue,
      hasSpecialSymbols: hasSpecialSymbolsValue,
    };
  }, [formValues.password.value]);

  return (
    <Modal
      isToBeClosed={isModalToBeClosed}
      onClose={handleModalClose}
      className={styles.password_change_request_modal}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <div className={styles.modal_title}>Создание пароля</div>
          <IconButton
            iconName={IconsNames.Cross}
            isClear
            onClick={handleCancelButtonClick}
            className={styles.cross_button}
          />
        </div>
        <div className={styles.modal_form}>
          <div className={styles.modal_input_row}>
            <div className={styles.modal_input_label}>
              {RegisterFieldsNames.get(RegisterFieldsNamesList.Password)}
            </div>
            <Input
              type={InputTypes.Password}
              value={formValues.password.value}
              onChange={handleFormValuesChange}
              name={RegisterFieldsNamesList.Password}
              className={styles.modal_input}
              isErrorShown={formValues.password.isErrorShown}
              errorMessage={formValues.password.errorMessage}
            />
          </div>
          <div className={styles.modal_input_row}>
            <div className={styles.modal_input_label}>
              {RegisterFieldsNames.get(RegisterFieldsNamesList.PasswordConfirm)}
            </div>
            <Input
              type={InputTypes.Password}
              value={formValues.passwordConfirm.value}
              onChange={handleFormValuesChange}
              name={RegisterFieldsNamesList.PasswordConfirm}
              className={styles.modal_input}
              isErrorShown={formValues.passwordConfirm.isErrorShown}
              errorMessage={formValues.passwordConfirm.errorMessage}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className={styles.modal_hint_row}>
            <div className={styles.modal_hint_title}>
              Пароль должен содержать:
              <ul className={styles.modal_hint_list}>
                <li
                  className={clsx(styles.modal_hint_list_item, {
                    [styles.active]: hasEightSymbols,
                  })}
                >
                  не менее 8 символов
                </li>
                <li
                  className={clsx(styles.modal_hint_list_item, {
                    [styles.active]: hasDigits,
                  })}
                >
                  цифры
                </li>
                <li
                  className={clsx(styles.modal_hint_list_item, {
                    [styles.active]: hasUpperCase,
                  })}
                >
                  заглавные буквы
                </li>
                <li
                  className={clsx(styles.modal_hint_list_item, {
                    [styles.active]: hasLowerCase,
                  })}
                >
                  строчные буквы
                </li>
                <li
                  className={clsx(styles.modal_hint_list_item, {
                    [styles.active]: hasSpecialSymbols,
                  })}
                >
                  знаки: &quot;!@#$%^&*&quot;
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.buttons_row}>
          <Button
            className={styles.button}
            isSecondary
            onClick={handleCancelButtonClick}
          >
            Отменить
          </Button>
          <Button
            className={styles.button}
            onClick={handleConfirmButtonClick}
            isDisabled={isConfirmButtonDisabled}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ChangePasswordModal;
