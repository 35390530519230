import { OptionType } from '../../Components/Select/Select.interface';

export type ReagentDataType = {
  id: number;
  name: string;
  description: string;
  yearConsumption: number;
  monthConsumption: number;
  dayConsumption: number;
  unitCost: number;
  units: OptionType;
  fileName: string;
};

export type PageReagentsTableProps = {
  reagents: ReagentDataType[];
  onReagentEdit: (reagent: ReagentDataType) => void;
  onReagentDelete: (id: number, name: string) => void;
  onReagentCreate: () => void;
  onReagentFileButtonClick: (id: number) => void;
};

export type ConfirmDeleteModalProps = {
  name: string;
  onConfirm: () => void;
  onClose: () => void;
};

export type DeleteModalDataType = {
  id: number;
  name: string;
  isShown: boolean;
};

export const enum ModalModes {
  Edit = 'edit',
  Create = 'create',
}

export type ModalReagentDataType = {
  id: number;
  name: string;
  units: OptionType;
  cost: number;
  description: string;
  fileName: string;
  file?: File;
  fileArrayBuffer?: string | ArrayBuffer;
  downloadUrl?: string;
};

export type EditCreateModalDataType = {
  isShown: boolean;
  modalMode: ModalModes;
  reagentData: ModalReagentDataType;
};

export type CreateEditModalProps = {
  reagentData: ModalReagentDataType;
  modalMode: ModalModes;
  isConfirmButtonDisabled: boolean;
  measuresOptionsList: OptionType[];
  onConfirm: () => void;
  onClose: () => void;
  onReagentDataChange: (reagentData: ModalReagentDataType) => void;
  onDocumentShow: (id: number) => void;
};

export type CreateAddReagentParamsType = {
  id?: number;
  name: string;
  price: number;
  description: string;
  measureId: number;
  file: File;
};

export type ChangeReagentDocumentParamsType = {
  id: number;
  file: File;
};
