import clsx from 'clsx';

import Button from '../Button/Button';
import Icon from '../Icon';

import { IconButtonProps } from './IconButton.interface';

import styles from './IconButton.module.scss';

function IconButton(props: IconButtonProps) {
  const { iconName, onClick, className, isDisabled, isClear, tabIndex } = props;
  return (
    <Button
      onClick={onClick}
      className={clsx(styles.icon_button, className, {
        [styles.icon_button_clear]: isClear,
      })}
      isSecondary
      isDisabled={isDisabled}
      tabIndex={tabIndex}
    >
      <Icon name={iconName} className={styles.icon} />
    </Button>
  );
}

export default IconButton;
