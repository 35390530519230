import { DatePickerTypes } from '../DatePicker/DatePicker.interface';

export const periodTypesOptionsList = [
  { value: DatePickerTypes.SingleDate, label: 'Сутки' },
  { value: DatePickerTypes.DateRange, label: 'Период' },
  { value: DatePickerTypes.MonthYear, label: 'Месяц' },
  { value: DatePickerTypes.Year, label: 'Год' },
];

export const defaultPeriodTypeOption = {
  value: DatePickerTypes.MonthYear,
  label: 'Месяц',
};
