import React, { ChangeEvent, useMemo, useRef } from 'react';

import { IconsNames } from '../Icon/constants';
import IconButton from '../IconButton/IconButton';

import { FileSelectorProps } from './FileSelector.interface';

import styles from './FileSelector.module.scss';

function FileSelector(props: FileSelectorProps) {
  const { downloadUrl, fileName, accept, fileType, onChange, onFileClick } =
    props;

  const inputRef = useRef(null);

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file: File = event.target.files && event.target.files[0];

      if (!file) {
        return;
      }

      const fileReader = new FileReader();

      fileReader.onload = (loadEvent) => {
        const text: string | ArrayBuffer = loadEvent.target.result;
        const blob: Blob = new Blob([file], {
          type: fileType,
        });

        const newDownloadUrl: string = URL.createObjectURL(blob);
        const newFileName: string = file.name;

        onChange({
          file,
          fileName: newFileName,
          downloadUrl: newDownloadUrl,
          arrayBuffer: text,
        });
      };

      fileReader.readAsText(file);
    }
  };

  const handleFileSelectorOpen = () => {
    inputRef?.current?.click();
  };
  const handleFileRemove = () => {
    onChange({
      file: null,
      fileName: '',
      downloadUrl: '',
      arrayBuffer: null,
    });
  };
  const iconsSection: JSX.Element = (
    <div className={styles.icons_col}>
      <IconButton
        iconName={IconsNames.Cross}
        className={styles.delete_file_icon}
        onClick={handleFileRemove}
        isClear
      />
      <IconButton
        iconName={IconsNames.Edit}
        className={styles.edit_file_icon}
        onClick={handleFileSelectorOpen}
        isClear
      />
    </div>
  );

  const downloadSection: JSX.Element = useMemo(() => {
    if (downloadUrl) {
      return (
        <div className={styles.download_section}>
          <a
            href={downloadUrl}
            download={fileName}
            className={styles.download_button}
          >
            {fileName}
          </a>
          {iconsSection}
        </div>
      );
    }
    if (fileName) {
      return (
        <div className={styles.download_section}>
          <button
            type="button"
            onClick={onFileClick}
            className={styles.download_button}
          >
            {fileName}
          </button>
          {iconsSection}
        </div>
      );
    }
    return (
      <button
        type="button"
        onClick={handleFileSelectorOpen}
        className={styles.download_button}
      >
        Файл не выбран
      </button>
    );
  }, [fileName, onFileClick, onChange, downloadUrl]);

  return (
    <div className={styles.file_selector}>
      <input
        type="file"
        onChange={handleValueChange}
        className={styles.input}
        ref={inputRef}
        accept={accept}
      />
      {downloadSection}
    </div>
  );
}

export default FileSelector;
