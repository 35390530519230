export enum StatusesList {
  Success = 'DONE',
  Warning = 'BORDER',
  Critical = 'FAILED',

  Pending = 'PENDING',
  ToBeFilled = 'WAITING',
  Freezed = 'LOCKED',
}

export enum StatusesLabels {
  Pending = 'Ожидание результатов исследования',
  Success = 'Контролируемые значения в пределах стандарта',
  Warning = 'Контролируемые значения в граничных пределах стандарта',
  Critical = 'Контролируемые значения не соответствуют стандарту',
  ToBeFilled = 'Ожидание ввода данных',
  All = 'Все статусы',
}

export const StatusesLabelsList = new Map([
  [StatusesList.Pending, StatusesLabels.Pending],
  [StatusesList.Success, StatusesLabels.Success],
  [StatusesList.Warning, StatusesLabels.Warning],
  [StatusesList.Critical, StatusesLabels.Critical],
  [StatusesList.ToBeFilled, StatusesLabels.ToBeFilled],
  [null, StatusesLabels.All],
]);

export type ClassifierStatusType =
  | StatusesList.Success
  | StatusesList.Warning
  | StatusesList.Critical;
