import { ChangeEvent, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';

import { OptionType } from '../../Components/Select/Select.interface';
import ReportModal from '../../Pages/PageLaboratoryTests/components/ReportModal';
import {
  IntermediateWaterSubTypes,
  ReportPropertiesType,
  ReportPropertyType,
  WaterTypes,
} from '../../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';
import { getCurrentUserFx } from '../../Store/authStore';
import {
  $classifiers,
  $defaultStandards,
  $queueNumber,
  getClassifiersListFx,
} from '../../Store/cataloguesStore';
import {
  $idOfEditingReport,
  $isModalInEditMode,
  $isWarningCheckboxChecked,
  $isWarningShown,
  $reportClassifiersList,
  $reportDate,
  $reportEditMaxDate,
  $reportEditMinDate,
  $reportExternalClassifiersList,
  $reportResearchType,
  $reportStandard,
  addResearchReportFx,
  editResearchReportFx,
  getClassifiersListByIdFx,
  resetReportModal,
  setIsWarningCheckboxChecked,
  setIsWarningShown,
  setReportClassifiersList,
  setReportDate,
  setReportExternalClassifiersList,
  setReportResearchType,
  setReportStandard,
} from '../../Store/researchReportsPageStore';

import {
  AddResearchReportParamsType,
  EditResearchReportParamsType,
  ReportModalContainerProps,
} from './ResearchReportsContainers.interface';

function ReportModalContainer(props: ReportModalContainerProps) {
  const { onModalClose } = props;
  const reportDate = useStore($reportDate);
  const researchType = useStore($reportResearchType);
  const reportStandard = useStore($reportStandard);
  const standardsList = useStore($defaultStandards);
  const reportClassifiersList = useStore($reportClassifiersList);
  const classifiersList = useStore($classifiers);
  const reportExternalClassifiersList = useStore(
    $reportExternalClassifiersList,
  );
  const isWarningShown = useStore($isWarningShown);
  const isWarningCheckboxChecked = useStore($isWarningCheckboxChecked);
  const idOfEditingReport = useStore($idOfEditingReport);
  const queueNumber = useStore($queueNumber);

  const isModalInEditMode = useStore($isModalInEditMode);

  const isClassifiersLoading = useStore(getClassifiersListByIdFx.pending);

  const reportMinDate = useStore($reportEditMinDate);
  const reportMaxDate = useStore($reportEditMaxDate);

  const handleReportDateChange = (date: Date) => {
    setReportDate(date);
  };
  const handleReportResearchTypeChange = (type: OptionType) => {
    setReportResearchType(type);

    const keyFromType = type.value.toString().toLowerCase();

    const standardListByType = standardsList[keyFromType];

    if (standardListByType[0]) {
      setReportStandard(standardListByType[0]);
    } else {
      setReportStandard(null);
      setReportClassifiersList({});
    }
  };

  const handleModalStandardChange = (standard: OptionType) => {
    setReportStandard(standard);
  };

  const handlePropertiesValuesChange = (
    propertiesValues: ReportPropertiesType,
  ) => {
    setReportClassifiersList(propertiesValues);
  };

  const handleSelectedAdditionalPropertiesValuesChange = (
    selectedProperties: ReportPropertiesType,
  ) => {
    setReportExternalClassifiersList(selectedProperties);
  };

  const handleModalClose = () => {
    resetReportModal();
    onModalClose();
  };
  const hasErrorRows: boolean = useMemo(
    () =>
      Object.keys(reportClassifiersList).some(
        (classifierKey: string) =>
          reportClassifiersList[classifierKey].isErrorShown,
      ),
    [reportClassifiersList],
  );
  const handleReportSave = () => {
    if (
      !hasErrorRows ||
      (isWarningShown && isWarningCheckboxChecked) ||
      reportStandard !== null
    ) {
      if (!isModalInEditMode) {
        const params: AddResearchReportParamsType = {
          standardId: Number(reportStandard?.value),
          datetime: reportDate,
          classifiers: reportClassifiersList,
          externalClassifiers: reportExternalClassifiersList,
          researchType: researchType.value as WaterTypes,
          queueNumber,
        };

        addResearchReportFx(params);
      } else {
        const params: EditResearchReportParamsType = {
          researchId: idOfEditingReport,
          classifiers: reportClassifiersList,
          externalClassifiers: reportExternalClassifiersList,
          datetime: reportDate,
        };

        editResearchReportFx(params);
      }

      getCurrentUserFx();
      handleModalClose();
    }
  };

  const handleWarningCheckboxValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    setIsWarningCheckboxChecked(checked);
  };

  const isSaveAvailable = useMemo(() => {
    const isPropertiesSectionFilled: boolean = Object.values(
      reportClassifiersList,
    ).every(
      (property: ReportPropertyType) =>
        property.value !== '' && property.value !== null,
    );
    const isAdditionalPropertiesSectionFilled: boolean = Object.values(
      reportExternalClassifiersList,
    ).every(
      (property: ReportPropertyType) =>
        property.value !== '' && property.value !== null,
    );

    return (
      isAdditionalPropertiesSectionFilled &&
      isPropertiesSectionFilled &&
      !(isWarningShown && !isWarningCheckboxChecked) &&
      reportStandard !== null
    );
  }, [
    reportClassifiersList,
    isWarningShown,
    isWarningCheckboxChecked,
    reportExternalClassifiersList,
    reportStandard,
  ]);

  const standardsOptionsList = useMemo(() => {
    switch (researchType.value) {
      case WaterTypes.Potable:
        return standardsList.potable;
      case WaterTypes.Surface:
        return standardsList.surface;
      case IntermediateWaterSubTypes.Filters:
        return standardsList.filters;
      case IntermediateWaterSubTypes.Sump:
        return standardsList.sump;
      case IntermediateWaterSubTypes.PostPotable:
        return standardsList.post_potable;
      default:
        return standardsList.all;
    }
  }, [researchType]);

  useEffect(() => {
    if (!isModalInEditMode) {
      if (reportStandard?.value && !isClassifiersLoading) {
        getClassifiersListByIdFx(Number(reportStandard.value));
      } else {
        setReportStandard(null);
      }
      setReportExternalClassifiersList({});
    }
  }, [reportStandard]);

  useEffect(() => {
    getClassifiersListFx();
    if (!isModalInEditMode) {
      setReportDate(new Date());
    }
  }, []);

  useEffect(() => {
    setIsWarningCheckboxChecked(false);
    setIsWarningShown(hasErrorRows);
  }, [hasErrorRows]);

  useEffect(() => {
    if (standardsOptionsList?.length !== 0 && !isModalInEditMode) {
      setReportStandard(standardsOptionsList[0]);
    }
  }, [standardsOptionsList]);

  const availableExternalClassifiersList: OptionType[] = useMemo(
    () =>
      classifiersList.filter((classifier: OptionType) => {
        const { value: classifierId } = classifier;
        const isNotInStandard: boolean = !Object.keys(
          reportClassifiersList,
        ).some((key) => key.toString() === classifierId.toString());
        const isNotIncluded: boolean = !Object.keys(
          reportExternalClassifiersList,
        ).some((key) => key.toString() === classifierId.toString());

        return isNotInStandard && isNotIncluded;
      }),
    [classifiersList, reportClassifiersList, reportExternalClassifiersList],
  );

  return (
    <ReportModal
      queueNumber={queueNumber}
      isInEditMode={isModalInEditMode}
      reportDate={reportDate}
      reportMinDate={reportMinDate !== null ? reportMinDate : undefined}
      reportMaxDate={reportMaxDate !== null ? reportMaxDate : undefined}
      researchType={researchType}
      standard={reportStandard}
      standardsOptionsList={standardsOptionsList}
      properties={reportClassifiersList}
      availablePropertiesOptionsList={availableExternalClassifiersList}
      selectedAdditionalProperties={reportExternalClassifiersList}
      isSaveAvailable={isSaveAvailable}
      isWarningShown={isWarningShown}
      isWarningCheckboxChecked={isWarningCheckboxChecked}
      onWarningCheckboxValueChange={handleWarningCheckboxValueChange}
      onReportDateChange={handleReportDateChange}
      onReportResearchTypeChange={handleReportResearchTypeChange}
      onModalClose={handleModalClose}
      onStandardChange={handleModalStandardChange}
      onPropertiesValuesChange={handlePropertiesValuesChange}
      onSelectedAdditionalPropertiesChange={
        handleSelectedAdditionalPropertiesValuesChange
      }
      onReportSave={handleReportSave}
    />
  );
}

export default ReportModalContainer;
