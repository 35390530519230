import {
  DatePickerCalendarView,
  DatePickerTypes,
} from '../DatePicker.interface';

export function getDatePickerProps(
  type: DatePickerTypes,
  calendarView: DatePickerCalendarView,
  startDate: Date,
  selectedDate: Date,
) {
  const selectedDateProp =
    type === DatePickerTypes.DateRange ? startDate : selectedDate;
  const isRangeSelect =
    type === DatePickerTypes.DateRange &&
    calendarView === DatePickerCalendarView.Days;
  const yearItemNumber = type === DatePickerTypes.Year ? 12 : 16;
  const dateFormat =
    type === DatePickerTypes.SingleDateTime ? 'mm-dd-yyyy hh:mm' : 'mm-dd-yyyy';

  return { selectedDateProp, isRangeSelect, yearItemNumber, dateFormat };
}
