import { useStore } from 'effector-react';

import { RangeType } from '../../Components/PeriodPickerSection/PeriodPickerSection.interface';
import { OptionType } from '../../Components/Select/Select.interface';
import PageMonitoringHeader from '../../Pages/PageMonitoring/components/PageMonitoringHeader';
import { StatusesList } from '../../Static/statusesList';
import { $userConfig, updateQueueConfigFx } from '../../Store/authStore';
import {
  $queueCount,
  $queueNumber,
  setQueueNumber,
} from '../../Store/cataloguesStore';
import { exportMonitoringReportsListFx } from '../../Store/exportStore';
import {
  $dateRange,
  $limit,
  $monitoringCardsIdsList,
  $offset,
  $status,
  getMonitoringReportsDetailedFx,
  setOpenPanelsIdsList,
} from '../../Store/monitoringPageStore';

import { MonitoringReportsSummaryParamsType } from './PageMonitoringContainers.interface';

function PageMonitoringHeaderContainer() {
  const dateRange: RangeType = useStore($dateRange);
  const { startDate, endDate } = dateRange;
  const status: OptionType = useStore($status);
  const offset: number = useStore($offset);
  const limit: number = useStore($limit);
  const idsList: number[] = useStore($monitoringCardsIdsList);
  const queueNumber = useStore($queueNumber);
  const queueCount: number = useStore($queueCount);
  const userConfig = useStore($userConfig);

  const handleExportButtonClick = () => {
    if (startDate && endDate) {
      const params: MonitoringReportsSummaryParamsType = {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
      };

      exportMonitoringReportsListFx(params);
    }
  };

  const handleShowAllButtonClick = () => {
    setOpenPanelsIdsList(idsList);
    getMonitoringReportsDetailedFx({
      queueNumber,
      dateEnd: endDate,
      dateStart: startDate,
      limit,
      offset,
      status: status.value as StatusesList,
    });
  };
  const handleHideAllButtonClick = () => {
    setOpenPanelsIdsList([]);
  };

  const changeQueueHandler = (queue: number) => {
    updateQueueConfigFx(queue);
    setQueueNumber(queue);
  };

  return (
    <PageMonitoringHeader
      onExportButtonClick={handleExportButtonClick}
      onShowAllButtonClick={handleShowAllButtonClick}
      onHideAllButtonClick={handleHideAllButtonClick}
      onChangeQueue={changeQueueHandler}
      activeQueue={queueNumber}
      queueCount={queueCount}
      queueConfig={userConfig}
    />
  );
}

export default PageMonitoringHeaderContainer;
