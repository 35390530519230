import React, { useMemo } from 'react';
import clsx from 'clsx';

import toggleArrayValue from '../../Utils/toggleArrayValue';

import StandardCard from './components/StandardCard';
import {
  StandardCardDataType,
  StandardsTreeProps,
} from './StandardsTree.interface';

import styles from './StandardsTree.module.scss';

function StandardsTree(props: StandardsTreeProps) {
  const {
    standards,
    activeCardId,
    openChildrenCardsIdsList,
    openHistoryCardsIdsList,
    isCopyDisabled,
    onCardClick,
    onOpenChildrenCardsIdsListChange,
    onOpenHistoryCardsIdsListChange,
    onCardCopy,
  } = props;

  const handleCardClick = (id: number) => {
    onCardClick(id);
  };
  const handleCopyButtonClick = (id: number) => {
    onCardCopy(id);
  };
  const handleHistoryButtonClick = (id: number) => {
    const newOpenHistoryCardsIdsList = toggleArrayValue(
      openHistoryCardsIdsList,
      id,
    );

    onOpenHistoryCardsIdsListChange(newOpenHistoryCardsIdsList);
  };
  const handleChevronButtonClick = (id: number) => {
    const newOpenChildrenCardsIdsList = toggleArrayValue(
      openChildrenCardsIdsList,
      id,
    );

    onOpenChildrenCardsIdsListChange(newOpenChildrenCardsIdsList);
  };

  const renderStandardsBlock = (
    standardsArr: StandardCardDataType[],
    isChild: boolean,
    isHistory: boolean,
  ): JSX.Element[] => {
    return standardsArr.map((standard: StandardCardDataType) => {
      const {
        id,
        name,
        standardType,
        isConst,
        datetime,
        children,
        history,
        author,
      } = standard;

      const isActive: boolean = activeCardId === id;
      const areChildrenOpen: boolean = openChildrenCardsIdsList.includes(id);
      const isHistoryOpen: boolean = openHistoryCardsIdsList.includes(id);
      const hasChildren: boolean = !!children && children?.length !== 0;
      const hasHistory: boolean = !!history && history?.length !== 0;

      const historyBlock: JSX.Element[] = hasHistory
        ? renderStandardsBlock(history, false, true)
        : null;
      const childrenBlock: JSX.Element[] = hasChildren
        ? renderStandardsBlock(children, true, false)
        : null;

      return (
        <div
          className={clsx(styles.standard_card_wrapper, {
            [styles.standard_card_active]: isActive,
          })}
          key={id}
        >
          <StandardCard
            id={id}
            name={name}
            standardType={standardType}
            isConst={isConst}
            datetime={datetime}
            isActive={isActive}
            isHistory={isHistory}
            isChild={isChild}
            areChildrenOpen={areChildrenOpen}
            isHistoryOpen={isHistoryOpen}
            hasChildren={hasChildren}
            hasHistory={hasHistory}
            author={author}
            isCopyDisabled={isCopyDisabled}
            onCardClick={handleCardClick}
            onChevronButtonClick={handleChevronButtonClick}
            onHistoryButtonClick={handleHistoryButtonClick}
            onCopyButtonClick={handleCopyButtonClick}
          />
          {isHistoryOpen && (
            <div className={styles.history_block}>{historyBlock}</div>
          )}
          {areChildrenOpen && (
            <div className={styles.children_block}>{childrenBlock}</div>
          )}
        </div>
      );
    });
  };

  const standardsList = useMemo(
    () => renderStandardsBlock(standards, false, false),
    [
      standards,
      activeCardId,
      openChildrenCardsIdsList,
      openHistoryCardsIdsList,
      onCardClick,
      onOpenChildrenCardsIdsListChange,
      onOpenHistoryCardsIdsListChange,
    ],
  );

  return <div className={styles.standards_tree_wrapper}>{standardsList}</div>;
}

export default StandardsTree;
