import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import Button from '../../../Components/Button';
import ButtonsSwitcher from '../../../Components/ButtonsSwitcher/ButtonsSwitcher';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton';
import { InputTypes } from '../../../Components/Input/constants';
import Input from '../../../Components/Input/Input';
import Modal from '../../../Components/Modal';
import Select from '../../../Components/Select/Select';
import Textarea from '../../../Components/Textarea/Textarea';
import {
  addClassifierButtonsSwitcherOptions,
  ClassifierTypes,
} from '../constants';
import { StandardClassifierModalProps } from '../PageStandards.interface';

import styles from '../PageStandards.module.scss';

function StandardClassifierModal(props: StandardClassifierModalProps) {
  const {
    measuresOptionsList,
    classifiersOptionsList,
    classifierType,
    classifierName,
    classifierDescription,
    selectedClassifier,
    selectedMeasure,
    onClassifierTypeChange,
    onSelectedClassifierChange,
    onSelectedMeasureChange,
    onClassifierDescriptionChange,
    onClassifierNameChange,
    onConfirm,
    onClose,
  } = props;

  const [isModalToBeClosed, setIsModalToBeClosed] = useState<boolean>(false);

  const handleCancelButtonClick = () => {
    setIsModalToBeClosed(true);
  };

  const handleConfirmButtonClick = () => {
    setIsModalToBeClosed(true);
    onConfirm();
  };

  const handleModalClose = () => {
    setIsModalToBeClosed(false);
    onClose();
  };

  const isNewClassifier: boolean = useMemo(
    () => classifierType === ClassifierTypes.NewClassifier,
    [classifierType],
  );

  const isConfirmButtonDisabled: boolean = useMemo(() => {
    if (classifierType === ClassifierTypes.ExistingClassifier) {
      return !selectedClassifier;
    }
    return !(classifierName && selectedMeasure && classifierDescription);
  }, [
    classifierType,
    classifierName,
    selectedClassifier,
    selectedMeasure,
    classifierDescription,
  ]);

  return (
    <Modal
      isToBeClosed={isModalToBeClosed}
      onClose={handleModalClose}
      className={styles.standard_modal}
    >
      <div className={styles.standard_warning_modal_content}>
        <div className={styles.modal_header}>
          <div className={styles.modal_title}>Новый показатель</div>
          <IconButton
            iconName={IconsNames.Cross}
            isClear
            onClick={handleCancelButtonClick}
            className={styles.modal_cross_icon}
          />
        </div>
        <div className={styles.modal_form_row}>
          <div className={styles.modal_form_row_title}>Способ добавления:</div>
          <ButtonsSwitcher
            buttons={addClassifierButtonsSwitcherOptions}
            activeButtonValue={classifierType}
            onButtonClick={onClassifierTypeChange}
            className={styles.modal_buttons_switcher}
          />
        </div>
        <div className={clsx(styles.modal_form_row, styles.two_col_row)}>
          <div className={styles.modal_form_col}>
            <div className={styles.modal_form_row_title}>
              Наименование показателя:
            </div>
            {isNewClassifier ? (
              <Input
                type={InputTypes.Text}
                value={classifierName}
                onChange={onClassifierNameChange}
                placeholder="Введите наименование показателя"
              />
            ) : (
              <Select
                options={classifiersOptionsList}
                value={selectedClassifier}
                onChange={onSelectedClassifierChange}
                placeholder="Выберите показатель"
              />
            )}
          </div>
          <div className={styles.modal_form_col}>
            {isNewClassifier && (
              <>
                <div className={styles.modal_form_row_title}>
                  Единицы измерения:
                </div>
                <Select
                  options={measuresOptionsList}
                  value={selectedMeasure}
                  onChange={onSelectedMeasureChange}
                  placeholder="Выберите единицы измерения"
                  isDisabled={!isNewClassifier}
                />
              </>
            )}
          </div>
        </div>
        <div className={styles.modal_form_row}>
          <div className={styles.modal_form_row_title}>
            Описание показателя:
          </div>
          <Textarea
            value={classifierDescription}
            onChange={onClassifierDescriptionChange}
            placeholder="Описание показателя"
            className={styles.modal_textarea}
            disabled={!isNewClassifier}
          />
        </div>
        <div className={styles.warning_modal_buttons_row}>
          <Button
            onClick={handleConfirmButtonClick}
            className={styles.warning_modal_button}
            isDisabled={isConfirmButtonDisabled}
          >
            Добавить показатель
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default StandardClassifierModal;
