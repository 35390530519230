import React, { ChangeEvent, useMemo } from 'react';

import Icon from '../../../Components/Icon';
import { IconsNames } from '../../../Components/Icon/constants';
import Input from '../../../Components/Input';
import { InputTypes } from '../../../Components/Input/constants';
import Select from '../../../Components/Select';
import { OptionType } from '../../../Components/Select/Select.interface';
import writeFloatInputValue from '../../../Utils/writeFloatInputValue';
import {
  AdditionalPropertiesSectionProps,
  ReportPropertiesType,
} from '../PageLaboratoryTests.interface';

import styles from '../PageLaboratoryTests.module.scss';

function AdditionalPropertiesSection(props: AdditionalPropertiesSectionProps) {
  const {
    availablePropertiesOptionsList,
    selectedProperties,
    onPropertiesChange,
  } = props;

  const handleAddPropertyButtonClick = () => {
    const { value, label } = availablePropertiesOptionsList[0];

    const newPropertiesList: ReportPropertiesType = {
      ...selectedProperties,
      [value.toString()]: {
        id: Number(value),
        name: label as string,
        units: null,
        value: '',
        minValue: null,
        maxValue: null,
      },
    };

    onPropertiesChange(newPropertiesList);
  };

  const handleDeletePropertyButtonClick = (propertyId: number) => () => {
    const newPropertiesList: ReportPropertiesType = {
      ...selectedProperties,
    };

    delete newPropertiesList[propertyId];

    onPropertiesChange(newPropertiesList);
  };

  const handlePropertyValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    let { value } = event.target;

    value = writeFloatInputValue(value);
    const newPropertiesList: ReportPropertiesType = {
      ...selectedProperties,
      [name]: {
        ...selectedProperties[name],
        value,
      },
    };

    onPropertiesChange(newPropertiesList);
  };

  const selectedPropertiesList = useMemo(
    () =>
      Object.keys(selectedProperties).map((propertyKey: string) => {
        const { id, name, value } = selectedProperties[propertyKey];

        const selectedOption: OptionType = {
          value: id,
          label: name,
        };

        let options: OptionType[] = [];
        options.push(selectedOption);
        options = options.concat(availablePropertiesOptionsList);

        const handlePropertySelectValueChange = (option: OptionType) => {
          if (option.value !== id) {
            const newPropertiesList: ReportPropertiesType = {
              ...selectedProperties,
              [option.value.toString()]: {
                id: Number(option.value),
                name: option.label as string,
                units: null,
                value: '',
                minValue: null,
                maxValue: null,
              },
            };

            delete newPropertiesList[id];

            onPropertiesChange(newPropertiesList);
          }
        };

        return (
          <div className={styles.additional_property_item} key={propertyKey}>
            <Select
              value={selectedOption}
              options={options}
              onChange={handlePropertySelectValueChange}
              className={styles.additional_property_select}
              isSearchable
              menuPlacement="top"
            />
            <Input
              type={InputTypes.Text}
              className={styles.additional_property_value}
              value={value?.replace(/[.,]/g, ',')}
              name={propertyKey}
              onChange={handlePropertyValueChange}
              min={0}
              step={0.1}
            />
            <button
              type="button"
              className={styles.delete_property_button}
              onClick={handleDeletePropertyButtonClick(id)}
            >
              Удалить
            </button>
          </div>
        );
      }),
    [selectedProperties, availablePropertiesOptionsList],
  );

  return (
    <div className={styles.additional_properties_section}>
      <div className={styles.additional_properties_section_header}>
        <div className={styles.additional_properties_header_title}>
          <Icon name={IconsNames.Save} />
          Дополнительные показатели:
        </div>
        {availablePropertiesOptionsList.length > 0 && (
          <button
            type="button"
            className={styles.add_property_button}
            onClick={handleAddPropertyButtonClick}
          >
            + Добавить
          </button>
        )}
      </div>
      {selectedPropertiesList.length > 0 && (
        <div className={styles.additional_properties_list}>
          {selectedPropertiesList}
        </div>
      )}
    </div>
  );
}

export default AdditionalPropertiesSection;
