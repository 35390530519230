import React, { useState } from 'react';

import PageResearchReportsContainer from '../../Containers/ResearchReportsContainers/PageResearchReportsContainer';
import ReportModalContainer from '../../Containers/ResearchReportsContainers/ReportModalContainer';

import styles from './PageLaboratoryTests.module.scss';

function PageLaboratoryTests() {
  const [isReportModalVisible, setIsReportModalVisible] =
    useState<boolean>(false);

  const handleModalShow = () => {
    setIsReportModalVisible(true);
  };
  const handleModalClose = () => {
    setIsReportModalVisible(false);
  };

  return (
    <div className={styles.page_wrapper}>
      <div className={styles.card_wrapper}>
        <PageResearchReportsContainer
          onTestCreate={handleModalShow}
          onTestEdit={handleModalShow}
          cardClassName={styles.card}
        />
      </div>
      {isReportModalVisible && (
        <ReportModalContainer onModalClose={handleModalClose} />
      )}
    </div>
  );
}

export default PageLaboratoryTests;
