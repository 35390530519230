import format from 'date-fns/format';

import { DateFormats } from '../Components/DatePicker/DatePicker.interface';
import { MonitoringGraphProps } from '../Components/MonitoringGraph/MonitoringGraph.interface';
import { MonitoringReportsListParamsType } from '../Containers/PageMonitoringContainers/PageMonitoringContainers.interface';
import {
  ClassifierValueDataType,
  ModalQueueDataType,
  ModalReagentDataType,
  MonitoringCardByIdType,
  MonitoringCardDataType,
  MonitoringCommonCardDataType,
  ReagentQueueValueDataType,
  ReagentsInputTypes,
  ReagentValueDataType,
} from '../Pages/PageMonitoring/PageMonitoring.interface';
import { StatusesList } from '../Static/statusesList';
import { AddDoseParamsType, EditDoseParamsType } from '../Store/types';
import { getShortFullName } from '../Utils/getFullName';

import {
  BackendAddDoseParamsType,
  BackendAddDoseReagentDataType,
  BackendClassifierValueHistoryDataType,
  BackendCommonMonitoringReportDataType,
  BackendEditDoseParamsType,
  BackendMonitoringClassifierDataType,
  BackendMonitoringData,
  BackendMonitoringQueueDataType,
  BackendMonitoringReportByIdDataType,
  BackendMonitoringReportDataType,
  BackendRecommendedReagentsQueuesDataType,
  MonitoringReportsAdapterDataType,
} from './types';

const MAX_GRAPH_ENTRIES = 6;
export function revertMonitoringReportsParamsAdapter(
  params: MonitoringReportsListParamsType,
) {
  const { dateStart, dateEnd, limit, offset, status, queueNumber } = params;

  return {
    date_start: dateStart ? format(dateStart, DateFormats.yyyy_MM_dd) : null,
    date_end: dateStart ? format(dateEnd, DateFormats.yyyy_MM_dd) : null,
    limit,
    offset,
    status,
    queue_number: queueNumber,
  };
}

function getClassifiersData(
  classifiersList: BackendMonitoringClassifierDataType[],
  reportDate: string,
): {
  classifiers: ClassifierValueDataType[];
  graphData: MonitoringGraphProps;
} {
  const classifiers: ClassifierValueDataType[] = [];
  const graphData: MonitoringGraphProps = {
    dates: [],
    values: [],
  };
  classifiersList?.forEach(
    (classifier: BackendMonitoringClassifierDataType, index: number) => {
      const {
        id: classifierId,
        measure_name: classifierMeasureName,
        name: classifierName,
        value: classifierValue,
        diff: classifierValuesDifference,
        last_values: classifierValuesList = [],
        status,
      } = classifier;

      const classifierData: ClassifierValueDataType = {
        id: classifierId,
        name: classifierName,
        measureName: classifierMeasureName,
        value: classifierValue,
        valueDifference: classifierValuesDifference,
        status,
      };

      classifiers.push(classifierData);
      classifierValuesList?.forEach(
        (classifierValuesListItem: BackendClassifierValueHistoryDataType) => {
          const { datetime: classifierValueItemDate } =
            classifierValuesListItem;

          if (index === 0) {
            graphData.dates.push(classifierValueItemDate);
          }
        },
      );

      if (index === 0) {
        graphData.dates.reverse().push(reportDate);
      }
    },
  );
  graphData.dates = graphData.dates.slice(-MAX_GRAPH_ENTRIES);

  classifiersList?.forEach(
    (classifier: BackendMonitoringClassifierDataType) => {
      const { last_values: classifierValuesList, value: classifierValue } =
        classifier;

      const graphDataCol: number[] = Array(graphData.dates.length).fill(null);
      if (graphData.dates?.length > 1) {
        graphData.dates?.forEach((date, dateIndex) => {
          const valueIndex = classifierValuesList.findIndex(
            (item) => item.datetime === date,
          );

          if (valueIndex !== -1) {
            graphDataCol[dateIndex] = classifierValuesList[valueIndex].value;
          } else {
            graphDataCol[dateIndex] = null;
          }
        });
      }

      graphDataCol[graphData.dates.length - 1] = classifierValue;

      graphData.values.push(graphDataCol);
    },
  );

  return { classifiers, graphData };
}

function getReagentsData(
  queuesList: BackendMonitoringQueueDataType[],
  recommendedDoses?: BackendRecommendedReagentsQueuesDataType[],
): ReagentValueDataType[] {
  if (queuesList) {
    const tempReagentsList = {};

    queuesList?.forEach((queue: BackendMonitoringQueueDataType) => {
      const QUEUE_NUMBER = 1;

      const { id, name, value } = queue;

      const recommendedValue =
        recommendedDoses !== undefined
          ? recommendedDoses?.find((reagent) => reagent.id === id)?.value || 0
          : null;

      const queues: ReagentQueueValueDataType = tempReagentsList[id]?.queues
        ? {
            ...tempReagentsList[id].queues,
            [QUEUE_NUMBER]: {
              id,
              value,
              recommendedValue,
              recommendedValueDifference: null,
              valueDifference: null,
            },
          }
        : {
            [QUEUE_NUMBER]: {
              id,
              value,
              recommendedValue,
              recommendedValueDifference: null,
              valueDifference: null,
            },
          };

      tempReagentsList[id] = {
        id,
        name,
        queues,
      };
    });

    const reagentsList: ReagentValueDataType[] = Object.keys(
      tempReagentsList,
    ).map((reagentKey) => tempReagentsList[reagentKey]);

    return reagentsList;
  }

  return [];
}

export function adaptMonitoringReport(
  report: BackendCommonMonitoringReportDataType,
  inputType: ReagentsInputTypes,
  isDisabled: boolean,
): MonitoringCommonCardDataType {
  const {
    id,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    datetime: reportDate,
    status,
    classifiers: classifiersList = [],
    comment,
    reagents: reagentsList = [],
    dose_id: doseId,
    performance,
    name,
    sub_type: subType,
  } = report;

  const author: string = getShortFullName(firstName, middleName, lastName);

  const { classifiers, graphData } = getClassifiersData(
    classifiersList,
    reportDate,
  );

  const reagents: ReagentValueDataType[] = getReagentsData(reagentsList);

  const datetime: string = format(
    new Date(reportDate),
    DateFormats.ddMMyyyyHHmm,
  );

  return {
    id,
    doseId,
    datetime,
    author,
    status,
    comment,
    isDisabled,
    classifiers,
    reagents,
    graphData,
    inputType,
    performance,
    name,
    subType,
  };
}

function adaptMonitoringResearchToCardData(
  researchData: BackendMonitoringReportDataType | null,
  type: 'surface' | 'potable',
) {
  if (researchData === null) {
    return { cardData: null, idsFromData: [] };
  }

  const ids: number[] = [];
  const { intermediates = [] } = researchData;

  ids.push(researchData.id);

  const isDisabled: boolean = researchData.status === StatusesList.Freezed;

  const {
    id,
    doseId,
    datetime,
    author,
    status,
    comment,
    classifiers,
    reagents,
    graphData,
    inputType,
    performance,
    name,
    subType,
  } = adaptMonitoringReport(
    researchData,
    type === 'potable'
      ? ReagentsInputTypes.Potable
      : ReagentsInputTypes.Surface,
    isDisabled,
  );

  const intermediatesList = intermediates
    ? intermediates.reverse().map((intermediateReportData) => {
        ids.push(intermediateReportData.id);

        return adaptMonitoringReport(
          intermediateReportData,
          ReagentsInputTypes.Intermediate,
          isDisabled,
        );
      })
    : [];
  const adaptedReportData: MonitoringCardDataType = {
    id,
    doseId,
    datetime,
    author,
    status,
    comment,
    classifiers,
    inputType,
    reagents,
    graphData,
    intermediates: intermediatesList.reverse(),
    isDisabled,
    performance,
    name,
    subType,
  };

  return { cardData: adaptedReportData, idsFromData: ids };
}

export function fetchMonitoringReportsAdapter(
  reportsList: BackendMonitoringData[],
): MonitoringReportsAdapterDataType {
  if (reportsList) {
    const ids: number[] = [];
    const cards = reportsList.map((report: BackendMonitoringData) => {
      const cardDataFromSurface = adaptMonitoringResearchToCardData(
        report.surface_research,
        'surface',
      );
      const cardDataFromPotable = adaptMonitoringResearchToCardData(
        report.potable_research,
        'potable',
      );
      ids.push(
        ...cardDataFromSurface.idsFromData,
        ...cardDataFromPotable.idsFromData,
      );
      return {
        surfaceResearch: cardDataFromSurface.cardData,
        potableResearch: cardDataFromPotable.cardData,
      };
    });

    return { cards, ids };
  }

  return { cards: [], ids: [] };
}

export function fetchRecommendedReagentsAdapter(
  reagentsQueues: BackendRecommendedReagentsQueuesDataType[],
): ModalReagentDataType[] {
  const tempReagentsList = {};

  reagentsQueues?.forEach((queue: BackendRecommendedReagentsQueuesDataType) => {
    const { id, max_value: maxValue, min_value: minValue, name, value } = queue;

    const QUEUE_NUMBER = 1;

    const queues: ModalQueueDataType = tempReagentsList[id]?.queues
      ? {
          ...tempReagentsList[id].queues,
          [QUEUE_NUMBER]: {
            value: value || 0,
            recommendedValue: value || 0,
            isRecommended: true,
            minValue,
            maxValue,
            isErrorShown: false,
          },
        }
      : {
          [QUEUE_NUMBER]: {
            value: value || 0,
            recommendedValue: value || 0,
            isRecommended: true,
            minValue,
            maxValue,
            isErrorShown: false,
          },
        };

    tempReagentsList[id] = {
      id,
      name,
      queues,
    };
  });

  const reagentsList: ModalReagentDataType[] = Object.keys(
    tempReagentsList,
  ).map((reagentKey) => tempReagentsList[reagentKey]);

  return reagentsList;
}

export function revertAddDoseParamsAdapter(
  params: AddDoseParamsType,
): BackendAddDoseParamsType {
  const { researchId, comment, performance, datetime, reagents } = params;

  const queuesList: BackendAddDoseReagentDataType[] = [];

  reagents?.forEach((reagent: ModalReagentDataType) => {
    const { id, queues } = reagent;
    Object.keys(queues)?.forEach((queueKey) => {
      const reagentValue: BackendAddDoseReagentDataType = {
        id,
        value: Number(queues[queueKey].value),
        queue_number: Number(queueKey),
      };
      queuesList.push(reagentValue);
    });
  });

  const commentValue: string = comment || '';

  return {
    research_id: researchId,
    comment: commentValue,
    performance,
    datetime,
    reagents: queuesList,
  };
}

export function revertEditDoseParamsAdapter(
  params: EditDoseParamsType,
): BackendEditDoseParamsType {
  const { doseId, comment, performance, datetime, reagents } = params;

  const queuesList: BackendAddDoseReagentDataType[] = [];

  reagents?.forEach((reagent: ModalReagentDataType) => {
    const { id, queues } = reagent;
    Object.keys(queues)?.forEach((queueKey) => {
      const reagentValue: BackendAddDoseReagentDataType = {
        id,
        value: Number(queues[queueKey].value),
        queue_number: Number(queueKey),
      };
      queuesList.push(reagentValue);
    });
  });

  const commentValue: string = comment || '';

  return {
    dose_id: doseId,
    comment: commentValue,
    performance,
    datetime,
    reagents: queuesList,
  };
}

export function adaptMonitoringReportById(
  report: BackendMonitoringReportByIdDataType,
  reportDate: string,
  recommendedDoses?: BackendRecommendedReagentsQueuesDataType[],
): MonitoringCardByIdType {
  const {
    id,
    classifiers: classifiersList = [],
    comment,
    reagents: reagentsList = [],
    dose_id: doseId,
    performance,
  } = report;

  const { classifiers, graphData } = getClassifiersData(
    classifiersList,
    reportDate,
  );

  const reagents: ReagentValueDataType[] = getReagentsData(
    reagentsList,
    recommendedDoses,
  );

  return {
    id,
    doseId,
    comment,
    classifiers,
    reagents,
    graphData,
    performance,
  };
}
