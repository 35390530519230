import { OptionType } from '../../Components/Select/Select.interface';
import { RegistrationRequestParamsType } from '../../Store/types';

export enum PageTypes {
  Login = 'login',
  ChangePassword = 'changePassword',
  Register = 'register',
}

export type FormValuesType = {
  [key: string]: string;
};
export type ValidatedFormValuesType = {
  [key: string]: {
    value: string;
    isErrorShown: boolean;
    errorMessage: string;
    isRequired: boolean;
  };
};

export type PageAuthProps = {
  pageType: PageTypes;
};

export type LoginFormProps = {
  onLinkClick: () => void;
  pageType: PageTypes;
};

export type RegisterFormProps = {
  onLinkClick: () => void;
  waterCanalsOptionsList: OptionType[];
  onRequestSubmit: (params: RegistrationRequestParamsType) => void;
};

export type ChangePasswordRequestModalProps = {
  title: string;
  onSubmit: (login: string, email: string) => void;
  onClose: () => void;
};
export type ChangePasswordModalProps = {
  onSubmit: (password: string) => void;
  onClose: () => void;
};

export type PasswordValidationDataType = {
  hasEightSymbols: boolean;
  hasDigits: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasSpecialSymbols: boolean;
};
