import React from 'react';

import styles from './Skeleton.module.scss';

function SkeletonPageHeader() {
  return (
    <div className={styles.skeleton_page_header}>
      <div className={styles.header_content}>
        <div className={styles.skeleton_line} />
        <div className={styles.skeleton_line} />
      </div>
      <div className={styles.header_content}>
        <div className={styles.skeleton_line} />
        <div className={styles.skeleton_line} />
        <div className={styles.skeleton_line} />
      </div>
    </div>
  );
}

export default SkeletonPageHeader;
