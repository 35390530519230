import React from 'react';

import { PanelHeaderProps } from '../PageLaboratoryTests.interface';

import styles from '../PageLaboratoryTests.module.scss';

function PanelHeader(props: PanelHeaderProps) {
  const { columnsList, tableColumnsList } = props;

  const panelColumns = columnsList.map((column: string, index) => (
    <div className={styles.header_column} key={index}>
      <div className={styles.header_column_title}>
        {tableColumnsList[index]}
      </div>
      <div>{column}</div>
    </div>
  ));

  return <div className={styles.panel_header}>{panelColumns}</div>;
}

export default PanelHeader;
