import {
  checkIsEmailValid,
  checkIsNameValid,
  checkIsPasswordConfirmValid,
  checkIsPasswordValid,
  checkIsPhoneNumberValid,
} from '../../../Utils/checkIsValueValid';
import { RegisterFieldsNamesList } from '../constants';

export function checkIsValueValid(
  value: string,
  fieldName: RegisterFieldsNamesList,
  valueConfirm?: string,
) {
  let isValueValid = true;

  if (value === '') {
    return isValueValid;
  }

  switch (fieldName) {
    case RegisterFieldsNamesList.FirstName:
    case RegisterFieldsNamesList.LastName:
    case RegisterFieldsNamesList.SecondName:
      isValueValid = checkIsNameValid(value);
      break;
    case RegisterFieldsNamesList.Email:
      isValueValid = checkIsEmailValid(value);
      break;
    case RegisterFieldsNamesList.Phone:
      isValueValid = checkIsPhoneNumberValid(value);
      break;
    case RegisterFieldsNamesList.Password:
      isValueValid = checkIsPasswordValid(value);
      break;
    case RegisterFieldsNamesList.PasswordConfirm:
      isValueValid = checkIsPasswordConfirmValid(value, valueConfirm);
      break;
    default:
      break;
  }

  return isValueValid;
}
