import { AxiosError, AxiosResponse } from 'axios';
import { createEffect, createEvent, createStore } from 'effector';

import { fetchReagentsListAdapter } from '../Adapters/fetchReagentsListAdapter';
import { BackendReagentDataType } from '../Adapters/types';
import {
  addReagent,
  addReagentDocument,
  changeReagent,
  changeReagentDocument,
  deleteReagent,
  deleteReagentDocument,
  getReagentDocument,
  getReagentsList,
} from '../DataAccessLayer/apiServices';
import {
  ChangeReagentDocumentParamsType,
  CreateAddReagentParamsType,
  DeleteModalDataType,
  EditCreateModalDataType,
  ModalModes,
  ReagentDataType,
} from '../Pages/PageReagents/PageReagents.interface';
import { downloadFileWithUrl } from '../Utils/downloadFile';
import handleError from '../Utils/handleError';

import { setIsLoading } from './loadingStore';
import { ResponseType } from './types';

const resetReagentsTable = createEvent();

const getReagentsListFx = createEffect<void, ReagentDataType[], Error>();
const getReagentsListAction = async () => {
  setIsLoading(true);

  const reagentsData: BackendReagentDataType[] = (
    await getReagentsList()
      .catch((error: AxiosError) => {
        handleError({ error, request: getReagentsListFx });

        return error.response;
      })
      .finally(() => {
        setIsLoading(false);
      })
  ).data;

  const adaptedReagentsData: ReagentDataType[] =
    fetchReagentsListAdapter(reagentsData);

  return adaptedReagentsData;
};
getReagentsListFx.use(getReagentsListAction);

const setReagents = createEvent<ReagentDataType[]>();
const $reagents = createStore<ReagentDataType[]>([])
  .on(setReagents, (_, reagentsList: ReagentDataType[]) => reagentsList)
  .on(
    getReagentsListFx.done,
    (_, response: ResponseType<ReagentDataType[]>) => response.result,
  )
  .reset(resetReagentsTable);

export const defaultEditCreateModalData: EditCreateModalDataType = {
  isShown: false,
  modalMode: ModalModes.Create,
  reagentData: {
    id: null,
    name: '',
    units: null,
    cost: null,
    description: '',
    fileName: '',
  },
};

const resetEditCreateModalData = createEvent();
const setEditCreateModalData = createEvent<EditCreateModalDataType>();
const $editCreateModalData = createStore<EditCreateModalDataType>(
  defaultEditCreateModalData,
)
  .on(
    setEditCreateModalData,
    (_, editCreateModalData: EditCreateModalDataType) => editCreateModalData,
  )
  .reset(resetEditCreateModalData);

export const defaultDeleteModalData: DeleteModalDataType = {
  isShown: false,
  id: null,
  name: '',
};

const resetDeleteModalData = createEvent();
const setDeleteModalData = createEvent<DeleteModalDataType>();
const $deleteModalData = createStore<DeleteModalDataType>(
  defaultDeleteModalData,
)
  .on(
    setDeleteModalData,
    (_, deleteModalData: DeleteModalDataType) => deleteModalData,
  )
  .reset(resetDeleteModalData);

const getReagentDocumentFx = createEffect<number, void, Error>();

const getReagentDocumentAction = async (reagentId: number) => {
  await getReagentDocument(reagentId)
    .then((response) => {
      const { filename, url } = response.data;
      downloadFileWithUrl(url, filename);
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: getReagentDocumentFx,
        params: reagentId,
        shouldShowToast: true,
        toastMessage: 'Не удалось скачать опросный лист',
      });
    });
};
getReagentDocumentFx.use(getReagentDocumentAction);

const deleteReagentFx = createEffect<number, void, Error>();
const deleteReagentAction = async (reagentId: number) => {
  await deleteReagent(reagentId)
    .then(() => {
      getReagentsListFx();
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: deleteReagentFx,
        params: reagentId,
        shouldShowToast: true,
        toastMessage: 'Не удалось удалить реагент',
      });
    });
};
deleteReagentFx.use(deleteReagentAction);

const changeReagentDocumentFx = createEffect<
  ChangeReagentDocumentParamsType,
  void,
  Error
>();

const changeReagentDocumentAction = async (
  params: ChangeReagentDocumentParamsType,
) => {
  await changeReagentDocument(params)
    .then(() => {
      getReagentsListFx();
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: changeReagentDocumentFx,
        params,
        shouldShowToast: true,
        toastMessage: 'Не удалось изменить опросный лист',
      });
    });
};

changeReagentDocumentFx.use(changeReagentDocumentAction);

const deleteReagentDocumentFx = createEffect<number, void, Error>();

const deleteReagentDocumentAction = async (id: number) => {
  await deleteReagentDocument(id)
    .then(() => {
      getReagentsListFx();
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: changeReagentDocumentFx,
        params: id,
        shouldShowToast: true,
        toastMessage: 'Не удалось удалить опросный лист',
      });
    });
};

deleteReagentDocumentFx.use(deleteReagentDocumentAction);

const addReagentDocumentFx = createEffect<
  ChangeReagentDocumentParamsType,
  void,
  Error
>();

const addReagentDocumentAction = async (
  params: ChangeReagentDocumentParamsType,
) => {
  await addReagentDocument(params)
    .then(() => {
      getReagentsListFx();
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: addReagentDocumentFx,
        params,
        shouldShowToast: true,
        toastMessage: 'Не удалось добавить опросный лист',
      });
    });
};

addReagentDocumentFx.use(addReagentDocumentAction);

const addReagentFx = createEffect<CreateAddReagentParamsType, void, Error>();
const addReagentAction = async (params: CreateAddReagentParamsType) => {
  await addReagent(params)
    .then((response: AxiosResponse) => {
      const { data } = response;
      const { id: reagentId } = data;
      const { file } = params;
      if (file) {
        addReagentDocumentFx({ id: reagentId, file });
      } else {
        getReagentsListFx();
      }
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: addReagentFx,
        params,
        shouldShowToast: true,
        toastMessage: 'Не удалось добавить новый реагент',
      });
    });
};

addReagentFx.use(addReagentAction);

const changeReagentFx = createEffect<CreateAddReagentParamsType, void, Error>();
const changeReagentAction = async (params: CreateAddReagentParamsType) => {
  await changeReagent(params)
    .then(() => {
      getReagentsListFx();
    })
    .catch((error: AxiosError) => {
      handleError({
        error,
        request: changeReagentFx,
        params,
        shouldShowToast: true,
        toastMessage: 'Не удалось изменить реагент',
      });
    });
};

changeReagentFx.use(changeReagentAction);

export {
  $deleteModalData,
  $editCreateModalData,
  $reagents,
  addReagentDocumentFx,
  addReagentFx,
  changeReagentDocumentFx,
  changeReagentFx,
  deleteReagentDocumentFx,
  deleteReagentFx,
  getReagentDocumentFx,
  getReagentsListFx,
  resetDeleteModalData,
  resetEditCreateModalData,
  setDeleteModalData,
  setEditCreateModalData,
};
