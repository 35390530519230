import { FormValuesType, ValidatedFormValuesType } from './PageAuth.interface';

const wrongEmailFormatErrorMessage: string = 'Неверный формат почтового адреса';

const wrongPhoneFormatErrorMessage: string = 'Неверный формат номера телефона';
const wrongPasswordFormatErrorMessage: string = 'Неверный формат пароля';
const wrongPasswordConfirmErrorMessage: string =
  'Введенные пароли не совпадают';

export const defaultResetPasswordRequestFormValues: ValidatedFormValuesType = {
  login: {
    value: '',
    isErrorShown: false,
    errorMessage: '',
    isRequired: true,
  },
  email: {
    value: '',
    isErrorShown: false,
    errorMessage: wrongEmailFormatErrorMessage,
    isRequired: true,
  },
};
export const defaultResetPasswordFormValues: ValidatedFormValuesType = {
  password: {
    value: '',
    isErrorShown: false,
    errorMessage: wrongPasswordFormatErrorMessage,
    isRequired: true,
  },
  passwordConfirm: {
    value: '',
    isErrorShown: false,
    errorMessage: wrongPasswordConfirmErrorMessage,
    isRequired: true,
  },
};

export const defaultLoginFormValues: FormValuesType = {
  login: '',
  password: '',
};
export const defaultRegisterFormValues: ValidatedFormValuesType = {
  lastName: {
    value: '',
    isErrorShown: false,
    errorMessage: '',
    isRequired: true,
  },
  firstName: {
    value: '',
    isErrorShown: false,
    errorMessage: '',
    isRequired: true,
  },
  secondName: {
    value: '',
    isErrorShown: false,
    errorMessage: '',
    isRequired: false,
  },
  phone: {
    value: '',
    isErrorShown: false,
    errorMessage: wrongPhoneFormatErrorMessage,
    isRequired: true,
  },
  email: {
    value: '',
    isErrorShown: false,
    errorMessage: wrongEmailFormatErrorMessage,
    isRequired: true,
  },
};
export enum RegisterFieldsNamesList {
  LastName = 'lastName',
  FirstName = 'firstName',
  SecondName = 'secondName',
  Phone = 'phone',
  Email = 'email',
  Login = 'login',
  Password = 'password',
  PasswordConfirm = 'passwordConfirm',
}
export const RegisterFieldsNames = new Map([
  ['lastName', 'Фамилия'],
  ['firstName', 'Имя'],
  ['secondName', 'Отчество'],
  ['phone', 'Номер телефона'],
  ['email', 'Электронная почта'],
  ['login', 'Логин'],
  ['password', 'Новый пароль'],
  ['passwordConfirm', 'Подтвердите новый пароль'],
]);

export const ignoreSymbolsList: string[] = ['Backspace', 'Tab', 'Alt', 'Shift'];

export const changePasswordRequestModalTitle =
  'Введите логин и почту, указанную при регистрации, для получения ссылки на восстановление пароля';

export const changePasswordRequestWrongTokenModalTitle =
  'Истек срок годности токена. Введите логин и почту, указанную при регистрации, для получения новой ссылки на восстановление пароля';
