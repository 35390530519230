import React from 'react';
import clsx from 'clsx';

import Icon from '../Icon/Icon';

import { IconWarningSectionProps } from './IconWarningSection.interface';

import styles from './IconWarningSection.module.scss';

function IconWarningSection(props: IconWarningSectionProps) {
  const { iconName, message, className } = props;

  return (
    <div className={clsx(styles.icon_warning_section_wrapper, className)}>
      <div className={styles.icon_warning_section}>
        <Icon name={iconName} className={styles.icon} />
        <div className={styles.message}>{message}</div>
      </div>
    </div>
  );
}

export default IconWarningSection;
