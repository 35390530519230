import React from 'react';
import clsx from 'clsx';

import {
  ChangeTypes,
  CustomHeaderProps,
  DatePickerCalendarView,
  DatePickerTypes,
} from '../DatePicker.interface';

import HeaderMonths from './HeaderMonths';
import HeaderYears from './HeaderYears';

import styles from '../DatePicker.module.scss';

function CustomHeader(props: CustomHeaderProps) {
  const {
    increaseMonth,
    decreaseMonth,
    decreaseYear,
    increaseYear,
    date,
    handleMonthClick,
    handleYearClick,
    calendarView,
    type,
  } = props;

  const handleMonthYearChange = (changeType: ChangeTypes) => {
    if (calendarView === DatePickerCalendarView.Years) {
      if (changeType === ChangeTypes.Increment) {
        increaseYear();
      } else {
        decreaseYear();
      }
    } else if (changeType === ChangeTypes.Increment) {
      increaseMonth();
    } else {
      decreaseMonth();
    }
  };

  const handleIncreaseClick = () => {
    handleMonthYearChange(ChangeTypes.Increment);
  };

  const handleDecreaseClick = () => {
    handleMonthYearChange(ChangeTypes.Decrement);
  };

  const getHeaderContent = () => {
    if (type === DatePickerTypes.Year) {
      return (
        <HeaderYears
          date={date}
          handleDecreaseClick={handleDecreaseClick}
          handleIncreaseClick={handleIncreaseClick}
        />
      );
    }

    return (
      <HeaderMonths
        date={date}
        calendarView={calendarView}
        handleDecreaseClick={handleDecreaseClick}
        handleIncreaseClick={handleIncreaseClick}
        handleMonthClick={handleMonthClick}
        handleYearClick={handleYearClick}
      />
    );
  };

  const headerClassName = clsx(styles.custom_header, {
    [styles.year_picker_header]: type === DatePickerTypes.Year,
  });

  return <div className={headerClassName}>{getHeaderContent()}</div>;
}

export default CustomHeader;
