import Button from '../Button';

import { PromptPropsType } from './Prompt.interface';

import styles from './Prompt.module.scss';

function Prompt({
  message,
  onSubmitButtonClick,
  submitButtonLabel,
  cancelButtonLabel,
  onCancelButtonClick,
}: PromptPropsType) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.message}>{message}</div>
      <div className={styles.buttons}>
        <Button onClick={onSubmitButtonClick}>{submitButtonLabel}</Button>
        <Button isSecondary onClick={onCancelButtonClick}>
          {cancelButtonLabel}
        </Button>
      </div>
    </div>
  );
}

export default Prompt;
