import React, { useMemo } from 'react';
import clsx from 'clsx';

import Button from '../Button';

import { ButtonsSwitcherProps } from './ButtonsSwitcher.interface';

import styles from './ButtonsSwitcher.module.scss';

function ButtonsSwitcher(props: ButtonsSwitcherProps) {
  const { activeButtonValue, buttons, onButtonClick, className } = props;

  const buttonsList = useMemo(
    () =>
      buttons.map((button) => {
        const { children, value } = button;

        const handleButtonClick = () => {
          onButtonClick(value);
        };

        return (
          <Button
            key={value}
            onClick={handleButtonClick}
            className={clsx(styles.switcher_button, {
              [styles.switcher_button_active]: value === activeButtonValue,
            })}
          >
            {children}
          </Button>
        );
      }),
    [buttons, activeButtonValue, onButtonClick],
  );

  return (
    <div className={clsx(styles.switcher_container, className)}>
      {buttonsList}
    </div>
  );
}

export default ButtonsSwitcher;
