import React, { useState } from 'react';

import Button from '../../../Components/Button';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton';
import Modal from '../../../Components/Modal';
import { PageAdminModalConfirmProps } from '../PageAdmin.interface';

import styles from '../PageAdmin.module.scss';

function PageAdminModalConfirm(props: PageAdminModalConfirmProps) {
  const { name, onConfirm, onClose } = props;

  const [isModalToBeClosed, setIsModalToBeClosed] = useState<boolean>(false);

  const handleModalClose = () => {
    setIsModalToBeClosed(false);
    onClose();
  };

  const handleCloseButtonClick = () => {
    setIsModalToBeClosed(true);
  };

  const handleConfirmButtonClick = () => {
    setIsModalToBeClosed(true);
    onConfirm();
  };

  return (
    <Modal
      isToBeClosed={isModalToBeClosed}
      onClose={handleModalClose}
      className={styles.confirm_modal}
    >
      <div className={styles.confirm_modal_wrapper}>
        <div className={styles.confirm_modal_header}>
          <div className={styles.confirm_modal_title}>
            Вы уверены, что хотите удалить аккаунт пользователя {name}?
          </div>
          <IconButton
            iconName={IconsNames.Cross}
            className={styles.modal_cross_icon_button}
            isClear
            onClick={handleCloseButtonClick}
          />
        </div>
        <div className={styles.modal_buttons_section}>
          <Button
            onClick={handleCloseButtonClick}
            className={styles.cancel_button}
            isSecondary
          >
            Отменить
          </Button>
          <Button
            onClick={handleConfirmButtonClick}
            className={styles.confirm_button}
          >
            Удалить
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default PageAdminModalConfirm;
