import React, {
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useXarrow, Xwrapper } from 'react-xarrows';
import clsx from 'clsx';
import { useStore } from 'effector-react';

import MonitoringTable from '../../../Components/Accordion/MonitoringTable';
import { IconsNames } from '../../../Components/Icon/constants';
import IconWarningSection from '../../../Components/IconWarningSection';
import { ToastTypes } from '../../../Components/Toast/Toast.interface';
import PageMonitoringReagentsModalContainer from '../../../Containers/PageMonitoringContainers/PageMonitoringReagentsModalContainer';
import { getMonitoringReportById } from '../../../DataAccessLayer/apiServices';
import { StatusesList } from '../../../Static/statusesList';
import { $userRoles } from '../../../Store/authStore';
import {
  $offset,
  getRecommendedReagentsFx,
  requestReportDetailsDataById,
} from '../../../Store/monitoringPageStore';
import { setToastData } from '../../../Store/toastStore';
import { RolesList } from '../../PageAdmin/PageAdmin.interface';
import { deleteInputDataDefault } from '../constants';
import {
  DeleteInputData,
  MonitoringCardDataType,
  PageMonitoringTableProps,
} from '../PageMonitoring.interface';

import PageMonitoringDeleteModal from './PageMonitoringDeleteModal';
import PageMonitoringPanelBody from './PageMonitoringPanelBody';
import PageMonitoringPanelHeader from './PageMonitoringPanelHeader';

import styles from '../PageMonitoring.module.scss';

function PageMonitoringTable(props: PageMonitoringTableProps) {
  const {
    cards,
    openPanelsIds,
    isShowMoreButtonVisible,
    onInputDelete,
    onShowMoreButtonClick,
    onOpenPanelsIdsListChange,
    onReagentsAdd,
    onReagentsSkip,
    isLoading,
  } = props;

  const currentUserRoles: number[] = useStore($userRoles);
  const updateXarrow = useXarrow();

  const isDeleteButtonShown: boolean = useMemo(
    () => currentUserRoles.includes(RolesList.TECHNOLOGIST),
    [currentUserRoles],
  );
  const [deleteInputData, setDeleteInputData] = useState<DeleteInputData>(
    deleteInputDataDefault,
  );
  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  const handleDeleteButtonClick = (id: number, researchId: number) => {
    setDeleteInputData({ isModalShown: true, id, researchId });
  };
  const handleResearchDeleteModalClose = () => {
    setDeleteInputData(deleteInputDataDefault);
  };
  const handleResearchDeleteModalConfirm = () => {
    onInputDelete(deleteInputData.id, deleteInputData.researchId);
  };
  const tableBodyRef: MutableRefObject<HTMLDivElement> = useRef(null);
  const offset = useStore($offset);

  const handleEditButtonClick = (id: number) => {
    getMonitoringReportById(id).then((response) => {
      if (response.data.dose_id !== null) {
        getRecommendedReagentsFx(id).then(() => {
          requestReportDetailsDataById(id);
        });
        setIsModalShown(true);
      } else {
        setToastData({
          toastType: ToastTypes.Info,
          title: 'Редактирование недоступно',
          message:
            'Ранее не была введена доза, следовательно нет возможности редактирования',
          isShown: true,
          closeDelay: 5000,
        });
      }
    });
  };

  const getPanelsFromCardsData = (card: MonitoringCardDataType) => {
    const {
      id,
      doseId,
      datetime,
      author,
      status,
      intermediates,
      inputType,
      isDisabled,
      name,
      subType,
    } = card;
    const hasIntermediates: boolean = intermediates?.length !== 0;
    const isToBeFilled: boolean =
      status === StatusesList.ToBeFilled && !hasIntermediates;
    const headerContent: JSX.Element = (
      <PageMonitoringPanelHeader
        datetime={datetime}
        author={author}
        status={status}
        inputType={inputType}
        name={name}
        subType={subType}
      />
    );
    const bodyContent: JSX.Element = (
      <PageMonitoringPanelBody
        cardData={card}
        isToBeFilled={isToBeFilled}
        onReagentsAdd={onReagentsAdd}
        onReagentsSkip={onReagentsSkip}
        inputType={inputType}
      />
    );
    const mainPanel = {
      id,
      isDisabled,
      doseId,
      headerContent,
      bodyContent,
      status,
    };

    const subPanels = hasIntermediates
      ? intermediates.map((intermediate) => {
          const {
            id: subPanelId,
            doseId: subPanelDoseId,
            datetime: subPanelDateTime,
            author: subPanelAuthor,
            status: subPanelStatus,
            inputType: subPanelInputType,
            isDisabled: isSubPanelDisabled,
            name: subPanelName,
            subType: subPanelSubType,
          } = intermediate;

          const isSubPanelToBeFilled: boolean =
            subPanelStatus === StatusesList.ToBeFilled;
          const subPanelHeaderContent: JSX.Element = (
            <PageMonitoringPanelHeader
              datetime={subPanelDateTime}
              author={subPanelAuthor}
              status={subPanelStatus}
              inputType={subPanelInputType}
              name={subPanelName}
              subType={subPanelSubType}
            />
          );
          const subPanelBodyContent: JSX.Element = (
            <PageMonitoringPanelBody
              inputType={subPanelInputType}
              cardData={intermediate}
              isToBeFilled={isSubPanelToBeFilled}
              onReagentsAdd={onReagentsAdd}
              onReagentsSkip={onReagentsSkip}
            />
          );
          const subPanel = {
            id: subPanelId,
            doseId: subPanelDoseId,
            headerContent: subPanelHeaderContent,
            bodyContent: subPanelBodyContent,
            status: subPanelStatus,
            isDisabled: isSubPanelDisabled,
          };

          return subPanel;
        })
      : [];
    return { mainPanel, subPanels };
  };

  const panelsList = cards.map((item) => {
    const { potableResearch, surfaceResearch } = item;
    return {
      potable:
        potableResearch !== null
          ? getPanelsFromCardsData(potableResearch)
          : null,
      surface: getPanelsFromCardsData(surfaceResearch),
    };
  });

  const isWarningShown: boolean = useMemo(() => cards?.length === 0, [cards]);

  useEffect(() => {
    if (tableBodyRef.current && offset !== 0) {
      tableBodyRef.current.scrollTo({
        top: tableBodyRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [cards.length]);

  const handleScroll = () => {
    updateXarrow();
  };

  useLayoutEffect(() => {
    updateXarrow();
    if (tableBodyRef.current)
      tableBodyRef.current.addEventListener('scroll', handleScroll);
    document.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      if (tableBodyRef.current)
        tableBodyRef.current.removeEventListener('scroll', handleScroll);
      document.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [cards, openPanelsIds, tableBodyRef.current !== null]);

  if (isWarningShown) {
    return (
      <IconWarningSection
        iconName={IconsNames.Note}
        className={styles.no_data_warning}
        message="Исследования не найдены"
      />
    );
  }

  return (
    <div className={styles.table_wrapper}>
      <div className={styles.table_body_wrapper} ref={tableBodyRef}>
        <Xwrapper>
          <MonitoringTable
            panels={panelsList}
            openPanelsIds={openPanelsIds}
            onOpenPanelsIdsListChange={onOpenPanelsIdsListChange}
            isDeleteActive={isDeleteButtonShown}
            onDelete={handleDeleteButtonClick}
            onEdit={handleEditButtonClick}
            isLoading={isLoading}
          />
        </Xwrapper>
        <div
          className={clsx(styles.button_wrapper, {
            [styles.button_wrapper_small]: !isShowMoreButtonVisible,
          })}
        >
          {isShowMoreButtonVisible && (
            <button
              type="button"
              className={styles.show_more_button}
              onClick={onShowMoreButtonClick}
            >
              Показать ещё
            </button>
          )}
        </div>
      </div>
      {deleteInputData.isModalShown && (
        <PageMonitoringDeleteModal
          onClose={handleResearchDeleteModalClose}
          onConfirm={handleResearchDeleteModalConfirm}
          id={deleteInputData.id}
        />
      )}
      {isModalShown && (
        <PageMonitoringReagentsModalContainer
          onClose={() => setIsModalShown(false)}
          isInEditMode
        />
      )}
    </div>
  );
}

export default PageMonitoringTable;
