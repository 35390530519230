import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

import Icon from '../../Icon';
import { IconsNames } from '../../Icon/constants';

import styles from '../Select.module.scss';

function DropdownIndicator(props: DropdownIndicatorProps) {
  const {
    innerProps,
    isFocused,
    isDisabled,
    clearValue,
    cx,
    getStyles,
    getClassNames,
    getValue,
    hasValue,
    isMulti,
    isRtl,
    options,
    selectOption,
    selectProps,
    setValue,
    theme,
  } = props;

  return (
    <components.DropdownIndicator
      innerProps={innerProps}
      isFocused={isFocused}
      isDisabled={isDisabled}
      clearValue={clearValue}
      cx={cx}
      getStyles={getStyles}
      getClassNames={getClassNames}
      getValue={getValue}
      hasValue={hasValue}
      isMulti={isMulti}
      isRtl={isRtl}
      options={options}
      selectOption={selectOption}
      selectProps={selectProps}
      setValue={setValue}
      theme={theme}
    >
      <Icon name={IconsNames.Chevron} className={styles.chevron} />
    </components.DropdownIndicator>
  );
}

export default DropdownIndicator;
