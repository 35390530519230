import {
  Dispatch,
  LegacyRef,
  ReactNode,
  RefObject,
  SetStateAction,
} from 'react';

export type DatePickerProps = {
  selectedDate?: Date;
  startDate?: Date;
  endDate?: Date;
  type: DatePickerTypes;
  onSingleDateChange?: (date: Date) => void;
  onDateRangeChange?: (date: Array<Date>) => void;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
};

export type CustomInputProps = {
  ref?: LegacyRef<HTMLButtonElement>;
  onClick?: () => void;
  selectedDate: Date;
  startDate: Date;
  endDate: Date;
  type: DatePickerTypes;
  disabled?: boolean;
};

export type CustomHeaderProps = {
  date: Date;
  calendarView: DatePickerCalendarView;
  type: DatePickerTypes;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  decreaseYear: () => void;
  increaseYear: () => void;
  handleMonthClick: () => void;
  handleYearClick: () => void;
};

export type HeaderMonthsProps = {
  calendarView: DatePickerCalendarView;
  date: Date;
  handleYearClick: () => void;
  handleMonthClick: () => void;
  handleDecreaseClick: () => void;
  handleIncreaseClick: () => void;
};

export type HeaderYearsProps = {
  date: Date;
  handleDecreaseClick: () => void;
  handleIncreaseClick: () => void;
};

export type CustomHeaderRenderProps = {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  decreaseYear: () => void;
  increaseYear: () => void;
};

export type CalendarContainerProps = {
  children: ReactNode;
  className?: string;
  calendarView: DatePickerCalendarView;
};

export type CustomTimeInputProps = {
  onTimeChange?: (value: Date) => void;
  value: Date;
  minTimePickerDate?: string;
  maxTimePickerDate?: string;
};

export type DatePickerRef = RefObject<{
  setOpen: Dispatch<SetStateAction<boolean>>;
}>;

export const WeekDaysShort = new Map([
  [1, 'Пн'],
  [2, 'Вт'],
  [3, 'Ср'],
  [4, 'Чт'],
  [5, 'Пт'],
  [6, 'Сб'],
  [0, 'Вс'],
]);

export enum DatePickerTypes {
  SingleDate = 'singleDate',
  SingleDateTime = 'singleDateTime',
  DateRange = 'dateRange',
  MonthYear = 'monthYear',
  Year = 'year',
}

export enum DatePickerCalendarView {
  Days = 'days',
  Months = 'months',
  Years = 'years',
}

export enum DateFormats {
  ddMMyyyy = 'dd.MM.yyyy',
  ddMMyyyyHHmm = 'dd.MM.yyyy  HH:mm',
  yyyy_MM_dd = 'yyyy-MM-dd',
  ddMM = 'dd.MM',
  HHmm = 'HH:mm',
  HHmmss = 'HH:mm:ss',
}

export enum ChangeTypes {
  Increment = 'increment',
  Decrement = 'decrement',
}
