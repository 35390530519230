import React, { useEffect, useRef } from 'react';

import { CalendarContainerProps } from '../DatePicker.interface';

function CalendarContainer(props: CalendarContainerProps) {
  const { children, className, calendarView } = props;

  const containerRef = useRef(null);

  // crop months' names to desired format
  useEffect(() => {
    const monthsCollection = containerRef.current.querySelectorAll(
      '.react-datepicker__month-wrapper div',
    );
    monthsCollection.forEach((month) => {
      month.textContent = month.textContent.slice(0, 3);
    });
  }, [calendarView]);

  return (
    <div className={className} ref={containerRef}>
      {children}
    </div>
  );
}

export default CalendarContainer;
