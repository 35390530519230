import { forwardRef, LegacyRef } from 'react';

import Select from '../Select';

import { PropsType } from './MonitoringPredictionPicker.interface';

import styles from './MonitoringPredictionPicker.module.scss';

const MonitoringPredictionPicker = forwardRef(
  ({ queues }: PropsType, ref: LegacyRef<HTMLDivElement>) => {
    return (
      <div ref={ref} className={styles.wrapper}>
        <div className={styles.title}>Выбор коагулянта</div>
        <div className={styles.column}>
          {queues.map(({ number, onChange, options, value }, index) => (
            <div key={index} className={styles.item}>
              {`Очередь ${number}`}
              <Select options={options} value={value} onChange={onChange} />
            </div>
          ))}
        </div>
      </div>
    );
  },
);

MonitoringPredictionPicker.displayName = 'MonitoringPredictionPicker';

export default MonitoringPredictionPicker;
