import React from 'react';
import ReactSelect from 'react-select';
import clsx from 'clsx';

import DropdownIndicator from './components/DropdownIndicator';
import { SelectProps } from './Select.interface';

import styles from './Select.module.scss';

function Select(props: SelectProps) {
  const {
    options,
    value,
    onChange,
    isDisabled,
    placeholder,
    className,
    isSearchable = false,
    menuPlacement = 'bottom',
  } = props;

  return (
    <ReactSelect
      options={options}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
      className={clsx(styles.select, className)}
      classNamePrefix="select"
      unstyled
      isSearchable={isSearchable}
      components={{ DropdownIndicator }}
      placeholder={placeholder}
      noOptionsMessage={() => 'Пусто'}
      menuPlacement={menuPlacement}
    />
  );
}

export default Select;
