import { useEffect } from 'react';

type DivRef = HTMLDivElement | null;
type BtnRef = HTMLButtonElement | null;

/**
 * Хук
 * Используя useEffect, вешает на элемент, для которого указан ref, обработчик клика не по самому элементу
 *
 * @param targetRef - ref на элемент, для которого отслеживаем клик снаружи
 * @param btnRef - ref на элемент кнопки, по которому не происходит закрытия
 * @param onClickOutside - функция, исполняющаяся в случае клика снаружи
 * @returns {void}
 */

export function useHandleClickOutside(
  targetRef: { current: DivRef },
  onClickOutside: Function,
  btnRef: { current: DivRef | BtnRef },
) {
  return useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent) => {
      if (
        !targetRef?.current?.contains(target as Element) &&
        !btnRef?.current?.contains(target as Element)
      ) {
        onClickOutside();
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, []);
}
