import { forwardRef, LegacyRef } from 'react';

import Checkbox from '../Checkbox/Checkbox';
import { IconsNames } from '../Icon/constants';
import Icon from '../Icon/Icon';

import ModalProps from './Modal.interface';

import styles from './SettingsModal.module.scss';

const SettingsModal = forwardRef(
  (props: ModalProps, ref: LegacyRef<HTMLDivElement>) => {
    const { onSettingsChange, settings, canBeAllUnchecked = true } = props;

    const isOnlyOneCheckedLast =
      settings.reduce((accumulator, current) => {
        if (current.isActive) {
          accumulator += 1;
        }
        return accumulator;
      }, 0) === 1 && !canBeAllUnchecked;

    const INFO_TITLE = 'Должен остаться активным хотя бы один стандарт';

    return (
      <div className={styles.settings_wrapper} ref={ref}>
        <div className={styles.title}>Активные стандарты исследований</div>

        {settings.map(({ id, title, isActive }, index) => (
          <div
            key={index}
            className={styles.values_container}
            title={isOnlyOneCheckedLast && isActive ? INFO_TITLE : ''}
          >
            <Checkbox
              checked={isActive}
              className={styles.modal_warning_section_checkbox}
              onChange={() => onSettingsChange(id)}
              caption={title}
              disabled={isOnlyOneCheckedLast && isActive}
            />
          </div>
        ))}
        <div className={styles.info_container}>
          <Icon name={IconsNames.Info} />
          <div className={styles.info}>
            Активные стандарты будут доступны для выбора в соответствующем
            выпадающем списке в окне введения результатов исследований
          </div>
        </div>
      </div>
    );
  },
);

SettingsModal.displayName = 'SettingsModal';

export default SettingsModal;
