import { OptionType } from '../Components/Select/Select.interface';
import {
  CreateAddReagentParamsType,
  ReagentDataType,
} from '../Pages/PageReagents/PageReagents.interface';

import { BackendReagentDataType } from './types';

export function fetchReagentsListAdapter(
  reagentsList: BackendReagentDataType[],
): ReagentDataType[] {
  return reagentsList.map((reagent: BackendReagentDataType) => {
    const {
      id,
      name,
      description,
      cost: unitCost,
      spent_year: yearConsumption,
      spent_month: monthConsumption,
      spent_day: dayConsumption,
      measure_id: measureId,
      measure_name: measureName,
      document: fileName,
    } = reagent;

    const units: OptionType = {
      value: measureId,
      label: measureName,
    };

    return {
      id,
      name,
      description,
      unitCost,
      yearConsumption,
      monthConsumption,
      dayConsumption,
      fileName,
      units,
      file: null,
    };
  });
}

export function revertCreateAddReagentParamsAdapter(
  params: CreateAddReagentParamsType,
) {
  const { id, name, price, description, measureId } = params;
  return { id, name, price, description, measure_id: measureId };
}
