import React, { ChangeEvent, useMemo } from 'react';
import clsx from 'clsx';

import { IconsNames } from '../../../Components/Icon/constants';
import Icon from '../../../Components/Icon/Icon';
import Input from '../../../Components/Input';
import { InputTypes } from '../../../Components/Input/constants';
import {
  ModalReagentDataType,
  PageMonitoringModalReagentsFormProps,
} from '../PageMonitoring.interface';

import styles from '../PageMonitoring.module.scss';

function PageMonitoringModalReagentsForm(
  props: PageMonitoringModalReagentsFormProps,
) {
  const { reagents, isWarningShown, onReagentsDataChange } = props;

  const CURRENT_QUEUE_ID = 1;

  const handleReagentValueChange =
    (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const reagentsList: ModalReagentDataType[] = reagents.map(
        (reagent: ModalReagentDataType) => {
          const { id: reagentId, queues } = reagent;

          if (reagentId === id) {
            const { recommendedValue, minValue, maxValue } =
              queues[CURRENT_QUEUE_ID];
            const isErrorShown: boolean =
              Number(value) > maxValue * 2 || Number(value) < minValue / 2;
            const isNewValueRecommended: boolean =
              value.toString() === recommendedValue.toString();

            return {
              ...reagent,
              queues: {
                ...reagent.queues,
                [CURRENT_QUEUE_ID]: {
                  value,
                  isRecommended: isNewValueRecommended,
                  recommendedValue,
                  minValue,
                  maxValue,
                  isErrorShown,
                },
              },
            };
          }

          return reagent;
        },
      );
      onReagentsDataChange(reagentsList);
    };

  const handleReagentInputBlur = (id: number) => (event) => {
    const { value } = event.target;
    if (value === '') {
      const reagentsList: ModalReagentDataType[] = reagents.map(
        (reagent: ModalReagentDataType) => {
          const { id: reagentId, queues } = reagent;

          if (reagentId === id) {
            const { recommendedValue, minValue, maxValue, isErrorShown } =
              queues[CURRENT_QUEUE_ID];

            return {
              ...reagent,
              queues: {
                ...reagent.queues,
                [CURRENT_QUEUE_ID]: {
                  value: recommendedValue,
                  isRecommended: true,
                  recommendedValue,
                  minValue,
                  maxValue,
                  isErrorShown,
                },
              },
            };
          }

          return reagent;
        },
      );
      onReagentsDataChange(reagentsList);
    }
  };

  const reagentsRows: JSX.Element[] = useMemo(
    () =>
      reagents.map((reagent: ModalReagentDataType, index) => {
        const { id, name, queues } = reagent;
        const queueData = queues[CURRENT_QUEUE_ID];
        let reagentValueFormatted: string = null;
        let isValueRecommended: boolean = true;
        let isValueErrorShown: boolean = false;
        if (queueData) {
          const {
            value: reagentValue,
            isRecommended,
            isErrorShown,
          } = queueData;
          reagentValueFormatted = reagentValue.toString();
          isValueRecommended = isRecommended;
          isValueErrorShown = isErrorShown;
        }

        return (
          <div className={styles.reagent_form_row} key={`${id}-${index}`}>
            <div className={styles.reagent_form_row_name}>{name}</div>
            <div
              className={clsx(styles.reagent_form_row_input, {
                [styles.reagent_form_row_changed]: !isValueRecommended,
              })}
            >
              <Input
                type={InputTypes.Number}
                value={reagentValueFormatted}
                onChange={handleReagentValueChange(id)}
                onBlur={handleReagentInputBlur(id)}
                step={0.1}
                isErrorShown={isValueErrorShown && isWarningShown}
              />
            </div>
          </div>
        );
      }),
    [reagents, isWarningShown, CURRENT_QUEUE_ID],
  );

  return (
    <div className={styles.reagents_form_wrapper}>
      <div className={styles.reagents_form_header}>
        <div className={styles.reagents_form_title}>
          Расчетные активные дозы реагентов:
        </div>
      </div>
      <div className={styles.reagents_form_hint}>
        <Icon name={IconsNames.Info} className={styles.hint_icon} />
        <div className={styles.reagents_form_hint_message}>
          По умолчанию были установлены рекомендованные значения доз реагентов.
          В случае изменения данных значений, пожалуйста, заполните поле
          комментария
        </div>
      </div>
      <div className={styles.reagent_form_body}>{reagentsRows}</div>
    </div>
  );
}

export default PageMonitoringModalReagentsForm;
