import React from 'react';

import Card from '../../Components/Card/Card';
import PageAdminRequestsListContainer from '../../Containers/PageAdminContainers/PageAdminRequestsListContainer';
import PageAdminTableContainer from '../../Containers/PageAdminContainers/PageAdminTableContainer';

import styles from './PageAdmin.module.scss';

function PageAdmin() {
  return (
    <div className={styles.page_wrapper}>
      <Card className={styles.card}>
        <PageAdminTableContainer />
        <PageAdminRequestsListContainer />
      </Card>
    </div>
  );
}

export default PageAdmin;
