import {
  ChangeStandardParamsType,
  StandardClassifierDataType,
} from '../Pages/PageStandards/PageStandards.interface';

import {
  BackendChangeStandardClassifierType,
  BackendChangeStandardParamsType,
} from './types';

function revertChangeStandardAdapter(
  params: ChangeStandardParamsType,
): BackendChangeStandardParamsType {
  const { id, name, description, standardType, subType, classifiers } = params;

  const classifiersList: BackendChangeStandardClassifierType[] =
    classifiers.map((classifier: StandardClassifierDataType) => {
      const { id: classifierId, lsl, lcl, usl, ucl, isRequired } = classifier;

      return {
        id: classifierId,
        lsl: lsl ? +lsl : lsl,
        lcl: lcl ? +lcl : lcl,
        usl: usl ? +usl : usl,
        ucl: ucl ? +ucl : ucl,
        is_required: isRequired,
      };
    });

  return {
    id,
    name,
    description,
    standard_type: standardType,
    sub_type: subType,
    classifiers: classifiersList,
  };
}

export default revertChangeStandardAdapter;
