import format from 'date-fns/format';

import { DateFormats } from '../Components/DatePicker/DatePicker.interface';
import {
  AddResearchReportParamsType,
  EditResearchReportParamsType,
  ResearchReportsListParamsType,
} from '../Containers/ResearchReportsContainers/ResearchReportsContainers.interface';
import {
  intermediateWaterSubTypesList,
  ReportPropertyType,
  WaterTypes,
} from '../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';

import { BackendAddReportClassifierType } from './types';

export function revertResearchReportsParamsAdapter(
  params: ResearchReportsListParamsType,
) {
  const {
    dateStart,
    dateEnd,
    limit,
    offset,
    researchType,
    standardId,
    status,
    queueNumber,
  } = params;
  let subTypeValue = null;
  let researchTypeValue: WaterTypes = researchType;
  if (intermediateWaterSubTypesList.includes(researchType)) {
    researchTypeValue = WaterTypes.Intermediate;
    subTypeValue = researchType;
  }
  return {
    date_start: dateStart ? format(dateStart, DateFormats.yyyy_MM_dd) : null,
    date_end: dateStart ? format(dateEnd, DateFormats.yyyy_MM_dd) : null,
    limit,
    offset,
    research_type: researchTypeValue,
    sub_type: subTypeValue,
    standard_id: standardId,
    status,
    queue_number: queueNumber,
  };
}

export function revertAddResearchReportsParamsAdapter(
  params: AddResearchReportParamsType,
) {
  const {
    standardId,
    researchType,
    datetime,
    classifiers,
    externalClassifiers,
    queueNumber,
  } = params;
  let subTypeValue = null;
  let researchTypeValue: WaterTypes = researchType;
  if (intermediateWaterSubTypesList.includes(researchType)) {
    researchTypeValue = WaterTypes.Intermediate;
    subTypeValue = researchType;
  }
  const classifiersList: BackendAddReportClassifierType[] = [];
  Object.values(classifiers).forEach((classifier: ReportPropertyType) => {
    const { id: classifierId, value } = classifier;
    classifiersList.push({
      id: classifierId,
      value: Number(value),
      is_external: false,
    });
  });
  Object.values(externalClassifiers).forEach(
    (classifier: ReportPropertyType) => {
      const { id: classifierId, value } = classifier;
      classifiersList.push({
        id: classifierId,
        value: Number(value),
        is_external: true,
      });
    },
  );

  return {
    standard_id: standardId,
    research_type: researchTypeValue,
    sub_type: subTypeValue,
    datetime,
    classifiers: classifiersList,
    queue_number: queueNumber,
  };
}

export function revertEditResearchReportsParamsAdapter(
  params: EditResearchReportParamsType,
) {
  const { researchId, classifiers, externalClassifiers, datetime } = params;
  const classifiersList: BackendAddReportClassifierType[] = [];
  Object.values(classifiers).forEach((classifier: ReportPropertyType) => {
    const { id: classifierId, value } = classifier;
    classifiersList.push({
      id: classifierId,
      value: Number(value),
      is_external: false,
    });
  });
  Object.values(externalClassifiers).forEach(
    (classifier: ReportPropertyType) => {
      const { id: classifierId, value } = classifier;
      classifiersList.push({
        id: classifierId,
        value: Number(value),
        is_external: true,
      });
    },
  );

  return {
    research_id: researchId,
    classifiers: classifiersList,
    datetime,
  };
}
