import { CreateClassifierParamsType } from '../Store/types';

import { BackendCreateClassifierParamsType } from './types';

export function revertAddClassifierParamsAdapter(
  classifierData: CreateClassifierParamsType,
): BackendCreateClassifierParamsType {
  const { measureId, name, description } = classifierData;

  return {
    name,
    description,
    measure_id: measureId,
  };
}
