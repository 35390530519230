import React, { useMemo } from 'react';
import clsx from 'clsx';

import Icon from '../../../Components/Icon';
import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton';
import Popover from '../../../Components/Popover';
import {
  PopoverPlacements,
  PopoverTriggerEvents,
} from '../../../Components/Popover/constants';
import Select from '../../../Components/Select/Select';
import { OptionType } from '../../../Components/Select/Select.interface';
import {
  ClassifierDataType,
  StandardAddedClassifiersListProps,
} from '../PageStandards.interface';

import styles from '../PageStandards.module.scss';

function StandardAddedClassifiersList(
  props: StandardAddedClassifiersListProps,
) {
  const {
    classifiersList,
    classifiersIds,
    availableClassifiersOptionsList,
    onClassifiersListChange,
  } = props;

  const handleAddPropertyButtonClick = () => {
    const { value } = availableClassifiersOptionsList[0];
    const classifiersIdsList: number[] = classifiersIds.slice();
    classifiersIdsList.push(Number(value));
    onClassifiersListChange(classifiersIdsList);
  };

  const handleClassifierDelete = (classifierId: number) => () => {
    const classifiersIdsList: number[] = classifiersIds.filter(
      (classifierItem: number) => classifierItem !== classifierId,
    );
    onClassifiersListChange(classifiersIdsList);
  };

  const classifiersListRows: JSX.Element[] = useMemo(
    () =>
      classifiersList.map((classifier: ClassifierDataType) => {
        const { id, name, description, units } = classifier;
        const selectedClassifier: OptionType = {
          value: id,
          label: `${name}, ${units}`,
        };

        const handleClassifierOptionChange = (newClassifier: OptionType) => {
          const { value } = newClassifier;
          const newClassifierId: number = Number(value);

          if (newClassifierId !== id) {
            const classifiersIdsList: number[] = classifiersIds.filter(
              (classifierItem: number) => classifierItem !== id,
            );
            classifiersIdsList.push(newClassifierId);
            onClassifiersListChange(classifiersIdsList);
          }
        };

        return (
          <div className={styles.modal_classifiers_list_row} key={id}>
            <Select
              value={selectedClassifier}
              options={availableClassifiersOptionsList}
              onChange={handleClassifierOptionChange}
              className={styles.modal_classifier_select}
              menuPlacement="top"
            />
            {description && (
              <Popover
                placement={PopoverPlacements.Right}
                trigger={PopoverTriggerEvents.Hover}
                content={<div>{description}</div>}
              >
                <span className={styles.hint_icon}>
                  <Icon name={IconsNames.Help} />
                </span>
              </Popover>
            )}
            <IconButton
              iconName={IconsNames.Delete}
              onClick={handleClassifierDelete(id)}
              className={styles.delete_classifier_button}
              isClear
            />
          </div>
        );
      }),
    [classifiersList, classifiersIds, availableClassifiersOptionsList],
  );

  const isAddedClassifierButtonShown: boolean = useMemo(
    () => availableClassifiersOptionsList.length !== 0,
    [availableClassifiersOptionsList],
  );

  if (!isAddedClassifierButtonShown && classifiersListRows?.length === 0) {
    return null;
  }

  return (
    <div className={styles.modal_form_row}>
      <div
        className={clsx(
          styles.modal_form_row_title,
          styles.added_classifiers_list_title,
        )}
      >
        Дополнительные показатели:
        {isAddedClassifierButtonShown && (
          <button
            type="button"
            className={styles.modal_add_classifier_button}
            onClick={handleAddPropertyButtonClick}
          >
            + Добавить
          </button>
        )}
      </div>
      <div className={styles.modal_classifiers_list_wrapper}>
        {classifiersListRows}
      </div>
    </div>
  );
}

export default StandardAddedClassifiersList;
