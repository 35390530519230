import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';

import { OptionType } from '../../Components/Select/Select.interface';
import {
  IntermediateWaterSubTypes,
  WaterTypes,
} from '../../Pages/PageLaboratoryTests/PageLaboratoryTests.interface';
import StandardCreateModal from '../../Pages/PageStandards/components/StandardCreateModal';
import { ClassifierDataType } from '../../Pages/PageStandards/PageStandards.interface';
import { $classifiers, $classifiersData } from '../../Store/cataloguesStore';
import {
  $standardAddedClassifiersIdsList,
  $standardDefaultClassifiersList,
  $standardName,
  $standardType,
  createStandardFx,
  getRecommendedClassifiersFx,
  resetCreateStandardModal,
  setStandardAddedClassifiersIdsList,
  setStandardDefaultClassifiersList,
  setStandardName,
  setStandardType,
} from '../../Store/standardsPageStore';
import { CreateStandardParamsType } from '../../Store/types';

import { StandardsModalContainerProps } from './PageStandardsContainers.interface';

function StandardCreateModalContainer(props: StandardsModalContainerProps) {
  const { onClose } = props;

  const standardType: OptionType = useStore($standardType);
  const standardName: string = useStore($standardName);
  const standardDefaultClassifiersList: ClassifierDataType[] = useStore(
    $standardDefaultClassifiersList,
  );
  const standardAddedClassifiersIdsList: number[] = useStore(
    $standardAddedClassifiersIdsList,
  );
  const classifiersList: ClassifierDataType[] = useStore($classifiersData);
  const classifiersOptionsList: OptionType[] = useStore($classifiers);

  const handleModalConfirm = () => {
    const createStandardParams: CreateStandardParamsType = {
      standardType: standardType.value as
        | WaterTypes
        | IntermediateWaterSubTypes,
      standardName,
      classifiers: [],
    };

    createStandardParams.classifiers = standardAddedClassifiersIdsList.map(
      (classifierId: number) => {
        return { id: classifierId };
      },
    );

    standardDefaultClassifiersList.forEach((classifier: ClassifierDataType) => {
      const { id } = classifier;
      createStandardParams.classifiers.push({ id });
    });

    createStandardFx(createStandardParams);
  };
  const handleModalClose = () => {
    resetCreateStandardModal();
    onClose();
  };
  const handleStandardTypeChange = (type: OptionType) => {
    setStandardType(type);
  };
  const handleStandardNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setStandardName(value);
  };
  const handleStandardDefaultClassifiersListChange = (
    classifiers: ClassifierDataType[],
  ) => {
    setStandardDefaultClassifiersList(classifiers);
  };
  const handleStandardAddedClassifiersListChange = (
    classifiersIds: number[],
  ) => {
    setStandardAddedClassifiersIdsList(classifiersIds);
  };

  const addedClassifiersData: ClassifierDataType[] = useMemo(
    () =>
      classifiersList.filter((classifier: ClassifierDataType) =>
        standardAddedClassifiersIdsList.includes(classifier.id),
      ),
    [classifiersList, standardAddedClassifiersIdsList],
  );
  const availableClassifiersOptionsList: OptionType[] = useMemo(
    () =>
      classifiersOptionsList.filter((classifierOption) => {
        const { value } = classifierOption;
        const classifierId = Number(value);
        const isDefaultClassifier: boolean =
          standardDefaultClassifiersList.some(
            (classifier: ClassifierDataType) => classifier.id === classifierId,
          );
        const isAddedClassifier: boolean =
          standardAddedClassifiersIdsList.includes(classifierId);

        return !(isDefaultClassifier || isAddedClassifier);
      }),
    [
      classifiersOptionsList,
      standardDefaultClassifiersList,
      standardAddedClassifiersIdsList,
    ],
  );

  const isConfirmButtonDisabled: boolean = useMemo(
    () => !standardName,
    [standardName],
  );

  useEffect(() => {
    getRecommendedClassifiersFx();
  }, []);

  return (
    <StandardCreateModal
      standardType={standardType}
      standardName={standardName}
      standardDefaultClassifiersList={standardDefaultClassifiersList}
      standardAddedClassifiersList={addedClassifiersData}
      standardAddedClassifiersIds={standardAddedClassifiersIdsList}
      availableClassifiersOptionsList={availableClassifiersOptionsList}
      isConfirmButtonDisabled={isConfirmButtonDisabled}
      onClose={handleModalClose}
      onConfirm={handleModalConfirm}
      onStandardNameChange={handleStandardNameChange}
      onStandardTypeChange={handleStandardTypeChange}
      onStandardAddedClassifiersListChange={
        handleStandardAddedClassifiersListChange
      }
      onStandardDefaultClassifiersListChange={
        handleStandardDefaultClassifiersListChange
      }
    />
  );
}

export default StandardCreateModalContainer;
