import { ChangeEvent } from 'react';

import { MonitoringGraphProps } from '../../Components/MonitoringGraph/MonitoringGraph.interface';
import { RangeType } from '../../Components/PeriodPickerSection/PeriodPickerSection.interface';
import { OptionType } from '../../Components/Select/Select.interface';
import { StatusesList } from '../../Static/statusesList';
import { type UserConfigType } from '../../Store/types';

export type ClassifierValueDataType = {
  id: number;
  name: string;
  measureName: string;
  value: number;
  valueDifference: number;
  status: StatusesList;
};

export type ReagentQueueValueDataType = {
  [key: number]: {
    recommendedValue: number;
    recommendedValueDifference: number;
    value: number;
    valueDifference: number;
  };
};

export type ReagentValueDataType = {
  id: number;
  name: string;
  queues: ReagentQueueValueDataType;
};
export enum ReagentsInputTypes {
  Surface = 'Поверхностный',
  Potable = 'Питьевой',
  Intermediate = 'Промежуточный',
}
export interface MonitoringCommonCardDataType {
  id: number;
  datetime: string;
  author: string;
  status: StatusesList;
  name: string;
  subType: string;

  inputType: ReagentsInputTypes;

  doseId: number;
  classifiers: ClassifierValueDataType[];
  comment: string;
  reagents: ReagentValueDataType[];
  graphData: MonitoringGraphProps;
  isDisabled: boolean;
  performance: number;
}
export interface MonitoringCardDataType extends MonitoringCommonCardDataType {
  intermediates?: MonitoringCommonCardDataType[];
}

export type MonitoringCardStructureType = {
  surfaceResearch: MonitoringCardDataType;
  potableResearch: MonitoringCardDataType | null;
};

export type PageMonitoringTableProps = {
  cards: MonitoringCardStructureType[];
  openPanelsIds: number[];
  isShowMoreButtonVisible: boolean;
  onInputDelete: (id: number, researchId: number) => void;
  onShowMoreButtonClick: () => void;
  onOpenPanelsIdsListChange: (ids: number[]) => void;
  onReagentsAdd: (id: number, minDate: Date) => void;
  onReagentsSkip: (id: number, date: Date) => void;
  isLoading: boolean;
};

export type PageMonitoringPanelHeaderProps = {
  datetime: string;
  author: string;
  status: StatusesList;
  inputType: ReagentsInputTypes;
  name: string;
  subType: string;
};
export type DeleteInputData = {
  id: number;
  isModalShown: boolean;
  researchId: number;
};
export type PageMonitoringPanelBodyProps = {
  cardData: MonitoringCardDataType;
  isToBeFilled: boolean;
  onReagentsAdd: (id: number, minDate: Date) => void;
  onReagentsSkip: (id: number, date: Date) => void;
  inputType: ReagentsInputTypes;
};
export type PageMonitoringDeleteModalProps = {
  id: number;
  onClose: () => void;
  onConfirm: () => void;
};

export type PageMonitoringHeaderProps = {
  onExportButtonClick: () => void;
  onShowAllButtonClick: () => void;
  onHideAllButtonClick: () => void;
  onChangeQueue: (queue: number) => void;
  activeQueue: number;
  queueCount: number;
  queueConfig: UserConfigType;
};

export type PageMonitoringFiltersProps = {
  dateRange: RangeType;
  status: OptionType;
  periodType: OptionType;
  subTypes: string[];
  onPeriodTypeChange: (option: OptionType) => void;
  onStatusChange: (option: OptionType) => void;
  onDateRangeChange: (dateRange: RangeType) => void;
  onChangeSettings: (subTypes: string[]) => void;
};

export type PageMonitoringPanelBodyClassifiersProps = {
  datetime: string;
  classifiers: ClassifierValueDataType[];
  graphData: MonitoringGraphProps;
  performance: number;
};

export type PageMonitoringPanelBodyReagentsProps = {
  comment: string;
  reagents: ReagentValueDataType[];
  isToBeFilled: boolean;
  onReagentsAdd: () => void;
  onReagentsSkip: () => void;
  inputType: ReagentsInputTypes;
};

export type PageMonitoringReagentsMessageProps = {
  onReagentsAdd: () => void;
  onReagentsSkip: () => void;
  inputType: ReagentsInputTypes;
};

export type ModalQueueDataType = {
  [key: number]: {
    value: number | string;
    recommendedValue: number | string;
    isRecommended: boolean;
    minValue: number;
    maxValue: number;
    isErrorShown: boolean;
  };
};

export type ModalReagentDataType = {
  id: number;
  name: string;
  queues: ModalQueueDataType;
};

export type PageMonitoringReagentsModalProps = {
  date: Date;
  minDate: Date;
  performance: number | string;
  reagents: ModalReagentDataType[];
  comment: string;
  isRecommendedValueChanged: boolean;
  isConfirmButtonDisabled: boolean;
  isWarningShown: boolean;
  isWarningCheckboxChecked: boolean;
  queueNumber: number;
  onWarningCheckboxValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCommentValueChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onReagentsDataChange: (reagents: ModalReagentDataType[]) => void;
  onPerformanceValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDateChange: (date: Date) => void;
  onClose: () => void;
  onConfirm: () => void;
};

export type PageMonitoringModalReagentsFormProps = {
  reagents: ModalReagentDataType[];
  isWarningShown: boolean;
  onReagentsDataChange: (reagents: ModalReagentDataType[]) => void;
};

export interface MonitoringCardByIdType {
  id: number;
  classifiers: ClassifierValueDataType[];
  comment: string;
  reagents: ReagentValueDataType[];
  doseId: number;
  performance: number;

  graphData: MonitoringGraphProps;
}
