import { IconsNames } from '../../../Components/Icon/constants';
import IconButton from '../../../Components/IconButton';
import PeriodPickerSection from '../../../Components/PeriodPickerSection';
import Select from '../../../Components/Select';
import { statusesOptionsList } from '../constants';
import { PageHeaderProps } from '../PageLaboratoryTests.interface';

import styles from '../PageLaboratoryTests.module.scss';

function PageHeader(props: PageHeaderProps) {
  const {
    waterType,
    standard,
    dateRange,
    status,
    waterTypesOptionsList,
    standardsOptionsList,
    periodType,
    onPeriodTypeChange,
    onWaterTypeChange,
    onStandardChange,
    onStatusChange,
    onDateRangeChange,
    onClearFilters,
  } = props;

  return (
    <div className={styles.header}>
      <div className={styles.right_header_content}>
        <PeriodPickerSection
          range={dateRange}
          periodType={periodType}
          onPeriodTypeChange={onPeriodTypeChange}
          onDateRangeChange={onDateRangeChange}
          className={styles.period_picker}
        />
      </div>
      <div className={styles.left_header_content}>
        <IconButton
          onClick={onClearFilters}
          iconName={IconsNames.ClearFilters}
        />
        <Select
          options={waterTypesOptionsList}
          value={waterType}
          onChange={onWaterTypeChange}
          className={styles.water_type_select}
          placeholder="Выберите тип отчета"
        />
        <Select
          options={standardsOptionsList}
          value={standard}
          onChange={onStandardChange}
          className={styles.standard_select}
          isSearchable
          placeholder="Выберите стандарт"
        />
        <Select
          options={statusesOptionsList}
          value={status}
          onChange={onStatusChange}
          className={styles.status_select}
          placeholder="Выберите статус отчета"
        />
      </div>
    </div>
  );
}

export default PageHeader;
