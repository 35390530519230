import { ChangeEvent } from 'react';

import { RangeType } from '../../Components/PeriodPickerSection/PeriodPickerSection.interface';
import {
  CommonOptionsType,
  OptionType,
} from '../../Components/Select/Select.interface';
import { ClassifierStatusType, StatusesList } from '../../Static/statusesList';

export enum WaterTypes {
  Potable = 'POTABLE',
  Surface = 'SURFACE',
  Intermediate = 'INTERMEDIATE',
  All = 'both',
}
export enum IntermediateWaterSubTypes {
  Filters = 'FILTERS',
  Sump = 'SUMP',
  PostPotable = 'POST_POTABLE',
}

export const intermediateWaterSubTypesList: string[] = [
  IntermediateWaterSubTypes.Filters,
  IntermediateWaterSubTypes.Sump,
  IntermediateWaterSubTypes.PostPotable,
];

export const WaterTypesLabels = new Map([
  [WaterTypes.Surface, 'Поверхностный'],
  [WaterTypes.Potable, 'Питьевой'],
  [WaterTypes.Intermediate, 'Промежуточный'],
  [WaterTypes.All, 'Все отчеты'],
]);

export const IntermediateWaterSubTypesLabel = new Map([
  [IntermediateWaterSubTypes.Filters, 'Скорые фильтры (сборный водовод)'],
  [IntermediateWaterSubTypes.Sump, 'Горизонтальные отстойники (сборный канал)'],
  [IntermediateWaterSubTypes.PostPotable, 'Город'],
]);

export type PageHeaderProps = {
  waterType: OptionType;
  standard: OptionType;
  status: OptionType;
  dateRange: RangeType;
  waterTypesOptionsList: CommonOptionsType[];
  standardsOptionsList: OptionType[];
  periodType: OptionType;
  onPeriodTypeChange: (option: OptionType) => void;
  onWaterTypeChange: (option: OptionType) => void;
  onStandardChange: (option: OptionType) => void;
  onStatusChange: (option: OptionType) => void;
  onDateRangeChange: (dateRange: RangeType) => void;
  onClearFilters: () => void;
};

export type PageTableProps = {
  tableColumnsList: string[];
  reportsList: ReportsDataType[];
  isShowMoreButtonVisible: boolean;
  onShowMoreButtonClick: () => void;
  onResearchDelete: (id: number) => void;
  onResearchEdit: (id: number) => void;
  onTestCreate: () => void;
};

export type PropertyType = {
  id: number;
  name: string;
  value: number;
  previousValue: number;
  valueDifference: number;
  minControlValue: number;
  maxControlValue: number;
  minStandardValue: number;
  maxStandardValue: number;
  isAdditionalProperty: boolean;
  measureName: string;
  status: ClassifierStatusType;
};

export type ReportsDataType = {
  id: number;
  name: string;
  date: string;
  createdAt: string;
  waterType: string;
  waterTypeValue: WaterTypes;
  standard: string;
  standardId: number;
  author: string;
  status: StatusesList;
  properties: PropertyType[];
  subType: string | null;
};

export type ReportsClassifiersDataType = {
  id: number;
  properties: PropertyType[];
};

export type PanelHeaderProps = {
  columnsList: string[];
  tableColumnsList: string[];
};
export type PanelTableHeaderProps = {
  onTestCreate: () => void;
  onExport: () => void;

  queueCount: number;
  activeQueue: number;
  onChangeQueue: (queue: number) => void;
};
export type DeleteResearchData = {
  id: number;
  isModalShown: boolean;
};
export type ResearchDeleteModalProps = {
  id: number;
  onConfirm: () => void;
  onClose: () => void;
};

export type PanelBodyProps = {
  propertiesList: PropertyType[];
};

export type ReportModalProps = {
  queueNumber: number;
  isInEditMode: boolean;
  reportDate: Date;
  reportMinDate?: Date;
  reportMaxDate?: Date;
  researchType: OptionType;
  standardsOptionsList: OptionType[];
  standard: OptionType;
  properties: ReportPropertiesType;
  availablePropertiesOptionsList: OptionType[];
  selectedAdditionalProperties: ReportPropertiesType;
  isSaveAvailable: boolean;
  isWarningShown: boolean;
  isWarningCheckboxChecked: boolean;
  onWarningCheckboxValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onReportDateChange: (date: Date) => void;
  onReportResearchTypeChange: (researchType: OptionType) => void;
  onModalClose: () => void;
  onStandardChange: (standard: OptionType) => void;
  onPropertiesValuesChange: (newPropertiesValue: ReportPropertiesType) => void;
  onSelectedAdditionalPropertiesChange: (
    properties: ReportPropertiesType,
  ) => void;
  onReportSave: () => void;
};

export type ReportPropertyType = {
  id: number;
  name: string;
  units: string;
  value: string;
  minValue: number;
  maxValue: number;
  isErrorShown?: boolean;
};

export type ReportPropertiesType = {
  [key: string]: ReportPropertyType;
};

export type ReportPropertiesTableProps = {
  properties: ReportPropertiesType;
  isWarningShown: boolean;
  onChange: (newPropertiesValue: ReportPropertiesType) => void;
};

export type AdditionalPropertiesSectionProps = {
  availablePropertiesOptionsList: OptionType[];
  selectedProperties: ReportPropertiesType;
  onPropertiesChange: (newPropertiesValue: ReportPropertiesType) => void;
};
