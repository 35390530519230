import React from 'react';

import { SwitcherButtonType } from '../../Components/ButtonsSwitcher/ButtonsSwitcher.interface';
import { WaterOptionLabels } from '../../Components/OptionLabels';
import {
  CommonOptionsType,
  OptionType,
} from '../../Components/Select/Select.interface';
import {
  IntermediateWaterSubTypes,
  IntermediateWaterSubTypesLabel,
  WaterTypes,
  WaterTypesLabels,
} from '../PageLaboratoryTests/PageLaboratoryTests.interface';

export const standardTableColumns: string[] = [
  'Показатель',
  'Нижняя граница допуска',
  'Нижняя контрольная граница',
  'Верхняя контрольная граница',
  'Верхняя граница допуска',
];
export const standardTableColumnsShort: string[] = [
  'Показатель',
  'Нижняя граница допуска',
  'Нижняя контр. граница',
  'Верхняя контр. граница',
  'Верхняя граница допуска',
];
export const standardTableColumnsShortest: string[] = [
  'Показатель',
  'Нижняя гр. допуска',
  'Нижняя контр. гр.',
  'Верхняя контр. гр.',
  'Верхняя гр. допуска',
];

export enum ClassifierTypes {
  ExistingClassifier = 'existingClassifier',
  NewClassifier = 'newClassifier',
}

export const addClassifierButtonsSwitcherOptions: SwitcherButtonType[] = [
  {
    children: 'Выбрать из списка',
    value: ClassifierTypes.ExistingClassifier,
  },
  {
    children: 'Создать новый',
    value: ClassifierTypes.NewClassifier,
  },
];
export const createStandardButtonsSwitcherOptions: SwitcherButtonType[] = [
  {
    children: WaterTypesLabels.get(WaterTypes.Surface),
    value: WaterTypes.Surface,
  },
  {
    children: WaterTypesLabels.get(WaterTypes.Potable),
    value: WaterTypes.Potable,
  },
  {
    children: WaterTypesLabels.get(WaterTypes.Intermediate),
    value: WaterTypes.Intermediate,
  },
];
export const createIntermediateStandardOptions: SwitcherButtonType[] = [
  {
    children: 'Поверхностный',
    value: WaterTypes.Surface,
  },
  {
    children: 'Питьевой',
    value: WaterTypes.Potable,
  },
  {
    children: 'Промежуточный',
    value: WaterTypes.Intermediate,
  },
];

export const modalWaterTypesOptionsList: CommonOptionsType[] = [
  {
    value: WaterTypes.Potable,
    label: <WaterOptionLabels waterType={WaterTypes.Potable} />,
  },
  {
    value: WaterTypes.Surface,
    label: <WaterOptionLabels waterType={WaterTypes.Surface} />,
  },
  {
    label: <WaterOptionLabels waterType={WaterTypes.Intermediate} />,
    options: [
      {
        value: IntermediateWaterSubTypes.Filters,
        label: IntermediateWaterSubTypesLabel.get(
          IntermediateWaterSubTypes.Filters,
        ),
      },
      {
        value: IntermediateWaterSubTypes.Sump,
        label: IntermediateWaterSubTypesLabel.get(
          IntermediateWaterSubTypes.Sump,
        ),
      },
      {
        value: IntermediateWaterSubTypes.PostPotable,
        label: IntermediateWaterSubTypesLabel.get(
          IntermediateWaterSubTypes.PostPotable,
        ),
      },
    ],
  },
];

export const defaultModalWaterTypeOption: OptionType = {
  value: WaterTypes.Potable,
  label: <WaterOptionLabels waterType={WaterTypes.Potable} />,
};
